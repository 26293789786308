import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import appToast from "../../../components/app-toast";
import Recaptcha from "../../../components/recaptcha";
import { useAuthentication } from "../../../hooks/useAuthetication";
import { DataLoadState } from "../../../model/types";
import { selectAuth } from "../../../store/modules/authentication";
import configuration from "../../../utils/configuration";

interface LoginState {
    formData: { username?: string; password?: string; rememberMe?: boolean };
    errorText?: string;
}

export function useLogin() {
    const [loginState, setLoginState] = React.useState<LoginState>({
        formData: { username: "", password: "", rememberMe: false },
    });
    const { handleLogin } = useAuthentication();
    const { loading, errors } = useSelector(selectAuth);
    const [showPass, setShowPass] = React.useState(false);
    const recaptchaRef = React.useRef<Recaptcha | null>(null);
    const [recaptchaToken, setRecaptchaToken] = React.useState<string | undefined>();
    const [isCaptchaLoading, setIsCaptchaLoading] = React.useState(false);

    useEffect(() => {
        setLoginState((s) => ({ ...s, errorText: errors ? errors[0] : undefined }));
    }, [errors]);

    const onCaptchaReady = () => {
        recaptchaRef.current?.execute();
        setIsCaptchaLoading(true);
    };

    const onTokenGenerated = (token: string) => {
        setRecaptchaToken(token);
        setIsCaptchaLoading(false);
    };

    const validate = () => {
        let flag = true;
        flag = flag && !!loginState.formData.username;
        flag = flag && !!loginState.formData.password;
        // flag = flag && !!loginState.formData.rememberMe;
        return flag;
    };
    const onFormSubmit = (evt: React.FormEvent) => {
        evt.preventDefault();

        if (loading) {
            return;
        }

        if (validate()) {
            handleLogin({
                username: loginState?.formData?.username ?? "",
                password: loginState?.formData?.password ?? "",
                rememberMe: loginState?.formData?.rememberMe ?? false,
            });
        } else {
            appToast.error("Some fields may be empty");
        }
    };
    const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLoginState({
            ...loginState,
            formData: { ...loginState.formData, [evt.target.name]: evt.target.value },
        });
    };
    const handleCheckChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setLoginState({
            ...loginState,
            formData: {
                ...loginState.formData,
                [evt.target.name]: evt.target.checked,
            },
        });
    };
    const getForgotPasswordLink = () => {
        const url = new URL(`${configuration.getValue("server")}/BusinessWeb/ForgotPassword.aspx`);
        if (loginState?.formData?.username) url.searchParams.append("user", loginState.formData.username);
        return url.toString();
    };

    const onFeedbackSubmissionComplete = (status: DataLoadState) => {
        onCaptchaReady();
    };

    return {
        onFormSubmit,
        handleChange,
        handleCheckChange,
        loginState,
        getForgotPasswordLink,
        recaptchaRef,
        showPass,
        recaptchaToken,
        isCaptchaLoading,
        onCaptchaReady,
        onTokenGenerated,
        onFeedbackSubmissionComplete,
        setShowPass
    };
}
