import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { buildGenericInitialState, handleError, selectGenericsOfActionType, updateStore } from "../../utils/store-utils";
import { GenericState, Meta } from "../types";
import { MinimalSubscriberData } from "../../model/minimal-subscriber-data";
import { AppStore } from '..';
import { post, get as httpGet } from "../../utils/http-utils";
import { HttpStatusCode } from "../../model/http-status-code";
import { InitSubscriberSearchWorker } from "../../workers";
import { SubscriberInfoBundleV2 } from "../../model/subscriber-info-bundle";
import { SUCCESS } from "../../constant/redux-constants";
import { OrganisationData } from "../../model/organisation-data";
import appToast from "../../components/app-toast";
import configuration from "../../utils/configuration";
import { isLegalChat, isServicesPro } from "../../utils/environment-utils";
import logger from "../../utils/log-utils";

interface InitialState extends GenericState {
    subscriberInfo?: SubscriberInfoBundleV2;
    allSubscribers?: MinimalSubscriberData[];
    organisation?: OrganisationData;
}

const initialState: InitialState = {
    ...buildGenericInitialState([]),
    loading: {
        "subscriber/setCurrentSubscriberInfo": true
    }
};

// Slices
const { actions, reducer } = createSlice({
    name: "subscriber",
    initialState,
    reducers: {
        setCurrentSubscriberInfo: (state, action: PayloadAction<SubscriberInfoBundleV2>) => {
            return updateStore<typeof state>(state, action, {
                subscriberInfo: action.payload
            });
        },
        setOrganisation: (state, action: PayloadAction<OrganisationData>) => {
            return updateStore<typeof state>(state, action, {
                organisation: action.payload
            });
        },
        emptyOrganisation: (state, action: PayloadAction<void>) => {
            const newState = { ...state };
            delete newState.organisation;
            return updateStore<typeof state>(newState, action);
        },
        emptyCurrentSubscriberInfo: (state, action: PayloadAction<void>) => {
            const newState = { ...state };
            delete newState.subscriberInfo;
            return updateStore<typeof state>(newState, action);
        },
        setSubscriberList: {
            reducer: (state, action: PayloadAction<MinimalSubscriberData[] | null, string, Meta>) => {
                return updateStore<typeof state>(state, action, {
                    allSubscribers: action.payload
                });
            },
            prepare(payload: MinimalSubscriberData[] | null, meta: Meta) {
                return { payload, meta };
            }
        }
    }
});

export default { reducer };
export const { setCurrentSubscriberInfo, setOrganisation, emptyOrganisation, emptyCurrentSubscriberInfo } = actions;

// Action Creators
export const fetchAllSubscribers = (performanceManager?: string, subscriberId?: string) => async (dispatch: Dispatch) => {
    const type = actions.setSubscriberList.type;
    try {
        const response = await httpGet(dispatch, type, `subscriber/getminimalsubscriberlist${subscriberId ? `?subID=${subscriberId}` : ''}`, false);

        if (response.status === HttpStatusCode.OK) {
            InitSubscriberSearchWorker(response.data, performanceManager);
            return dispatch(actions.setSubscriberList(response.data, { status: SUCCESS }));
        }
    } catch (error: any) {
        if (error.response?.status === HttpStatusCode.Unauthorized || error.response?.status === HttpStatusCode.Forbidden) {
            // TODO: Handle
        }
        await handleError(dispatch, error, type);
    }
    return Promise.resolve();
};

export const getOrganisation = (subscriberId: string) => async (dispatch: Dispatch) => {
    const type = actions.setOrganisation.type;
    try {
        await httpGet(dispatch, type, `SubscriberOrganisation/${isServicesPro || isLegalChat ? "GetOrganizationDataForServicesPro" : "GetOrganisationData"}?subscriberID=${subscriberId}`, {}, true);
    } catch (err: any) {
        await handleError(dispatch, err, type);
    }
};

export const setOrganisationData = (data: OrganisationData) => async (dispatch: Dispatch) => {
    const type = actions.setOrganisation.type;
    try {
        const response = await post(dispatch, type, `SubscriberOrganisation/${isServicesPro || isLegalChat ? "SetOrganisationDataForServicesPro" : "SetOrganisationData"}`, data, false);
        if (response.status === HttpStatusCode.OK) {
            dispatch({
                type,
                payload: data,
                meta: { status: SUCCESS },
            });
        }
        appToast.success("Submitted successfully");
    } catch (err: any) {
        await handleError(dispatch, err, type);
    }
};

export const loadSubscriberInfo = (subscriberId: string) => async (dispatch: Dispatch) => {
    const type = actions.setCurrentSubscriberInfo.type;
    try {
        await httpGet(dispatch, type, `subscriber/GetSubscriberInfo`, { id: subscriberId }, true);
    } catch (err: any) {
        await handleError(dispatch, err, type);
    }
};
// Selectors
export const selectAllSubscribers = ({ subscriber }: AppStore) => ({ allSubscribers: subscriber.allSubscribers, ...selectGenericsOfActionType<InitialState>(subscriber, actions.setSubscriberList.type) });
export const selectSubscriberInfo = ({ subscriber }: AppStore) => ({ currentSubscriber: subscriber.subscriberInfo, ...selectGenericsOfActionType<InitialState>(subscriber, actions.setCurrentSubscriberInfo.type) });
export const selectOrganisation = (state: AppStore) => ({ organization: state.subscriber.organisation, ...selectGenericsOfActionType<InitialState>(state.subscriber, actions.setOrganisation.type) });
