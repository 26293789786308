import * as React from "react";
import { useSelector } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
} from "react-router-dom";
import { RouteConfig } from "../../model/route-config";
import { AuthedRoutes } from "../../router/route-constants";
import { selectAuth } from "../../store/modules/authentication";

const NonAuthRoute: React.FC<RouteConfig> = ({
  render,
  component: Component,
  ...others
}) => {
    const { isAuthed } = useSelector(selectAuth);

    const getRenderer = (props: RouteComponentProps, routes?: RouteConfig[]) => {
        const withRoutesProps = { ...props, routes };

        if (render) return render(withRoutesProps);
        if (Component) return <Component {...withRoutesProps} />;
        return undefined;
    };

    return (
        <Route
            {...others}
            render={(props) => {
                return isAuthed ? (
                    <Redirect to={AuthedRoutes.home} />
                ) : (
                    getRenderer(props, others.routes)
                );
            }}
        />
    );
};

export default NonAuthRoute;
