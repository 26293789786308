import React from "react";
import { TableCell, TableSortLabel } from "@material-ui/core";
// import { BlueResponsiveTable } from "../../../components/blue-responsive-table";
// import logger from "../../../utils/log-utils";
import { ResponsiveTable } from "../../../components/responsive-table";
import { HeaderComponentProps, HeaderData } from "../../../components/responsive-table/responsive-table";

// export type CellAlignment = "left" | "center" | "right";
// export type TableSizes = "small" | "medium";
// export type Order = 'asc' | 'desc';

interface PMData {
    id: string;
    name: string;
    type: string;
    from: string;
    to: string;
}

interface PMTableProps {
    pmData?: PMData[];
}

const PerformanceManagerTable: React.FC<PMTableProps> = ({ pmData }) => {
    // logger.log(`table props pmdata: ${JSON.stringify(pmData)}`);
    return <ResponsiveTable
        components={{
            Header: HeaderItem,
            BodyComponent: BodyRowItem
        }}
        data={{
            headerData: headCells,
            values: pmData ?? []// pmRows
        }}
        className='b_table_full'
        innerProps={{}}
        rowCount={5}
        isSelectable={false}
        size="small"
        orderBy="name"
    />;
};

type HeadCell = HeaderData<PMData>;

type HeaderItemProps = HeaderComponentProps<PMData>;

const headCells: HeadCell[] = [
    { id: 'name', align: "left", disablePadding: false, label: 'Performance Manager', isSortable: false },
    { id: 'type', align: "left", disablePadding: false, label: 'Type', isSortable: false },
    { id: 'from', align: "left", disablePadding: true, label: 'Valid From', isSortable: false },
    { id: 'to', align: "left", disablePadding: true, label: 'Valid To', isSortable: false },
];

const HeaderItem: React.FC<HeaderItemProps> = (props) => {
    const { data: headCell, orderBy, order, onSort } = props;

    return <TableCell
        key={headCell.id}
        align={headCell.align}
        sortDirection={orderBy === headCell.id ? order : false}
    >
        {
            (!headCell.isSortable) ? <span>{headCell.label}</span> : <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={onSort ? onSort(headCell.id) : undefined}
            >
                {headCell.label}
                {orderBy === headCell.id ? (
                    <span className="visually-hidden">
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                ) : null}
            </TableSortLabel>
        }
    </TableCell>;
};

interface BodyRowItemProps {
    data: PMData;
}

const BodyRowItem: React.FC<BodyRowItemProps> = (props) => {
    const { data: row } = props;

    return <>
        <TableCell
            component="td"
            id={row.id}
            scope="row"
        >
            {row.name}
        </TableCell>

        <TableCell align="left">{row.type}</TableCell>
        <TableCell align="left">{row.from}</TableCell>
        <TableCell align="left">{row.to}</TableCell>
    </>;
};

export default PerformanceManagerTable;
