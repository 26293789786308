import { makeStyles, Popover, Tooltip, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import theme from '../../../theme';
import { AppointmentDetails } from '../models/appointment-details';

interface ApptPopProps {
    dayComponent: ReactNode;
    apptInfo: AppointmentDetails[];
    date: string;
}

const ApptPopover: React.FC<ApptPopProps> = ({ dayComponent, apptInfo, date }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const useStyles = makeStyles({
        popover: {
            // pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(1),
            color: theme.palette.primary.dark,
        },
        dayWithDotContainer: {
            position: 'relative'
        },
        dayWithDot: {
            position: 'absolute',
            height: 0,
            width: 0,
            border: '2px solid',
            borderRadius: 8,
            borderColor: theme.palette.primary.main,
            right: '50%',
            transform: 'translateX(1px)',
            top: '80%'
        }
    });
    const classes = useStyles();

    return (
        <>
            <Tooltip title='Click here for details'>
                <Typography
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    // onMouseEnter={handlePopoverOpen}
                    // onMouseLeave={handlePopoverClose}
                    onClick={handlePopoverOpen}
                >
                    <div className={classes.dayWithDotContainer}>
                        {dayComponent}
                        <div className={classes.dayWithDot} />
                    </div>
                </Typography>
            </Tooltip>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <p>
                    <div style={{ fontWeight: 'bolder', padding: '4px' }}>
                        {date}
                    </div>
                    {apptInfo?.map((info, i) => (
                        <div className='grid-container' key={i}>
                            <div style={{ padding: '6px 16px' }}>
                                <span style={{ fontWeight: 'bold' }}>{info.AppointmentTime?.slice(-12).slice(0, 5)}{' '}</span>
                                {/* <span style={{ fontWeight: 500, paddingLeft: '10px' }}>{info.FirstName} {info.LastName} ({info.EmailId}, {info.PhoneNumber})</span> */}
                            </div>
                            <div className='flex-column space-between t-m-1 r-m-1 l-m-1 b-m-3' style={{ fontWeight: 600, fontSize: '0.95rem' }}>
                                <span className='flex-row flex-center'>
                                    {info.FirstName} {info.LastName} {' '}
                                    <Tooltip
                                        title={
                                            <div style={{ fontSize: '0.8rem' }}>
                                                <div className='t-m-2'>Email: {info.EmailId}</div>
                                                <div className='t-m-2 b-m-2'>Phone: {info.PhoneNumber}</div>
                                            </div>
                                        }
                                    >
                                        <InfoIcon fontSize='small' style={{ marginLeft: '4px', padding: '2px' }} htmlColor="var(--muted-text-color2)" />
                                    </Tooltip>
                                </span>
                                <span className='text-muted' style={{ fontWeight: 500, fontSize: '0.8rem' }}>{info.Message}</span>
                                <span style={{ fontWeight: 500, fontSize: '0.7rem' }}>{info.Address}</span>
                                <span className='flex-row flex-wrap'>{info.Services?.map((service, i) => (

                                    <Chip
                                        variant="outlined"
                                        size="small"
                                        avatar={<Avatar>S</Avatar>}
                                        label={service}
                                        key={i}
                                        style={{ margin: '2px 0', fontSize: '0.75rem' }}
                                    />
                                ))}</span>
                            </div>
                        </div>
                    ))}
                </p>
            </Popover>
        </>
    );
};

export default ApptPopover;
