import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { ApiData } from "../../../model/xhrCallState";
import httpService from "../../../service/http-service";
import { selectSubscriberInfo } from "../../../store/modules/subscriber";
import { DoughnutData, WebsiteInfo } from "../../../model/dashboard-chart-hc";
import { LeadData } from "../../../model/dashboard-table";
import { selectUser } from "../../../store/modules/authentication";
import { LeadInfoV2, _LeadType } from "../../../model/lead-info";
import logger from "../../../utils/log-utils";
import theme from "../../../theme";
import { cc24AdminRoles } from "../../../model/roles-and-rights";

type InitialState = {

    leadByDepartment: ApiData<DoughnutData[]>,
    leadByType: ApiData<DoughnutData[]>,
    leadInfo: ApiData<LeadData>
    totalLead: ApiData<WebsiteInfo>,
    totalChat: ApiData<WebsiteInfo>,
    totalUV: ApiData<WebsiteInfo>
};

export function useDashboardChart() {
    const { currentSubscriber } = useSelector(selectSubscriberInfo);
    const { user } = useSelector(selectUser);
    const history = useHistory();

    const initialState: InitialState = {
        leadByDepartment: new ApiData(),
        leadByType: new ApiData(),
        leadInfo: new ApiData(),
        totalLead: new ApiData(),
        totalChat: new ApiData(),
        totalUV: new ApiData()
    };
    const [state, setState] = useState<InitialState>(initialState);

    const getSubscriberIdParameterBasedOnUserRole = (object: { [x: string]: any; } = {}) => {
        return cc24AdminRoles.includes(user?.role) ? { ...object, subscriberID: currentSubscriber?.SubscriberId } : object;
    };

    useEffect(() => {
        if ((cc24AdminRoles.includes(user?.role) && currentSubscriber?.SubscriberId) || user?.role === 'DealerAdmin') {
            fetchAllLead();
            getTotalChat();
            getTotalUV();
        }
    }, [user, currentSubscriber, history.location.search]);

    const ModifyWebisteInfo = (info: any) => {
        return {
            data: info,
            status: "Completed",
        };
    };

    const getTotalChat = async () => {
        setState(state => ({ ...state, totalChat: { status: "Pending" } }));
        try {
            const response = await httpService.get<any>('SubscriberDashboard/Performance/GetTotalChats', {
                params: getSubscriberIdParameterBasedOnUserRole({ // TODO: temp Static sub id
                    NumberOfDays: 1,
                })
            });
            // logger.log(`Total Chat >> ${response.data}`);
            setState(state => ({ ...state, totalChat: { status: "Completed", data: ModifyWebisteInfo(response.data) } }));
        } catch (error) {
            logger.log(error);
            setState(state => ({ ...state, totalChat: { status: "Failed" } }));
        }
    };

    const getTotalUV = async () => {
        setState(state => ({ ...state, totalUV: { status: "Pending" } }));
        try {
            const response = await httpService.get<any>('SubscriberDashboard/Performance/GetTotalWebsiteUV', {
                params: getSubscriberIdParameterBasedOnUserRole({ // TODO: temp Static sub id
                    NumberOfDays: 1,
                })
            });
            setState(state => ({ ...state, totalUV: { status: "Completed", data: ModifyWebisteInfo(response.data) } }));
        } catch (error) {
            logger.log(error);
            setState(state => ({ ...state, totalUV: { status: "Failed" } }));
        }
    };

    function getTotalLeadCount(info: LeadInfoV2[]) {
        if (info) {
            setState(state => ({ ...state, totalLead: { status: "Completed", data: ModifyWebisteInfo(info.length) } }));
        }
    }

    function getLeadsByDepartment(info: LeadInfoV2[]) {
        const data: DoughnutData[] = [];
        const color = [theme.palette.primary.main, theme.palette.error.main, theme.palette.secondary.main];
        let i = 0;
        try {
            if (info) {
                // let k: string;
                // for (k in info) {

                // }
                info.forEach((item, k) => {
                    const a = {} as DoughnutData;
                    a.name = info[k].Department.charAt(0).toUpperCase() + info[k].Department.slice(1);
                    a.y = 1;

                    // let t = data.hasOwnProperty(a.name);
                    // if (a.name in data) {
                    let flag: boolean = false;

                    data.forEach((j) => {
                        if (j.name === a.name) {
                            a.color = j.color;
                            j.y++;
                            flag = true;
                        }
                    });
                    if (!flag) {
                        a.color = color[i];
                        data.push(a);
                        i++;
                    }
                });
            }
            setState(state => ({ ...state, leadByDepartment: { status: "Completed", data } }));
        } catch (error) {
            logger.log(error);
        }
    }

    function getLeadsByType(info: LeadInfoV2[]) {
        const data: DoughnutData[] = [];
        const color = [theme.palette.primary.main, theme.palette.error.main, theme.palette.secondary.main];

        let i = 0;
        try {
            if (info) {
                // let k: string;
                // for (k in info) {

                // }
                info.forEach((item, k) => {
                    const a = {} as DoughnutData;
                    a.name = GetLeadType(info[k].LeadType);
                    a.y = 1;

                    let flag: boolean = false;
                    data.forEach(j => {
                        if (j.name === a.name) {
                            a.color = j.color;
                            j.y++;
                            flag = true;
                        }
                    });
                    if (!flag) {
                        a.color = color[i];
                        data.push(a);
                        i++;
                    }
                });
            }
            setState(state => ({ ...state, leadByType: { status: "Completed", data } }));
        } catch (error) {
            logger.log(error);
        }
        // return data;
    }

    function GetLeadType(data: _LeadType) {
        switch (data) {
            case _LeadType.Elead:
                return "Email";
            case _LeadType.Plead:
                return "Phone";
            case _LeadType.VIPlead:
                return "VIP";
            case _LeadType.Query:
                return "Query";
            default:
                return "";
        }
    }

    const fetchAllLead = async () => {
        setState(state => ({ ...state, leadInfo: { status: "Pending" } }));

        const endDate = dayjs();
        const startDate = dayjs().subtract(24, "hour");

        try {
            const response = await httpService.get<LeadInfoV2[]>('SubscriberDashboard/Leads/GetAllLeads', {
                params: getSubscriberIdParameterBasedOnUserRole({
                    StartDate: startDate.toJSON(),
                    EndDate: endDate.toJSON()
                })
            });
            // logger.log(response.data);
            // setState(state => ({ ...state, leadInfo: { status: "Completed", data: ModifyLeadObj(response.data) } }));
            setState(state => ({ ...state, leadInfo: { status: "Completed" } }));
            if (Array.isArray(response.data)) {
                ModifyLeadObj(response.data);
            }
        } catch (error) {
            logger.log(error);
            setState(state => ({ ...state, leadInfo: { status: "Failed" } }));
        }
    };

    const ModifyLeadObj = (info: LeadInfoV2[]) => {
        getTotalLeadCount(info);
        getLeadsByDepartment(info);
        getLeadsByType(info);
        // getLeadDetails(info);
    };

    const refreshLeadData = () => {
        fetchAllLead();
        getTotalChat();
        getTotalUV();
    };

    return {
        totalChat: state.totalChat,
        totalLead: state.totalLead,
        totalUV: state.totalUV,
        leadByDepartment: state.leadByDepartment,
        leadByType: state.leadByType,
        refreshLeadData
    };
}
