import { TableCell, TableSortLabel, Tooltip } from '@material-ui/core';
import React from 'react';
// import CreateIcon from '@material-ui/icons/Create';
// import { Delete } from '@material-ui/icons';
// import PageviewIcon from '@material-ui/icons/Pageview';
import { ResponsiveTable } from '../../../responsive-table';
import { AgreementCompactData } from '../../../../model/agreement-compact-data';
import { RowEventType } from '../models/types';
import { UIButton } from '../../../form';
import ChatleadIcon from '../../../chatlead-icon';
import { HeaderComponentProps, HeaderData } from '../../../responsive-table/responsive-table';

type HeadCell = HeaderData<AgreementCompactData>;

const headCells: HeadCell[] = [
    { id: 'title', align: 'left', disablePadding: true, label: "Title", isSortable: true },
    { id: 'from', align: "left", disablePadding: true, label: "From", isSortable: true },
    { id: 'to', align: "center", disablePadding: true, label: "To", isSortable: true },
    { id: 'comment', align: "center", disablePadding: true, label: 'Comment', isSortable: true },
    { id: 'actions', align: "center", disablePadding: true, label: 'Actions ', isSortable: false }
];
type HeaderItemProps = HeaderComponentProps<AgreementCompactData>;

const HeaderItem: React.FC<HeaderItemProps> = (props) => {
    const { data: headCell, orderBy, order, onSort } = props;

    return <TableCell
        key={headCell.id}
        align={headCell.align}
        sortDirection={orderBy === headCell.id ? order : false}

    >
        {
            (!headCell.isSortable) ? <span>{headCell.label}</span> : (
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={onSort ? onSort(headCell.id) : undefined}
                >
                    {headCell.label}

                </TableSortLabel>
            )
        }

    </TableCell>;
};

interface BodyRowItemProps {
    data: AgreementCompactData,
    onRowEvent: (id: string, eventType: RowEventType) => void;
}

const BodyRowItem: React.FC<BodyRowItemProps> = (props) => {
    const { data: row, onRowEvent } = props;
    return (
        <>
            <TableCell id={row.id} align='left'>{row.title}</TableCell>
            <TableCell align='left'>{row.from}</TableCell>
            <TableCell align='center'>{row.to}</TableCell>
            <TableCell align='center'>{row.comment}</TableCell>
            <TableCell>
                <div className='flex flex-row flex-center space-between '>
                    <Tooltip title="View">
                        <UIButton variant="icon" size='small' onClick={() => onRowEvent(row.id, "view")}>
                            {/* <PageviewIcon /> */}
                            <ChatleadIcon icon='view' size={20} />
                        </UIButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <UIButton variant="icon" size='small' onClick={() => onRowEvent(row.id, "edit")}>
                            {/* <CreateIcon /> */}
                            <ChatleadIcon icon='edit' size={20} />
                        </UIButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <UIButton variant="icon" size='small' onClick={() => onRowEvent(row.id, "delete")}>
                            {/* <Delete /> */}
                            <ChatleadIcon icon='delete' size={20} color="var(--danger-red-font)" />
                        </UIButton>
                    </Tooltip>
                </div>
            </TableCell>
        </>
    );
};
interface AgreementTableProps {
    agreementTableData?: AgreementCompactData[];
    onRowEvent: (id: string, eventType: RowEventType) => void;
}
const AgreementTable: React.FC<AgreementTableProps> = (props) => {
    return (
        <ResponsiveTable
            data={{
                values: props.agreementTableData ?? [],
                headerData: headCells
            }}
            components={{
                Header: HeaderItem,
                BodyComponent: BodyRowItem

            }}
            innerProps={{
                bodyProps: { onRowEvent: props.onRowEvent }
            }}
        />
    );
};

export default AgreementTable;
