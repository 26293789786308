import { useEffect, useState } from "react";
import appToast from "../../../../components/app-toast";
import uniLoader from "../../../../components/uni-loader";
import { EMPTY_GUID } from "../../../../constant/app-constants";
import { HttpStatusCode } from "../../../../model/http-status-code";
import { ApiData } from "../../../../model/xhrCallState";
import httpService from "../../../../service/http-service";
import logger from "../../../../utils/log-utils";

export default function useGroupSettings() {
    class GroupData {
        constructor(options?: { label: any; value: any; }) {
            if (options) {
                this.label = options.label;
                this.value = options.value;
            }
        }

        label: string = '';
        value: string = '';
    }
    const [status, setStatus] = useState<ApiData<any>>();
    const [data, setData] = useState<GroupData[]>([{ label: "None", value: EMPTY_GUID }]);

    useEffect(() => {
        if (status?.status === "Pending") uniLoader.showLoader(''); else uniLoader.close();
    }, [status]);

    const getAllGroups = async () => {
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            const response = await httpService.get(`Subscriber/GetAllGroups`);

            // logger.log("getAllGroups");
            // logger.log(response.data);
            // appToast.success('Get All Groups succeeded.');
            // var data: groupData[] = new Array();
            setData(
                [
                    { label: "None", value: EMPTY_GUID },
                    ...Object.entries(response.data).map(
                        ([key, value]) => new GroupData({ label: value, value: key })
                    )
                ]
            );
            // setData(data);
            // logger.log(data);
            // logger.log("getAllGroups: completed");
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    const setAsGroupHead = async (subId: any) => {
        // logger.log("setAsGroupHead");
        // logger.log(`SetAsGroupHead: SubscriberId: ${subId}`);
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            await httpService.post<any>(`Subscriber/SetAsGroupHead?subscriberId=${subId}&isGroup=${true}`, null);
            // logger.log(response.data);
            // logger.log("setAsGroupHead: Completed");
            appToast.success('Subscriber has been set as group head');
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            appToast.error('Set group head unsuccessful');
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    const SetAsGroup = async (subId: any, groupId: any) => {
        // logger.log("SetAsGroup");
        // logger.log(`SetAsGroup: SubscriberId: ${subId}`);
        // logger.log(`SetAsGroup: grpId: ${groupId}`);
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            const response = await httpService.post<any>(`Subscriber/SetAsGroup?subscriberId=${subId}&groupId=${groupId}`, null);
            // logger.log(response.data);
            if (response.status === HttpStatusCode.OK) { appToast.success('Subscriber has been set as group member'); }
            // logger.log("SetAsGroup: Completed");
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            appToast.error('Set group member unsuccessful');
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    const unsetAsGroup = async (subId: any) => {
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            await httpService.post<any>(`Subscriber/UnSetAsGroup?subscriberId=${subId}`, null);
            // logger.log(response.data);
            // logger.log("setAsGroupHead: Completed");
            appToast.success('Subscriber has been unset as group head');
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            appToast.error('Unset as group head unsuccessful');
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    useEffect(() => {
        getAllGroups();
    }, []);

    return { status, data, setAsGroupHead, SetAsGroup, unsetAsGroup };
}
