/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { ApiData } from "../../../model/xhrCallState";
import httpService from "../../../service/http-service";
import { selectSubscriberInfo } from "../../../store/modules/subscriber";

import { LeadData, LeadInfo } from "../../../model/dashboard-table";
import { selectUser } from "../../../store/modules/authentication";
import { LeadInfoV2 } from "../../../model/lead-info";
import logger from "../../../utils/log-utils";
import { cc24AdminRoles } from "../../../model/roles-and-rights";

type InitialState = {

    leadInfo: ApiData<LeadData>

};

export function useDashboardTable() {
    const { currentSubscriber } = useSelector(selectSubscriberInfo);
    const { user } = useSelector(selectUser);
    const history = useHistory();

    const initialState: InitialState = {

        leadInfo: new ApiData(),

    };
    const [state, setState] = useState<InitialState>(initialState);

    const getSubscriberIdParameterBasedOnUserRole = (object: { [x: string]: any; } = {}) => {
        return cc24AdminRoles.includes(user?.role) ? { ...object, subscriberID: currentSubscriber?.SubscriberId } : object;
    };

    useEffect(() => {
        if ((cc24AdminRoles.includes(user?.role) && currentSubscriber?.SubscriberId) || user?.role === 'DealerAdmin') {
            fetchAllLead();
        }
    }, [user, currentSubscriber, history.location.search]);

    const fetchAllLead = async () => {
        setState(state => ({ ...state, leadInfo: { status: "Pending" } }));

        const endDate = dayjs();
        const startDate = dayjs().subtract(24, "hour");

        try {
            const response = await httpService.get<LeadInfoV2[]>('SubscriberDashboard/Leads/GetAllLeads', {
                params: getSubscriberIdParameterBasedOnUserRole({
                    StartDate: startDate.toJSON(),
                    EndDate: endDate.toJSON()
                })
            });
            // logger.log(response.data);
            // setState(state => ({ ...state, leadInfo: { status: "Completed", data: ModifyLeadObj(response.data) } }));
            setState(state => ({ ...state, leadInfo: { status: "Completed" } }));
            if (Array.isArray(response.data)) {
                ModifyLeadObj(response.data);
            }
        } catch (error) {
            logger.log(error);
            setState(state => ({ ...state, leadInfo: { status: "Failed" } }));
        }
    };

    function createLeadInfo(
        id: string,
        created: string,
        firstName: string,
        lastName: string,
        phone: string,
        email: string
    ): LeadInfo {
        return { id, created, firstName, lastName, phone, email };
    }

    const ModifyLeadObj = (info: LeadInfoV2[]) => {
        // getTotalLeadCount(info);
        // getLeadsByDepartment(info);
        // getLeadsByType(info);
        getLeadDetails(info);
    };

    function getLeadDetails(info: LeadInfoV2[]) {
        const arr: LeadInfo[] = [];
        let leadobj: LeadData = { data: [], title: "" };
        try {
            if (info) {
                // let k: string;
                // for (k in info) {

                // }
                info.forEach((item, k) => {
                    const name: string[] = info[k].VisitorName.split(" ");

                    arr.push(
                        createLeadInfo(info[k].LeadId,
                            new Date(info[k].AppointmentDate).toLocaleDateString(),
                            name[0],
                            name[1] == "NotGiven" ? " " : name[1],
                            info[k].PhoneNumber,
                            info[k].EmailId)
                    );
                });

                leadobj = {
                    data: arr,
                    title: "Last 24hr Leads",
                };
                setState(state => ({ ...state, leadInfo: { status: "Completed", data: leadobj } }));
            }
        } catch (error) {
            // TODO: needs fix
            setState(state => ({ ...state, leadInfo: { status: "Failed" } }));
            logger.log(error);
        }
    }

    return {

        leadInfo: state.leadInfo,
    };
}
