import * as React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { RouteConfig } from "../../model/route-config";
import { NonAuthRoutes } from "../../router/route-constants";
import { selectAuth } from "../../store/modules/authentication";

const ProtectedRoute: React.FunctionComponent<RouteConfig> = ({
  component: Component,
  render,
  ...others
}) => {
    const { isAuthed } = useSelector(selectAuth);

    const getRenderer = (props: RouteComponentProps, routes: RouteConfig) => {
        const withRoutesProps = { ...props, routeInfo: routes };
        if (render) return render(withRoutesProps);
        if (Component) return <Component {...withRoutesProps} />;
        return undefined;
    };

    return (
        <Route
            {...others}
            render={(props: RouteComponentProps) => (isAuthed ? (
                getRenderer(props, others)
            ) : (
                <Redirect
                    to={{
              pathname: NonAuthRoutes.login,
              state: { from: props.location },
                    }}
                />
            ))}
        />
    );
};

export default ProtectedRoute;
