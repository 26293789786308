import { useMediaQuery } from "@material-ui/core";
import { MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import { RouteConfig } from "../../../../model/route-config";
import { selectCollapseSideBar } from "../../../../store/modules/ui";
import theme from "../../../../theme";
import { constructPath } from "../../../../utils/route-utils";

export default function useSingleNavigationItem(route: RouteConfig, basePath?: string) {
    const { pathname: currentLocation } = useLocation();
    const path = constructPath(route.path, basePath);
    const [isExpanded, changeExpansion] = useState(false);
    const [isActive, changeActive] = useState(false);
    const { isSideBarCollapsed } = useSelector(selectCollapseSideBar);
    const isTab = useMediaQuery(theme.breakpoints.down("md"));
    const anchorEl = useRef<HTMLDivElement | null>(null);
    const shouldBeCollapsed = !isTab && isSideBarCollapsed;

    useEffect(() => {
        const match = matchPath(currentLocation, {
            path,
            exact: route.exact,
            sensitive: route.sensitive,
            strict: route.strict
        });
        changeExpansion(!!match && !shouldBeCollapsed);
        changeActive(() => !!match);
    }, [currentLocation, route, path]);

    const extractChildNRoutes = useCallback(() => {
        return (route.routes || []).filter((item) => !item.bypassDisplay);
    }, [route]);

    // useEffect(() => {
    //     if (!(shouldBeCollapsed)) {
    //         changeExpansion(false);
    //         anchorEl.current = null;
    //     }
    // }, [isTab, isSideBarCollapsed]);

    useEffect(() => {
        if (isSideBarCollapsed) {
            changeExpansion(false);
            anchorEl.current = null;
        }
    }, [isSideBarCollapsed]);

    const handlePopOpen = (event: MouseEvent<HTMLDivElement>) => {
        // setAnchorEl(event.currentTarget);
        anchorEl.current = event.currentTarget;
        changeExpansion(!isExpanded);
    };
    const handlePopClose = (event: MouseEvent<HTMLDivElement>) => {
        changeExpansion(!isExpanded);
        anchorEl.current = null;
    };

    const handlePopoverClose = () => {
        anchorEl.current = null;
    };

    return { extractChildNRoutes, isExpanded, isActive, changeExpansion, isSideBarCollapsed, isTab, anchorEl, handlePopOpen, handlePopClose, handlePopoverClose, shouldBeCollapsed };
}
