import * as React from "react";
import { useRouteMatch } from "react-router-dom";
import { RouteAdditionalPropsV2 } from "../../model/route-additional-propsV2";
import RouteWithSubRoutes from "./route-with-subroutes";

const ChildRouteRender: React.FC<RouteAdditionalPropsV2> = ({ routeInfo }) => {
    const { url } = useRouteMatch();
    return (
        <>{routeInfo.routes && <RouteWithSubRoutes routes={routeInfo.routes} currentPath={url} />}</>
    );
};

export default ChildRouteRender;
