import React from "react";
import ReactDOM from "react-dom";
import "./styles/_app.scss";
import { Provider } from "react-redux";
// import axios from "axios";
import { AxiosResponse } from "axios";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import initStore from "./store";
import App from "./components/app";
import { forceLogout } from "./store/modules/authentication";
import httpService from "./service/http-service";
import { initSentry } from "./serverLog";
import ErrorBoundary from "./components/error-boundary";
import uniLoader from "./components/uni-loader";

initSentry();

const store = initStore();

const { dispatch } = store;

httpService.insertResponseInterceptor(
    response => {
        return forceLogout(dispatch)(response);
    },
    (error) => {
        const response: AxiosResponse<any> = error.response;
        const reqUrl = response?.request?.responseURL || "";
        const tokenExpired = error?.response?.headers["token-expired"];
        if ((reqUrl.includes("/api/auth/validate") || reqUrl.includes("/api/auth/logout"))) {
            forceLogout(dispatch)(error);
        } else if (tokenExpired && tokenExpired === "true") {
            uniLoader.show(<div className="flex-row flex-center justify-center">
                <ErrorOutlineIcon className="r-m-1" fontSize="small" />Your Token Expired! Click to reload
            </div>, "error", undefined, false, () => {
                forceLogout(dispatch, true)(error);
            });
        } else {
            forceLogout(dispatch)(error);
        }
        return Promise.reject(error);
    }
);

ReactDOM.render(
    (
        <React.StrictMode>
            <ErrorBoundary>
                <Provider store={store}>
                    <App />
                </Provider>
            </ErrorBoundary>
        </React.StrictMode>
    ),
    document.getElementById("root")
);
