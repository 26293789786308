import React, { ReactNode } from "react";

import { toast, ToastOptions, Slide } from "react-toastify";
import CloseIcon from '@material-ui/icons/Close';
import appConfig from "../../utils/app-config-utils";
import { UIButton } from "../form";

const uniLoaderInit = () => {
    let defaultToastId: number | string = "loader-toast";
    let showing = false;
    const options: ToastOptions = {
        toastId: defaultToastId,
        containerId: appConfig.loaderToastContainerId,
        transition: Slide
    };
    toast.onChange((toast) => {
        // console.log(toast);
        if (toast.status === "removed") {
            showing = false;
        }
    });

    const show = (text: string | ReactNode, type: "success" | "error" | "warning" | "info", closeTime?: number, isClosable?: boolean, onClick?: (evt?: React.MouseEvent<Element, MouseEvent>) => void, onClose?: (evt?: React.MouseEvent<Element, MouseEvent>) => void) => {
        // showing = toast.isActive(defaultToastId);
        const extraProps = {
            closeButton: isClosable ? ({ closeToast }: { closeToast: () => void }) => (<UIButton style={{ zIndex: 99 }} variant="icon" size="small" color="inherit" onClick={(evt) => { closeToast(); if (onClose) onClose(evt); }}>
                <CloseIcon fontSize="small" />
            </UIButton>) : false,
        };
        // console.log("Showing: ", showing);
        if (!showing) {
            showing = true;
            switch (type) {
                case "success":
                    defaultToastId = toast.success(text, {
                        ...options,
                        ...extraProps,
                        autoClose: closeTime ?? false,
                        onClick,
                    });
                    break;
                case "error":
                    defaultToastId = toast.error(text, {
                        ...options,
                        ...extraProps,
                        autoClose: closeTime ?? false,
                        onClick,
                    });
                    break;
                case "info":
                    defaultToastId = toast.info(text, { ...options, ...extraProps, autoClose: closeTime ?? false, onClick, });
                    break;
                case "warning":
                    defaultToastId = toast.warning(text, { ...options, ...extraProps, autoClose: closeTime ?? false, onClick, });
                    break;
                default:
                    break;
            }
        } else {
            switch (type) {
                case "success":
                    toast.update(defaultToastId, { ...options, ...extraProps, render: text, type: toast.TYPE.SUCCESS, autoClose: closeTime ?? false, onClick, });
                    break;
                case "error":
                    toast.update(defaultToastId, { ...options, ...extraProps, render: text, type: toast.TYPE.ERROR, autoClose: closeTime ?? false, onClick, });
                    break;
                case "info":
                    toast.update(defaultToastId, { ...options, ...extraProps, render: text, type: toast.TYPE.INFO, autoClose: closeTime ?? false, onClick, });
                    break;
                case "warning":
                    toast.update(defaultToastId, { ...options, ...extraProps, render: text, type: toast.TYPE.WARNING, autoClose: closeTime ?? false, onClick });

                    break;
                default:
                    break;
            }
        }
    };

    const showLoader = (text?: string) => {
        show(<div className="flex-row flex-center justify-center">
            <div className="loader" style={{ marginRight: text ? "10px" : "0px" }} />
            {text}
        </div>, "warning");
    };

    const close = () => {
        showing = false;
        // toast.dismiss();
        toast.dismiss(options.toastId);
        // console.log("Showing Dismiss: ", showing);
    };

    return { show, showLoader, close };
};

export default uniLoaderInit;
