import { createContext, useContext, useMemo, useState, Dispatch, SetStateAction, ReactNode } from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import { MinimalSubscriberData } from "../../../model/minimal-subscriber-data";
import { selectRole, selectUser } from "../../../store/modules/authentication";
import { UserRoles } from "../../../model/types";
import { AccountUser } from "../../../model";

export interface MinimalSubscriberDataContextInfo {
    SubscriberName: string;
    SubscriberId: string;
}

type SubscriberDataType = { [hash: string]: MinimalSubscriberDataContextInfo[] };

export interface SubsciberDataContextState {
    data: SubscriberDataType;
    setData: Dispatch<SetStateAction<SubscriberDataType>>;
    user?: AccountUser;
}

export const SubsciberDataContext = createContext<SubsciberDataContextState | undefined>(undefined);
SubsciberDataContext.displayName = "SubsciberDataContext";

export function useSubsciberDataContext() {
    const context = useContext(SubsciberDataContext);
    return context;
}

interface SubsciberDataProviderProps {

}

export const SubsciberDataProvider: React.FC<SubsciberDataProviderProps> = (props) => {
    const [data, setData] = useState<SubscriberDataType>({});
    const { user } = useSelector(selectUser);
    const { children } = props;

    const value = useMemo(() => ({ data, user, setData }), [data, user]);
    return (
        <SubsciberDataContext.Provider value={value}>
            {children}
        </SubsciberDataContext.Provider>
    );
};

interface SubsciberDataConsumerProps {
    children: (value: SubsciberDataContextState) => ReactNode;
}

export const SubsciberDataConsumer: React.FC<SubsciberDataConsumerProps> = ({ children }) => {
    return (
        <SubsciberDataContext.Consumer>
            {(context) => {
                if (context === undefined) {
                    throw new Error('SubsciberDataConsumer must be used within a SubsciberDataProvider');
                }
                return children(context);
            }}
        </SubsciberDataContext.Consumer>
    );
};
