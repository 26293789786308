import axios, { CancelToken } from "axios";
import dayjs from "dayjs";
import { Dictionary, groupBy } from "lodash";
import React, { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import httpService from "../../../service/http-service";
import { selectUser } from "../../../store/modules/authentication";
import { selectSubscriberInfo } from "../../../store/modules/subscriber";
import { stripTimeFromDateWithFormat } from "../../../utils/date-timezone-utils";
import logger from "../../../utils/log-utils";
import ApptPopover from "../components/appt-popover";
import { AppointmentDetails } from "../models/appointment-details";

// interface AppointmentDetails {
//     [x: string]: {
//         [x: string]: string
//     }
// }
type groupedAppt = [string, AppointmentDetails[]][];

export function useApptCalendar() {
    const [daysWithDot, setDaysWithDot] = useState<string[]>([]);

    const [selectedDate, setSelectedDate] = useState<string>(
        dayjs().format()
    );
    const [startDate, setStartDate] = useState<string>(
        dayjs().startOf('month').format()
    );
    const [endDate, setEndDate] = useState<string>(
        dayjs().endOf('month').format()
    );
    const { currentSubscriber } = useSelector(selectSubscriberInfo);
    const { user } = useSelector(selectUser);

    // const [apptData, setApptData] = useState<AppointmentDetails[]>();
    const [apptDataByDate, setApptDataByDate] = useState<Dictionary<AppointmentDetails[]>>();
    const [datesMarked, setDatesMarked] = useState<string[]>();

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (currentSubscriber?.SubscriberId || user?.sub_id) { getAppointmentData(startDate, endDate, source.token); }
        return () => { source.cancel("Cancelled due to successive calls!"); };
    }, [currentSubscriber?.SubscriberId, user?.sub_id, startDate, endDate]);

    // const datesMarked = ['06/07/2022', '06/18/2022', '07/01/2022'];
    const apptDates = {
        "06/07/2022": {
            "1 pm": "Appointment 1",
            "6 pm": "Appointment 2"
        },
        "06/18/2022": {
            "3 pm": "Appointment 3",
        },
        "07/01/2022": {
            "2 pm": "Appointment 4",
        },
    };

    const getAppointmentData = async (start: string, end: string, cancelToken?: CancelToken) => {
        try {
            const response = await httpService.get<AppointmentDetails[]>(`SubscriberDashboard/Appointment/GetAllAppointments`, {
                params: {
                    SubscriberId: currentSubscriber?.SubscriberId || user?.sub_id,
                    StartDate: stripTimeFromDateWithFormat(start),
                    EndDate: stripTimeFromDateWithFormat(end)
                },
                cancelToken
            });
            // setApptData(response.data);
            setApptDataByDate(getGroupedApptByDate(response.data));
            setDatesMarked(response.data.map(appt => dayjs(appt.AppointmentTime.slice(0, 10)).format('MM/DD/YYYY')));
        } catch (error) {
            logger.log(error);
        }
    };

    const getGroupedApptByDate = (apptData: AppointmentDetails[]) => {
        return groupBy(apptData, appt => appt.AppointmentTime.slice(0, 10));
    };

    const onPickerViewChange = (date: dayjs.Dayjs | string) => {
        // const variables = {
        //     fromDate: dayjs(date).startOf('month').format('MM/DD/YYYY'),
        //     toDate: dayjs(date).endOf('month').format('MM/DD/YYYY')
        // };

        // return request(url, query, variables).then(response => {
        //     setDaysWithDot(response.data.map(day => moment(day).format('YYYY/MM/DD')))
        // }).catch((err) => Logger.error(err))
        const datesMarked = Object.keys(apptDates);

        setDaysWithDot(datesMarked.filter(day => dayjs(date).month() === dayjs(day).month()).map(day => dayjs(day).format('MM/DD/YYYY')));
    };

    const renderDayInPicker = (day: dayjs.Dayjs, selectedDate: dayjs.Dayjs, dayInCurrentMonth: boolean, dayComponent: ReactNode) => {
        // const datesMarked = Object.keys(apptDates);
        if (datesMarked?.includes(day.format('MM/DD/YYYY')) && dayInCurrentMonth && apptDataByDate) {
            // const str: { time: string, info: string }[] = [];
            // Object.entries(apptDates[(day.format('MM/DD/YYYY'))])?.forEach(appt => {
            //     str.push({ time: `${appt[0]}: `, info: `${appt[1]}` });
            // });
            const apptInfo = Object.entries(apptDataByDate[(day.format('YYYY-MM-DD'))])?.map(r => r[1]);
            return <ApptPopover dayComponent={dayComponent} apptInfo={apptInfo} date={day.format('MM/DD/YYYY')} />;
        }

        return dayComponent;
    };

    const handleDateChange = (date: dayjs.Dayjs | string) => {
        setSelectedDate(dayjs(date)?.format());
        // console.log();
    };

    const handleMonthChange = (date: dayjs.Dayjs | string) => {
        setStartDate(dayjs(date)?.startOf('month').format());
        setEndDate(dayjs(date)?.endOf('month').format());
    };

    return {
        handleDateChange,
        renderDayInPicker,
        onPickerViewChange,
        selectedDate,
        handleMonthChange
    };
}
