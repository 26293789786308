import { DialogActions } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { UIButton, UIRadio, UIRadioGroup, UISelect } from "../../../components/form";
import useResellerSettings from "./hooks/useResellerSettings";

export interface ResellerSettingsProps {
    handleClose: any,
    subscriberId: any
}

const ResellerSettings: React.FunctionComponent<any> = (props) => {
    const { handleClose, subscriberId, isResellerHead, resellerName } = props;

    const {
        data, setAsResellerHead, SetAsReseller, unsetAsReseller
    } = useResellerSettings();

    const [rbSelected, setrbSelected] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const [rslrSelected, setRslrSelected] = useState<string>('');

    const formSchema = {
        resellerRadio: {},
        reseller: { name: "reseller", options: data, label: "Select an option", value: rslrSelected, disabled, className: "r-m-2 r-m-phone-inv" },
    };

    // const handleChange =(e: React.ChangeEvent<any>)=>{
    //     setrbSelected(e.target.value);
    // }
    const handleChange = (_event: any, value: any) => {
        // logger.log(value);
        setrbSelected(value);
    };
    const handleSelectChange = (e: React.ChangeEvent<any>) => {
        // logger.log(`Reseller Selected: ${e.target.value}`);
        // logger.log(subscriberId);
        setRslrSelected(e.target.value);
        // logger.log(`Reseller Selected: ${rslrSelected}`);
    };
    const saveData = () => {
        // logger.log(`saveData hit`);
        if (rbSelected === 'rbMakeAsResellerHead') {
            // logger.log(`setRslrHead Hit`);
            setAsResellerHead(subscriberId);
        } else if (rbSelected === 'rbAddtoReseller' && rslrSelected !== '') {
            // logger.log(`SetReseller Hit`);
            SetAsReseller(subscriberId, rslrSelected);
        } else if (rbSelected === 'rbUnsetAsResellerHead') {
            unsetAsReseller(subscriberId, isResellerHead);
        }
    };

    useEffect(() => {
        // logger.log(`rbselected: ${rbSelected}`);
        if (rbSelected === 'rbAddtoReseller') setDisabled(false); else setDisabled(true);
        // logger.log(disabled);
    }, [rbSelected]);

    return (
        <div>
            <div className="flex-responsive">
                {resellerName ? <small>Reseller Member of {resellerName}</small> : ''}
            </div>
            <div className="flex-responsive t-m-4">
                <small>Action:</small>
            </div>
            <div className="flex-responsive">
                {(() => {
                    if (isResellerHead) {
                        return (
                            <UIRadioGroup {...formSchema.resellerRadio} onChange={handleChange}>
                                <UIRadio id="rbUnsetAsResellerHead" value='rbUnsetAsResellerHead' label="Unset this subscriber as reseller head" />
                                <UIRadio id="rbAddtoReseller" value='rbAddtoReseller' label="Add to the following reseller below" disabled />
                            </UIRadioGroup>);
                    }

                    return (
                        <UIRadioGroup {...formSchema.resellerRadio} onChange={handleChange}>
                            <UIRadio id="rbMakeAsResellerHead" value='rbMakeAsResellerHead' label="Make this subscriber reseller head" />
                            <UIRadio id="rbAddtoReseller" value='rbAddtoReseller' label="Add to the following reseller below" />
                        </UIRadioGroup>);
                }
                )()}
            </div>
            <div className="flex-responsive t-m-1 r-m-2 l-m-2">
                <UISelect {...formSchema.reseller} onChange={handleSelectChange} />
            </div>
            <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                <UIButton onClick={saveData}>
                    Save
                </UIButton>
                <UIButton color="danger" onClick={handleClose}>
                    Close
                </UIButton>
            </DialogActions>
        </div>
    );
};

export default ResellerSettings;
