import * as React from "react";
import {
    Box,
} from "@material-ui/core";
import { FiMenu } from "react-icons/fi";
import BrandLogo from "./brand-logo";
import { UIButton } from "../../../components/form";

export interface TopBarProps {
    // className?: string;
    onMobileNavOpen: () => void;
}

const TopBar: React.FC<TopBarProps> = ({ onMobileNavOpen }) => {
    const menuOpenButton = (
        <UIButton type="button" variant="icon" color="secondary" onClick={onMobileNavOpen}>
            <FiMenu size={27} />
        </UIButton>
    );

    return (
        <div className="topbar flex-row flex-noshrink">
            {menuOpenButton}
            <BrandLogo />
            <Box flexGrow={1} />
            {/* <Avatar alt="Carchat24" src={cc24_icon_logo} /> */}
        </div>
    );
};

export default TopBar;
