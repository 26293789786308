import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../hooks/custom-router-hooks";
import { _TransactionStatus } from "../../../model/enums/invoice-enums";
import { cc24AdminRoles } from "../../../model/roles-and-rights";
import httpService from "../../../service/http-service";
import { selectUser } from "../../../store/modules/authentication";
// import { selectSubscriberInfo } from "../../../store/modules/subscriber";

function useProcessPayment() {
    const { user } = useSelector(selectUser);
    // const { currentSubscriber } = useSelector(selectSubscriberInfo);
    const history = useHistory();
    const data: any = useQuery();

    const initialState = {
        checkStatusStarted: true,
        transactionId: data.ti,
        transactionState: data.ts || calcTSFromPaymentPortalParams(data.Status as string),
        tokenId: data.TokenID,
        amount: data.amt,
        paymentStatus: data.Status // For add card and payment
    };
    const [state, setState] = useState<typeof initialState>(initialState);

    useEffect(() => {
        startCheckStatus();
    }, []);

    const startCheckStatus = async () => {
        if (data?.ti) await checkStatus();
    };

    const checkStatus = async () => {
        try {
            const response = await httpService.get<_TransactionStatus>('subscriberInvoice/CheckPaymentStatus', {
                params: {
                    transactionId: data.ti
                }
            });
            if (response.data === _TransactionStatus.Initiated || response.data === _TransactionStatus.Processing) {
                setTimeout(() => {
                    checkStatus();
                }, 2000);
            } else {
                setState(s => ({ ...s, checkStatusStarted: false, transactionState: response.data }));
            }
        } catch (error) {
            setState(s => ({ ...s, transactionState: _TransactionStatus.Failed }));
        }
    };

    const isPaymentProcessing = () => {
        return state.checkStatusStarted && (state.transactionState === _TransactionStatus.Initiated || state.transactionState === _TransactionStatus.Processing);
    };

    const isPaymentSuccess = () => {
        return state.transactionState === _TransactionStatus.Successful;
    };

    const isPaymentFailed = () => {
        return (state.transactionState === _TransactionStatus.Failed
            || state.transactionState === _TransactionStatus.Canceled
            || state.transactionState === _TransactionStatus.Timeout
            || state.transactionState === _TransactionStatus.Expired);
    };

    const backToInvoice = () => {
        let url = '/account/billing/payment';
        if (cc24AdminRoles.includes(user?.role)) {
            url = `/dashboard/subscriber/${data.si}${url}`;
        } else {
            url = `/dashboard${url}`;
        }
        history.replace(url);
    };

    return {
        state,
        isPaymentProcessing,
        isPaymentSuccess,
        isPaymentFailed,
        backToInvoice
    };
}

export default useProcessPayment;

// Ts: Transaction State
const calcTSFromPaymentPortalParams = (status: string): _TransactionStatus => {
    switch (status) {
        case "Successful":
            return _TransactionStatus.Successful;
        case "Initiated":
            return _TransactionStatus.Initiated;
        case "Failed":
            return _TransactionStatus.Failed;
        case "Canceled":
            return _TransactionStatus.Canceled;
        case "Expired":
            return _TransactionStatus.Expired;
        case "Timeout":
            return _TransactionStatus.Timeout;
        case "Processing":
            return _TransactionStatus.Processing;
        default:
            return _TransactionStatus.None;
    }
};
