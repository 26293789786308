import * as React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import { RouteConfig } from "../../model/route-config";

const UnprotectedRoute: React.FC<RouteConfig> = ({
  component: Component,
  render,
  ...others
}) => {
    const getRenderer = (props: RouteComponentProps, routes?: RouteConfig[]) => {
        const withRoutesProps = { ...props, routes };
        if (render) return render(withRoutesProps);
        if (Component) return <Component {...withRoutesProps} />;
        return undefined;
    };

    return (
        <Route
            {...others}
            render={(props: RouteComponentProps) => getRenderer(props, others.routes)}
        />
    );
};

export default UnprotectedRoute;
