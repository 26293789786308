import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, verifyUser, selectUser } from "../../../store/modules/authentication";
import * as authService from '../../../service/auth-service';
import * as filterService from '../../../service/filter-service';
import { fetchAllSubscribers, loadSubscriberInfo } from "../../../store/modules/subscriber";
import { DateFilterParam } from "../../../model/date-filter-param";
import { setFilterDate, changeTestMode } from "../../../store/modules/ui";
import logger from "../../../utils/log-utils";
import { cc24AdminRolesOnly } from "../../../model/roles-and-rights";
import { InitRemoteLogger } from "../../../workers";
import { getCookie } from "../../../utils/cookie-utils";
import appConfig from "../../../utils/app-config-utils";
import { castAsIs } from "../../../utils/object-utils";

InitRemoteLogger();

export default function useApp() {
    const dispatch = useDispatch();

    const { isAuthed } = useSelector(selectAuth);
    const { loading: userLoading, user } = useSelector(selectUser);

    useEffect(() => {
        (async function asyncFunc() {
            if (isAuthed) {
                await authService.initialize();
                dispatch(verifyUser());
                const dateObj: DateFilterParam | undefined = filterService.mountState();
                if (dateObj) dispatch({ type: setFilterDate.type, payload: dateObj });
            }
        }());
    }, [dispatch]);

    useEffect(() => {
        if (isAuthed) {
            if (user && user.role === "DealerGroupAdmin") {
                // if (user && (user.role === "CC24Admin" || user.role === "CC24PerformanceManager" || user?.role === "CC24SalesManager")) {
                // if (user && (cc24AdminRoles.includes(user.role))) {
                dispatch(fetchAllSubscribers(undefined, user?.sub_id));
            } else if (user && (user.role === "DealerAdmin" || user.role === "DealerUser")) {
                // Just passing it through
                // dispatch(loadSubscriberInfo(user.sub_id));
            } else if (user?.sub_id) {
                // logger.log("fetch");
                logger.log("Fetching All Subscriber, User:", user);
                const perfManager = user?.role === "CC24PerformanceManager" ? user.email : undefined;
                dispatch(fetchAllSubscribers(perfManager));
            }
        }
    }, [user]);

    useEffect(() => {
        if (isAuthed && user && cc24AdminRolesOnly.includes(user.role)) {
            const testMode = castAsIs<boolean>(getCookie(appConfig.cookieKeys.IS_TESTMODE) ?? false);
            changeTestMode(testMode)(dispatch);
        }
    }, [user, dispatch]);

    return { user, userLoading };
}
