import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStore } from "..";
import { buildGenericInitialState, selectGenericsOfActionType, updateStore } from "../../utils/store-utils";
import { GenericState } from "../types";

interface InitialState extends GenericState {
    hashMap: { [key: string]: string }
}

const initialState: InitialState = {
    hashMap: {},
    ...buildGenericInitialState([])
};

// Slices
const { actions, reducer } = createSlice({
    name: "reports",
    initialState,
    reducers: {
        setHashMap: (state, action: PayloadAction<{ [key: string]: string }>) => {
            return updateStore<typeof state>(state, action, {
                hashMap: action.payload,
            });
        }
    }
});

export default { reducer };
export const { setHashMap } = actions;

// selectors
export const selectHashMap = ({ reports }: AppStore) => ({
    hashMap: reports.hashMap, ...selectGenericsOfActionType<InitialState>(reports, actions.setHashMap.type)
});
