import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Formik } from 'formik';
// import PublishIcon from '@material-ui/icons/Publish';
import React from 'react';
import { UIButton, UIInput, UILoaderButton, UISelect } from '../../form';
// import UiFileDropzone from '../../form/inputElements/ui-file-dropzone';
import useFeedBackDialog from './hooks/useFeedBackDialog';
import { FeedBackDialogProps } from './models/feedback-dialog-props';

const FeedBackDialog: React.FC<FeedBackDialogProps> = (props) => {
    const { isCaptchaLoading = false, recaptchaToken } = props;
    const { initialValue, validationSchema, sendFeedBack } = useFeedBackDialog(props);
    return (
        <Dialog className='feedback-dialog' open={!!props?.dialogOpen} maxWidth="md" fullWidth onClose={props.dialogClose}>
            <DialogTitle>
                FeedBack
            </DialogTitle>
            <DialogContent>
                <Formik
                    initialValues={initialValue}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                        // setSubmitting(true);
                        sendFeedBack(values, setSubmitting, recaptchaToken);
                    }}
                >
                    {({
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                        setFieldValue,
                        handleReset
                    }) => {
                        const formSchema = {
                            emailId: { name: "emailId", label: "User Name", value: values.emailId, errorMessage: errors.emailId },
                            subject: { name: "subject", label: "Subject", value: values.subject, errorMessage: errors.subject },
                            message: { name: "message", label: "Message", value: values.message, errorMessage: errors.message },
                            type: { name: "type", showEmptyOption: false, label: "Type", options: [{ value: 0, label: "None" }, { value: 2, label: "Crash" }, { value: 1, label: "Error" }, { value: 4, label: "Feedback or Suggestion" }, { value: 5, label: "Others" }, { value: 3, label: "Problem Logging" }], value: values.type },

                        };
                        return <>
                            <form id="feedBack-control" onSubmit={handleSubmit} onReset={handleReset}>
                                <UIInput {...formSchema.emailId} onChange={handleChange} />
                                <div className='feedback-dialog__input-box t-m-3'>
                                    <UIInput {...formSchema.subject} onChange={handleChange} />
                                    <UISelect className=' feedback-dialog__input-box__type' {...formSchema.type} />
                                </div>
                                <UIInput className='t-m-3 ' {...formSchema.message} inputProps={{ multiline: true, rows: 5 }} onChange={handleChange} />
                                {/* <div className='t-m-3'>
                                    <UiFileDropzone
                                        dropAreaTitle='Upload File'
                                        onChange={handleFileUpload}
                                    />
                                </div> */}
                                {/* {
                                    fileUpload &&
                                    <div className="flex-row">
                                        <UIButton type='button' onClick={handleUploadFile(setFieldValue)} buttonProps={fileUploadState !== "Pending" ? { startIcon: <PublishIcon fontSize='small' /> } : undefined}>
                                            {
                                                fileUploadState === "Pending" ? <CircularProgress size={20} color="secondary" /> : "Upload File"
                                            }
                                        </UIButton>
                                    </div>
                                } */}
                                <div className='flex-row t-m-3 justify-end'>
                                    <UILoaderButton isLoading={isSubmitting} type='submit' form="feedBack-control" disabled={isSubmitting || isCaptchaLoading}>
                                        Send
                                    </UILoaderButton>
                                    <UIButton type='reset' color='danger' form="feedBack-control" className='l-m-2'>Reset</UIButton>
                                </div>
                            </form>
                        </>;
                    }}
                </Formik>

            </DialogContent>
        </Dialog>

    );
};

export default FeedBackDialog;
