import {
    CircularProgress,
    // IconButton,
    InputAdornment,
} from "@material-ui/core";
// import { Search } from "@material-ui/icons";
import * as React from "react";
import DashboardIcon from "@material-ui/icons/Dashboard";
// import AppInput from "../AppInput";
import { useSubscriberSwitchDialogContent } from "./hooks/useSubscriberSwitchDialogContent";
import { TableColumn } from "../../model/table-column";
import { AppTable } from "../table";
import { UIButton, UIInput } from "../form";
import ChatleadIcon from "../chatlead-icon";

export interface SubscriberSwitchDialogContentProps { }

const SubscriberSwitchDialogContent: React.SFC<SubscriberSwitchDialogContentProps> = () => {
    const {
        handleSearchInput,
        query,
        subscriberSearchResult,
        searchInProgress,
        gotoSubscriberControlPanel,
        inputRef
    } = useSubscriberSwitchDialogContent();

    const tableColumns: TableColumn[] = [
        {
            name: "Subscriber Name",
            label: "Subscriber Name",
            path: "SubscriberName",
        },
        {
            name: "Location",
            label: "Location",
            key: "location",
            content: (data: any) => {
                return (
                    <>
                        {data.City && <p>{data.City},</p>}
                        {data.State && <p>{data.State},</p>}
                        {data.Country && <p>{data.Country}</p>}
                    </>
                );
            },
        },
        {
            name: "Controls",
            label: "Controls",
            content: (data: any) => (
                <UIButton
                    variant="icon"
                    onClick={() => gotoSubscriberControlPanel(data.SubscriberId)}
                >
                    <DashboardIcon color="primary" />
                </UIButton>
            ),
        },
    ];

    return (
        <div className="flex-column subscriber-switch-dialog-content--wrapper">
            <UIInput
                className="search b-m-2"
                id="subscriber-search"
                label="Search Subscriber"
                ref={inputRef}
                value={query}
                onChange={handleSearchInput}
                inputProps={{
                    placeholder: "Subscriber Name",
                    autoComplete: "off",
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                {/* <Search /> */}
                                <ChatleadIcon icon="search" size={25} color="inherit" />
                            </InputAdornment>
                        ),
                    },
                }}
            />

            {searchInProgress && (
                <div className="flex-column search-loader flex-center justify-center">
                    <CircularProgress />
                </div>
            )}
            <div className="flex-column search-result flex-center justify-center">
                {query &&
                    query.length > 1 &&
                    !searchInProgress &&
                    !subscriberSearchResult?.length && (<p className="text-muted"> No subscriber found</p>)}
                {!!subscriberSearchResult?.length && (
                    <AppTable
                        useCard
                        data={subscriberSearchResult}
                        columns={tableColumns}
                        idPath="SubscriberId"
                    />
                )}
            </div>
        </div>
    );
};

export default SubscriberSwitchDialogContent;
