import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { AuthedRoutes } from "../../../router/route-constants";
import { getFullBrandLogo, getSmallBrandLogo } from "../../../utils/image-util";
import configuration from "../../../utils/configuration";

export interface BrandLogoProps {
  minimal?: boolean;
  classes?: string;
}

const BrandLogo: React.SFC<BrandLogoProps> = ({ minimal = false, classes }) => {
    return (
        <div className={clsx("brand-logo", classes)}>
            <Link to={AuthedRoutes.home}>
                {!minimal && (
                    <div className={clsx("full-logo")}>
                        <img alt={configuration.getValue("productName")} src={getFullBrandLogo(configuration.getValue("productName"))} />
                    </div>
                )}

                {minimal && (
                    <div className={clsx("small-logo")}>
                        <img alt={configuration.getValue("productName")} src={getSmallBrandLogo(configuration.getValue("productName"))} />
                    </div>
                )}
            </Link>
        </div>
    );
};

export default BrandLogo;
