import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import ChildRouteRender from "../components/routers/child-route-renderer";
import { SuspendedPage } from "../components/suspended-page";
import { RouteConfig } from "../model/route-config";
import configuration from "./configuration";

export const redirectTo = (path: string) => {
    const base = configuration.getValue("baseUrl") || ".";
    window.location.href = `${base}${path}`;
};

/**
 * Creates route, for any user
 * @param config Route Config object
 */
export const definePublicRoute = (
    config: RouteConfig,
    bypassSearch: boolean = false
): RouteConfig => {
    return {
        ...config,
        authRequired: false,
        nonAuthRequired: false,
        bypassSearch,
    };
};

/**
 * Creates route, for any logged in user
 * @param config Route Config object
 */
export const defineAuthedRoute = (
    config: RouteConfig,
    bypassSearch: boolean = false
): RouteConfig => {
    return {
        ...config,
        authRequired: true,
        nonAuthRequired: false,
        bypassSearch,
    };
};

/**
 * Creates route, only for cc24Admin and dealerAdmin
 * @param config Route Config object
 */
export const defineRouteWithCommonAdminRole = (
    config: RouteConfig
): RouteConfig => {
    return {
        ...defineAuthedRoute(config),
        guard: {
            allowedRoles: ["CC24Admin", "DealerGroupAdmin", "DealerAdmin", "CC24PerformanceManager", "CC24SalesManager"],
            exceptSubscriptions: ["Invalid"],
            ...config.guard
        },
    };
};

/**
 * Creates route, only for cc24Admin and dealerAdmin
 * @param config Route Config object
 */
export const defineRouteWithCc24AdminRole = (
    config: RouteConfig
): RouteConfig => {
    return {
        ...defineAuthedRoute(config),
        guard: {
            allowedRoles: ["CC24Admin", "CC24PerformanceManager", "CC24SalesManager"],
            allowedSubscriptions: ["NoSupport", "FullSupport", "PartialSupport"],
            ...config.guard
        },
    };
};

/**
 * Creates route, which will not be available in search and any navigation menu
 * @param config Route Config object
 */
export const defineInvisibleRoute = (config: RouteConfig): RouteConfig => {
    return {
        ...config,
        bypassSearch: true,
        bypassDisplay: true,
    };
};

/**
 * Creates route, which will redirect to given url, if opened
 * @param config Route Config object
 * @param redirectionTo path to redirect
 */
export const defineRedirectRoute = (
    config: RouteConfig,
    redirectionTo: string
): RouteConfig => {
    return {
        ...config,
        render: () => <Redirect to={redirectionTo} />,
    };
};

/**
 * Creates route, which will have ChildRouteRenderer as component
 * @param config Route Config object
 */
export const defineRouteWithChildRouteRenderer = (config: RouteConfig) => {
    return { ...config, component: ChildRouteRender };
};

export const constructPath = (
    nextPath: string,
    currentPath: string = ""
): string => {
    const formattedCurrentPath = currentPath.endsWith("/")
        ? currentPath.substr(0, currentPath.length - 1)
        : currentPath;
    const path = `${formattedCurrentPath.toLowerCase()}${nextPath.toLowerCase()}`;
    return path;
};

/**
 * Creates a mapped link for the link elements
 * @param link the $\d seperated link
 * @param mapObj mapper object, example: {"$1":"usb"}
 */
export const getPathWithReplacement = (link: string, mapObj: { [key: string]: string }): string => {
    return link.replace(/(\$\d+)/g, (v) => (mapObj[v]));
};

/**
 * Create suspended component
 * @param Component the component that needs to be rendered
 * @param props the props that needs to be passed to the component
 */
export const suspendedComponent = (Component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>) => (props: any) => (<SuspendedPage><Component {...props} /></SuspendedPage>);
