import { configureStore, getDefaultMiddleware, combineReducers } from '@reduxjs/toolkit';
import authenticationModule from './modules/authentication';
import subscriberModule from './modules/subscriber';
import uiModule from './modules/ui';
import reportsModule from './modules/reports';

const rootReducer = combineReducers({
    authentication: authenticationModule.reducer,
    ui: uiModule.reducer,
    subscriber: subscriberModule.reducer,
    reports: reportsModule.reducer
    // user: userReducer,
    // form: formReducer,
});

const initStore = () => configureStore({
    reducer: rootReducer,
    middleware: [
        ...getDefaultMiddleware(),
    ]
});
export default initStore;
export type AppStore = ReturnType<typeof rootReducer>;

// export type AppDispatch = ReturnType<typeof createStore>