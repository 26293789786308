import React from "react";
import { DialogActions } from "@material-ui/core";
import { UIButton, UIDateTimePicker } from "../../../components/form";
import useBillingSettings from "./hooks/useBillingSettings";

const BillingSettings: React.FunctionComponent<any> = (props) => {
    const { date, handleDate, SetBillingActivation, SetNoBilling } = useBillingSettings(props.date);
    // setDate(dayjs(props.date).format('DD-MM-YYYY'));
    // logger.log(Date.parse(props.date).)
    return (
        <div className="flex t-m-6">
            <small>Activate Billing From</small>
            <div className="flex-responsive t-m-1 r-m-2 l-m-2">
                <UIDateTimePicker type="date" value={date} views="d-m-y" label="Select Date" onChange={handleDate} format="DD-MM-YYYY" />
            </div>
            <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                <UIButton onClick={() => SetBillingActivation(props.subscriberId)}>
                    Save
                </UIButton>
                <UIButton color="teal" onClick={() => SetNoBilling(props.subscriberId)}>
                    No Billing Required
                </UIButton>
                <UIButton color="danger" onClick={props.handleClose}>
                    Close
                </UIButton>
            </DialogActions>
        </div>
    );
};

export default BillingSettings;
