import { FormControl, FormHelperText, FormLabel, RadioGroup, RadioGroupProps } from "@material-ui/core";
import clsx from "clsx";
import React, { memo } from "react";
import { CommonInputProps } from "../../../model/common-props";

interface UIRadioGroupProps extends CommonInputProps {
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>, value: string) => void;
    inputProps?: RadioGroupProps;
    children: React.ReactNode;
    // orientation: 'vertical' | 'horizontal';
}

const UIRadioGroup: React.FunctionComponent<UIRadioGroupProps> = (props) => {
    let { inputProps } = props;
    const { label, name, required, errorMessage, children } = props;

    if (!inputProps) inputProps = {};

    if (props.value !== undefined) { inputProps.value = props.value; }
    if (props.name !== undefined) { inputProps.name = name; }
    if (props.onChange !== undefined) { inputProps.onChange = props.onChange; }

    return (
        <div className={clsx("flex-row", props.className)} style={props.style}>
            <FormControl component="fieldset" error={!!errorMessage} required={required}>
                {
                    label && <FormLabel component="legend">{label}</FormLabel>
                }
                <RadioGroup {...inputProps}>
                    {children}
                </RadioGroup>
                <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>
        </div>
    );
};

export default memo(UIRadioGroup);
