import { useEffect, useState } from "react";
import appToast from "../../../../components/app-toast";
import uniLoader from "../../../../components/uni-loader";
import { ChatWindowNotification, ChatWindowNotificationSearchParam, _InstructionPriority } from "../../../../model/chat-window- notification";
import { ApiData } from "../../../../model/xhrCallState";
import httpService from "../../../../service/http-service";
import logger from "../../../../utils/log-utils";
import { castAs } from "../../../../utils/object-utils";
import { RowEventType } from "../../../contact-settings/models/types";

export default function useNotificationSettings(subId: string) {
    const initialState: InitialState = {
        notificationData: new ApiData(),
    };
    type InitialState = {

        notificationData: ApiData<ChatWindowNotification[]>,
    };
    interface siteData {
        label: string;
        value: string;
    }
    const [checked, setChecked] = useState<boolean>(false);
    const [notificationState, setNotificationState] = useState<InitialState>(initialState);
    const [edRowData, setEdRowData] = useState<ChatWindowNotification>();
    const [allSites, setAllSites] = useState<siteData[]>([]);
    const [selectedSite, setSelectedSite] = useState<string>("");
    const [priority, setPriority] = useState<string>('');
    const [msgTitle, setMsgTitle] = useState<string>('');
    const [msgBody, setMsgBody] = useState<string>('');
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [textColor, setTextColor] = useState<string>('');
    const [backColor, setBackColor] = useState<string>('');
    const priorityType = [{ label: 'High', value: '0' }, { label: 'Normal', value: '1' }, { label: 'Low', value: '2' }];

    useEffect(() => {
        if (notificationState?.notificationData.status === "Pending") uniLoader.showLoader(''); else uniLoader.close();
    }, [notificationState]);

    const getSiteName = (key: string) => {
        // if (key === EMPTY_GUID) {
        //     return "All Sites"
        // }
        // else {
        if (allSites !== undefined) {
            const a = allSites.find(el => el.value === key);
            return a?.label;
        }
        return "";
        // var a = allSites !== undefined ? allSites.find(el => el.value == key).label :;

        // }
    };

    const getNotificationSetupData = async () => {
        const body: ChatWindowNotificationSearchParam = new ChatWindowNotificationSearchParam();
        body.SubscriberID = subId;
        // logger.log(`getNotificationSetupData --- body: ${JSON.stringify(body)}`);
        setNotificationState((s) => ({ ...s, notificationData: { status: "Pending" } }));
        try {
            // logger.log('getNotificationSetupData --- Try Hit');
            const response = await httpService.post(`Subscriber/GetNotificationSetupData`, body);

            // logger.log(`response data: ${JSON.stringify(response.data)}`);
            setNotificationState(s => ({ ...s, notificationData: { status: "Completed", data: response.data } }));
        } catch (error) {
            // logger.log('getNotificationSetupData --- Catch Hit');
            logger.log(error);
            setNotificationState(s => ({ ...s, notificationData: { status: "Failed" } }));
        }
    };
    const getSitesBySubId = async () => {
        setNotificationState((s) => ({ ...s, notificationData: { status: "Pending" } }));
        try {
            // logger.log("get All Sites");
            const response = await httpService.get(`Subscriber/GetSitesBySubId?subscriberId=${subId}`);
            setAllSites(Object.entries(response.data).map((d) => ({ "label": d[1] as string, "value": d[0] })));

            await getNotificationSetupData();
        } catch (error) {
            logger.log(error);
            setNotificationState(s => ({ ...s, notificationData: { status: "Failed" } }));
        }
    };

    const saveNotificationSetup = async (e: any) => {
        e.preventDefault();
        let searchParam;
        if (edRowData) {
            edRowData.SiteID = selectedSite;
            edRowData.MessageTitle = msgTitle;
            edRowData.MessageBody = msgBody;
            edRowData.StartDate = new Date(startDate);
            edRowData.EndDate = new Date(endDate);
            edRowData.Priority = castAs<number>(priority);
            edRowData.TextColor = textColor;
            edRowData.Color = backColor;
            searchParam = edRowData;
        }
        logger.log(`saveChatNotification --- body: ${JSON.stringify(searchParam)}`);
        setNotificationState((s) => ({ ...s, featureData: { status: "Pending" } }));
        try {
            // logger.log('saveNotificationSetup --- Try Hit');
            const response = await httpService.post<ChatWindowNotification>(`Subscriber/SaveNotificationSetupData?type=1`, searchParam); // Insert=0 Update=1

            logger.log(`response data: ${JSON.stringify(response.data)}`);
            appToast.success('Chat notification setup saved successfully.');
            await handleBack();
            setNotificationState(s => ({ ...s, featureData: { status: "Completed", data: response.data } }));
        } catch (error) {
            logger.log(error);
            appToast.error('Chat notification setup save unsuccessful');
            setNotificationState(s => ({ ...s, featureData: { status: "Failed" } }));
        }
    };

    const onRowEvent = (id: string, eventType: RowEventType) => {
        logger.log(`${id} - ${eventType}`);
        switch (eventType) {
            case "edit":
                setEdRowData(notificationState.notificationData.data?.find(r => r.ID === id));
                break;
            default:
                break;
        }
    };

    const getPriority = (key: _InstructionPriority) => {
        switch (key) {
            case 99:
                return 'All';
            case 0:
                return 'High';
            case 1:
                return 'Normal';
            case 2:
                return 'Low';
            default:
                return '';
        }
    };

    const handleBack = async () => {
        await getNotificationSetupData();
        setChecked(false);
    };
    useEffect(() => {
        getSitesBySubId();
    }, []);

    useEffect(() => {
        const sites = allSites;
        sites.push({ 'label': 'All Sites', 'value': '00000000-0000-0000-0000-000000000000' });
        setAllSites(sites);
        allSites.sort((a, b) => ((a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)));
        // logger.log(`all sites data: ----- ${JSON.stringify(allSites)}`);
    }, [allSites]);
    useEffect(() => {
        if (edRowData) {
            // logger.log('EDITED ROW DATA ---------  ' + JSON.stringify(edRowData))
            setSelectedSite(edRowData.SiteID);
            setPriority(edRowData.Priority.toLocaleString());
            if (edRowData.StartDate != null) setStartDate(edRowData.StartDate.toLocaleString());
            if (edRowData.EndDate != null) setEndDate(edRowData.EndDate.toLocaleString());
            setMsgTitle(edRowData.MessageTitle);
            setMsgBody(edRowData.MessageBody);
            setTextColor(edRowData.TextColor);
            setBackColor(edRowData.Color);
            setChecked(true);
        }
    }, [edRowData]);

    return {
        notificationState,
        onRowEvent,
        checked,
        setChecked,
        edRowData,
        saveNotificationSetup,
        allSites,
        selectedSite,
        setSelectedSite,
        priority,
        setPriority,
        getPriority,
        msgTitle,
        setMsgTitle,
        msgBody,
        setMsgBody,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        textColor,
        setTextColor,
        backColor,
        setBackColor,
        getSiteName,
        priorityType,
        handleBack
    };
}
