// @ts-nocheck
import clsx from "clsx";
import React, { memo } from "react";
import { KeyboardDatePicker, KeyboardDatePickerProps, KeyboardDateTimePicker, KeyboardTimePicker, KeyboardTimePickerProps, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from '@date-io/dayjs';
import { PropTypes } from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import dayjs from 'dayjs';
import { CommonInputProps } from "../../../model/common-props";
import ChatleadIcon from "../../chatlead-icon";

interface UIDateTimePickerProps extends CommonInputProps {
    onChange?: (date: string, value?: string) => void;
    type: "date" | "time" | "dateTime";
    views?: "y" | "m-y" | "d-m-y";
    disableToolbar?: boolean;
    minDate?: string;
    maxDate?: string;
    format?: string;
    maxDateMessage?: string;
    minDateMessage?: string;
    emptyLabel?: string;
    inputProps?: KeyboardDatePickerProps | KeyboardTimePickerProps;
    margin?: PropTypes.Margin;
    ampm?: boolean;
}

const UIDateTimePicker: React.FunctionComponent<UIDateTimePickerProps> = (props) => {
    let { inputProps } = props;
    const { value, type, onChange, views, emptyLabel = "", margin = "normal", disableToolbar = false } = props;
    if (!inputProps) inputProps = {};
    if (props.label !== undefined) { inputProps.label = props.label; }
    const [selectedDate, setSelectedDate] = React.useState<string | null>(
        (dayjs(value) ?? dayjs()).format()
    );

    let selectedView;
    if (views) {
        switch (views) {
            case "y":
                selectedView = ["year"];
                break;
            case "m-y":
                selectedView = ["year", "month"];
                break;
            case "d-m-y":
                selectedView = ["year", "month", "date"];
                break;
            default:
                break;
        }
    }

    React.useEffect(() => {
        if (value) setSelectedDate((dayjs(value) ?? dayjs()).format());
        else setSelectedDate(null);
    }, [value]);

    const handleDateChange = (date: dayjs.Dayjs | null, value?: string) => {
        setSelectedDate(dayjs(date)?.format());
    };
    const renderObject = () => {
        switch (type) {
            case "date":
                return <KeyboardDatePicker
                    emptyLabel={emptyLabel}
                    disableToolbar={disableToolbar}
                    variant="inline"
                    inputVariant="outlined"
                    margin={margin}
                    label="Date picker"
                    format={props.format}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    maxDateMessage={props.maxDateMessage}
                    minDateMessage={props.minDateMessage}
                    disabled={props.disabled}
                    views={selectedView}
                    value={selectedDate}
                    // defaultValue={null}
                    onChange={onChange ?? handleDateChange}
                    keyboardIcon={<ChatleadIcon icon="calender" size={25} color="inherit" />}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    className="b-m-1 t-m-2"
                    style={{ width: "100%" }}
                    {...inputProps}
                />;
            case "time":
                return <KeyboardTimePicker
                    emptyLabel={emptyLabel}
                    disableToolbar={disableToolbar}
                    variant="inline"
                    inputVariant="outlined"
                    margin={margin}
                    label="Time picker"
                    disabled={props.disabled}
                    format={props.format}
                    value={selectedDate}
                    // defaultValue={null}
                    onChange={onChange ?? handleDateChange}
                    keyboardIcon={<AccessTimeIcon htmlColor="inherit" />}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                    className="b-m-1 t-m-2"
                    style={{ width: "100%" }}
                    {...inputProps}
                />;
            case "dateTime":
                return (
                    <KeyboardDateTimePicker
                        emptyLabel={emptyLabel}
                        disableToolbar={disableToolbar}
                        variant="inline"
                        inputVariant="outlined"
                        margin={margin}
                        label="Date/Time picker"
                        format={props.format}
                        minDate={props.minDate}
                        maxDate={props.maxDate}
                        maxDateMessage={props.maxDateMessage}
                        minDateMessage={props.minDateMessage}
                        disabled={props.disabled}
                        views={selectedView}
                        value={selectedDate}
                        ampm={props.ampm}
                        // defaultValue={null}
                        onChange={onChange ?? handleDateChange}
                        keyboardIcon={<ChatleadIcon icon="calender" size={25} color="inherit" />}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        className="b-m-1 t-m-2"
                        style={{ width: "100%" }}
                        {...inputProps}
                    />
                );
            default:
                return <></>;
        }
    };
    return (
        <div className={clsx("flex-row", props.className)} style={{ width: "100%", ...props.style }}>

            <MuiPickersUtilsProvider utils={DayjsUtils}>
                {
                    renderObject()
                }
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default memo(UIDateTimePicker);
