import { useEffect, useState } from "react";
import appToast from "../../../../components/app-toast";
import uniLoader from "../../../../components/uni-loader";
import { ApiData } from "../../../../model/xhrCallState";
import httpService from "../../../../service/http-service";
import logger from "../../../../utils/log-utils";

export default function useRebrandingSettings() {
    interface RebrandingData {
        label: string;
        value: string;
    }
    const [status, setStatus] = useState<ApiData<any>>();
    const [data, setData] = useState<RebrandingData[]>([]);

    useEffect(() => {
        if (status?.status === "Pending") uniLoader.showLoader(''); else uniLoader.close();
    }, [status]);

    const getAllRebrandings = async () => {
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            const response = await httpService.get<any>(`Subscriber/GetRebranding`);

            // logger.log("getAllRebrandings");
            // logger.log(response.data)
            const data: RebrandingData[] = [];
            Object.entries<string>(response.data).map(
                ([key, value]) => data.push({ label: value, value: key })
            );
            setData(data);
            // logger.log(data);
            // logger.log("getAllRebrandings: completed");
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    const setAsRebrandingHead = async (subId: any) => {
        // logger.log("setAsRebrandingHead");
        // logger.log(`SetAsRebrandingHead: SubscriberId: ${subId}`);
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            await httpService.post<any>(`Subscriber/SetAsRebrandingHead?subscriberId=${subId}&isRebranding=${true}`, null);
            // logger.log(response.data);
            // logger.log("setAsRebrandingHead: Completed");
            appToast.success('Subscriber has been set as rebranding head');
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            appToast.error('Set rebranding head unsuccessful');
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    const SetAsRebranding = async (subId: any, rebrandingId: any) => {
        // logger.log("SetAsRebranding");
        // logger.log(`SetAsRebranding: SubscriberId: ${subId}`);
        // logger.log(`SetAsRebranding: rslrId: ${rebrandingId}`);
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            await httpService.post<any>(`Subscriber/SetAsRebranding?rebrandedId=${subId}&memberId=${rebrandingId}`, null);
            // logger.log(response.data);
            // logger.log("SetAsRebranding: Completed");
            appToast.success('Subscriber has been set as rebranding');
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            appToast.error('Set rebranding unsuccessful');
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    const unsetAsRebranding = async (subId: any) => {
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            await httpService.post<any>(`Subscriber/UnSetAsRebranding?subscriberId=${subId}`, null);
            appToast.success('Subscriber has been unset as rebranding head');
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            appToast.error('Unset as rebranding head unsuccessful');
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    useEffect(() => {
        getAllRebrandings();
    }, []);

    return { status, data, setAsRebrandingHead, SetAsRebranding, unsetAsRebranding };
}
