import clsx from "clsx";
import React, { FunctionComponent, memo, useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import appToast from "../../app-toast";
// import appToast from "./app-toast";
// import appToast from '../../../app-toast';
interface UIFileDropZoneProps {
    onChange?: (file: File) => void;
    disabled?: boolean;
    dropAreaTitle: string;
    multiple?: boolean | undefined;
    accept?: string | string[] | undefined;
}
interface FileWithPreview extends File {
    preview: string;
}
const UIFileDropZone: FunctionComponent<UIFileDropZoneProps> = ({ onChange, disabled, dropAreaTitle, multiple, accept = "" }) => {
    const [file, setFile] = useState<FileWithPreview | undefined>();
    const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        // Do something with the files
        if (acceptedFiles) {
            const newFile = acceptedFiles[0];
            if (newFile) {
                setFile(Object.assign(newFile, {
                    preview: URL.createObjectURL(newFile)
                }));
            }
        }
        if (rejectedFiles && rejectedFiles.length) {
            appToast.error(rejectedFiles?.[0]?.errors?.[0]?.message || "Something went wrong!");
        }
    }, []);
    useEffect(() => {
        if (file && onChange) {
            onChange(file);
        }
    }, [file]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        disabled,
        accept,
        multiple: false,
        maxFiles: 5124,
    });
    return (
        <>
            <section className="ui-file-dropzone">
                <section className="file-drop-container ">
                    <div {...getRootProps({ className: clsx("dropzone-area", { "disabled": disabled, "fileborder": !!file }) })}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ? <p>Drop the files here ...</p> :
                                    (<p>{dropAreaTitle || "Drag 'n' drop some files here, or click to select files"}</p>)

                        }
                    </div>
                    {
                        file?.preview &&
                        <div className="preview-area">
                            {/* <div className="preview">
                                <img
                                    alt="Preview"
                                    src={file.preview}
                                />
                            </div> */}
                            <h4>Files</h4>
                            <ul>{file.name}</ul>
                        </div>
                    }
                </section>
            </section>
        </>

    );
};
export default memo(UIFileDropZone);
