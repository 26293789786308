import { FormControlLabel, FormControlLabelProps, Radio, ButtonProps } from "@material-ui/core";
import clsx from "clsx";
import React, { forwardRef, memo } from "react";
import { CommonInputProps } from "../../../model/common-props";

interface UIRadioProps extends CommonInputProps {
    buttonProps?: ButtonProps;
    checked?: boolean;
    onClick?: (evt: React.MouseEvent<HTMLLabelElement> | undefined) => void;
    onChange?: (
        evt: React.ChangeEvent<{} | HTMLInputElement>,
        checked: boolean
    ) => void;
    inputProps?: FormControlLabelProps;
    checkedIcon?: React.ReactNode;
}

const UIRadio: React.ForwardRefRenderFunction<HTMLDivElement, UIRadioProps> = (props, ref) => {
    let { inputProps } = props;
    const { label, name, id, disabled, checkedIcon, checked } = props;

    if (!inputProps) inputProps = { label: "", control: <></> };

    if (props.id !== undefined) { inputProps.id = props.id; }
    if (props.value !== undefined) { inputProps.value = props.value; }
    if (props.label !== undefined) { inputProps.label = label; }
    if (props.disabled !== undefined) { inputProps.disabled = disabled; }
    if (props.onChange !== undefined) { inputProps.onChange = props.onChange; }
    if (props.onClick !== undefined) { inputProps.onClick = props.onClick; }

    let formContorlLabelProps = {};
    if (checked !== undefined) {
        formContorlLabelProps = {
            checked,
        };
    }
    if (props.onClick !== undefined) {
        formContorlLabelProps = {
            ...formContorlLabelProps,
            onClick: props.onClick,
        };
    }

    return (
        <div className={clsx("flex-row", props.className)} style={props.style} ref={ref}>
            {label ? (
                <FormControlLabel
                    className="flex-grow"
                    name={name}
                    {...inputProps}
                    control={<Radio {...formContorlLabelProps} />}
                />
            ) : (
                <Radio
                    className="flex-grow"
                    disabled={disabled ?? false}
                    name={name}
                    id={id}
                    value={props.value}
                    onChange={props.onChange}
                    {...formContorlLabelProps}
                    checkedIcon={checkedIcon}
                />
            )}
        </div>
    );
};

export default memo(forwardRef(UIRadio));
