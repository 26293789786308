import { useCallback, useEffect, useRef, useState } from "react";

import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MinimalSubscriberData } from "../../../model/minimal-subscriber-data";
import { emptyCurrentSubscriberInfo, emptyOrganisation, selectAllSubscribers } from "../../../store/modules/subscriber";
import { getWorkerInstance } from "../../../workers";

export function useSubscriberSwitchDialogContent() {
    const { loading, errors } = useSelector(selectAllSubscribers);
    const history = useHistory();
    const dispatch = useDispatch();

    const [query, setQuery] = useState("");
    const [subscriberSearchResult, setSubscriberSearchResult] = useState<MinimalSubscriberData[] | null>(null);
    const [searchInProgress, setSearchInProgress] = useState(false);
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

    useEffect(() => {
        // logger.log("going to set search in progress");
        setSearchInProgress(() => (!(!loading && !errors?.length)));
        if (!loading && !errors?.length && query) {
            processSearchInput(query); // Re Perform a search if the query present, after complete loading of all subscriber data
        }
    }, [loading, errors]);

    useEffect(() => {
        inputRef?.current?.focus();
    }, []);

    const processSearchInput = useCallback(async (value: string) => {
        const data = await getWorkerInstance("subscriberSearchWorker")?.findSubscriberByName(value) as MinimalSubscriberData[]; // Call api
        setSubscriberSearchResult(data);
    }, []);

    const debouncedQueryInput = useCallback(
        debounce(processSearchInput, 200),
        []
    );

    const handleSearchInput = (e: any) => {
        setQuery(e.target.value);
        if (e.target.value?.length > 1) debouncedQueryInput(e.target.value); else setSubscriberSearchResult(null);
    };

    const gotoSubscriberControlPanel = (subscribeId: string) => {
        dispatch(emptyOrganisation());
        dispatch(emptyCurrentSubscriberInfo());
        history.push(`/dashboard/subscriber/${subscribeId}`);
    };

    return {
        handleSearchInput,
        query,
        subscriberSearchResult,
        searchInProgress,
        gotoSubscriberControlPanel,
        inputRef
    };
}
