/* eslint-disable camelcase */
export enum InvoiceItemType {
    None = 0,
    ELead = 1,
    PLead = 2,
    VIPLead = 3,
    Query = 4,
    ChatOnly = 5,
    MonthlyFixed = 6,
    OneTime = 7,
    SetupFee = 8,
    SMSSetupFee = 9,
    VideoChatSetupFee = 10,
    LeadToRingSetupFee = 11,
    FBChatSetupFee = 12,
    FBMarketPlaceSetupFee = 13,
    LocalDirectoryListingManagementSetupFee = 14,
    ServiceVIPLead = 15
}

export enum InvoiceGenerationMode {
    Default = 0,
    Mocked = 1,
    SystemGenerated = 2,
    All = 99
}

export enum InvoiceType {
    All = 99,
    Fixed = 0,
    Variable = 1,
    AdHoc = 2,
    AdditionalFixed = 3,
    AdditionalVariable = 4
}

export enum TransactionType {
    Bill = 0,
    Payment = 1,
    PaymentReversal = 2,
    BillReversal = 3,
    Charges = 4,
    CancelledBill = 5,
    FailedPayment = 6,
    ChargeBack = 7,
    Debit = 8,
    Credit = 9,
    Adjustment = 10,
    FixedInvoice = 11,
    VariableInvoice = 12,
    AdHocInvoice = 13,
    OpeningBalance = 14,
    ClosingBalance = 15,
    PaymentDistribution = 16,
    CreditNote = 17,
    DebitNote = 18,
    AddCCToVault = 19,
    DeleteCCFromVault = 20,
    None = 999
}

export enum _InvoiceOperationType {
    Generation = 0,
    Regeneration = 1,
    Edit = 2,
    Deletion = 3
}

export enum InvoicePaymentStatus {
    Any = 99,
    FullPaid = 1,
    PartPaid = 2,
    Unpaid = 3,
    PaymentNotRequired = 4
}

export enum _TaxType {
    Total = 0,
    ItemWise = 1
}

export enum _TransactionStatus {
    None = 0,
    NotInitiated = 1,
    Initiated = 2,
    Successful = 3,
    Failed = 4,
    Canceled = 5,
    Expired = 6,
    Timeout = 7,
    Processing = 8
}
export enum _TransactionMode {
    AutoDebit = 0,
    Online = 1,
    Cheque = 2,
    CreditCard = 3,
    PayPal_VT = 4,
    Unknown = 5,
    Any = 99
}