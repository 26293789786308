import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from "@material-ui/core";
import clsx from "clsx";
import React, { memo } from "react";
import { CommonInputProps } from "../../../model/common-props";

interface UISelectProps extends CommonInputProps {
    onChange?: (evt: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => void;
    emptyOptionLabel?: string;
    showEmptyOption?: boolean;
    options: { label: string, value: string | number }[];
    inputProps?: SelectProps;
}

const UISelect: React.FunctionComponent<UISelectProps> = (props) => {
    let { inputProps } = props;
    const { options, label, required, errorMessage, disabled, emptyOptionLabel, readOnly, showEmptyOption = true } = props;

    if (!inputProps) inputProps = {};

    inputProps.value = props.value ?? "";
    if (props.onChange !== undefined) { inputProps.onChange = props.onChange; }

    return (
        <div className={clsx("flex-row", props.className)} style={{ width: "100%", ...props.style }}>
            {label ? (<FormControl className="flex-grow" variant="outlined" error={!!errorMessage}>
                <InputLabel htmlFor={props.id}>{label}</InputLabel>
                <Select
                    // native
                    disabled={disabled ?? false}
                    label={label}
                    inputProps={{
                        name: props.name,
                        id: props.id,
                    }}
                    required={required}
                    readOnly={readOnly}
                    {...inputProps}
                >
                    {
                        showEmptyOption &&
                        <MenuItem aria-label="None" value="">{emptyOptionLabel || "None"}</MenuItem>
                    }
                    {
                        options && options.map((v, i) => <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>)
                    }
                </Select>
                <FormHelperText>{errorMessage}</FormHelperText>
            </FormControl>) : (<Select
                // native
                disabled={disabled ?? false}
                className="flex-grow"
                inputProps={{
                    name: props.name,
                    id: props.id,
                }}
                error={!!errorMessage}
                required={required}
                readOnly={readOnly}
                {...inputProps}
            >
                {
                    showEmptyOption &&
                    <MenuItem aria-label="None" value="">{emptyOptionLabel || "None"}</MenuItem>
                }
                {
                    options && options.map((v, i) => <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>)
                }
            </Select>)}
        </div>
    );
};

export default memo(UISelect);
