import { CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import FailedComponent from "../../../components/failed-component";
import { LeadData } from "../../../model/dashboard-table";
import { useDashboardTable } from "../hooks/useDashboardTable";
import LeadsTable from "./leads-table";

export interface DashboardTableProps {
    data: LeadData;
    loading: string;
}

export interface DashboardTableState { }

// class DashboardTable extends React.Component<DashboardTableProps, DashboardTableState> {
const DashboardTable: React.FC = () => {
    const { leadInfo } = useDashboardTable();

    const leadsData = {
        data: (leadInfo.data?.data) ? leadInfo.data.data : [],
        title: leadInfo.data?.title ? leadInfo.data?.title : ""
    };

    return (
        // requestData: LeadData;
        // loading: string;

        // constructor(props: DashboardTableProps) {
        //     super(props);
        //     this.requestData = props.data;
        //     this.loading = props.loading;
        // }

        // render() {
        // return (
        <div className={clsx("card", "b-m-4 card-half")}>
            <div className="card-title">Last 24hr Leads</div>
            {/* <div className="card-title">Last 31 Leads</div> */}
            {leadInfo.status === "Pending" &&
                <div className="card-body">
                    <div className="loading flex-column flex-center justify-center" style={{ marginBottom: 100 }}>
                        <CircularProgress size={40} color="secondary" />
                    </div>
                </div>}
            {
                leadInfo.status === "Failed" &&
                <div className="card-body">
                    <div className="card-error">
                        <FailedComponent type="error" text="No Data Found" size="small" />
                    </div>
                </div>
            }
            {
                leadInfo.status === "Completed" &&
                <LeadsTable leadData={leadsData} />
            }
        </div>
    );
};
// }
export default DashboardTable;
