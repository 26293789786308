import { Checkbox, FormControlLabel, FormControlLabelProps } from "@material-ui/core";
import clsx from "clsx";
import React, { memo } from "react";
import { CommonInputProps } from "../../../model/common-props";

interface UICheckboxProps extends CommonInputProps {
    onChange?: (evt: React.ChangeEvent<HTMLInputElement | {}>, checked: boolean) => void;
    inputProps?: FormControlLabelProps;
    checked?: boolean;
}

const UICheckbox: React.FunctionComponent<UICheckboxProps> = (props) => {
    let { inputProps } = props;
    const { label, value, disabled = false, checked } = props;

    if (!inputProps) inputProps = { label: "", control: <></> };

    // if (props.value !== undefined) { inputProps.checked = props.value; }
    if (props.label !== undefined) { inputProps.label = props.label; }
    if (checked !== undefined) { inputProps.checked = checked; }
    // if (props.onChange !== undefined) { inputProps.onChange = props.onChange; }

    return (
        <div className={clsx("flex-row", props.className)} style={props.style}>
            {label ? (<FormControlLabel
                // className="flex-grow"
                {...inputProps}
                control={
                    <Checkbox
                        // className="flex-grow"
                        disabled={disabled}
                        name={props.name}
                        id={props.id}
                        checked={checked}
                        value={value}
                        onChange={props.onChange}
                    />
                }
            />) : (<Checkbox
                // className="flex-grow"
                disabled={disabled}
                name={props.name}
                id={props.id}
                checked={checked}
                value={value}
                onChange={props.onChange}
            />)}

        </div>
    );
};

export default memo(UICheckbox);
