import React, { memo } from "react";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
} from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { FormControl, FormHelperText } from "@material-ui/core";
import { CommonInputProps } from "../../../model/common-props";

type InputType = { label: string; value: string | number };
interface UICreatableSelectProps extends CommonInputProps {
  onChange?: (
    event: React.ChangeEvent<{}>,
    value: unknown,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown> | undefined
  ) => void;
  onBlur?: () => void;
  onClose?: () => void;
  options: InputType[];
  value?: string[];
  inputProps?: AutocompleteProps<InputType, true, false, true>;
}

const UICreatableSelect: React.FC<UICreatableSelectProps> = (props) => {
    let { inputProps } = props;
    const { options, label, value, errorMessage } = props;

    if (!inputProps) {
        inputProps = {
      renderInput: (params) => (
          <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder="Select"
          />
      ),
      options,
        };
    }

  // if (props.value !== undefined) { inputProps.value = props.value; }
    if (props.onChange !== undefined) {
        inputProps.onChange = props.onChange;
    }
    if (props.onBlur !== undefined) {
        inputProps.onBlur = props.onBlur;
    }
    if (props.onClose !== undefined) {
        inputProps.onClose = props.onClose;
    }

    const useStyles = makeStyles((theme: Theme) => createStyles({
      formControl: {
        // margin: theme.spacing(1),
        minWidth: 200,
        // maxWidth: 1000,
      },
      chips: {
        display: "flex",
        flexWrap: "wrap",
      },
      chip: {
        margin: 2,
      },
    }));
    const classes = useStyles();

    return (
        <div
            className={clsx("flex-row", props.className)}
            style={{ width: "100%", ...props.style }}
        >
            <FormControl
                className={clsx("flex-grow", classes.formControl)}
                variant="outlined"
                error={!!errorMessage}
            >
                <Autocomplete
                    multiple
                    id={props.id}
                    freeSolo
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    value={value}
                    renderTags={(value, getTagProps) => value.map((option, index) => (
                        <Chip
                            label={option?.label ?? option}
                            {...getTagProps({ index })}
                        />
                    ))}
                    {...inputProps}
                />
                {
                    !!errorMessage &&
                    <FormHelperText>{errorMessage}</FormHelperText>
                }
            </FormControl>
        </div>
    );
};
export default memo(UICreatableSelect);
