import * as yup from 'yup';
import React, { useRef } from 'react';
// import timezone from 'dayjs/plugin/timezone';
// import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';
import { AgreementTableData } from '../models/response';
import httpService from '../../../../service/http-service';
import { AgreementDialogProps } from '../models/agreement-dialog-props';
import { FormActionType, RowEventType } from '../models/types';
import { HttpStatusCode } from '../../../../model/http-status-code';
import appToast from '../../../app-toast';
import { DataLoadState } from '../../../../model/types';
import { AgreementFormData } from '../models/agreement-form-data';
import logger from '../../../../utils/log-utils';
import { ApiData } from '../../../../model/xhrCallState';
import { convertTimezone } from '../../../../utils/date-timezone-utils';

// dayjs.extend(utc);
// dayjs.extend(timezone);

// type FormActionType = "add" | "edit" | undefined;

export default function useAgreementDialog(activeSubscriberId?: string) {
    const startDate = dayjs();
    // const [startDatestate, setStartDateState] = React.useState(convertTimezone(dayjs().year(2015)).format())
    // const [endDateState, setEndDateState] = React.useState(convertTimezone(dayjs()).format())
    const [agreementTable, setAgreementTable] = React.useState<ApiData<AgreementTableData[]>>(new ApiData('Pending'));
    const [formActionType, setFormActionType] = React.useState<FormActionType>();
    const [formData, setFormData] = React.useState<AgreementTableData | null>();
    // const [url, setUrl] = React.useState<string | null>()
    const [tabValue, setTabValue] = React.useState(0);
    const [activeDeleteTransId, setActiveDeleteTransId] = React.useState<string>();
    const [fileUpload, setFileUpload] = React.useState<File | undefined>();
    const [fileUploadState, setFileUploadState] = React.useState<DataLoadState>("Completed");
    const formikRef = useRef<FormikProps<AgreementFormData> | null>(null);

    const onRowDeleteClick = (transId: string) => {
        setActiveDeleteTransId(transId);
    };
    const handleSnackBarClose = () => {
        setActiveDeleteTransId(undefined);
    };
    const handleSnackBarConfirm = () => {
        const ed = agreementTable?.data?.find(r => r.UploadId === activeDeleteTransId);
        if (ed) {
            deleteAgreementTableData(ed).then(() => {
                setActiveDeleteTransId(undefined);
                if (activeSubscriberId) { getAgreementTableData(activeSubscriberId); }
            });
        }
    };
    const handleChange = (newValue: number) => {
        setTabValue(newValue);
        setFileUpload(undefined);
        if (newValue === 1 && formActionType === undefined) {
            setFormActionType("add");
            setFormData(null);
            setFileUpload(undefined);
            // setUrl(null)
            formikRef.current?.setFieldValue("fileName", undefined);
        }
    };
    const handleEditIconChange = () => {
        setFormActionType("edit");
        setFileUpload(undefined);
        // handleChange(newValue)
    };
    const handleButtonChange = () => {
        setFormActionType("add");
        setFormData(null);
        // handleChange(undefined, 1);
        // setUrl(null)
        formikRef.current?.setFieldValue("fileName", undefined);
        setTabValue(1);
    };
    const customTimeRangeStart = (date: string) => {
        const st = dayjs(date);
        formikRef.current?.setFieldValue("fromDate", convertTimezone(st).format("MM/DD/YY"));
    };
    const customTimeRangeEnd = (date: string) => {
        const end = dayjs(date);
        formikRef.current?.setFieldValue("toDate", convertTimezone(end).format("MM/DD/YY"));
    };
    const handleButtonClose = () => {
        setFormActionType(undefined);
        setTabValue(0);
    };

    const handleFileUpload = (file: File) => {
        setFileUpload(file);
    };

    const handleUploadAgreementFile = (setFieldValue: (field: keyof AgreementFormData, value: any, shouldValidate?: boolean | undefined) => void) => () => {
        if (fileUpload) {
            uploadAgreementFile(fileUpload).then((url) => {
                setFieldValue("fileName", url);
                // setUrl(url);
            }).catch((err) => {
                appToast.error(err.message);
            });
        } else {
            appToast.error("Select a file");
        }
    };

    const uploadAgreementFile = async (file: File) => {
        const formData = new FormData();
        formData.append("file", file);
        setFileUploadState("Pending");
        try {
            const res = await httpService.post<string>(`/SalesPortal/UploadAgreementFile`, formData, { params: { SubscriberId: activeSubscriberId } });
            if (res.status === HttpStatusCode.OK) {
                setFileUploadState("Completed");
                setFileUpload(undefined);
                return res.data;
            }
            setFileUploadState("Failed");
            return Promise.reject(new Error("File Upload Failed"));
        } catch (error) {
            setFileUploadState("Failed");
            return Promise.reject(new Error("File Upload Failed"));
        }
    };

    const saveAgreementFile = async (values: AgreementFormData, setFieldValue: (field: keyof AgreementFormData, value: any, shouldValidate?: boolean | undefined) => void) => {
        try {
            const response = await httpService.post('/Subscriber/SetAgreementsBySubID', {
                "subscriberId": activeSubscriberId,
                "title": values.title,
                "fromActive": dayjs(values.fromDate).utc().toISOString(),
                "toActive": dayjs(values.toDate).utc().toISOString(),
                "comment": values.comment,
                "filePath": values.fileName,
            }, { params: { operation: 0 } });
            if (response.status === 200) {
                if (activeSubscriberId) {
                    getAgreementTableData(activeSubscriberId);
                    appToast.success("Agreement Saved!");
                    // setUrl(null)
                    setFieldValue("fileName", undefined);
                    setFileUpload(undefined); // doubt
                }
            } else {
                appToast.error("Agreement Couldn't be saved");
            }
        } catch (error) {
            appToast.error("Agreement Couldn't be saved");
        }
    };

    const onRowEventType = (id: string, eventType: RowEventType) => {
        switch (eventType) {
            case "edit": {
                const ed = agreementTable?.data?.find(r => r.UploadId === id);
                setFormData(ed);
                handleEditIconChange();
                // setUrl(ed?.FilePath)
                formikRef.current?.setFieldValue("filename", ed?.FilePath);
                setTabValue(1);
                // setFileName(ed?.FilePath?.substring(ed?.FilePath?.lastIndexOf('/') + 1))
                break;
            }
            case "delete": {
                onRowDeleteClick(id);
                break;
            }
            case "view": {
                const vw = agreementTable?.data?.find(r => r.UploadId === id);
                window.open(vw?.FilePath);
                break;
            }

            default: break;
        }
    };
    React.useEffect(() => {
        if (activeSubscriberId) { getAgreementTableData(activeSubscriberId); }
        return () => {
            setTabValue(0);
        };
    }, [activeSubscriberId]);

    const extractFileName = (path: string | undefined) => {
        return path?.substring(path.lastIndexOf('/') + 1) || "";
    };

    const iniValues: AgreementFormData = {
        title: formData?.Title ?? "",
        comment: formData?.Comment ?? "",
        fromDate: formData?.FromActive ?? convertTimezone(startDate).toISOString(),
        toDate: formData?.ToActive ?? convertTimezone(dayjs()).toISOString(),
        fileName: formData?.FilePath ?? ""
    };

    const valSchema = yup.object().shape({
        fromDate: yup.string(),
        toDate: yup.string(),
        title: yup.string().required("Title is required"),
        fileName: yup.string().required("Filename is required"),
        comment: yup.string()
    });

    const getAgreementTableData = async (activeSubscriberId: string) => {
        setAgreementTable(new ApiData("Pending"));
        try {
            const res = await httpService.get<{ Table: AgreementTableData[] }>(`/Subscriber/GetAllUploadedAgreementsBySubid`, { params: { subscriberId: activeSubscriberId } });
            setAgreementTable({ status: "Completed", data: res.data?.Table || [] });
            // console.log(res.data)
        } catch (error) {
            setAgreementTable(new ApiData("Failed"));
            logger.error(error);
        }
    };

    const deleteAgreementTableData = async (tableData: AgreementTableData) => {
        try {
            const currentDate = convertTimezone(dayjs());
            const response = await httpService.post('/Subscriber/SetAgreementsBySubID', {
                "Bitmask": 0,
                "subscriberId": tableData.SubscriberID,
                "FromActive": currentDate.toISOString(),
                "UploadId": tableData.UploadId

            }, { params: { operation: 2 } });
            if (response.status === HttpStatusCode.OK) {
                appToast.info("Agreement has been deleted");
            }
        } catch (error) {
            appToast.error("Couldn't delete the agreement!");
        }
    };

    const dateValidCheck = (date: string) => {
        if (date === "Invalid Date") return "";
        return date;
    };
    const getData = () => agreementTable?.data?.map((r, i) => ({
        title: r.Title,
        from: dateValidCheck(dayjs(r.FromActive).format("MM/DD/YYYY")),
        to: dateValidCheck(dayjs(r.ToActive).format("MM/DD/YYYY")),
        comment: r.Comment,
        id: r.UploadId
    }));

    const getTabTitle = (actionType: FormActionType) => {
        switch (actionType) {
            case "add":
                return "Add";
            case "edit":
                return "Edit";
            default:
                return "Add/Edit";
        }
    };

    return {
        iniValues,
        valSchema,
        handleButtonChange,
        customTimeRangeEnd,
        customTimeRangeStart,
        getData,
        handleEditIconChange,
        onRowEventType,
        extractFileName,
        handleButtonClose,
        tabValue,
        setTabValue,
        formActionType,
        handleChange,
        handleSnackBarClose,
        activeDeleteTransId,
        handleSnackBarConfirm,
        getTabTitle,
        agreementTable,
        uploadAgreementFile,
        handleFileUpload,
        fileUpload,
        handleUploadAgreementFile,
        fileUploadState,
        saveAgreementFile,
        formikRef
    };
}
