import jwtDecode from "jwt-decode";

import { AccountUser } from "../model";
import { StorageService } from "./storage-service";
import httpService from "./http-service";
import { redirectTo } from "../utils/route-utils";
import { deleteCookie, setCookie } from "../utils/cookie-utils";
import { setSentryLogUser } from "../serverLog";
import { getWorkerInstance } from "../workers";
import { UserRightKeys } from "../model/types";
import appToast from "../components/app-toast";
import logger from "../utils/log-utils";
import appConfig from "../utils/app-config-utils";

const storage = new StorageService('cl-auth');
const storageRights = new StorageService('cl-auth-rights');

export function isAuthed(): boolean {
    return !!storage.get();
}

export async function initialize(): Promise<void> {
    try {
        const auth = storage.get();
        if (auth) {
            const token = auth.accessToken;
            getWorkerInstance("remoteLogger")?.injectAuthToken(token);
            httpService.injectAuthToken(token);
            try {
                const userRights = await httpService.get<UserRightKeys[]>('Auth/GetUserRights');
                storageRights.set(userRights.data);
                const user = jwtDecode<AccountUser>(token);
                setSentryLogUser({ email: user?.email, id: user?.sub_id, role: user?.role });
            } catch (err) {
                appToast.error("Couldn't fetch the user rights");
                logger.error(err);
            }
        } else {
            httpService.removeAuthToken();
        }
    } catch (err) {
        httpService.removeAuthToken();
    }
}

export function getCurrentUser(): AccountUser | undefined {
    try {
        const token = storage.get().accessToken;
        const authRights = storageRights.get();
        getWorkerInstance("remoteLogger")?.injectAuthToken(token);
        httpService.injectAuthToken(token);
        const user = jwtDecode<AccountUser>(token);
        user.rights = authRights;
        return user;
    } catch (err) {
        appToast.error("Couldn't fetch the user rights");
        logger.error(err);
    }
    return undefined;
}

export async function onLoginSuccess(token: { accessToken: string; tokenType: string; expiresIn: number }, postLoginPath: string = '/'): Promise<void> {
    if (token) {
        try {
            const user = jwtDecode<AccountUser>(token.accessToken);
            // Just to verify a valid token
            if (user) {
                httpService.injectAuthToken(token.accessToken);
                getWorkerInstance("remoteLogger")?.injectAuthToken(token.accessToken);
                const userRights = await httpService.get<UserRightKeys[]>('Auth/GetUserRights');
                if (user.auth_token) {
                    setCookie(appConfig.cookieKeys.FCP_CWWEB, user.auth_token, 10);
                }
                setCookie(appConfig.cookieKeys.NEW_FCP, true, 10);
                storage.set(token);
                storageRights.set(userRights.data);
                setSentryLogUser({ email: user?.email, id: user?.sub_id, role: user?.role });
            }
            redirectTo(postLoginPath);
        } catch (err) {
            appToast.error("Couldn't fetch the user rights");
            logger.error(err);
        }
    }
}

export function logout() {
    storage.clear();
    storageRights.clear();
    setSentryLogUser(null);
    deleteCookie(appConfig.cookieKeys.FCP_CWWEB);
    deleteCookie(appConfig.cookieKeys.NEW_FCP);
    deleteCookie(appConfig.cookieKeys.IS_TESTMODE);
    deleteCookie(appConfig.cookieKeys.MANAGE_SAAS_LLP);
    httpService.removeAuthToken();
    getWorkerInstance("remoteLogger")?.removeAuthToken();
    redirectTo('/login');
}
