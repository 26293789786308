import { createSlice, Dispatch, PayloadAction } from "@reduxjs/toolkit";
import { AppStore } from "..";
import { SUCCESS } from "../../constant/redux-constants";
import { DateFilterParam } from "../../model/date-filter-param";
import appConfig from "../../utils/app-config-utils";
import { setCookie } from "../../utils/cookie-utils";
import { get } from "../../utils/http-utils";
import { buildGenericInitialState, handleError, selectGenericsOfActionType, updateStore } from "../../utils/store-utils";
import { GenericState } from "../types";
import { setCurrentSubscriberInfo } from "./subscriber";

interface InitialState extends GenericState {
    subscriberViewEnabled?: boolean;
    isPaymentProcessing?: boolean;
    dateFilterData?: DateFilterParam;
    dateFilterDataForRepo?: DateFilterParam;
    feedBackDialogOpen?: boolean;
    isSideBarCollapsed?: boolean;
    testMode?: boolean;
}

const initialState: InitialState = {
    subscriberViewEnabled: false,
    feedBackDialogOpen: false,
    isSideBarCollapsed: false,
    testMode: false,
    // dateFilterData: {
    //     StartDateRange: dayjs().startOf('month').format('MM-DD-YYYY'),
    //     EndDateRange: dayjs().format('MM-DD-YYYY')
    // },
    ...buildGenericInitialState([]),
    loading: {
        "ui/enableSubscriberView": true
    }
};

// Slices
const { actions, reducer } = createSlice({
    name: "ui",
    initialState,
    reducers: {
        enableSubscriberView: (state, action: PayloadAction<boolean>) => {
            return updateStore<typeof state>(state, action, {
                subscriberViewEnabled: !!action.payload,
            });
        },
        backToAdminView: (state, action: PayloadAction<void>) => {
            return updateStore<typeof state>(state, action, {
                subscriberViewEnabled: false,
            });
        },
        isPaymentProcessing: (state, action: PayloadAction<boolean>) => {
            return updateStore<typeof state>(state, action, {
                isPaymentProcessing: action.payload,
            });
        },
        setCollapseSidebarState: (state, action: PayloadAction<boolean>) => {
            return updateStore<typeof state>(state, action, {
                isSideBarCollapsed: action.payload,
            });
        },
        setTestModeState: (state, action: PayloadAction<boolean>) => {
            return updateStore<typeof state>(state, action, {
                testMode: action.payload,
            });
        },
        setFilterDate: (state, action: PayloadAction<DateFilterParam>) => {
            return updateStore<typeof state>(state, action, {
                dateFilterData: action.payload,
            });
        },
        setFilterDateForRepo: (state, action: PayloadAction<DateFilterParam>) => {
            return updateStore<typeof state>(state, action, {
                dateFilterDataForRepo: action.payload,
            });
        },
        setFeedBackDialogOpen: (state, action: PayloadAction<boolean>) => {
            return updateStore<typeof state>(state, action, {
                feedBackDialogOpen: action.payload,
            });
        }
    }
});

export default { reducer };
export const { backToAdminView, isPaymentProcessing, setFilterDate, setFilterDateForRepo, enableSubscriberView, setFeedBackDialogOpen, setCollapseSidebarState, setTestModeState } = actions;

// Action Creators
export const switchToSubscriberView = (subscriberId: string) => async (dispatch: Dispatch) => {
    const type = actions.enableSubscriberView.type;
    try {
        const response = await get(dispatch, type, `subscriber/GetSubscriberInfo`, { id: subscriberId });

        if (response.data) {
            dispatch(setCurrentSubscriberInfo(response.data));
            dispatch({
                type,
                meta: { status: SUCCESS },
                payload: !!response.data,
            });
        }
    } catch (err: any) {
        await handleError(dispatch, err, type);
    }
};
export const changeTestMode = (testModeState: boolean) => async (dispatch: Dispatch) => {
    const type = actions.setTestModeState.type;
    try {
        dispatch(setTestModeState(testModeState));
        setCookie(appConfig.cookieKeys.IS_TESTMODE, testModeState, 10);
    } catch (err: any) {
        await handleError(dispatch, err, type);
    }
};

// selectors
export const selectSwitchToSubscriberView = ({ ui }: AppStore) => ({
    enable: ui.subscriberViewEnabled,
    ...selectGenericsOfActionType<InitialState>(ui, actions.enableSubscriberView.type)
});
export const selectCollapseSideBar = ({ ui }: AppStore) => ({
    isSideBarCollapsed: ui.isSideBarCollapsed,
    ...selectGenericsOfActionType<InitialState>(ui, actions.setCollapseSidebarState.type)
});
export const selectTestMode = ({ ui }: AppStore) => ({
    testMode: ui.testMode,
    ...selectGenericsOfActionType<InitialState>(ui, actions.setTestModeState.type)
});

export const selectDateFilter = ({ ui }: AppStore) => ({
    dateRangeFilter: ui.dateFilterData, ...selectGenericsOfActionType<InitialState>(ui, actions.setFilterDate.type)
});

export const selectDateFilterForRepo = ({ ui }: AppStore) => ({
    dateFilterDataForRepo: ui.dateFilterDataForRepo, ...selectGenericsOfActionType<InitialState>(ui, actions.setFilterDateForRepo.type)
});

export const selectFeedBackOpen = ({ ui }: AppStore) => ({
    feedBackDialog: ui.feedBackDialogOpen, ...selectGenericsOfActionType<InitialState>(ui, actions.setFeedBackDialogOpen.type)
});
// export const selectEndDate = ({ui}: AppStore) => ({
//     endDateRange: ui.dateFilterData?.EndDateRange, ...selectGenericsOfActionType<InitialState>(ui, actions.setFilterDate.type)
// });
