import clsx from 'clsx';
import React from 'react';
import errorImage from "../../assets/images/404-vector.svg";
import unauthedImage from "../../assets/images/401-vector.svg";

interface FailedComponentProps {
    size?: "small" | "medium" | "large";
    type: "unauthorized" | "error";
    text?: string;
    className?: string;
}

const FailedComponent: React.FC<FailedComponentProps> = (props: FailedComponentProps) => {
    const { size, text, type, className } = props;
    const getImageSrc = () => {
        switch (type) {
            case "unauthorized":
                return unauthedImage;
            case "error":
                return errorImage;
            default:
                break;
        }
        return "";
    };
    return <div className={clsx("failed-component", size ?? "small", className)}>
        {getImageSrc() && <img src={getImageSrc()} alt="error" />}
        {
            !!text &&
            <span className="failed-data">{text ?? ""}</span>
        }
    </div>;
};

export default FailedComponent;
