import React from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
// import { HttpStatusCode } from '../../../../model/http-status-code';
import { DataLoadState } from '../../../../model/types';
import httpService from '../../../../service/http-service';
import { selectUser } from '../../../../store/modules/authentication';
// import { objectToQuery } from '../../../../utils/uri-utils';
import appToast from '../../../app-toast';
import { FeedBackDialogProps } from '../models/feedback-dialog-props';

export interface FeedBackFormData {
    emailId: string,
    subject: string,
    message: string,
    type: number,
    fileName: string;
}
export interface IniValue {
    emailId: string,
    subject: string,
    message: string,
    type: number,
    fileName: string;
    // subscriberId: string
}

type SetSubmittingType = (isSubmitting: boolean) => void;

export default function useFeedBackDialog(props: FeedBackDialogProps) {
    const [fileUpload, setFileUpload] = React.useState<File | undefined>();
    const [fileUploadState, setFileUploadState] = React.useState<DataLoadState>("Completed");
    // const { currentSubscriber } = useSelector(selectSubscriberInfo);
    // const [qparam, SetQparam] = React.useState<IniValue>();
    const { user } = useSelector(selectUser);
    const { onSubmissionComplete } = props;

    // React.useEffect(() => {
    //     if (qparam) sendFeedBack(qparam);
    //     // logger.log(user?.sub_id);
    // }, [qparam]);

    // const handleFileUpload = (file: File) => {
    //     setFileUpload(file);
    // };
    const initialValue = {
        emailId: user?.email ?? "",
        subject: "",
        message: "",
        type: 4,
        fileName: ""
    };
    const validationSchema = yup.object().shape({
        emailId: yup.string().required("Email id is required"),
        subject: yup.string().required("Subject is required"),
        message: yup.string().required("Message is required"),
        type: yup.number()
    });

    // const uploadAgreementFile = async (file: File) => {
    //     const formData = new FormData();
    //     formData.append("file", file);
    //     setFileUploadState("Pending");
    //     const object = {

    //         SubscriberId: user?.sub_id,

    //     };
    //     const query = objectToQuery(object);
    //     try {
    //         const res = await httpService.post<string>(`/SalesPortal/UploadAgreementFile?${query}`, formData);
    //         if (res.status === HttpStatusCode.OK) {
    //             setFileUploadState("Completed");
    //             setFileUpload(undefined);
    //             return res.data;
    //         }
    //         setFileUploadState("failed");
    //         return Promise.reject(new Error("File Upload Failed"));
    //     } catch (error) {
    //         setFileUploadState("failed");
    //         return Promise.reject(new Error("File Upload Failed"));
    //     }
    // };
    const sendFeedBack = async (qparam: IniValue, setSubmitting: SetSubmittingType, reCaptchaToken?: string) => {
        const object = {

            SubscriberId: user?.sub_id,
            UserName: qparam?.emailId,
            Description: qparam?.message,
            Subject: qparam?.subject,
            SubscriptionType: qparam?.type,
            // UploadID: qparam.fileName

        };
        // const query = objectToQuery(object);
        try {
            setSubmitting(true);
            const res = await httpService.post<string>(`Support/SetFeedBackInfo?`, object, { params: { reCaptchaToken } });
            if (res.status === 200) {
                appToast.success("Feedback Sent Succesfully");
                setSubmitting(false);
                if (onSubmissionComplete) onSubmissionComplete("Completed");
            }
        } catch (error) {
            appToast.error("feedback couldn't be sent successfully");
            setSubmitting(false);
            if (onSubmissionComplete) onSubmissionComplete("Failed");
        }
    };

    // const handleUploadFile = (setFieldValue: (field: keyof FeedBackFormData, value: any, shouldValidate?: boolean | undefined) => void) => () => {
    //     if (fileUpload) {
    //         uploadAgreementFile(fileUpload).then((url) => {
    //             setFieldValue("fileName", url);
    //             // setUrl(url);
    //         }).catch((err) => {
    //             appToast.error(err.message);
    //         });
    //     } else {
    //         appToast.error("Select a file");
    //     }
    // };
    return {
        initialValue, validationSchema, sendFeedBack, fileUpload, fileUploadState,
    };
}
