export enum _VehicleType {
    // [Description("Pre Owned")]
    PreOwned = -1,
    None = 0,
    Acura = 1,
    // [Description("Aston Martin")]
    AstonMartin = 2,
    Audi = 3,
    Bentley = 4,
    BMW = 5,
    Bugatti = 6,
    Buick = 7,
    Cadillac = 8,
    Chevrolet = 9,
    Chrysler = 10,
    Dodge = 11,
    Ferrari = 12,
    FIAT = 13,
    Fisker = 14,
    Ford = 15,
    GMC = 16,
    Honda = 17,
    Hyundai = 18,
    Infiniti = 19,
    Jaguar = 20,
    Jeep = 21,
    Kia = 22,
    Lamborghini = 23,
    // [Description("Land Rover")]
    LandRover = 24,
    Lexus = 25,
    Lincoln = 26,
    Lotus = 27,
    Maserati = 28,
    Maybach = 29,
    Mazda = 30,
    McLaren = 31,
    MercedesBenz = 32,
    Mercury = 33,
    MINI = 34,
    Mitsubishi = 35,
    Nissan = 36,
    Porsche = 37,
    Ram = 38,
    RollsRoyce = 39,
    Saab = 40,
    Scion = 41,
    Smart = 42,
    Spyker = 43,
    Subaru = 44,
    Suzuki = 45,
    Tesla = 46,
    Toyota = 47,
    Volkswagen = 48,
    Volvo = 49,
    Other = 50,
    // [Description("Multi Brand")]
    MultiBrand = 51
}

export enum _BoatType {
    PreOwned = -1,
    None = 0,
    AFTheriaultSonsShipyard = 1,
    AAD = 2,
    AB = 3,
    Abaco = 4,
    Abbott = 5,
    ABDAluminum = 6,
    AbekingRasmussen = 7,
    Able = 8,
    AbsolutePowerboats = 9,
    Acadia = 10,
    Achilles = 11,
    Acquaviva = 12,
    ActeMarine = 13,
    Action = 14,
    ActionCraft = 15,
    ActionMarine = 16,
    Activator = 17,
    ActiveThunder = 18,
    Adams = 19,
    Addictor = 20,
    Adler = 21,
    Admiral = 22,
    Adrenaline = 23,
    Adria = 24,
    Advance = 25,
    AdvancedOutdoors = 26,
    Advantage = 27,
    AdventureCraft = 28,
    AdventureHouseboat = 29,
    AdversityBoats = 30,
    Aeon = 31,
    Aerocraft = 32,
    AeroTekHighPerformance = 33,
    Affinity = 34,
    AfricatMarine = 35,
    Aftershock = 36,
    AganturYachting = 37,
    AIBS = 38,
    Aicon = 39,
    AirRanger = 40,
    AirboatsUnlimited = 41,
    Aksano = 42,
    AlDavidPerformanceAirboats = 43,
    Alalunga = 44,
    Alaskan = 45,
    Albatross = 46,
    Albemarle = 47,
    AlbinYachts = 48,
    AlburyBrothers = 49,
    Alcock = 50,
    AlconYachts = 51,
    AldenYachts = 52,
    Alerion = 53,
    AlexWillis = 54,
    AlexandraSteel = 55,
    Alglas = 56,
    AllCraft = 57,
    AllianceMarine = 58,
    Allied = 59,
    AlliedPrincess = 60,
    Allison = 61,
    Allmand = 62,
    AllWeld = 63,
    Almar = 64,
    AlohaPontoonbyWacoMfg = 65,
    AlohaYachts = 66,
    Alpa = 67,
    AlpexbyAlumacraft = 68,
    Alpina = 69,
    AlsbergBrothers = 70,
    AlsbergClassic = 71,
    Alson = 72,
    Altima = 73,
    Altinel = 74,
    Alumacraft = 75,
    Alumaweld = 76,
    Alumigator = 77,
    AluminumChamberedBoats = 78,
    AluminumCruisers = 79,
    AluminumMarine = 80,
    Alumitech = 81,
    Alumituff = 82,
    Alura = 83,
    Alweld = 84,
    Amapalama = 85,
    Amato = 86,
    AmbroseFulcher = 87,
    Amel = 88,
    AmelYachts = 89,
    Amels = 90,
    Ameracat = 91,
    American = 92,
    AmericanBoat = 93,
    AmericanCommercial = 94,
    AmericanInternationalMarine = 95,
    AmericanMarine = 96,
    AmericanMariner = 97,
    AmericanMultihulls = 98,
    AmericanOffshore = 99,
    AmericanRediBuilt = 100,
    AmericanRiverTaxi = 101,
    AmericanSkier = 102,
    AmericanSkiff = 103,
    AmericanTug = 104,
    AMF = 105,
    AMTPowerboats = 106,
    AnacapriMarine = 107,
    AndréeRosenqvist = 108,
    AndrewPropeller = 109,
    Andrews = 110,
    Andreyale = 111,
    Andros = 112,
    AndyMortensen = 113,
    Angel = 114,
    Angelman = 115,
    Angler = 116,
    Annapolis = 117,
    AnswerMarine = 118,
    Antago = 119,
    Antigua = 120,
    Antrim = 121,
    AokoYachts = 122,
    ApachePowerboats = 123,
    ApexInflatables = 124,
    ApexNavis = 125,
    Apollo = 126,
    ApplebeeManufacturing = 127,
    Apreamare = 128,
    AquaBay = 129,
    AquaChalet = 130,
    AquaCruiser = 131,
    AquaCycle = 132,
    AquaForce = 133,
    AquaPatio = 134,
    AquaCraft = 135,
    Aquapro = 136,
    Aquarius = 137,
    Aquascan = 138,
    Aquasport = 139,
    Aquaterra = 140,
    Aquatron = 141,
    AriesBoats = 142,
    Arima = 143,
    Aristocraft = 144,
    ArkansasTraveler = 145,
    Armada = 146,
    Armstrong = 147,
    Arno = 148,
    ArnoldWalker = 149,
    Aronow = 150,
    ARP = 151,
    Arriva = 152,
    ArrowGlassBoat = 153,
    ArvidLaurin = 154,
    Arvidson = 155,
    ArzuYachts = 156,
    AshCraft = 157,
    AstillerosGallart = 158,
    Astondoa = 159,
    Astro = 160,
    Atlantic = 161,
    AtlanticChallenge = 162,
    AtlantisYachts = 163,
    Atlas = 164,
    AurorabyTritonIndustries = 165,
    Avalon = 166,
    AvalonTahoe = 167,
    Avance = 168,
    Avanti = 169,
    AvengerbyCeeBeeMfg = 170,
    Aventure = 171,
    Avon = 172,
    Awesome = 173,
    Axis = 174,
    Azimut = 175,
    Aztec = 176,
    Azuli = 177,
    Azure = 178,
    BDBoatworks = 179,
    BRMarine = 180,
    BCraft = 181,
    BHM = 182,
    BackBay = 183,
    BackCountry = 184,
    BackCove = 185,
    Baglietto = 186,
    BahaCruisers = 187,
    Bahama = 188,
    Bahia = 189,
    Bahner = 190,
    Baia = 191,
    Baja = 192,
    BakerCustom = 193,
    BakewellWhite = 194,
    BalBoa = 195,
    Baldwin = 196,
    Baltic = 197,
    Baltik = 198,
    BananaBoat = 199,
    Bandit = 200,
    Banshee = 201,
    BarbaryCove = 202,
    BarcoestSearibs = 203,
    Barens = 204,
    Barka = 205,
    Barnett = 206,
    Baron = 207,
    Barreta = 208,
    BartenderBoats = 209,
    BassCat = 210,
    BassHawkBoat = 211,
    BassMaster = 212,
    BassTender = 213,
    BassTrackerPro = 214,
    Bateau = 215,
    Bavaria = 216,
    BayMax = 217,
    BayQuest = 218,
    BayStealth = 219,
    BayWeld = 220,
    Baycraft = 221,
    BayfieldYachts = 222,
    Bayhawk = 223,
    Bayliner = 224,
    Baymaster = 225,
    Bayrunner = 226,
    Bayshore = 227,
    Baysport = 228,
    Beachcat = 229,
    Beachcomber = 230,
    Beachcraft = 231,
    Bear = 232,
    BeavertailSkiffs = 233,
    Beckmann = 234,
    BeeLine = 235,
    BellHalter = 236,
    Bella = 237,
    Bellboy = 238,
    Bender = 239,
    Beneteau = 240,
    Bénéteau = 241,
    Benetti = 242,
    Benford = 243,
    Bennett = 244,
    Bennington = 245,
    Bentley = 246,
    BerkleyEngineering = 247,
    Berkshire = 248,
    Bertram = 249,
    BertramYacht = 250,
    Bestway = 251,
    Bianca = 252,
    BigSandbarCustom = 253,
    BillyHolton = 254,
    Bimini = 255,
    Biscayne = 256,
    BlackThunder = 257,
    BlackTip = 258,
    BlackWatch = 259,
    Blackfin = 260,
    Blackhawk = 261,
    Blackjack = 262,
    Blackman = 263,
    Blackwell = 264,
    Blazer = 265,
    BlazerBay = 266,
    BlohmVoss = 267,
    BlueBuoy = 268,
    BlueFin = 269,
    BlueLightning = 270,
    BlueSeas = 271,
    BlueWaterBoats = 272,
    BlueWaterCruisingYachts = 273,
    BlueWave = 274,
    Bluewater = 275,
    BluewaterYachts = 276,
    BluWingTriStar = 277,
    BMH = 278,
    Boatel = 279,
    BobRoth = 280,
    BocaGrande = 281,
    Bodrum = 282,
    Bolger = 283,
    Bombardier = 284,
    BombayClipper = 285,
    BombayLaunch = 286,
    Bomber = 287,
    Bonadeo = 288,
    BoneBoats = 289,
    Bonefish = 290,
    Bones = 291,
    Bonito = 292,
    Bonner = 293,
    Borresen = 294,
    Bossman = 295,
    BostonWhaler = 296,
    Boulton = 297,
    Bounty = 298,
    Bountyhunter = 299,
    Bradley = 300,
    Breaux = 301,
    Brerons = 302,
    Breuil = 303,
    Brewer = 304,
    BridgesPoint = 305,
    Brigg = 306,
    Brighton = 307,
    BristolBay = 308,
    BristolYachts = 309,
    Brookes = 310,
    Broward = 311,
    Brownell = 312,
    Browning = 313,
    BRT = 314,
    BruceRoberts = 315,
    BrunoStillman = 316,
    BrunswickBoatGroup = 317,
    Bryant = 318,
    Buchan = 319,
    BuddyDavis = 320,
    Bull = 321,
    Bullet = 322,
    BumbleBee = 323,
    Burger = 324,
    BurgerBoatCompany = 325,
    BurnsCraft = 326,
    Burpee = 327,
    Busken = 328,
    Buster = 329,
    BWA = 330,
    CCYachts = 331,
    CL = 332,
    CaboRico = 333,
    CaboRicoYachts = 334,
    CaboYachts = 335,
    CacciCraft = 336,
    Cadorette = 337,
    Cajun = 338,
    CAL = 339,
    CalWestern = 340,
    CalYachts = 341,
    Calabria = 342,
    Caliber = 343,
    Caliber1 = 344,
    CaliforniaPerformance = 345,
    CaliforniaSkier = 346,
    CaliforniaYachts = 347,
    Californian = 348,
    CalvinBeal = 349,
    Calypso = 350,
    Camano = 351,
    Camargue = 352,
    CamperNicholsons = 353,
    Campion = 354,
    CanadianElectric = 355,
    CanadianSailcraft = 356,
    Canados = 357,
    CanoeCove = 358,
    CantiereNautico = 359,
    CantieriDiPisa = 360,
    CanyonBay = 361,
    CapeCraft = 362,
    CapeDory = 363,
    CapeHorn = 364,
    CapitalYachts = 365,
    Capps = 366,
    CaptainsCraft = 367,
    Captiva = 368,
    Caracal = 369,
    CaracalCat = 370,
    Caravelle = 371,
    Caribbean = 372,
    Caribe = 373,
    CarlCraft = 374,
    Carlson = 375,
    Carnevali = 376,
    Carolina = 377,
    CarolinaBay = 378,
    CarolinaClassic = 379,
    CarolinaSkiff = 380,
    CarreraCustom = 381,
    CarriCraft = 382,
    CarrollLowell = 383,
    CarrollMarine = 384,
    Carstens = 385,
    Carvel = 386,
    Carver = 387,
    CarverYachts = 388,
    Cary = 389,
    Cascade = 390,
    Casey = 391,
    Castolidi = 392,
    CatLimbo = 393,
    Catalina = 394,
    CatalinaYachts = 395,
    Catamaran = 396,
    CatamaranCoaches = 397,
    CatamaranCruisers = 398,
    Catch = 399,
    CatCraft = 400,
    Catera = 401,
    Cayman = 402,
    CCraft = 403,
    CDorybyTritonMarineIndustries = 404,
    Celebrity = 405,
    Cenmarine = 406,
    Centra = 407,
    Centurion = 408,
    Century = 409,
    Chadwick = 410,
    Challenger = 411,
    Champ = 412,
    Champion = 413,
    ChantiersLesterel = 414,
    Chaos = 415,
    Chaparral = 416,
    ChaparralBoats = 417,
    Charger = 418,
    CHawk = 419,
    CHB = 420,
    Checkmate = 421,
    Cheetah = 422,
    CheoyLee = 423,
    Cherokee = 424,
    Cherubini = 425,
    Chesapeake = 426,
    Cheyenne = 427,
    Chiasson = 428,
    ChienHwa = 429,
    ChineseJunk = 430,
    Chinook = 431,
    Chivers = 432,
    Choate = 433,
    Chopper = 434,
    ChrisCraft = 435,
    ChrisCraftBoats = 436,
    CHW = 437,
    CigalaBertinetti = 438,
    Cigarette = 439,
    CimShipyard = 440,
    Cimmaron = 441,
    CimmarronBoats = 442,
    Citation = 443,
    Classic = 444,
    Classico = 445,
    Clearwater = 446,
    ClipperCraft = 447,
    ClipperMarine = 448,
    ClydeBoats = 449,
    Coastal = 450,
    Cobalt = 451,
    Cobia = 452,
    Cobra = 453,
    Cobrera = 454,
    Cobscook = 455,
    Codecasa = 456,
    ColeBoats = 457,
    Coleman = 458,
    Colgate = 459,
    ColinArcher = 460,
    Colonial = 461,
    Columbia = 462,
    CometBoats = 463,
    Commander = 464,
    CommercialWaterSports = 465,
    Commodore = 466,
    ComPac = 467,
    Compass = 468,
    Competition = 469,
    Compmillenia = 470,
    ConceptMarine = 471,
    Conch = 472,
    Concord = 473,
    Concorde = 474,
    Concordia = 475,
    Condor = 476,
    Confab = 477,
    Connection = 478,
    ConnollyDemarco = 479,
    Connor = 480,
    Conquest = 481,
    Conroy = 482,
    ConsolidatedDiesel = 483,
    ConsolidatedSpeed = 484,
    Constellation = 485,
    Contender = 486,
    Contessa = 487,
    Contest = 488,
    ContinentalBoats = 489,
    Contour = 490,
    CooperMarine = 491,
    CooperYachts = 492,
    Corbin = 493,
    Corinthian = 494,
    Coronado = 495,
    CorrectCraft = 496,
    Corsa = 497,
    CorsairMarine = 498,
    Corson = 499,
    CortenSteel = 500,
    Costa = 501,
    Couach = 502,
    Cougar = 503,
    CrHeisler = 504,
    CraigCraft = 505,
    CraigCat = 506,
    Cranchi = 507,
    Creekmore = 508,
    Crest = 509,
    Crestliner = 510,
    Crocker = 511,
    Crosby = 512,
    Croswait = 513,
    Crown = 514,
    Crownline = 515,
    Crowther = 516,
    CrozierCraft = 517,
    CruiseCraft = 518,
    CruiseaHome = 519,
    Cruisers = 520,
    CruisersYachts = 521,
    Cruizon = 522,
    Crusader = 523,
    CSBoatWorks = 524,
    CSY = 525,
    CtYachts = 526,
    Custom = 527,
    CustomAluminum = 528,
    CustomBuilt = 529,
    CustomCarolina = 530,
    CustomCraft = 531,
    CustomLiberty = 532,
    CustomVernese = 533,
    CustomWeld = 534,
    Cutter = 535,
    Cutwater = 536,
    CWSArielPro = 537,
    CypressCay = 538,
    Cyprus = 539,
    DAYachts = 540,
    Dakota = 541,
    Dana = 542,
    DargelExplorer = 543,
    DaveSintas = 544,
    DavesCustom = 545,
    DavisYachts = 546,
    Dawson = 547,
    Daytona = 548,
    DCB = 549,
    DeBirsYachts = 550,
    DeVriesLentsch = 551,
    DeadRise = 552,
    DeepImpact = 553,
    DeepSeaMarine = 554,
    DeepWaters = 555,
    Defender = 556,
    Defever = 557,
    Defiance = 558,
    Defiant = 559,
    Delhi = 560,
    Delta = 561,
    Deluxe = 562,
    Dennison = 563,
    Deno = 564,
    Derecktor = 565,
    DeschampJackson = 566,
    Desco = 567,
    DesertShores = 568,
    DestinationYacht = 569,
    Dettling = 570,
    DevlinDesigningBoatBuilders = 571,
    Diablo = 572,
    Diamondback = 573,
    Dickerson = 574,
    Dickson = 575,
    Ditchburn = 576,
    DixieBoatWorks = 577,
    DMIAluminum = 578,
    DMR = 579,
    Dobbertin = 580,
    Dobroth = 581,
    Dodge = 582,
    Dodwell = 583,
    Dolphin = 584,
    Dominator = 585,
    DonSenor = 586,
    DonSmith = 587,
    Donelle = 588,
    Donzi = 589,
    Dorado = 590,
    Doral = 591,
    Dorsett = 592,
    DougLarson = 593,
    DoughertyMarine = 594,
    DouglassSabre = 595,
    DownEast = 596,
    Dragon = 597,
    Dragonfly = 598,
    Dragos = 599,
    Dravo = 600,
    Dream = 601,
    Drifter = 602,
    Duckmaster = 603,
    Duckworth = 604,
    Duffy = 605,
    DuffyDuffy = 606,
    Dufour = 607,
    Duracraft = 608,
    Durbeck = 609,
    Duroboat = 610,
    DuskyMarine = 611,
    DutchBarge = 612,
    DutchBuilt = 613,
    Dyer = 614,
    Dyna = 615,
    Dynasty = 616,
    DynatechbyDynatechIndustries = 617,
    ETicket = 618,
    Eagle = 619,
    EagleCraft = 620,
    EaglePacific = 621,
    EastBay = 622,
    EastCape = 623,
    Eastbay = 624,
    Eastern = 625,
    Eastport = 626,
    Eastward = 627,
    Ebbtide = 628,
    EdMonk = 629,
    EddieVarnam = 630,
    EdelbyEdelCatamarans = 631,
    EdgeMarine = 632,
    EdgeRunner = 633,
    Edgewater = 634,
    EggHarbor = 635,
    ElPescador = 636,
    ElcoMarine = 637,
    Elgin = 638,
    Eliminator = 639,
    EliteCraft = 640,
    EliteYachts = 641,
    Elling = 642,
    Ellis = 643,
    Elzey = 644,
    Emerald = 645,
    EmeraldBay = 646,
    EncoreBoatBuilders = 647,
    Endeavor = 648,
    Endeavour = 649,
    Endeavourcat = 650,
    Endurance = 651,
    Englund = 652,
    Envision = 653,
    Epic = 654,
    EquitableEquipmentCo = 655,
    Ercoa = 656,
    EricDowCo = 657,
    EricsonYachts = 658,
    Ernecraft = 659,
    ErnestLibbey = 660,
    Escape = 661,
    Espirit = 662,
    Essex = 663,
    Euroship = 664,
    Eurosport = 665,
    EvansBoats = 666,
    Everglades = 667,
    Evinrude = 668,
    EvolveYachts = 669,
    Excalibur = 670,
    Excel = 671,
    ExeMarineLtd = 672,
    Explorer = 673,
    ExpressCat = 674,
    ExpressYachting = 675,
    Exuma = 676,
    FC = 677,
    FF = 678,
    Fabuglas = 679,
    Fairbanks = 680,
    Fairline = 681,
    Falcon = 682,
    Fales = 683,
    Falmouth = 684,
    FamousCraft = 685,
    Fantasy = 686,
    Farallon = 687,
    Farr = 688,
    Farrier = 689,
    FarrierMarine = 690,
    FastCatMarine = 691,
    Feadship = 692,
    FenwickWilliams = 693,
    Ferretti = 694,
    FerrettiGroup = 695,
    Ferro = 696,
    FibFab = 697,
    Fiberform = 698,
    FiberglassShrimpBoat = 699,
    FiberglassUnlimited = 700,
    Fibertel = 701,
    Fiesta = 702,
    FinChaser = 703,
    FreedomYachts = 704,
    FrontStreetShipyard = 705,
    Glastron = 706,
    HallbergRassy = 707,
    HanseYachts = 708,
    HBMarine = 709,
    HinckleyYachts = 710,
    HondaMarineGroup = 711,
    HunterMarine = 712,
    HylasYachts = 713,
    ICONYachts = 714,
    IslandPacketYachts = 715,
    KadeyKrogenYachts = 716,
    KawasakiHeavyIndustries = 717,
    Kherson = 718,
    Lazzara = 719,
    LordNelsonYachts = 720,
    LoweBoats = 721,
    Lürssen = 722,
    MalibuBoats = 723,
    MasterCraft = 724,
    Maxum = 725,
    MontereyBoats = 726,
    Nobiskrug = 727,
    Nordhavn = 728,
    oceAnco = 729,
    OysterMarine = 730,
    PacificAsianEnterprises = 731,
    PacificSeacraft = 732,
    PearsonYachts = 733,
    Portabote = 734,
    Reinell = 735,
    RoyalYacht = 736,
    SeaRay = 737,
    SkiNautique = 738,
    StarcraftMarine = 739,
    SuMarineYachts = 740,
    Sunseeker = 741,
    TayanaYachts = 742,
    TiaraYachts = 743,
    Tollycraft = 744,
    TrojanYachts = 745,
    Uniflite = 746,
    WacandaMarine = 747,
    WallyYachts = 748,
    WasaYachtsAB = 749,
    Westsail = 750,
    YamahaMotorCorporation = 751,
    Z1Boats = 752,
    ZodiacGroup = 753,
    MultiBrand = 754,
  }

export enum _RvType {
    PreOwned = -1,
    None = 0,
    AmericanCoach = 1,
    Coachmen = 2,
    Entegra = 3,
    Fleetwood = 4,
    ForestRiver = 5,
    Foretravel = 6,
    HolidayRambler = 7,
    Jayco = 8,
    Monaco = 9,
    NewellCoach = 10,
    Newmar = 11,
    Nexus = 12,
    Prevost = 13,
    Thor = 14,
    Tiffin = 15,
    Trek = 16,
    Winnebago = 17,
    Other = 18,
    MultiBrand = 19
}
