import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import configuration from './utils/configuration';

const isLogEnabled = configuration.getValue("environment") === "live";

type Severity = "fatal" | "error" | "warning" | "log" | "info" | "debug" | "critical";

export const initSentry = () => {
    Sentry.init({
        dsn: "https://a56362ff22754c3fa3c124e65e25ea8c@o26307.ingest.sentry.io/6001041",
        integrations: [new Integrations.BrowserTracing()],
        enabled: isLogEnabled,
        autoSessionTracking: true,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.3,
    });
};

export const logSentryError = (error: any) => {
    Sentry.captureException(error);
};

export const logSentryMessage = (message: string, severity: Severity = "log") => {
    let sentrySeverity = Sentry.Severity.Log;
    switch (severity) {
        case "critical":
            sentrySeverity = Sentry.Severity.Critical;
            break;
        case "log":
            sentrySeverity = Sentry.Severity.Log;
            break;
        case "debug":
            sentrySeverity = Sentry.Severity.Debug;
            break;
        case "error":
            sentrySeverity = Sentry.Severity.Error;
            break;
        case "fatal":
            sentrySeverity = Sentry.Severity.Fatal;
            break;
        case "info":
            sentrySeverity = Sentry.Severity.Info;
            break;
        case "warning":
            sentrySeverity = Sentry.Severity.Warning;
            break;
        default:
            sentrySeverity = Sentry.Severity.Log;
            break;
    }
    Sentry.captureMessage(message, sentrySeverity);
};

export const setSentryLogUser = (user: Sentry.User | null) => {
    if (user) {
        Sentry.setUser(user);
    } else {
        Sentry.setUser(null);
    }
};
