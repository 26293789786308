import React from "react";
// import { Integrations } from "@sentry/react";
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import ShowChartRoundedIcon from '@material-ui/icons/ShowChartRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import SettingsInputSvideoRoundedIcon from '@material-ui/icons/SettingsInputSvideoRounded';
import ChildRouteRender from "../../components/routers/child-route-renderer";
import { RouteConfig } from "../../model/route-config";
import {
  defineAuthedRoute,
  defineInvisibleRoute,
  defineRedirectRoute,
  defineRouteWithChildRouteRenderer,
  defineRouteWithCommonAdminRole,
  suspendedComponent,
} from "../../utils/route-utils";
import SubscriberDashboard from "../../pages/subscriber-dashboard/subscriber-dashboard";

const OrganisationSettingsLazy = React.lazy(
  () => import("../../pages/organisation-settings")
);
const OrganisationSettingsNewLazy = React.lazy(
  () => import("../../pages/organisation-settings-new")
);

const ContactSettingsLazy = React.lazy(
  () => import("../../pages/contact-settings")
);

const ContactSettingsNewLazy = React.lazy(
  () => import("../../pages/contact-settings-new")
);

const UserManagementLazy = React.lazy(
  () => import("../../pages/user-management")
);

const UserManagementNewLazy = React.lazy(
  () => import("../../pages/user-management-new")
);

const UserGroupLazy = React.lazy(
  () => import('../../pages/user-groups')
);

const UsersRoleLazy = React.lazy(() => import("../../pages/users-roles"));

const IntegrationLazy = React.lazy(
  () => import('../../pages/integrations')
);

const APIKeysLazy = React.lazy(
  () => import('../../pages/api-keys')
);

const ChatAnalysisLazy = React.lazy(() => import("../../pages/chat-analysis"));
const GroupStatisticsLazy = React.lazy(() => import("../../pages/group-statistics"));
const ChatSnapshotLazy = React.lazy(() => import("../../pages/chat-snapshot"));
const LeadAnalysisLazy = React.lazy(() => import("../../pages/lead-analysis"));
const LeadSnapshotLazy = React.lazy(() => import("../../pages/lead-snapshot"));
const MissedChatLazy = React.lazy(() => import("../../pages/chat-missed"));
const OfflineLeadLazy = React.lazy(() => import("../../pages/lead-offline"));
const OperatorAnalysisLazy = React.lazy(
  () => import("../../pages/operator-analysis")
);
const OperatorMissedChatLazy = React.lazy(
  () => import("../../pages/operator-missed-chat")
);
const OperatorPerformanceLazy = React.lazy(
  () => import("../../pages/operator-performance")
);
const OperatorSnapshotLazy = React.lazy(
  () => import("../../pages/operator-snapshot")
);
const OperatorTimeclockLazy = React.lazy(
  () => import("../../pages/operator-timeclock")
);
const ReportOverviewLazy = React.lazy(
  () => import("../../pages/report-overview")
);
const SurveyAnalysisLazy = React.lazy(
  () => import("../../pages/survey-analysis")
);
const Top10Lazy = React.lazy(() => import("../../pages/top-10"));
const VisitorSnapshotLazy = React.lazy(
  () => import("../../pages/visitor-snapshot")
);
const ConfirmInvoicePaymentLazy = React.lazy(() => import("../../pages/confirm-invoice-payment"));
const PaymentInvoicesLazy = React.lazy(() => import("../../pages/payment-invoices/payment-invoices"));
const BillingDashboardLazy = React.lazy(() => import("../../pages/billing-dashboard"));
const PaymentHistoryLazy = React.lazy(() => import("../../pages/payment-history"));
const PlanDetailsLazy = React.lazy(() => import("../../pages/plan-details"));
const LeadRoutingLazy = React.lazy(() => import("../../pages/lead-routing"));
const SocialMediaRequestLazy = React.lazy(() => import("../../pages/social-media-request"));
const ServiceLeadToRingLazy = React.lazy(() => import("../../pages/service-lead-to-ring"));
const ServiceAppointmentLazy = React.lazy(() => import("../../pages/service-appointment"));
const MyProfileLazy = React.lazy(() => import("../../pages/my-profile"));
const GeneralSettingsLazy = React.lazy(() => import("../../pages/settings-general"));
const SettingsSitesLazy = React.lazy(() => import("../../pages/settings-sites"));
const ReputationManagementReviewsLazy = React.lazy(() => import("../../pages/reputation-management-reviews"));
const IPBlacklistLazy = React.lazy(() => import("../../pages/ip-blacklist"));
const IPWhitelistLazy = React.lazy(() => import("../../pages/ip-whitelist"));
const VisitorBlockLazy = React.lazy(() => import("../../pages/visitor-block"));
const BlockLocationPhoneLazy = React.lazy(() => import("../../pages/block-location-phone"));
const DeptBasedRoutingLazy = React.lazy(() => import("../../pages/dept-based-routing"));
const FormsInChatLazy = React.lazy(() => import("../../pages/forms-in-chat"));

const OrganisationSettings = suspendedComponent(OrganisationSettingsLazy);
const OrganisationSettingsNew = suspendedComponent(OrganisationSettingsNewLazy);

const ContactSettings = suspendedComponent(ContactSettingsLazy);
const ContactSettingsNew = suspendedComponent(ContactSettingsNewLazy);

const UserManagement = suspendedComponent(UserManagementLazy);
const UserManagementNew = suspendedComponent(UserManagementNewLazy);
const UserGroups = suspendedComponent(UserGroupLazy);
const UsersRole = suspendedComponent(UsersRoleLazy);

const ChatAnalysis = suspendedComponent(ChatAnalysisLazy);
const ChatSnapshot = suspendedComponent(ChatSnapshotLazy);
const LeadAnalysis = suspendedComponent(LeadAnalysisLazy);
const LeadSnapshot = suspendedComponent(LeadSnapshotLazy);
const MissedChat = suspendedComponent(MissedChatLazy);
const OfflineLead = suspendedComponent(OfflineLeadLazy);
const OperatorAnalysis = suspendedComponent(OperatorAnalysisLazy);
const OperatorPerformance = suspendedComponent(OperatorPerformanceLazy);
const OperatorMissedChat = suspendedComponent(OperatorMissedChatLazy);
const OperatorTimeclock = suspendedComponent(OperatorTimeclockLazy);
const OperatorSnapshot = suspendedComponent(OperatorSnapshotLazy);
const ReportOverview = suspendedComponent(ReportOverviewLazy);
const SurveyAnalysis = suspendedComponent(SurveyAnalysisLazy);
const GroupStatistics = suspendedComponent(GroupStatisticsLazy);

const Top10 = suspendedComponent(Top10Lazy);
const VisitorSnapshot = suspendedComponent(VisitorSnapshotLazy);

const ConfirmInvoicePayment = suspendedComponent(ConfirmInvoicePaymentLazy);
const PaymentInvoices = suspendedComponent(PaymentInvoicesLazy);
const BillingDashboard = suspendedComponent(BillingDashboardLazy);
const PaymentHistory = suspendedComponent(PaymentHistoryLazy);
const PlanDetails = suspendedComponent(PlanDetailsLazy);
const LeadRouting = suspendedComponent(LeadRoutingLazy);
const SocialMediaRequest = suspendedComponent(SocialMediaRequestLazy);
const ServiceLeadToRing = suspendedComponent(ServiceLeadToRingLazy);
const ServiceAppointment = suspendedComponent(ServiceAppointmentLazy);
const MyProfile = suspendedComponent(MyProfileLazy);
const GeneralSettings = suspendedComponent(GeneralSettingsLazy);
const SettingsSites = suspendedComponent(SettingsSitesLazy);
const ReputationManagementReviews = suspendedComponent(ReputationManagementReviewsLazy);
const IPBlacklist = suspendedComponent(IPBlacklistLazy);
const IPWhitelist = suspendedComponent(IPWhitelistLazy);
const VisitorBlock = suspendedComponent(VisitorBlockLazy);
const BlockLocationPhone = suspendedComponent(BlockLocationPhoneLazy);
const Integrations = suspendedComponent(IntegrationLazy);
const APIKeys = suspendedComponent(APIKeysLazy);
const DeptBasedRouting = suspendedComponent(DeptBasedRoutingLazy);
const FormsInChat = suspendedComponent(FormsInChatLazy);

/* start /account */

// /account/billing
const billingRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Overview",
    path: "/",
    exact: true,
    title: "Billing | Overview",
    component: BillingDashboard,
    // render: () => <h1>Billing Overview</h1>,
  }),
  defineAuthedRoute({
    name: "Payment History",
    path: "/history",
    title: "Billing | Payment History",
    component: PaymentHistory,
  }),
  defineAuthedRoute({
    name: "Payment",
    path: "/payment",
    title: "Billing | Payment Invoices",
    component: PaymentInvoices,
  }),
  defineAuthedRoute({
    name: "Plan Details",
    path: "/plan",
    title: "Billing | Plan Details",
    component: PlanDetails,
  }),
  // defineAuthedRoute({
  //   name: "Payment Methods",
  //   path: "/paymentmethods",
  //   title: "Billing | Methods",
  //   component: WorkInProgress,
  // }),
  defineInvisibleRoute(
    defineAuthedRoute({
      name: "Confirm Payment",
      path: "/confirmInvoice",
      title: "Billing | Confirm Invoices",
      component: ConfirmInvoicePayment,
    })
  ),
];

// /account/users
const userRoutes: RouteConfig[] = [
  // defineAuthedRoute({
  //   name: "Profile",
  //   bypassDisplay: true,
  //   path: "/profile:userid",
  //   title: "Profile",
  //   component: WorkInProgress,
  // }),
  defineAuthedRoute({
    name: "Manage",
    path: "/manage",
    title: "Users | Manage",
    component: UserManagement,
    guard: { exceptProducts: ["ServicesPro", "LegalChat"] },
  }),
  defineAuthedRoute({
    name: "Manage",
    path: "/manage",
    title: "Users | Manage",
    component: UserManagementNew,
    guard: { allowedProducts: ["ServicesPro", "LegalChat"] },
  }),
  defineAuthedRoute({
    name: "Groups",
    path: "/groups",
    title: "Users | Groups",
    component: UserGroups,
    // guard: { allowedProducts: ["ServicesPro", "LegalChat"] },
  }),
  defineAuthedRoute({
    name: "Roles",
    path: "/role",
    title: "Users | Role",
    component: UsersRole,
  }),
];

const accountRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Organization",
    pageHeader: "Organization Setup",
    path: "/organization",
    title: "Account | Organization Settings",
    component: OrganisationSettings,
    guard: { exceptProducts: ["ServicesPro", "LegalChat"] },
  }),
  defineAuthedRoute({
    name: "Organization",
    pageHeader: "Organization Setup",
    path: "/organization",
    title: "Account | Organization Settings",
    component: OrganisationSettingsNew,
    guard: { allowedProducts: ["ServicesPro", "LegalChat"] },
  }),
  defineAuthedRoute({
    name: "Contacts",
    path: "/contacts",
    title: "Account | Contacts",
    component: ContactSettings,
    guard: { exceptProducts: ["ServicesPro", "LegalChat"] },
  }),
  defineAuthedRoute({
    name: "Contacts",
    path: "/contacts",
    title: "Account | Contacts",
    component: ContactSettingsNew,
    guard: { allowedProducts: ["ServicesPro", "LegalChat"] },
  }),
  defineAuthedRoute({
    name: "Lead Routing",
    path: "/leadrouting",
    title: "Account | Lead Routing",
    component: LeadRouting,
    guard: { exceptProducts: ["ServicesPro", "LegalChat"] },
  }),
  defineAuthedRoute({
    name: "Social Media Request",
    path: "/socialmediarequest",
    title: "Account | Social Media Request",
    component: SocialMediaRequest,
    guard: { allowedProducts: ["ServicesPro"] }
  }),
  defineAuthedRoute({
    name: "Billing",
    path: "/billing",
    title: "Billing",
    component: ChildRouteRender,
    routes: billingRoutes,
    // guard: {
    //   exceptRoles: ["CC24PerformanceManager", "CC24SalesManager"],
    // },
  }),
  defineAuthedRoute({
    name: "Users",
    path: "/users",
    title: "Manage Users",
    component: ChildRouteRender,
    routes: userRoutes,
  }),
];
/* end /account */

/* start /report */
const reportChatRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Snapshot",
    path: "/chat-snapshot",
    title: "Analytics | Chat Snapshot",
    pageHeader: "Chat Snapshot",
    component: ChatSnapshot,
  }),
  defineAuthedRoute({
    name: "Analysis",
    path: "/chat-analysis",
    title: "Analytics | Chat Analysis",
    pageHeader: "Chat Analysis",
    component: ChatAnalysis,
  }),
  defineAuthedRoute({
    name: "Missed Chat",
    path: "/chat-missed",
    title: "Analytics | Missed Chat",
    pageHeader: "Missed Chat",
    component: MissedChat,
    guard: { exceptSubscriptions: ["FullSupport"] },
  }),
];
const reportOperatorRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Snapshot",
    path: "/operator-snapshot",
    title: "Analytics | Operator Snapshot",
    pageHeader: "Operator Snapshot",
    component: OperatorSnapshot,
  }),
  defineAuthedRoute({
    name: "Analysis",
    path: "/operator-analysis",
    title: "Analytics | Operator Analysis",
    pageHeader: "Operator Analysis",
    component: OperatorAnalysis,
  }),
  defineAuthedRoute({
    name: "Timeclock",
    path: "/operator-timeclock",
    title: "Analytics | Operator Timeclock",
    pageHeader: "Operator Timeclock",
    component: OperatorTimeclock,
  }),
  defineAuthedRoute({
    name: "Missed Chat Details",
    path: "/operator-missed-chat",
    title: "Analytics | Operator Missed Chat Details",
    pageHeader: "Missed Chat Details",
    component: OperatorMissedChat,
    guard: { allowedRoles: ["DealerAdmin"] },
  }),
  defineAuthedRoute({
    name: "Performance",
    path: "/operator-performance",
    title: "Analytics | Operator Performance",
    pageHeader: "Operator Performance",
    component: OperatorPerformance,
    guard: { allowedRoles: ["DealerAdmin"] },
  }),
];
const reportLeadRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Snapshot",
    path: "/lead-snapshot",
    title: "Analytics | Lead Snapshot",
    pageHeader: "Lead Snapshot",
    component: LeadSnapshot,
  }),
  defineAuthedRoute({
    name: "Analysis",
    path: "/lead-analysis",
    title: "Analytics | Lead Analysis",
    pageHeader: "Lead Analysis",
    component: LeadAnalysis,
  }),
  defineAuthedRoute({
    name: "Offline Lead",
    path: "/lead-offline",
    title: "Analytics | Offline Lead",
    pageHeader: "Offline Lead",
    component: OfflineLead,
  }),
];
const reportVisitorRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Snapshot",
    path: "/visitor-snapshot",
    title: "Analytics | Visitor Snapshot",
    pageHeader: "Visitor Snapshot",
    component: VisitorSnapshot,
  }),
  defineAuthedRoute({
    name: "Blocked",
    path: "/visitor-block",
    title: "Analytics | Blocked Visitor",
    pageHeader: "Blocked Visitor",
    component: VisitorBlock
  }),
];

const blockRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "IP Blacklist",
    path: "/ip-blacklist",
    title: "Block | IP Blacklist",
    pageHeader: "IP Blacklist",
    component: IPBlacklist
  }),
  defineAuthedRoute({
    name: "IP Whitelist",
    path: "/ip-whitelist",
    title: "Block | IP Whitelist",
    pageHeader: "IP Whitelist",
    component: IPWhitelist
  }),
  defineAuthedRoute({
    name: "Location/Phone",
    path: "/location-phone",
    title: "Block | Location/Phone",
    pageHeader: "Location/Phone",
    component: BlockLocationPhone
  }),
];

const RoutingRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Department",
    path: "/department",
    title: "Routing | Department",
    pageHeader: "Department",
    component: DeptBasedRouting
  }),

];

const reportRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Overview",
    path: "/",
    exact: true,
    title: "Analytics | Overview",
    component: ReportOverview,
  }),
  defineAuthedRoute({
    name: "Top 10",
    path: "/top-10",
    title: "Analytics | Top 10",
    component: Top10,
  }),
  defineAuthedRoute({
    name: "Chat",
    path: "/chat",
    title: "Chat",
    component: ChildRouteRender,
    routes: reportChatRoutes,
  }),
  defineAuthedRoute({
    name: "Operator",
    path: "/operator",
    title: "Operator",
    component: ChildRouteRender,
    routes: reportOperatorRoutes,
    guard: { exceptSubscriptions: ["FullSupport"] },
  }),
  defineAuthedRoute({
    name: "Lead",
    path: "/lead",
    title: "Lead",
    component: ChildRouteRender,
    routes: reportLeadRoutes,
  }),
  defineAuthedRoute({
    name: "Visitor",
    path: "/visitor-snapshot",
    title: "Visitor",
    pageHeader: "Visitor Snapshot",
    component: ChildRouteRender,
    routes: reportVisitorRoutes,
  }),
  defineAuthedRoute({
    name: "Survey Analysis",
    path: "/survey-analysis",
    title: "Analytics | Survey Analysis",
    component: SurveyAnalysis,
    guard: { exceptSubscriptions: ["FullSupport"] },
  }),

  defineAuthedRoute({
    name: "Group Statistics",
    path: "/group-statistics",
    title: "Analytics | Group Statistics",
    component: GroupStatistics,
    guard: { allowedRoles: ["DealerGroupAdmin", "CC24Admin"] },
  }),
];

/* end /report */

/* start /settings */
const settingsRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "General",
    path: "/general",
    title: "Settings | General",
    component: GeneralSettings,
  }),
  defineAuthedRoute({
    name: "Sites",
    pageHeader: "Manage Sites",
    path: "/sites",
    title: "Settings | Manage Sites",
    component: SettingsSites,
  }),
  defineAuthedRoute({
    name: "Block",
    path: "/block",
    title: "Block Settings",
    component: ChildRouteRender,
    routes: blockRoutes
  }),
  defineAuthedRoute({
    name: "Routing",
    path: "/routing",
    title: "Routing",
    component: ChildRouteRender,
    routes: RoutingRoutes
  })

];
/* end /settings */

/* start /reputation-management */
const reputationManagementRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Reviews",
    path: "/reviews",
    title: "Reviews",
    component: ReputationManagementReviews,
  }),
];

/* end /reputation-management */

/* start /visual experience */
// const visualExperienceRoutes: RouteConfig[] = [
//   defineInvisibleRoute(
//     defineRedirectRoute(
//       {
//         name: "",
//         path: "/",
//         exact: true,
//       },
//       "/"
//     )
//   ),
//   defineAuthedRoute({
//     name: "Manage Sites",
//     path: "/site",
//     title: "Site",
//     component: WorkInProgress,
//   }),
//   defineAuthedRoute({
//     name: "Messenger",
//     path: "/messenger",
//     title: "Messenger",
//     component: WorkInProgress,
//   }),
//   defineAuthedRoute({
//     name: "Messages",
//     path: "/messages",
//     title: "Setup Messages",
//     component: WorkInProgress,
//   }),
// ];
/* end /visual experience */

/* start /configuration Routes */
const serviceRoutes: RouteConfig[] = [
  defineInvisibleRoute(
    defineRedirectRoute(
      {
        name: "",
        path: "/",
        exact: true,
      },
      "/dashboard"
    )
  ),
  defineAuthedRoute({
    name: "Lead To Ring",
    path: "/l2r",
    title: "Services | Setup L2R",
    component: ServiceLeadToRing,
  }),
  defineAuthedRoute({
    name: "Appointment Email",
    path: "/appointment",
    title: "Services | Setup Appointment Email",
    component: ServiceAppointment,
  }),
];
// const integrationRoutes: RouteConfig[] = [
//   defineAuthedRoute({
//     name: " Integrations",
//     path: "/integration",
//     title: "Integrations",
//     component: Integrations,
//   }),

// defineAuthedRoute({
//   name: "Facebook",
//   path: "/facebook",
//   title: "Setup Facebook Chat",
//   component: WorkInProgress,
// }),
// defineAuthedRoute({
//   name: "Google Business Chat",
//   path: "/gbc",
//   title: "Setup Google Business Chat",
//   component: WorkInProgress,
// }),
// ];

const configurationRoutes: RouteConfig[] = [
  defineInvisibleRoute(
    defineRedirectRoute(
      {
        name: "",
        path: "/",
        exact: true,
      },
      "/dashboard"
    )
  ),
  defineRouteWithChildRouteRenderer(
    defineRouteWithCommonAdminRole({
      name: "Services",
      path: "/services",
      routes: serviceRoutes,
    })
  ),
  defineAuthedRoute({
    name: "Integrations",
    path: "/integrations",
    title: "Configure | Integrations",
    component: Integrations,
    // routes: integrationRoutes,
  }),
  defineAuthedRoute({
    name: "Forms In Chat",
    path: "/forms-in-chat",
    title: "Forms In Chat",
    component: FormsInChat,
    // routes: integrationRoutes,
  }),
  defineAuthedRoute({
    name: "API Keys",
    path: "/api-keys",
    title: "Configure | API Keys",
    pageHeader: "Your API Keys",
    component: APIKeys,
    // routes: integrationRoutes,
  })
];
/* end /Configuration Routes */

export const subscriberRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Dashboard",
    path: "/",
    exact: true,
    title: "Dashboard",
    component: SubscriberDashboard,
    icon: <DashboardRoundedIcon fontSize="small" />
  }),
  defineRouteWithChildRouteRenderer(
    defineRouteWithCommonAdminRole({
      name: "Account",
      path: "/account",
      routes: accountRoutes,
      icon: <PersonRoundedIcon fontSize="small" />
    })
  ),
  defineRouteWithChildRouteRenderer(
    defineAuthedRoute({
      name: "Analytics",
      path: "/analytics",
      routes: reportRoutes,
      guard: { exceptRoles: ["CC24User"] },
      icon: <ShowChartRoundedIcon fontSize="small" />
    })
  ),
  defineRouteWithChildRouteRenderer(
    defineAuthedRoute({
      name: "Reputation",
      path: "/reputation",
      routes: reputationManagementRoutes,
      guard: { allowedRoles: ["DealerAdmin", "DealerUser"] },
      icon: <ThumbUpRoundedIcon fontSize="small" />
    })
  ),
  defineRouteWithChildRouteRenderer(
    defineAuthedRoute({
      name: "Settings",
      path: "/settings",
      routes: settingsRoutes,
      guard: { allowedRoles: ["DealerAdmin", "CC24Admin", "DealerGroupAdmin"] },
      icon: <SettingsRoundedIcon fontSize="small" />
    })
  ),
  // defineRouteWithChildRouteRenderer(
  //   defineRouteWithCommonAdminRole({
  //     name: "Visual Exprience",
  //     path: "/visual",
  //     routes: visualExperienceRoutes,
  //   })
  // ),
  defineRouteWithChildRouteRenderer(
    defineRouteWithCommonAdminRole({
      name: "Configure",
      path: "/Configure",
      routes: configurationRoutes,
      icon: <SettingsInputSvideoRoundedIcon fontSize="small" />
    })
  ),
  defineAuthedRoute({
    name: "My Profile",
    path: "/profile",
    title: "My Profile",
    guard: { allowedRoles: ["DealerAdmin", "DealerUser"] },
    bypassDisplay: true,
    component: MyProfile,
  }),
  defineAuthedRoute({
    name: "SynUp",
    path: "https://v2.synup.com/users/sign_in",
    externalLink: true,
    guard: { allowedProducts: ["ServicesPro"] }
  }),
];
