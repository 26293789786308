import cc24FullLogo from "../assets/images/carchat24/logo/cc24FullLogo.png";
import cc24SmallLogo from "../assets/images/carchat24/logo/cc24SmallLogo.png";
import bcFullLogo from "../assets/images/boatchat/logo/bcFullLogo.png";
import bcSmallLogo from "../assets/images/boatchat/logo/bcSmallLogo.png";
import rvcFullLogo from "../assets/images/rvchat/logo/rvFullLogo.png";
import rvcSmallLogo from "../assets/images/rvchat/logo/rvSmallLogo.png";
import lcFullLogo from "../assets/images/legalchat/logo/lcFullLogo.png";
import lcSmallLogo from "../assets/images/legalchat/logo/lcSmallLogo.png";
import clFullLogo from "../assets/images/chatlead/logo/clFullLogo.png";
import clSmallLogo from "../assets/images/chatlead/logo/clSmallLogo.png";
import spFullLogo from "../assets/images/servicespro/logo/spFullLogo.png";
import spSmallLogo from "../assets/images/servicespro/logo/spSmallLogo.png";
import actionDashboard from "../assets/images/icons/actionDashboard.svg";
import appointment from "../assets/images/icons/appointment.svg";
import webChat from "../assets/images/icons/webChat.svg";
import googleAnalytics from "../assets/images/icons/google_analytics.svg";
import fbLogo from "../assets/images/icons/icons8-facebook.png";
import instaLogo from "../assets/images/icons/icons8-instagram.png";
import twitterLogo from "../assets/images/icons/icons8-twitter.png";
import youtubeLogo from "../assets/images/icons/icons8-youtube.png";

import callService from "../assets/images/icons/callService.svg";
import callTransfer from "../assets/images/icons/callTransfer.svg";
import dualBilling from "../assets/images/icons/dualBilling.svg";
import facebookChat from "../assets/images/icons/facebookChat.svg";
import facebookMarketplace from "../assets/images/icons/facebookMarketplace.svg";
import googleAdsPerClick from "../assets/images/icons/googleAdsPerClick.svg";
import group from "../assets/images/icons/group.svg";
import groupHead from "../assets/images/icons/groupHead.svg";
import leadToRing from "../assets/images/icons/leadToRing.svg";
import performanceManager from "../assets/images/icons/performanceManager.svg";
import reseller from "../assets/images/icons/reseller.svg";
import seoWebsite from "../assets/images/icons/seoWebsite.svg";
import smsChat from "../assets/images/icons/smsChat.svg";
import videoChat from "../assets/images/icons/videoChat.svg";
import contentMarketingAndBlog from "../assets/images/icons/contentMarketingAndBlog.svg";
import americanExpress from "../assets/images/credit/americanExpress.svg";
import blankCard from "../assets/images/credit/blankCard.png";
import cirrus from "../assets/images/credit/cirrus.png";
import discoverCard from "../assets/images/credit/discoverCard.svg";
import masterCard from "../assets/images/credit/masterCard.svg";
import mestro from "../assets/images/credit/mestro.png";
import paypal from "../assets/images/credit/paypal.png";
import paypal2 from "../assets/images/credit/paypal2.png";
import visa from "../assets/images/credit/visa.svg";
import cc24PaymentConfBg from "../assets/images/carchat24/confirm-payment-bg.png";
import bcPaymentConfBg from "../assets/images/boatchat/confirm-payment-bg.png";
import rvPaymentConfBg from "../assets/images/rvchat/confirm-payment-bg.png";
import legalPaymentConfBg from "../assets/images/legalchat/confirm-payment-bg.png";
import clPaymentConfBg from "../assets/images/chatlead/confirm-payment-bg.png";
import spPaymentConfBg from "../assets/images/servicespro/confirm-payment-bg.png";
import subscriberSettings from "../assets/images/settings-blue.svg";
import crossIcon from "../assets/images/cross-red.svg";

import cc24ProgressBarIcon from "../assets/images/carchat24/progress-bar-icon.svg";
import bcProgressBarIcon from "../assets/images/boatchat/progress-bar-icon.svg";
import rvProgressBarIcon from "../assets/images/rvchat/progress-bar-icon.svg";
import legalProgressBarIcon from "../assets/images/legalchat/progress-bar-icon.svg";
import spProgressBarIcon from "../assets/images/servicespro/progress-bar-icon.svg";
import plivo from "../assets/images/plivo.svg";
import twilio from "../assets/images/twilio.svg";
import facebookSetupIcon from "../assets/images/icons/fb.svg";
import googleMyBusinessSetupIcon from "../assets/images/icons/gmb.svg";
import appServiceSetupIcon from "../assets/images/icons/app-service.svg";
import instagramSetupIcon from "../assets/images/icons/insta.svg";
import whatsAppBusinessSetupIcon from "../assets/images/icons/wp-business.svg";
import reputationManagementSetupIcon from "../assets/images/icons/rm.svg";

import configuration from "./configuration";

const image: { [s: string]: string } = {
    actionDashboard,
    appointment,
    webChat,
    callService,
    callTransfer,
    contentMarketingAndBlog,
    dualBilling,
    facebookChat,
    facebookMarketplace,
    googleAdsPerClick,
    group,
    groupHead,
    leadToRing,
    performanceManager,
    reseller,
    seoWebsite,
    smsChat,
    videoChat,
    americanExpress,
    blankCard,
    cirrus,
    discoverCard,
    masterCard,
    mestro,
    paypal,
    paypal2,
    visa,
    subscriberSettings,
    crossIcon,
    fbLogo,
    instaLogo,
    twitterLogo,
    youtubeLogo,
    facebookSetupIcon,
    googleMyBusinessSetupIcon,
    appServiceSetupIcon,
    instagramSetupIcon,
    whatsAppBusinessSetupIcon,
    reputationManagementSetupIcon,
    googleAnalytics,
    plivo,
    twilio
};

export function getFullBrandLogo(brand: string) {
    switch (brand) {
        case "CarChat24":
            return cc24FullLogo;
        case "BoatChat":
            return bcFullLogo;
        case "RVChat":
            return rvcFullLogo;
        case "LegalChat":
            return lcFullLogo;
        case "ChatLead":
            return clFullLogo;
        case "ServicesPro":
            return spFullLogo;
        default:
            return cc24FullLogo;
    }
}

export function getPaymentConfirmBg() {
    switch (configuration.getValue("productName")) {
        case "CarChat24":
            return cc24PaymentConfBg;
        case "BoatChat":
            return bcPaymentConfBg;
        case "RVChat":
            return rvPaymentConfBg;
        case "LegalChat":
            return legalPaymentConfBg;
        case "ChatLead":
            return clPaymentConfBg;
        case "ServicesPro":
            return spPaymentConfBg;
        default:
            return cc24PaymentConfBg;
    }
}

export function getSmallBrandLogo(brand: string) {
    switch (brand) {
        case "CarChat24":
            return cc24SmallLogo;
        case "BoatChat":
            return bcSmallLogo;
        case "RVChat":
            return rvcSmallLogo;
        case "LegalChat":
            return lcSmallLogo;
        case "ChatLead":
            return clSmallLogo;
        case "ServicesPro":
            return spSmallLogo;
        default:
            return cc24SmallLogo;
    }
}

export function getProgessIcon() {
    switch (configuration.getValue("productName")) {
        case "CarChat24":
            return cc24ProgressBarIcon;
        case "BoatChat":
            return bcProgressBarIcon;
        case "RVChat":
            return rvProgressBarIcon;
        case "LegalChat":
            return legalProgressBarIcon;
        case "ServicesPro":
            return spProgressBarIcon;
        default:
            return cc24ProgressBarIcon;
    }
}

export function getImage(name: keyof (typeof image)) {
    return image[name];
}

export function getProfileImage(imageFile: string) {
    const staticServer = configuration.getValue("static");
    return imageFile ? `${staticServer}/ProfilePhotos/ProfilePhotos/${imageFile}` : "";
}

export function blobCreationFromURL(inputURI: string) {
    let binaryVal;

    // mime extension extraction
    const inputMIME = inputURI.split(',')[0].split(':')[1].split(';')[0];

    // Extract remaining part of URL and convert it to binary value
    if (inputURI.split(',')[0].indexOf('base64') >= 0) binaryVal = atob(inputURI.split(',')[1]);

    // Decoding of base64 encoded string
    else { binaryVal = unescape(inputURI.split(',')[1]); }

    // Computation of new string in which hexadecimal
    // escape sequences are replaced by the character
    // it represents

    // Store the bytes of the string to a typed array
    const blobArray = new Uint8Array(binaryVal.length);
    for (let index = 0; index < binaryVal.length; index++) {
        blobArray[index] = binaryVal.charCodeAt(index);
    }

    return new Blob([blobArray], {
        type: inputMIME
    });
}
