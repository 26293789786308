import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectUser } from "../../../../store/modules/authentication";
import { emptyCurrentSubscriberInfo, emptyOrganisation, selectSubscriberInfo } from "../../../../store/modules/subscriber";
import { enableSubscriberView, selectSwitchToSubscriberView } from "../../../../store/modules/ui";
import { isCarchat24Admin } from "../../../../utils/account-user-utils";

export function useQuickSubscriberSwitch() {
    const history = useHistory();
    const dispatch = useDispatch();

    const [visibleSubscriberSwitch, setVisibleSubscriberSwitch] = useState(false);

    const { user } = useSelector(selectUser);
    const { enable: isInsideSubscriberView } = useSelector(selectSwitchToSubscriberView);
    const { currentSubscriber } = useSelector(selectSubscriberInfo);

    useEffect(() => {
        if (user) {
            setVisibleSubscriberSwitch(isCarchat24Admin(user));
        }
    }, [user]);

    const handleBackToAdmin = () => {
        dispatch(enableSubscriberView(false));
        dispatch(emptyOrganisation());
        dispatch(emptyCurrentSubscriberInfo());
        history.push("/dashboard");
    };
    return { handleBackToAdmin, visibleSubscriberSwitch, isInsideSubscriberView, currentSubscriberName: currentSubscriber?.SubscriberName, user };
}
