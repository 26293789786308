const appConfig = {
    defaultToastContainerId: "default-container",
    loaderToastContainerId: "loader-container",
    // recaptchaSiteKey: "6LfX3m0hAAAAABg7l3LbWqqC3M3HFp_6LGV5m22L",
    recaptchaSiteKey: "6LcUq4ghAAAAABf7XD56Eej-ki0q1RVLFAA5TV7t",

    cookieKeys: {
        SHOW_WALKTHROUGH: "SHOW_WALKTHROUGH",
        FCP_CWWEB: "FCP_CWWEB",
        NEW_FCP: "NEW_FCP",
        MANAGE_SAAS_LLP: "MANAGE_SAAS_LAST_LOADED_SEARCH_QUERY",
        IS_TESTMODE: "IS_TESTMODE"
    }
};

export default appConfig;
