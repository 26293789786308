import React, { memo } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  SelectProps,
  // Input,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import clsx from "clsx";
import Chip from '@material-ui/core/Chip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CommonInputProps } from "../../../model/common-props";
import { UICheckbox } from "..";

interface UIMultiSelectProps extends CommonInputProps {
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onBlur?: () => void;
  onClose?: () => void;
  options: { label: string, value: string | number }[];
  value?: string[];
  inputProps?: SelectProps;
  chipsToShow?: number;
}

const UIMultiSelect: React.FC<UIMultiSelectProps> = (props) => {
    let { inputProps } = props;
    const { options, label, required, errorMessage, chipsToShow } = props;

    if (!inputProps) inputProps = {};

  // if (props.value !== undefined) { inputProps.value = props.value; }
    if (props.onChange !== undefined) { inputProps.onChange = props.onChange; }
    if (props.onBlur !== undefined) { inputProps.onBlur = props.onBlur; }
    if (props.onClose !== undefined) { inputProps.onClose = props.onClose; }

    const getLabel = (value: string) => {
        return options.find((r) => r.value === value)?.label;
    };

    const useStyles = makeStyles((theme: Theme) => createStyles({
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 200,
      // maxWidth: 1000,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
      // padding: 1,
    },
    paddingChip: {
      margin: "2px 0px",
      backgroundColor: "transparent",
      fontWeight: "bold",
      color: theme.palette.text.header,
      "& span": {
        paddingLeft: 5,
        paddingRight: 5,
      }
    },
    }));
    const classes = useStyles();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: (ITEM_HEIGHT * 4.5) + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    };

    return (
        <div className={clsx("flex-row", props.className)} style={{ width: "100%", ...props.style }}>
            <FormControl className={clsx("flex-grow", classes.formControl)} variant="outlined" error={!!errorMessage}>
                <InputLabel htmlFor={props.id}>{label}</InputLabel>
                <Select
                    multiple
                    label={label}
                    value={props.value}
                    onChange={props.onChange}
                    inputProps={{
            name: props.name,
            id: props.id,
                    }}
                    required={required}
                    {...inputProps}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                            {!!chipsToShow && <>
                                {(selected as string[]).filter((value, i) => i < chipsToShow).map((value) => (
                                    <Chip key={value} label={getLabel(value)} className={classes.chip} />
                                ))}
                                {
                                    ((selected as string[]).length - chipsToShow > 0) &&
                                    <Chip label={`+${(selected as string[]).length - chipsToShow} more`} className={classes.paddingChip} />
                                }
                            </>}
                            {!chipsToShow && (selected as string[]).map((value) => (
                                <Chip key={value} label={getLabel(value)} className={classes.chip} />
                            ))}
                        </div>
                    )}
                    SelectDisplayProps={
                        {
              style: {
                padding: props?.value?.length ? "10px 32px 10px 10px" : "18px 32px 18px 10px"
              }
                        }
                    }
                    MenuProps={MenuProps}
                >
                    {options.map((opt) => (
                        <MenuItem key={opt.value} value={opt.value}>
                            <>
                                <UICheckbox checked={props.value?.includes(opt.value as string) ?? false} /> {opt.label}
                            </>
                        </MenuItem>
                    ))}

                </Select>
                {
                    !!errorMessage &&
                    <FormHelperText>{errorMessage}</FormHelperText>
                }
            </FormControl>
        </div>
    );
};

export default memo(UIMultiSelect);
