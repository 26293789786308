import { Checkbox, createStyles, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Theme } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import DefaultPaginationActions from './components/default-pagination-actions';

export type CellAlignment = "left" | "center" | "right";
export type TableSizes = "small" | "medium";
export type Order = 'asc' | 'desc';
interface DefiniteValue {
    id: string;
}

interface ToolbarProps {
    numSelected: number;
    selected: string[];
    // [name: string]: any;
}
// interface BodyComponentProps {
//     data: any;
//     [name: string]: any;
// }

export interface HeaderData<TItem> {
    id: HeaderKeys<TItem>;
    align: CellAlignment;
    disablePadding: boolean;
    label: string;
    isSortable: boolean;
}

export type HeaderKeys<TItem> = keyof TItem | "actions";
export interface HeaderComponentProps<TItem> {
    data: HeaderData<TItem>;
    onSort?: (property: HeaderKeys<TItem>) => (event: React.MouseEvent<unknown>) => void;
    // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    orderBy: HeaderKeys<TItem>;
    order: Order;
    [name: string]: any;
}

interface Components<TItem, TBProps> {
    Toolbar?: React.FC<TBProps & ToolbarProps>;
    Header?: React.FC<HeaderComponentProps<TItem>>;
    BodyComponent?: React.FC<any>;
}

interface TableData<TItem extends DefiniteValue> {
    // toolBarData?: any;
    headerData?: HeaderData<TItem>[];
    values: TItem[];
}

interface TableInnerProps<TBProps> {
    toolBarProps?: Omit<TBProps, "numSelected" | "selected">;
    headerProps?: any;
    bodyProps?: any;
}

interface ResposiveTableProps<TItem extends DefiniteValue, TBProps> {
    data: TableData<TItem>;
    components: Components<TItem, TBProps>;
    innerProps: TableInnerProps<Omit<TBProps, "numSelected" | "selected">>;
    size?: TableSizes;
    orderBy?: HeaderKeys<TItem>;
    rowCount?: number;
    isSelectable?: boolean;
    className?: string;
    showJumpToPageInPagination?: boolean;
    // onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;

    "aria-label"?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        borderRadius: 6,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<TItem, Key extends HeaderKeys<TItem>>(
    order: Order,
    orderBy: Key,
): (a: TItem, b: TItem) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy as keyof TItem)
        : (a, b) => -descendingComparator(a, b, orderBy as keyof TItem);
}

const ResponsiveTable = <TItem extends DefiniteValue, TBProps extends ToolbarProps>(props: ResposiveTableProps<TItem, TBProps>) => {
    const { Toolbar, Header, BodyComponent } = props?.components;
    const { headerData, values } = props?.data;
    const { toolBarProps, headerProps, bodyProps } = props?.innerProps;
    const { size, orderBy: orderByHeaderId = "", rowCount, className, isSelectable = false, showJumpToPageInPagination = false } = props;
    const classes = useStyles();

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<HeaderKeys<TItem>>(orderByHeaderId as HeaderKeys<TItem>);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(rowCount ?? 5);

    const numSelected = selected.length;
    const totalRows = values.length;

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: HeaderKeys<TItem>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const createSortHandler = (property: HeaderKeys<TItem>) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = values.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (id: string) => (event: React.MouseEvent<unknown>) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    return (
        <div className={clsx(classes.root, className)}>
            <Paper className={clsx(classes.paper, "universal-box-shadow")}>
                {
                    !!Toolbar && <Toolbar {...toolBarProps as TBProps} numSelected={numSelected} selected={selected} />
                }
                <TableContainer>
                    <Table
                        className={clsx(classes.table, "responsive-table")}
                        aria-labelledby="tableTitle"
                        size={size ?? "medium"}
                        aria-label={props["aria-label"]}
                    >
                        <TableHead className={clsx("responsive-table__header", !Toolbar && "no-toolbar")}>
                            <TableRow>
                                {
                                    isSelectable && <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={numSelected > 0 && numSelected < totalRows}
                                            checked={totalRows > 0 && numSelected === totalRows}
                                            onChange={handleSelectAllClick}
                                            inputProps={{ 'aria-label': 'select all users' }}
                                        />
                                    </TableCell>
                                }
                                {!!headerData && headerData.map((headCell) => (
                                    !!Header && <Header
                                        onSort={createSortHandler}
                                        order={order}
                                        orderBy={orderBy}
                                        {...headerProps}
                                        data={headCell}
                                        key={headCell.id}
                                    />
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                stableSort<TItem>(values, getComparator<TItem, HeaderKeys<TItem>>(order, orderBy))
                                    .slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.id}
                                                selected={isItemSelected}
                                            >
                                                {isSelectable && <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={handleClick(row.id)}
                                                        checked={isItemSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell>}

                                                {
                                                    !!BodyComponent && <BodyComponent data={row} {...bodyProps} />
                                                }

                                            </TableRow>
                                        );
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={values.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={
                        showJumpToPageInPagination
                            ? DefaultPaginationActions
                            : undefined
                    }
                />
            </Paper>
        </div>
    );
};

export default ResponsiveTable;
