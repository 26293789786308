import React from "react";
import { TableCell, TableSortLabel, Tooltip } from "@material-ui/core";
// import CreateIcon from '@material-ui/icons/Create';
// import { BlueResponsiveTable } from "../../../components/blue-responsive-table";
import { RowEventType } from "../../contact-settings/models/types";
import { UIButton } from "../../../components/form";
import { ResponsiveTable } from "../../../components/responsive-table";
import ChatleadIcon from "../../../components/chatlead-icon";
import { HeaderComponentProps, HeaderData } from "../../../components/responsive-table/responsive-table";

// export type CellAlignment = "left" | "center" | "right";
// export type TableSizes = "small" | "medium";
// export type Order = 'asc' | 'desc';

interface NotificationData {
    id: string;
    site: string | undefined;
    messageTitle: string;
    startDate: string;
    endDate: string;
    priority: string;
    textColor: string;
    backColor: string;
}

interface NotificationTableProps {
    notificationData?: NotificationData[];
    onRowEvent: (id: string, eventType: RowEventType) => void;
}

type HeadCell = HeaderData<NotificationData>;

type HeaderItemProps = HeaderComponentProps<NotificationData>;

const headCells: HeadCell[] = [
    { id: 'site', align: "left", disablePadding: false, label: 'Site', isSortable: false },
    { id: 'messageTitle', align: "left", disablePadding: false, label: 'Message Title', isSortable: false },
    { id: 'startDate', align: "left", disablePadding: true, label: 'Start Date', isSortable: false },
    { id: 'endDate', align: "left", disablePadding: true, label: 'End Date', isSortable: false },
    { id: 'priority', align: "left", disablePadding: true, label: 'Priority', isSortable: false },
    { id: 'textColor', align: "left", disablePadding: true, label: 'Text Color', isSortable: false },
    { id: 'backColor', align: "left", disablePadding: true, label: 'Background Color', isSortable: false },
    { id: 'actions', align: "right", disablePadding: false, label: 'Actions', isSortable: false },
];

const HeaderItem: React.FC<HeaderItemProps> = (props) => {
    const { data: headCell, orderBy, order, onSort } = props;

    return <TableCell
        className='head'
        size='medium'
        key={headCell.id}
        align={headCell.align}
        sortDirection={orderBy === headCell.id ? order : false}
    >
        {
            (!headCell.isSortable) ? <span>{headCell.label}</span> : (
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={onSort ? onSort(headCell.id) : undefined}
                >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                        <span className="visually-hidden">
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    ) : null}
                </TableSortLabel>
            )
        }
    </TableCell>;
};

interface BodyRowItemProps {
    data: NotificationData;
    onRowEvent: (id: string, eventType: RowEventType) => void;
}

const BodyRowItem: React.FC<BodyRowItemProps> = (props) => {
    const { data: row, onRowEvent } = props;

    return <>
        <TableCell
            component="td"
            id={row.id}
            scope="row"
        >
            {row.site}
        </TableCell>

        <TableCell align="left">{row.messageTitle}</TableCell>
        <TableCell align="left">{row.startDate}</TableCell>
        <TableCell align="left">{row.endDate}</TableCell>
        <TableCell align="left">{row.priority}</TableCell>
        <TableCell align="left">{row.textColor}</TableCell>
        <TableCell align="left">{row.backColor}</TableCell>
        <TableCell align="right">
            <div className="flex-row" style={{ justifyContent: 'flex-end' }}>
                <Tooltip title="Edit">
                    <UIButton variant="icon" buttonProps={{ "aria-label": "edit" }} size="small" onClick={() => onRowEvent(row.id, "edit")}>
                        {/* <CreateIcon fontSize="small" /> */}
                        <ChatleadIcon icon="edit" size={23} />
                    </UIButton>
                </Tooltip>
            </div>
        </TableCell>
    </>;
};

const NotificationSettingsTable: React.FC<NotificationTableProps> = (props) => {
    // logger.log(`table props notificationData: ${JSON.stringify(props.notificationData)}`);
    return <ResponsiveTable
        components={{
            Header: HeaderItem,
            BodyComponent: BodyRowItem
        }}
        data={{
            headerData: headCells,
            values: props.notificationData ?? []
        }}
        className="b_table_sticky"
        innerProps={{ bodyProps: { onRowEvent: props.onRowEvent } }}
        rowCount={10}
        isSelectable={false}
        size="small"
        orderBy="startDate"
    />;
};

export default NotificationSettingsTable;
