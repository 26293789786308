import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { TbArrowBackUp } from "react-icons/tb";
import { CgUndo } from "react-icons/cg";
import { logSentryError } from "../../serverLog";
import configuration from "../../utils/configuration";
import FailedComponent from "../failed-component";
import { UIButton } from "../form";

// interface ErrorBoundaryWrappedState {
//     hasError: boolean;
// }
interface ErrorBoundaryWrappedProps {
    resetKeys?: unknown[];
    onReset?: (...args: unknown[]) => void;
}

// class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
//     constructor(props: ErrorBoundaryProps) {
//         super(props);
//         this.state = { hasError: false };
//     }

//     static getDerivedStateFromError(error: any) {
//         // Update state so the next render will show the fallback UI.
//         return { hasError: true };
//     }

//     componentDidCatch(error: any, errorInfo: any) {
//         // You can also log the error to an error reporting service
//         logRemoteError(error);
//     }

//     render() {
//         if (this.state.hasError) {
//             // You can render any custom fallback UI
//             return <div className="flex-row flex-center justify-center" style={{ width: "100%" }}>
//                 <FailedComponent type="error" text="Something went wrong" size="medium" />
//             </div>;
//         }

//         return this.props.children;
//     }
// }

const ErrorFallback = ({ resetKeys, onReset, error, resetErrorBoundary }: FallbackProps & ErrorBoundaryWrappedProps) => {
    const backToMainPage = () => {
        window.location.assign(`${window.location.protocol}//${window.location.host}/${configuration.getValue("baseUrl")}`);
    };
    return (
        <div className="flex-column flex-center justify-center" style={{ width: "100%", height: "100%" }}>
            <FailedComponent type="error" text="Something went wrong" size="medium" />
            <UIButton className="t-m-4" onClick={backToMainPage} buttonProps={{ startIcon: <TbArrowBackUp /> }}>Back to Main Page</UIButton>
            {
                (!!resetKeys && !!onReset) &&
                <UIButton className="t-m-2" onClick={resetErrorBoundary} buttonProps={{ startIcon: <CgUndo /> }}>Try to Reset</UIButton>
            }
        </div>
    );
};

const ErrorBoundaryWrapped: React.FC<ErrorBoundaryWrappedProps> = ({ resetKeys, onReset, children }) => {
    return <ErrorBoundary FallbackComponent={(props) => <ErrorFallback {...props} resetKeys={resetKeys} onReset={onReset} />} onError={logSentryError} resetKeys={resetKeys} onReset={onReset}>
        {children}
    </ErrorBoundary>;
};

export default ErrorBoundaryWrapped;
