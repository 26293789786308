import { CircularProgress, createStyles, DialogActions, makeStyles, Theme, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { Formik } from "formik";
import React from "react";
import appToast from "../../../components/app-toast";
import { UIButton, UICheckbox, UIInput, UILoaderButton, UISelect } from "../../../components/form";
import { AdminEmailParams } from "../../../model/Admin-info";
import { ContactType } from "../../../model/enums/contact-type";
import { HttpStatusCode } from "../../../model/http-status-code";
import theme from "../../../theme";
import logger from "../../../utils/log-utils";
import { castAs, getErrorString } from "../../../utils/object-utils";
import useGeneralSettings from "./hooks/useGeneralSettings";

const GeneralSettings: React.FC<any> = (props) => {
    const { formSubmitting, contactTypes, cellularProvider, oldUsername, showCellularProvider,
        setShowCellularProvider, setFormSubmitting,
        getInitialValues, getValidationSchema, UpdateAdminEmail } = useGeneralSettings(props.subscriberId);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const useStyles = makeStyles((theme: Theme) => createStyles({
        root: {
            '& .MuiTextField-root': {
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            padding: "0px 5px",
            height: isMobile ? "calc(90vh - 180px)" : "calc(73vh - 140px)",
            overflowY: "auto",
        },
    }));

    const classes = useStyles();

    return (
        <div>
            {
                getInitialValues() && <Formik
                    initialValues={getInitialValues()}
                    validationSchema={getValidationSchema()}
                    onSubmit={async (values, { setSubmitting }) => {
                        if (oldUsername) {
                            setSubmitting(true);
                            setFormSubmitting(true);
                            // let newFormData = formData;

                            // newFormData.notificationPreferences = getEnumKeys(NotificationPreferncesKeys).filter(r => r !== "None").filter(v => values[getDelearshipDeptCBKey(v)]).map(r => ({
                            //     id: newFormData.id,
                            //     preferences: castAs<number>(NotificationPreferncesKeys[r]),
                            //     contactType: castAs<number>(values[getDelearshipDeptMethodKey(r)])
                            // })) ?? []

                            const newFormData: AdminEmailParams = {
                                SubscriberID: props.subscriberId,
                                oldUserName: oldUsername ?? "",
                                newUserName: values?.email,
                                firstName: values?.firstName ?? "",
                                lastName: values?.lastName ?? "",
                                newPassword: values?.password,
                                contact: {
                                    EmailID1: values?.email,
                                    Phone1: values?.adminPhone,
                                    Mobile1: values?.adminMobile,
                                    Mobile1_Service_Provider: values?.cellularProvider,
                                    EmailID2: "",
                                    Phone2: "",
                                    Mobile2: "",
                                    Mobile2_Service_Provider: "",
                                    FAX1: "",
                                    FAX2: ""
                                },
                                SalesLead: values?.salesLeads,
                                SalesQuery: values?.salesLeads,
                                SalesService: values?.serviceLeads,
                                PartsService: values?.partsLeads,
                                SalesLeadContactType: values?.salesLeads ? castAs<number>(values?.contactType) : ContactType.None,
                                SalesQueryContactType: values?.salesLeads ? castAs<number>(values?.contactType) : ContactType.None,
                                SalesServiceContactType: values?.serviceLeads ? castAs<number>(values?.contactType) : ContactType.None,
                                PartsServiceContactType: values?.partsLeads ? castAs<number>(values?.contactType) : ContactType.None,
                                BodyShopService: false,
                                BodyShopServiceContactType: ContactType.None
                            };
                            logger.log(`General update data: ${JSON.stringify(newFormData)}`);
                            try {
                                const res = await UpdateAdminEmail(newFormData);
                                if (res.status !== HttpStatusCode.OK) {
                                    appToast.error(getErrorString(res));
                                } else {
                                    appToast.success("Admin details has been updated");
                                }
                            } catch (error) {
                                // console.error(error);
                                appToast.error(getErrorString(error));
                            }
                            setSubmitting(false);
                            setFormSubmitting(false);
                        }
                    }}
                >
                    {
                        ({
                            values,
                            errors,
                            // touched,
                            handleChange,
                            // handleBlur,
                            handleSubmit,
                            setFieldValue,
                            setErrors,
                            validateForm,
                            submitForm,
                            isSubmitting,
                            isValid
                        }) => {
                            const formSchema = {
                                firstName: { label: 'First Name', name: 'firstName', className: "r-m-4 r-m-phone-inv", value: values.firstName, errorMessage: errors?.firstName },
                                lastName: { label: 'Last Name', name: 'lastName', className: "r-m-4 r-m-phone-inv", value: values.lastName, errorMessage: errors?.lastName },
                                email: { label: 'Email Address / Username', name: 'email', className: "r-m-4 r-m-phone-inv", value: values.email, errorMessage: errors?.email },
                                password: { label: 'Password', name: 'password', className: "r-m-4 r-m-phone-inv", value: values.password, errorMessage: errors?.password },
                                confirmPassword: { label: 'Confirm Password', name: 'confirmPassword', className: "r-m-4 r-m-phone-inv", value: values.confirmPassword, errorMessage: errors?.confirmPassword },
                                adminPhone: { label: 'Admin Phone', name: 'adminPhone', mask: "+1 (999) 999 9999", maskChar: " ", className: "r-m-4 r-m-phone-inv", value: values.adminPhone, errorMessage: errors?.adminPhone },
                                // extension: { label: 'Extension', name: 'extension', className: "r-m-4 r-m-phone-inv", value: values.extension, errorMessage: errors?.extension },
                                adminMobile: { label: 'Admin Mobile', name: 'adminMobile', mask: "+1 (999) 999 9999", maskChar: " ", className: "r-m-4 r-m-phone-inv", value: values.adminMobile, errorMessage: errors?.adminMobile },
                                salesLeads: { label: "Sales Leads", name: "salesLeads", value: (values.salesLeads ?? false) },
                                serviceLeads: { label: "Service Leads", name: "serviceLeads", value: (values.serviceLeads ?? false) },
                                partsLeads: { label: "Parts Leads", name: "partsLeads", value: (values.partsLeads ?? false) },
                                contactType: { label: 'Select Contact Type', showEmptyOption: false, name: 'contactType', value: values.contactType, options: contactTypes, className: "r-m-4 r-m-phone-inv", errorMessage: errors?.contactType },
                                cellularProvider: { label: 'Select Cellular Provider', name: 'cellularProvider', value: values.cellularProvider, options: cellularProvider, className: "r-m-4 r-m-phone-inv", errorMessage: errors?.cellularProvider },

                            };
                            // const handleDPChange = (name: string, value: string | ArrayBuffer | null | undefined) => {
                            //     setFieldValue(name, value, true);
                            // };
                            const onContactTypeChange = (evt: React.ChangeEvent<{} | HTMLInputElement>, value: any) => {
                                if ("name" in evt.target) {
                                    // logger.log(`change value: ${evt.target.value}`);
                                    setFieldValue("name" in evt.target ? evt.target.name : "", evt.target.value, true);
                                    if (evt.target.name === "contactType") {
                                        setShowCellularProvider(!!((evt.target.value === '2' || evt.target.value === '3')));
                                    }
                                    // if (evt.target.name === "copyFirstNameToDisplayName") {
                                    //     setFieldValue("displayName", checked ? values?.firstName : "", true);
                                    // }
                                }
                            };
                            return (
                                <form className={clsx(classes.root, "flex-grow", "custom-scroll")} noValidate autoComplete="off" id="edit-form" onSubmit={handleSubmit}>

                                    <div className="flex">
                                        <div className="flex-responsive">
                                            <UIInput {...formSchema.firstName} onChange={handleChange} />
                                            <UIInput {...formSchema.lastName} onChange={handleChange} />
                                        </div>
                                        <div className="flex-responsive">
                                            <UIInput {...formSchema.email} onChange={handleChange} />
                                        </div>
                                        <div className="flex-responsive">
                                            <UIInput {...formSchema.adminPhone} onChange={handleChange} />
                                            <UIInput {...formSchema.adminMobile} onChange={handleChange} />
                                            {/* <UIInput  {...formSchema.extension} onChange={handleChange}></UIInput> */}
                                        </div>
                                        <div className="flex-responsive">
                                            <UIInput {...formSchema.password} onChange={handleChange} />
                                            <UIInput {...formSchema.confirmPassword} onChange={handleChange} />
                                        </div>
                                        <div className="flex-row">
                                            <UICheckbox {...formSchema.salesLeads} onChange={handleChange} />
                                            <UICheckbox {...formSchema.serviceLeads} onChange={handleChange} />
                                            <UICheckbox {...formSchema.partsLeads} onChange={handleChange} />
                                        </div>
                                        <div className="flex-responsive t-m-2">
                                            <UISelect {...formSchema.contactType} onChange={onContactTypeChange} />
                                            {showCellularProvider ? <UISelect {...formSchema.cellularProvider} onChange={handleChange} /> : <></>}
                                        </div>
                                    </div>
                                </form>
                            );
                        }
                    }
                </Formik>
            }
            <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                <UILoaderButton isLoading={formSubmitting} shouldBeDisabledWhileLoading type="submit" form="edit-form">
                    Save
                </UILoaderButton>
                <UIButton color="danger" onClick={props.handleClose}>
                    Close
                </UIButton>
            </DialogActions>
        </div>
    );
};

export default GeneralSettings;
