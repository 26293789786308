export enum _SubscriberFeature {
    // [Description("Lead To Ring")]
    LeadToRing = 1,
    // [Description("Facebook Market")]
    FbMarketPlace = 2,
    // [Description("Facebook Chat")]
    FbChat = 4,
    // [Description("Web Chat")]
    WebChat = 8,
    // [Description("Video Chat")]
    VideoChat = 16,
    // [Description("SMS Chat")]
    SMSChat = 32,
    // [Description("Dual Billing")]
    DualBilling = 64,
    // [Description("Answering Service")]
    AnsweringService = 128,
    // [Description("Appointment Setting")]
    AppointmentService = 256,
    // [Description("Content Marketing – Blog & Social Media")]
    ContentMarketingBlogAndSocialMedia = 512,
    // [Description("Google Ads pay-per-click advertising")]
    GoogleAdsPayPerClickAdvertisingSubscribed = 1024,
    // [Description("Live Chat with SMS Text Messaging")]
    LiveChatwithSMSTextMessaging = 2048,
    // [Description("Search Engine Friendly Website")]
    SearchEngineFriendlyWebsite = 4096,
    // [Description("Service Appointment")]
    ServiceAppointment = 8192,
    // [Description("Google My Business")]
    GoogleMyBusiness = 16384,

    // ServicesPro

    // [Description("WebsitePro")]
    WebsitePro = 32768,
    // [Description("SEOPro")]
    SEOPro = 65536,
    // [Description("ContentPro")]
    ContentPro = 131072,
    // [Description("Live Chat and SMS Text Software")]
    LiveChatandSMSTextSoftware = 262144,
    // [Description("ChatPro")]
    ChatPro = 524288,
    // [Description("Appointment Setting Software")]
    AppointmentSettingSoftware = 1048576,
    // [Description("AppointmentPro")]
    AppointmentPro = 2097152,
    // [Description("CitationPro")]
    CitationPro = 4194304,
    // [Description("PhonePro")]
    PhonePro = 8388608,
    // [Description("Social Media Good Plan")]
    SocialMediaGoodPlan = 16777216,
    // [Description("Social Media Better Plan")]
    SocialMediaBetterPlan = 33554432,
    // [Description("Social Media Best Plan")]
    SocialMediaBestPlan = 67108864,

    // ServicesPro

    // [Description("Instagram")]
    Instagram = 134217728,
    // [Description("WhatsApp")]
    WhatsApp = 268435456,
    // [Description("Reputation Management")]
    ReputationManagement = 536870912,
    // [Description("Any")]
    Any = 0
}
