import clsx from "clsx";
import * as React from "react";

export interface ScrollableProps {
    classes?: string | { [x: string]: any };
    styles?: React.CSSProperties;
    superSlim?: boolean;
}

const Scrollable: React.FC<ScrollableProps> = ({
    classes,
    children,
    superSlim,
    styles,
}) => {
    return (
        <div
            className={clsx(
                "flex-grow flex flex-column scroll-hidden relative",
                classes
            )}
            style={styles}
        >
            <div
                className={clsx(
                    superSlim ? "custom-scroll-super-slim" : "custom-scroll",
                    "flex-column flex-grow"
                )}
            >
                <div className="content flex-column flex-grow">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Scrollable;
