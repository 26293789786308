// @ts-nocheck
import * as React from "react";
import Highcharts from 'highcharts';
import { DoughnutData } from "../../model/dashboard-chart-hc";
import theme from "../../theme";
import chartColors from "./models/chart-colors";

export interface DoughnutChartProps {
    title?: string;
    data: DoughnutData[];
    // color?: string | string[];
    theme?: "primary" | "secondary";
    id: string;
}

export interface DoughnutChartState { }

class Donut extends React.Component<DoughnutChartProps, DoughnutChartState> {
    id: string;
    myChart: Highcharts.Chart | undefined;
    canvasRef: React.RefObject<HTMLCanvasElement>;

    constructor(props: DoughnutChartProps) {
        super(props);
        this.canvasRef = React.createRef<HTMLCanvasElement>();
        this.id = props.id;
    }

    componentDidMount() {
        this.initializeChart();
    }

    componentDidUpdate(prevprop: DoughnutChartProps) {
        if (JSON.stringify(prevprop.data) !== JSON.stringify(this.props.data)) {
            this.initializeChart();
        }
    }

    getColorFromTheme() {
        if (this.props.theme === "primary") {
            return theme.palette.primary.main;
        } if (this.props.theme === "secondary") {
            return theme.palette.secondary.main;
        }
        return null;
    }

    initializeChart() {
        this.myChart = new Highcharts.Chart(this.id, {
            chart: {
                margin: [4, 10, 0, 0],
                height: 250,
            },
            credits: {
                enabled: false
            },
            title: {
                text: this.props.title,
                style: {
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    fontSize: "medium"
                },

            },

            series: [{
                name: '',
                type: 'pie',
                data: this.props.data,
                size: '40%',
                innerSize: '40%',
                showInLegend: true,
                dataLabels: {
                    enabled: false,
                },
                colors: chartColors
            }],
            legend: {
                useHTML: true,
                labelFormatter() {
                    return `${this.name} (${this.y})`;
                }
            },
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 768
                        },
                        chartOptions: {
                            chart: {
                                height: 400
                            },
                            legend: {
                                align: 'center',
                                y: 10,
                                maxHeight: 100,
                                navigation: {
                                    enabled: true,
                                    activeColor: '#3E576F',
                                    animation: true,
                                    arrowSize: 12,
                                    inactiveColor: '#CCC',
                                    style: {
                                        fontWeight: 'bold',
                                        color: '#333',
                                        fontSize: '12px'
                                    }
                                }
                            },
                        },
                    }
                ]
            }
        });
    }

    // componentWillUnmount() {
    //     this.myChart?.destroy();
    //   }
    // componentDidUpdate() {
    //     if (this.myChart) {
    //         this.myChart.data.labels = this.props.data.map((d) => d.label);
    //         if (
    //             this.myChart.data &&
    //             this.myChart.data.datasets &&
    //             this.myChart.data?.datasets[0]?.data
    //         )
    //             this.myChart.data.datasets[0].data = this.props.data.map(
    //                 (d) => d.value
    //             );
    //     }
    //     this.myChart?.update();
    // }

    render() {
        return (
            <div style={{ position: 'relative', overflow: "auto" }}>
                <div id={this.id} />
            </div>
        );
    }
}

export default Donut;
