import * as React from "react";
// import CameraAltIcon from '@material-ui/icons/CameraAlt';
import clsx from "clsx";
import Cropper from "react-easy-crop";
import { getOrientation, Orientation } from "get-orientation/browser";
import { Slider, Typography } from "@material-ui/core";
import { CommonInputProps } from "../../../model/common-props";
import { UIButton } from "..";
import { CropData, getCroppedImg, getRotatedImage } from "../../../utils/canvas-utils";
import defImageSrc from "../../../assets/images/user-avatar.png";
import ChatleadIcon from "../../chatlead-icon";

interface ImagePickerProps extends CommonInputProps {
    onChange?: (name: string, value: string | ArrayBuffer | null | undefined) => void;
}

interface ProfileImagePickerState {
    src: string;
    croppedImage: string;
    crop: CropData;
    croppedArea: CropData;
    zoom: number;
    rotate: number;
    aspect: number;
}
const ORIENTATION_TO_ANGLE: { [id: number]: number } = {
    [Orientation.BOTTOM_RIGHT]: 180,
    [Orientation.RIGHT_TOP]: 90,
    [Orientation.LEFT_BOTTOM]: -90,
};

const UIImagePicker: React.FunctionComponent<ImagePickerProps> = (props) => {
    const { name, value, className, onChange, disabled } = props;

    const [state, setState] = React.useState<ProfileImagePickerState>(
        {
            src: "",
            croppedImage: "",
            aspect: 9 / 9,
            crop: { x: 0, y: 0 },
            croppedArea: { x: 0, y: 0 },
            zoom: 1,
            rotate: 0,
        }
    );

    function readFile(file: Blob): Promise<string | ArrayBuffer | null> {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    }

    React.useEffect(() => {
        if (value) setState({ ...state, croppedImage: value });
    }, [value]);
    const onInChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (evt.target?.files && evt.target.files.length > 0) {
            const file = evt.target.files[0];
            let imageDataUrl = await readFile(file);

            // apply rotation if needed
            const orientation = await getOrientation(file);
            const rotation: number = ORIENTATION_TO_ANGLE[orientation];
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
            }
            if (typeof imageDataUrl === 'string') { setState({ ...state, src: imageDataUrl }); }
        }
    };

    const showCroppedImage = React.useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                state.src,
                state.croppedArea,
                state.rotate
            );
            // logger.log('donee', { croppedImage })
            setState({ ...state, croppedImage, src: "" });
            if (onChange && name) {
                onChange(name, croppedImage);
            }
        } catch (e) {
            // console.error(e)
        }
    }, [state.src, state.crop, state.rotate]);

    // const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    //     setState({ ...state, crop: croppedAreaPixels });
    // }, [])

    const onCropChange = (crop: CropData) => {
        setState({ ...state, crop });
    };

    const onCropComplete = (croppedArea: CropData, croppedAreaPixels: CropData) => {
        setState({ ...state, croppedArea: croppedAreaPixels });
    };

    const onZoomChange = (zoom: number) => {
        setState({ ...state, zoom });
    };

    return (<div className={clsx("picker-container", className)}>
        <div className="image-picker-container">
            <div className="image-picker">
                {
                    !disabled &&
                    <>
                        <input type="file" name={props.name} id={props.name} form={props.form} className={clsx("input-dp", props.className)} onChange={onInChange} accept="image/*" />
                        <div className="image-picker__overlay">
                            {/* <CameraAltIcon /> */}
                            <ChatleadIcon icon="camera" size={40} color="var(--solids-white)" />
                        </div>
                    </>
                }
                <img alt="picker" className="image-selected" src={state.croppedImage ?? ""} onError={(e) => { e.currentTarget.onerror = null; e.currentTarget.src = defImageSrc; }} />
            </div>
        </div>
        {state.src && (
            <div className="crop-tools">
                <div className="flex-column">
                    <div className="crop-container">
                        <Cropper
                            image={state.src}
                            crop={state.crop}
                            zoom={state.zoom}
                            aspect={state.aspect}
                            cropShape="round"
                            onCropChange={onCropChange}
                            onCropComplete={onCropComplete}
                            onZoomChange={onZoomChange}
                        />
                    </div>
                    <div className="flex-row">
                        <Typography
                            variant="overline"
                            style={{ minWidth: "65px" }}
                        >Zoom
                        </Typography>
                        <Slider
                            value={state.zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, zoomLvl) => setState({ ...state, zoom: Array.isArray(zoomLvl) ? zoomLvl[0] : zoomLvl })}
                        />
                    </div>
                    <UIButton onClick={showCroppedImage}>Save</UIButton>
                </div>
            </div>
        )}
    </div>
    );
};

export default React.memo(UIImagePicker);
