import clsx from "clsx";
import * as React from "react";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { createStyles, makeStyles, Popover, Theme } from "@material-ui/core";
import { NavigationLink } from "../../../components/navigation-link";
import { RouteConfig } from "../../../model/route-config";
import { constructPath } from "../../../utils/route-utils";
import useSingleNavigationItem from "./hooks/useSingleNavigationItem";
import NavItemText from "./nav-item-text";

const useStyles = makeStyles((theme: Theme) => createStyles({
    popover: {
        // pointerEvents: 'none',
        // position: "relative",
        // zIndex: 999,
    },
    paper: {
        // padding: theme.spacing(1),
        // paddingRight: 20,
        backgroundColor: "var(--navbar-background)",
    },
}));

export interface SingleNavigationItemProps {
    navItem: RouteConfig;
    basePath?: string;
    showChildRoutesInPopOver?: boolean;
    l1Route?: boolean;
}
const SingleNavigationItem: React.FC<SingleNavigationItemProps> = ({
    navItem,
    basePath,
    l1Route = false,
    showChildRoutesInPopOver = true
}) => {
    const classes = useStyles();
    const {
        extractChildNRoutes,
        isExpanded,
        isActive,
        changeExpansion,
        isSideBarCollapsed,
        isTab, shouldBeCollapsed,
        anchorEl, handlePopOpen, handlePopClose, handlePopoverClose
    } = useSingleNavigationItem(navItem, basePath);

    const content =
        extractChildNRoutes().length > 0 ? (
            <div
                role="presentation"
                className="navigation-group"
                onClick={handlePopOpen}
                ref={anchorEl}
            >
                <div className="flex-row" style={{ justifyContent: 'space-between' }}>
                    <NavItemText text={navItem.name} icon={navItem.icon} showOnlyIcon={l1Route && shouldBeCollapsed} showIconsIfNotPresent={l1Route} />
                    <KeyboardArrowRightIcon className="anchor" />
                </div>
            </div>
        ) : (
            <NavigationLink
                to={constructPath(navItem.path, basePath)}
                route={navItem}
                className="navigation-element focused flex-grow flex-center"
            >
                <NavItemText text={navItem.name} icon={navItem.icon} showOnlyIcon={l1Route && shouldBeCollapsed} showIconsIfNotPresent={l1Route} />
            </NavigationLink>
        );

    return (
        <>
            <div
                className={clsx(
                    "flex-column flex-noshrink relative nav-group-container",
                    {
                        "expanded-container": isExpanded,
                        "highlight-container": isActive,
                        "highlight-font": isActive && !isExpanded,
                        "pop-over-transform": shouldBeCollapsed,
                        "r-p-0": shouldBeCollapsed && l1Route,
                        "r-p-5": shouldBeCollapsed && !l1Route && !(extractChildNRoutes().length > 0)
                    }
                )}
            // onMouseEnter={handlePopoverOpen}
            // onMouseLeave={handlePopoverClose}
            >
                {content}
                {
                    (showChildRoutesInPopOver && shouldBeCollapsed && navItem.routes) ? (
                        <Popover
                            // id="mouse-over-popover"
                            className={classes.popover}
                            classes={{
                                paper: classes.paper,
                            }}
                            open={isExpanded && Boolean(anchorEl?.current)}
                            anchorEl={anchorEl?.current || null}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={handlePopClose}
                            disableRestoreFocus
                        >
                            <div className="navigation-content flex-grow flex-column">
                                {extractChildNRoutes().map((item) => (
                                    <SingleNavigationItem
                                        key={item.path}
                                        navItem={item}
                                        basePath={constructPath(navItem.path, basePath)}
                                    />
                                ))}
                            </div>
                        </Popover>
                    ) : (
                        isExpanded && (
                            <>
                                {extractChildNRoutes().map((item) => (
                                    <SingleNavigationItem
                                        key={item.path}
                                        navItem={item}
                                        basePath={constructPath(navItem.path, basePath)}
                                        showChildRoutesInPopOver={false}
                                    />
                                ))}
                            </>
                        )
                    )
                }

            </div>
        </>
    );
};

export default SingleNavigationItem;
