import { TableBody, TableRow, TableCell } from "@material-ui/core";
import { get } from "lodash";
import * as React from "react";
import { TableColumn } from "../../../model/table-column";

export interface AppTableBodyProps {
  data: any[];
  columns: TableColumn[];
  idPath: string;
}

const AppTableBody: React.FC<AppTableBodyProps> = ({
  data,
  columns,
  idPath,
}) => {
    const renderCell = (dataRow: any, column: TableColumn) => {
        if (column.content) {
            return column.content(dataRow);
        } if (column.path) {
            return get(dataRow, column.path);
        }
        return null;
    };
    return (
        <TableBody>
            {data.map((row) => {
                return (
                    <TableRow key={row[idPath]}>
                        {columns.map((column) => {
                            const { name, label, path, key, content, ...others } = column;
                            return (
                                <TableCell key={row[idPath] + (path || key)} {...others}>
                                    {renderCell(row, column)}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    );
};

export default AppTableBody;
