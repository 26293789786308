import * as React from "react";
import { Avatar, Chip } from "@material-ui/core";
import clsx from "clsx";
import Scrollable from "../scrollable";
import { FilterData, FilterOutput } from "../../model/filter-data";
import OptionGroup from "./components/option-group";
import { useQuickFilter } from "./hooks/useQuickFilter";
import { UIButton } from "../form";

export interface QuickFilterProps {
    title?: string;
    filterData: FilterData[];
    selectedValues?: FilterOutput;
    onSubmitFilter: (data: FilterOutput) => any;
    isMobile?: boolean;
}
const QuickFilter: React.FC<QuickFilterProps> = React.forwardRef<HTMLDivElement, QuickFilterProps>((props, ref) => {
    const { title, filterData, isMobile } = props;
    const {
        handleOptionChange,
        handleFilterSubmit,
        filterResult,
        handleClearFilter,
    } = useQuickFilter(props);
    const appliedFilterCount = Object.keys(filterResult || {}).length;
    return (
        <div className="quick-filter-menu--Wrapper flex-column" ref={ref}>
            <div className="header flex-row">
                <div className="simple-flex-responsive flex-center">
                    <div className="title">
                        <h4>{title || "Filter"}</h4>
                    </div>
                    <div className="counter">
                        {!!appliedFilterCount && (
                            <Chip
                                color="primary"
                                size="small"
                                onDelete={handleClearFilter}
                                label="Filter Applied"
                                avatar={<Avatar>{appliedFilterCount}</Avatar>}
                            />
                        )}
                    </div>
                </div>
                <div className={clsx("submit", isMobile && "r-m-2")}>
                    <UIButton color="primary" onClick={handleFilterSubmit}>
                        Done
                    </UIButton>
                </div>
            </div>
            <Scrollable>
                <div className="content flex-grow simple-flex-responsive b-m-2" style={{ columnGap: 5 }}>
                    {filterData.map((item) => (
                        <OptionGroup
                            key={item.path}
                            {...item}
                            selectedValues={filterResult[item.path]}
                            onOptionChange={handleOptionChange}
                        />
                    ))}
                </div>
            </Scrollable>
        </div>
    );
});

export default QuickFilter;
