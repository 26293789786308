import { TableContainer, Table, Paper } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";
import { TableColumn } from "../../model/table-column";
import { AppTableBody, AppTableHead } from "./components";

export interface AppTableProps {
  data: any[];
  useCard?: boolean;
  idPath: string;
  columns: TableColumn<any>[];
  classes?: string;
  style?: React.CSSProperties;
}

const AppTable: React.FC<AppTableProps> = (props) => {
    return (
        <TableContainer
            component={props.useCard ? Paper : "div"}
            className={clsx("table-container custom-scroll", props.classes)}
            style={props.style}
        >
            <Table stickyHeader aria-label="sticky table">
                <AppTableHead columns={props.columns} />
                <AppTableBody
                    data={props.data}
                    columns={props.columns}
                    idPath={props.idPath}
                />
            </Table>
        </TableContainer>
    );
};

export default AppTable;
