import { DialogActions } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { UIButton, UIRadio, UIRadioGroup, UISelect } from "../../../components/form";
import useGroupSettings from "./hooks/useGroupSettings";

export interface GroupSettingsProps {
    handleClose: any,
    subscriberId: any
}

const GroupSettings: React.FunctionComponent<any> = (props) => {
    const { handleClose, subscriberId, isGroupHead, groupName, groupId } = props;
    const {
        data, setAsGroupHead, SetAsGroup, unsetAsGroup
    } = useGroupSettings();
    // logger.log(`${isGroupHead}, ${groupName}, ${groupId}`)

    const [rbSelected, setrbSelected] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const [grpSelected, setGrpSelected] = useState(groupId);

    const formSchema = {
        groupRadio: {},
        group: { name: "group", options: data, label: "Select an option", disabled, showEmptyOption: false, value: grpSelected, className: "r-m-2 r-m-phone-inv" },
    };

    // const handleChange =(e: React.ChangeEvent<any>)=>{
    //     setrbSelected(e.target.value);
    // }
    const handleChange = (_event: any, value: any) => {
        // logger.log(value);
        setrbSelected(value);
    };
    const handleSelectChange = (e: React.ChangeEvent<any>) => {
        // logger.log(`Group Selected: ${e.target.value}`);
        // logger.log(subscriberId);
        setGrpSelected(e.target.value);
        // logger.log(`Group Selected: ${grpSelected}`);
    };
    const saveData = () => {
        // logger.log(`saveData hit`);
        if (rbSelected === 'rbMakeAsGroupHead') {
            // logger.log(`setGrpHead Hit`);
            setAsGroupHead(subscriberId);
        } else if (rbSelected === 'rbAddtoGroup' && grpSelected !== '') {
            // logger.log(`SetGroup Hit`);
            SetAsGroup(subscriberId, grpSelected);
        } else if (rbSelected === 'rbUnsetAsGroupHead') {
            unsetAsGroup(subscriberId);
        }
    };

    useEffect(() => {
        // logger.log(`rbselected: ${rbSelected}`);
        if (rbSelected === 'rbAddtoGroup') setDisabled(false); else setDisabled(true);
        // logger.log(disabled);
    }, [rbSelected]);

    return (
        <div>
            <div className="flex-responsive">
                {groupName ? <small>Group Member of {groupName}</small> : ''}
            </div>
            <div className="flex-responsive t-m-4">
                <small>Action:</small>
            </div>
            <div className="flex-responsive">
                {(() => {
                    if (isGroupHead) {
                        return (
                            <UIRadioGroup {...formSchema.groupRadio} onChange={handleChange}>
                                <UIRadio id="rbUnsetAsGroupHead" value='rbUnsetAsGroupHead' label="Unset this subscriber as group head" />
                                <UIRadio id="rbAddtoGroup" value='rbAddtoGroup' label="Add to the following group below" disabled />
                            </UIRadioGroup>);
                    }

                    return (
                        <UIRadioGroup {...formSchema.groupRadio} onChange={handleChange}>
                            <UIRadio id="rbMakeAsGroupHead" value='rbMakeAsGroupHead' label="Make this subscriber group head" />
                            <UIRadio id="rbAddtoGroup" value='rbAddtoGroup' label="Add to the following group below" />
                        </UIRadioGroup>);
                }
                )()}

            </div>
            <div className="flex-responsive t-m-1 r-m-2 l-m-2">
                <UISelect {...formSchema.group} onChange={handleSelectChange} />
            </div>
            <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                <UIButton onClick={saveData}>
                    Save
                </UIButton>
                <UIButton color="danger" onClick={handleClose}>
                    Close
                </UIButton>
            </DialogActions>
        </div>
    );
};

export default GroupSettings;
