/* eslint-disable camelcase */

export enum _SubscriberFunctionalStatus {
    Disabled = 0,
    Active = 1,
    // [Description("Active No Login")]
    Active_No_Login = 2,
    // [Description("Monitoring Only")]
    Monitoring_Only = 3,
    // [Description("Old Subscriber For Old Invoice")]
    Old_Subscriber_For_Old_Invoice = 4,
    Any = 99
}
