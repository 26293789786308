import * as React from "react";
import {
    // Button,
    Hidden,
    // IconButton,
    InputAdornment,
    Menu,
    Drawer,
    useMediaQuery,
    Popover,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Tooltip,
} from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
// import { Search } from "@material-ui/icons";
import clsx from "clsx";
// import FilterListIcon from "@material-ui/icons/FilterList";
// import AppInput from "../../../components/AppInput";
import theme from "../../../theme";
import { useFilterControls } from "./hooks/useFilterControls";
import { QuickFilter } from "../../../components/quick-filter";
import { subscriberStaticFilterOptions } from "./static-filter-options";
import { UIButton, UIInput } from "../../../components/form";
import ChatleadIcon from "../../../components/chatlead-icon";

const FilterControls: React.FC = () => {
    const {
        visibleQuickFilter,
        setQuickFilterVisibility,
        visibleSuggestion,
        setSuggestionVisibility,
        selectedValues,
        onFilterSubmit,
        query,
        searchOptions,
        onSearchInput,
        handleSuggestionClick,
        handleOnBlurSearchField,
        handleEnterKeyInSearch,
    } = useFilterControls();
    const searchRef = React.useRef<HTMLInputElement>(null);

    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const filterComponent = (
        <QuickFilter
            isMobile={isMobile}
            filterData={subscriberStaticFilterOptions}
            onSubmitFilter={onFilterSubmit}
            selectedValues={selectedValues}
        />
    );

    const searchSuggestion = (
        <List subheader={<li />} className="manage-saas--search-suggestions">
            {Object.keys(searchOptions).map((groupName: string) => (
                <li key={groupName}>
                    <ul>
                        <ListSubheader className="group-name">
                            {searchOptions[groupName].label}
                        </ListSubheader>
                        {searchOptions[groupName].value.map((item: string, i: number) => (
                            <ListItem
                                button
                                key={`${groupName}-${i}`}
                                className="options"
                                onClick={() => handleSuggestionClick(searchOptions[groupName].path, item)}
                            >
                                <ListItemText primary={item} className="options-text" />
                            </ListItem>
                        ))}
                    </ul>
                </li>
            ))}
        </List>
    );

    const filterCounter = (
        <>
            {!!Object.keys(selectedValues || {}).length &&
                ` / ${Object.keys(selectedValues || {}).length}`}
        </>
    );

    return (
        <>
            <div
                className={clsx("filter-wrapper flex-row", { "flex-grow": isMobile })}
            >
                <UIInput
                    className={clsx("search-field", { "flex-grow": isMobile })}
                    value={query}
                    label="Smart Search"
                    style={{
                        width: "unset",
                    }}
                    inputProps={{
                        ref: searchRef,
                        placeholder: "Search everything....",
                        onInput: (e) => onSearchInput((e.target as HTMLInputElement).value),
                        onBlur: (e) => handleOnBlurSearchField((e.target as HTMLInputElement).value),
                        onKeyPress: (e) => {
                            if (e.key === "Enter") {
                                handleEnterKeyInSearch((e.target as HTMLInputElement).value);
                            }
                        },
                        InputProps: {
                            startAdornment: (
                                <InputAdornment position="start">
                                    {/* <Search /> */}
                                    <ChatleadIcon icon="search" size={25} color="inherit" />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <Tooltip title="Search by Subscriber Name, Dealership Name, Owner Name, Group Name, Performance Manager, City, Country.">
                                    <UIButton variant="icon" size='small'>
                                        <InfoIcon color="inherit" />
                                    </UIButton>

                                </Tooltip>

                            )
                        },
                    }}
                />
                <Popover
                    disableAutoFocus
                    disableEnforceFocus
                    elevation={5}
                    id="search-autofill"
                    anchorEl={searchRef.current}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    open={Boolean(visibleSuggestion)}
                    onClose={() => {
                        setSuggestionVisibility(false);
                    }}
                >
                    {searchSuggestion}
                </Popover>

                {isMobile ? (
                    <UIButton
                        variant="icon"
                        style={{ padding: "13px 2px" }}
                        color="primary"
                        className="filter"
                        onClick={(e) => setQuickFilterVisibility(e.currentTarget)}
                    >
                        {/* <FilterListIcon />  */}
                        <ChatleadIcon icon="filter" size={20} color="var(primary-color)" /> {filterCounter}
                    </UIButton>
                ) : (
                    <UIButton
                        variant={isMobile ? "text" : "outlined"}
                        size="medium"
                        color="primary"
                        className="filter"
                        // buttonProps={{ startIcon: <FilterListIcon /> }}
                        onClick={(e) => setQuickFilterVisibility(e.currentTarget)}
                    >
                        <ChatleadIcon icon="filter" size={20} color="var(primary-color)" />
                        Filter {filterCounter}
                    </UIButton>
                )}
            </div>

            <Hidden lgDown>
                <Menu
                    elevation={5}
                    id="filter-menu"
                    anchorEl={visibleQuickFilter}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    // keepMounted
                    open={Boolean(visibleQuickFilter)}
                    onClose={() => setQuickFilterVisibility(null)}
                >
                    {filterComponent}
                </Menu>
            </Hidden>

            <Hidden xlUp>
                <Drawer
                    anchor="bottom"
                    open={Boolean(visibleQuickFilter)}
                    onClose={() => setQuickFilterVisibility(null)}
                >
                    {filterComponent}
                </Drawer>
            </Hidden>
        </>
    );
};

export default FilterControls;
