import { CC24_ADMIN_SUB_ID } from "../constant/app-constants";
import { AccountUser } from "../model";
import { cc24AdminRoles, cc24UserRoles } from "../model/roles-and-rights";

export function isCarchat24Admin(user: AccountUser) {
    return isAnyCarchat24User(user) && cc24AdminRoles.includes(user.role);
}

export function isAnyCarchat24User(user: AccountUser) {
    // const isGroupAdmin = cc24AdminRoles?.includes("DealerGroupAdmin");
    const isGroupAdmin = user.role === "DealerGroupAdmin";
    const isOperatorUser = user.sub_id === CC24_ADMIN_SUB_ID && user.role === "CC24User";

    return (isGroupAdmin || isOperatorUser) ? (cc24UserRoles.indexOf(user.role) > -1) : (user.sub_id === CC24_ADMIN_SUB_ID && cc24UserRoles.indexOf(user.role) > -1);
}
