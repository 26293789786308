export function destructorCompositeKey(key: string, value: string | string[], separator: string = "--") {
    let keyValuePair = {};
    const paths = key.split("--");
    if (Array.isArray(value)) {
        const valueMatrix = value.map(item => item.split(separator));
        const transposedValue = matrixTranspose(valueMatrix);
        paths.forEach((path, i) => {
            keyValuePair = { ...keyValuePair, [path]: Array.from(new Set(transposedValue[i])) }; // remove duplicate values before insert
        });
    } else {
        const valueArr = value.split(separator);
        paths.forEach((path, i) => {
            keyValuePair = { ...keyValuePair, [path]: valueArr[i] };
        });
    }
    return keyValuePair;
}

// Capital case and _ within the word
export const separateWords = (word: string): string => {
    return (
        word
            ?.replace(/([A-Z])(?!([A-Z]|$))/g, " $1")
            ?.replace(/_/g, " ")
            ?.trim() ?? ""
    );
};

/** Might not work properly. Please check with all possible value before use */
export function castInRealType(value: string) {
    try {
        if (typeof value === "undefined" || value === null || typeof value !== "string") return value;

        // ~(-1) = 0 and ~(0) = -1
        if (~["true", "false"].indexOf(value)) return value === 'true';
        if (!Number.isNaN(Number(value))) return Number(value);
        return value;
    } catch (e) {
        // logger.log(e);
        return value;
    }
}

export function convertBitmask(flags: number | number[]) {
    if (!Array.isArray(flags)) return flags;
    let result = 0;
    // eslint-disable-next-line no-bitwise
    flags.forEach(item => { result |= item; });
    return result;
}

export function appliedInBitmask(flag: number, bitmaskValue: number): boolean {
    return (flag & bitmaskValue) === flag;
}

function matrixTranspose(obj: string[][]) {
    return obj && Object.keys(obj[0]).map((col: string) => obj.map((row: string[]) => row[parseInt(col)]));
}

export function castAs<T>(data: any): T {
    let temp: any;
    const k = +data;
    if (Number.isNaN(k)) {
        temp = data === "true" ? true : data === "false" ? false : undefined;
        if (temp === undefined) temp = data;
    } else {
        temp = (data === "") ? undefined : k;
    }
    return temp;
}

export function castAsIs<T>(data: any) {
    const type = typeof (data);
    let temp: any;
    if (data === null || data === undefined || data === "") return data;
    switch (type) {
        case "boolean":
        case "number":
        case "function":
        case "bigint":
        case "object":
        case "symbol":
            temp = data;
            break;
        case "string":
            // eslint-disable-next-line no-case-declarations
            const k = +data;
            if (Number.isNaN(k)) {
                const trimmedData = data.trim().toLowerCase();
                temp = trimmedData === "true" ? true : trimmedData === "false" ? false : undefined;
                if (temp === undefined) temp = data;
            } else {
                temp = k;
            }
            break;
        default:
            temp = data;
            break;
    }
    return temp;
}

export const getErrorString = (errorData: any): string => {
    if (typeof errorData === "string") return errorData;
    if (Array.isArray(errorData)) return getErrorString(errorData[0]);
    if (typeof errorData === "object") {
        if (errorData?.message) return getErrorString(errorData?.message);
        if (errorData?.Message) return getErrorString(errorData?.Message);
        if (errorData?.error) return getErrorString(errorData?.error);
        if (errorData?.errors) return getErrorString(errorData?.errors);
        if (errorData?.statusText) return getErrorString(errorData?.statusText);
    }
    return "Encountered an error";
};

export const getEnumKeys = (enumObj: any): string[] => {
    return Object.keys(enumObj).filter(r => Number.isNaN(parseInt(r)));
};
export const getEnumValues = (enumObj: any): string[] => {
    return Object.keys(enumObj).filter(r => !Number.isNaN(parseInt(r)));
};

export const isObjectEqual = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};
