import React, { useMemo, useState, useEffect, ChangeEvent } from "react";
import TablePaginationActions, { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import { UIInput } from "../../form";

const DefaultPaginationActions = ({
    page,
    onChangePage,
    count,
    rowsPerPage,
    ...rest
}: TablePaginationActionsProps) => {
    const maxPage = useMemo(
        () => Math.ceil(count / rowsPerPage) - 1,
        [count, rowsPerPage]
    );

    function useDebounce<T>(value: T, delay: number = 500) {
        const [debounced, setDebounced] = useState(value);

        useEffect(() => {
            const timer = setTimeout(() => setDebounced(value), delay);
            return () => clearTimeout(timer);
        }, [value, delay]);

        return debounced;
    }
    const [current, setPage] = useState(page);
    const delayedPage = useDebounce(current, 1000);
    const handlePageChange = (
        evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const insertedPage = Number(evt.target.value);
        if (insertedPage <= maxPage) {
            setPage(Number(evt.target.value));
        }
    };

    useEffect(() => {
        onChangePage(null, delayedPage);
    }, [delayedPage]);

    useEffect(() => {
        if (page !== delayedPage) {
            setPage(page);
        }
    }, [page]);

    const inlineStyles = {
        marginLeft: '1.25rem',
        width: '9rem',
        minWidth: '8rem',
        padding: 'min-w-min',
    };

    return (
        <>
            <UIInput
                label="Jump to Page"
                style={inlineStyles}
                value={current}
                // inputProps={{
                //     inputProps: { max: maxPage, min: 0, size: "small", },
                // }}
                inputProps={{ size: "small", autoComplete: "off", inputProps: { max: maxPage, min: 0, } }}
                onChange={handlePageChange}
                type="number"
                // size="small"
            />
            <TablePaginationActions
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={onChangePage}
                {...rest}
            />
        </>
    );
};

export default DefaultPaginationActions;
