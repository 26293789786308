import { CircularProgress } from "@material-ui/core";
import * as React from "react";
import { Link } from "react-router-dom";
import { UIButton } from "../../../components/form";
import { UserRoles } from "../../../model/types";
import { SubscriberRoutes } from "../../../router/route-constants";
import { isLegalChat } from "../../../utils/environment-utils";
import { getProgessIcon } from "../../../utils/image-util";
import { renderIfNotUserRoles } from "../../../utils/render-utils";
import { constructPath } from "../../../utils/route-utils";
import { useProfileCompleteness } from "../hooks/useProfileCompleteness";

const ProfileCompleteness: React.FC = () => {
    const { profileCompleteStatus, user } = useProfileCompleteness();
    const progress = profileCompleteStatus?.data ?? 0;
    const isLoading = (profileCompleteStatus.status === "Pending");
    const error: string = (profileCompleteStatus.status === "Failed" ? "Couldn't fetch desired data" : "");

    const progressBarIcon = getProgessIcon();

    let progressCar; let
        progressDash;
    if (progress < 20) {
        progressCar = 4;
        progressDash = 0;
    } else {
        progressCar = progress - 15;
        progressDash = progress - 16;
    }
    return (
        <div className="card b-m-4 flex-g-1">
            <h1 className="card-title b-m-2">{isLegalChat ? "Firm" : "Dealership"} profile completeness</h1>
            <div className="card-body flex-column flex-center profile-completeness">
                <div className="percentage">
                    {
                        isLoading && <div className="flex-row justify-center flex-center"> <CircularProgress color="secondary" size={25} /></div>
                    }
                    {
                        (!isLoading && progress !== undefined) &&
                        `${progress}%`
                    }
                    {
                        (!isLoading && progress === undefined && error) &&
                        <div className="value">{error}</div>
                    }
                </div>
                <div className="text-muted">Average Profile Completeness</div>
                <div className="progress-indicator-container">
                    <div className="progress-indicator" style={{ width: `${progress}%` }} />
                    <span className="dashed-line" style={{ width: `${progressDash}%` }} />
                    <img src={progressBarIcon} style={{ left: `${progressCar}%` }} alt="Progress Bar Icon" />
                </div>
                {/* <div className="scale"></div> */}
                <div className="help-text">
                    We need your input to update this profile
                </div>
                {
                    renderIfNotUserRoles(["CC24User", "DealerUser"], user?.role as UserRoles)(
                        <Link style={{ textDecoration: "none" }} to={location => constructPath(SubscriberRoutes.organization, location.pathname)}>
                            <UIButton rounded size="large">Manage Profile</UIButton>
                        </Link>
                    )
                }
            </div>
        </div>
    );
};

export default React.memo(ProfileCompleteness);
