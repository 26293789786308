import { useEffect, useState } from "react";
import * as yup from 'yup';
import uniLoader from "../../../../components/uni-loader";
import { ApiData } from "../../../../model/xhrCallState";
import httpService from "../../../../service/http-service";
import configuration from "../../../../utils/configuration";
import logger from "../../../../utils/log-utils";

interface CCAFData {
    setupFee: number;
    fixedFee: number;
    variableFee: number;
    currencyType: string;
    currencySymbol: string;
}

export default function useCCAFLink(subId: string) {
    const [ccafData, setCCafData] = useState<ApiData<CCAFData>>();

    const [url, setUrl] = useState('');
    useEffect(() => {
        if (ccafData?.status === "Pending") uniLoader.showLoader(''); else uniLoader.close();
    }, [ccafData]);

    const getCCAF = async () => {
        setCCafData((s) => ({ ...s, status: "Pending" }));
        try {
            const response = await httpService.get(`Subscriber/GetMinimumAuthorizedAmtForCurrentSubscription?subscriberID=${subId}&isCustomised=${false}`);

            setCCafData((s) => ({
                ...s,
                status: "Completed",
                data: {
                    setupFee: response.data.dicSubscriptionInfo.Setup,
                    fixedFee: response.data.dicSubscriptionInfo.Fixed,
                    variableFee: response.data.dicSubscriptionInfo.Variable,
                    currencySymbol: response.data.AdditionalInfo.BillingCurrencySymbol,
                    currencyType: response.data.AdditionalInfo.BillingCurrency
                }
            }));
        } catch (error) {
            logger.log(error);
            setCCafData((s) => ({ ...s, status: "Failed" }));
        }
    };

    const saveCCInfo = async (values: Partial<CCAFData>) => {
        // setCCafData((s) => ({ ...s, status: "Pending" }));
        try {
            setUrl(`${configuration.getValue("server")}/BusinessWeb/Web/MailActionRouter.aspx?Action=Add_Credit_Card&SubscriberID=${subId}&Mail=true&IsCustomized=true`);
            // const response = await httpService.post(`Subscriber/saveCCInfo`, body);
            // setCCafData((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            // setCCafData((s) => ({ ...s, status: "failed" }));
        }
    };

    const getInitialValues = () => {
        return {
            setupFee: ccafData?.data?.setupFee,
            fixedFee: ccafData?.data?.fixedFee,
            variableFee: ccafData?.data?.variableFee
        };
    };

    const validationSchema = yup.object().shape({
        setupFee: yup.number().min(0, "Minimum 'SetUp Fee' is 0").required("'SetUp Fee' is required"),
        fixedFee: yup.number().min(0, "Minimum 'Fixed Fee' is 0").required("'Fixed Fee' is required"),
        variableFee: yup.number().min(0, "Minimum 'Variable Fee' is 0").required("'Variable Fee' is required")
    });

    useEffect(() => {
        getCCAF();
    }, []);

    return { ccafData, saveCCInfo, getInitialValues, validationSchema, url };
}
