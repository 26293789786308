import { _SubscriberFeature } from "../../../model/enums/subscriber-feature";
import { _BoatType, _RvType, _VehicleType } from "../../../model/enums/vehicle-type";
import { FilterData, FilterOption } from "../../../model/filter-data";
import configuration from "../../../utils/configuration";
import { separateWords } from "../../../utils/object-utils";

const shouldShowVehicleMake = ["ServicesPro", "LegalChat"].includes(configuration.getValue("productName"));

const subscriptionOptions: FilterOption[] = [{
    displayValue: "Software Only Basic",
    internalValue: "1--10--0",
    selected: false
},
{
    displayValue: "Software Only Premium Pro",
    internalValue: "1--11--0",
    selected: false
},
{
    displayValue: "Software Only Enterprise",
    internalValue: "1--12--0",
    selected: false
},
{
    displayValue: "Full Support Performance",
    internalValue: "2--13--0",
    selected: false
},
{
    displayValue: "Full Support Flat Rate",
    internalValue: "2--14--0",
    selected: false
},
{
    displayValue: "Backup Performance",
    internalValue: "3--99--4",
    selected: false
},
{
    displayValue: "Backup Flat Rate",
    internalValue: "3--99--5",
    selected: false
}];

const featureBitmaskOptions: FilterOption[] = [{
    internalValue: _SubscriberFeature.LeadToRing,
    displayValue: "Lead To Ring",
    selected: false
},
{
    internalValue: _SubscriberFeature.FbMarketPlace,
    displayValue: "Facebook Market Place",
    selected: false
},
{
    internalValue: _SubscriberFeature.FbChat,
    displayValue: "Facebook Chat",
    selected: false
},
{
    internalValue: _SubscriberFeature.WebChat,
    displayValue: "Web Chat",
    selected: false
}, {
    internalValue: _SubscriberFeature.VideoChat,
    displayValue: "Video Chat",
    selected: false
},
{
    internalValue: _SubscriberFeature.SMSChat,
    displayValue: "SMS Chat",
    selected: false
}, {
    internalValue: _SubscriberFeature.DualBilling,
    displayValue: "Dual Billing",
    selected: false
},
{
    internalValue: _SubscriberFeature.WhatsApp,
    displayValue: "Whatsapp Chat",
    selected: false
},
{
    internalValue: _SubscriberFeature.Instagram,
    displayValue: "Instagram Chat",
    selected: false
},
{
    internalValue: _SubscriberFeature.GoogleMyBusiness,
    displayValue: "Google My Business Chat",
    selected: false
},
{
    internalValue: _SubscriberFeature.ReputationManagement,
    displayValue: "Reputation Management",
    selected: false
},
{
    internalValue: _SubscriberFeature.AppointmentService,
    displayValue: "Appointment Setting",
    selected: false
},
{
    internalValue: _SubscriberFeature.ServiceAppointment,
    displayValue: "Service Appointment",
    selected: false
}
];

const boatMakeOptions = Object.entries(_BoatType).filter(([key, val]) => typeof val === "number").map(([key, val]) => ({ displayValue: separateWords(key), internalValue: val, selected: false }));

const vehicleMakeOptions = Object.entries(_VehicleType).filter(([key, val]) => typeof val === "number").map(([key, val]) => ({ displayValue: separateWords(key), internalValue: val, selected: false }));

const rvMakeOptions = Object.entries(_RvType).filter(([key, val]) => typeof val === "number").map(([key, val]) => ({ displayValue: separateWords(key), internalValue: val, selected: false }));

export const bitmaskFields: string[] = ["FeatureBitmask"];
export const compositeFields: string[] = ["SubscriptionCategory--SubscriptionType--AdditionalSubscriptionType"];

// This values are used to process the existing filter value from query string.
// Always add the composite value in this object when add new subscriptionOptions
export const compositeFieldOptions: { [x: string]: any[][]; } = {
    "SubscriptionCategory--SubscriptionType--AdditionalSubscriptionType": [[1, 10, 0], [1, 11, 0], [1, 12, 0], [2, 13, 0], [2, 14, 0], [3, 99, 4], [3, 99, 5]]
};

const productName:string = configuration.getValue("productName");

export const subscriberStaticFilterOptions: FilterData[] = ([
    {
        name: "subscriberStatus",
        filterType: "select",
        groupLabel: "Status",
        path: "SubscriberStatus",
        selectionBehavior: "single",
        options: [
            { displayValue: "Any", internalValue: 99, selected: false },
            { displayValue: "Active", internalValue: 1, selected: false },
            { displayValue: "Disabled", internalValue: 0, selected: false }
        ]
    },
    {
        name: "subscriptions",
        filterType: "select",
        groupLabel: "Subscription",
        path: "SubscriptionCategory--SubscriptionType--AdditionalSubscriptionType",
        selectionBehavior: "multiple",
        options: subscriptionOptions
    },
    {
        name: "featureBitMask",
        filterType: "select",
        groupLabel: "Feature Bit Mask",
        path: "FeatureBitmask",
        isBitMask: true,
        selectionBehavior: "multiple",
        options: featureBitmaskOptions
    },
    {
        name: "hasBillingActivated",
        filterType: "select",
        groupLabel: "Billing Active",
        path: "HasBillingActivated",
        selectionBehavior: "single",
        options: [{ displayValue: "Yes", internalValue: true, selected: false },
        { displayValue: "No", internalValue: false, selected: false }]
    },
    {
        name: "hasCC",
        filterType: "select",
        groupLabel: "Has CC",
        path: "HasCC",
        selectionBehavior: "single",
        options: [{ displayValue: "Yes", internalValue: true, selected: false },
        { displayValue: "No", internalValue: false, selected: false }]
    },
    {
        name: "hasAgreement",
        filterType: "select",
        groupLabel: "Agreement",
        path: "HasAgreement",
        selectionBehavior: "single",
        options: [{ displayValue: "Yes", internalValue: true, selected: false },
        { displayValue: "No", internalValue: false, selected: false }]
    },
    {
        name: "hasGoneLive",
        filterType: "select",
        groupLabel: "Gone Live",
        path: "HasGoneLive",
        selectionBehavior: "single",
        options: [{ displayValue: "Yes", internalValue: true, selected: false },
        { displayValue: "No", internalValue: false, selected: false }]
    },
    {
        name: "hasWebIntegration",
        filterType: "select",
        groupLabel: "Web Integration",
        path: "HasWebIntegration",
        selectionBehavior: "multiple",
        options: [{ displayValue: "Yes", internalValue: true, selected: false },
        { displayValue: "No", internalValue: false, selected: false }]
    },
    {
        name: "paymentMode",
        filterType: "select",
        groupLabel: "Payment Mode",
        path: "PaymentMode",
        selectionBehavior: "single",
        options: [
            { displayValue: "Auto Paid By Other", internalValue: 4, selected: false },
            { displayValue: "Manual Paid By Other", internalValue: 5, selected: false }
        ]
    },
] as FilterData[]).filter(item => (shouldShowVehicleMake ? item.name !== "vehicleMake" : true));

switch (productName) {
    case "CarChat24":
        subscriberStaticFilterOptions.splice(2, 0, { name: "vehicleMake",
        filterType: "select",
        groupLabel: "Vehicle Make",
        path: "CarType",
        selectionBehavior: "multiple",
        options: vehicleMakeOptions });
        break;
    case "BoatChat":
        subscriberStaticFilterOptions.splice(2, 0, { name: "vehicleMake",
        filterType: "select",
        groupLabel: "Boat Make",
        path: "CarType",
        selectionBehavior: "multiple",
        options: boatMakeOptions });
        break;
    case "RvChat":
        subscriberStaticFilterOptions.splice(2, 0, { name: "vehicleMake",
        filterType: "select",
        groupLabel: "Rv Make",
        path: "CarType",
        selectionBehavior: "multiple",
        options: rvMakeOptions });
        break;
    case "ServicesPro":
        break;
    case "LegalChat":
        break;
    default:
        break;
}
