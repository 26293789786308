/* eslint-disable eqeqeq */
import React from "react";
import { createStyles, DialogActions, Fade, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { UIButton, UIDateTimePicker, UISelect } from "../../../components/form";
import FeatureSettingsTable from "./feature-settings-table";
import useFeatureSettings from "./hooks/useFeatureSettings";

const useStyles = makeStyles((theme: Theme) => createStyles({
    tbl: {
        // width: "calc(100vw - 650px)",
        height: "calc(73vh - 140px)",
        overflowY: "auto",
    },
}));

const FeatureSettings: React.FC<any> = (props) => {
    const {
        featureState, onRowEvent, checked, edRowData, saveFeature, allPMList, selectedSP,
        setSelectedSP, serviceDate, setServiceDate, startDate, setStartDate, endDate, setEndDate, handleBack
    } = useFeatureSettings(props.subscriberId);

    const classes = useStyles();

    const getFeatureName = (key: string) => {
        switch (key) {
            case 'SMSChat':
                return 'SMS Chat';
            case 'VideoChat':
                return 'Video Chat';
            case 'LeadToRing':
                return 'Lead To Ring';
            case 'FBChat':
                return 'Facebook Chat';
            case 'FacebookMarketPlace':
                return 'Facebook Marketplace';
            case 'WebChat':
                return 'Web Chat';
            case 'FBMarketplaceNew':
                return 'Facebook Marketplace New';
            case 'ServiceAppointment':
                return 'Service Appointment';
            case 'GoogleMyBusiness':
                return 'Google Business Chat';
            default:
                return '';
        }
    };
    const formSchema = {
        salesPerson_select: { name: "salesPerson", value: selectedSP, options: allPMList, label: "Select Sales Person", className: "t-m-3 r-m-3 r-m-phone-inv", required: true },
        pm_select: { name: "performanceManager", label: "Performance Manager", disabled: true },
    };

    if (checked) {
        return (
            <Fade in={checked} style={{ transitionDelay: checked ? '100ms' : '0ms' }}>
                <div>
                    {edRowData &&
                        <form className={clsx(classes.tbl, "flex-grow", "custom-scroll")} autoComplete="off" id="feature-form" onSubmit={(e) => saveFeature(e)}>
                            <small>Setup {getFeatureName(edRowData.FeatureName)}</small>
                            <div className="flex-responsive t-m-6 r-m-2 l-m-2">
                                <UISelect options={[{ label: edRowData.ManagedBy, value: edRowData.ManagedBy }]} value={edRowData.ManagedBy} {...formSchema.pm_select} />
                            </div>
                            <div className="flex-responsive r-m-2 l-m-2">
                                <UISelect {...formSchema.salesPerson_select} onChange={(e) => setSelectedSP(`${e.target.value}`)} />
                                <UIDateTimePicker
                                    type="date"
                                    className="t-m-1"
                                    value={serviceDate}
                                    views="d-m-y"
                                    label="Service Activation Date"
                                    format="DD-MM-YYYY"
                                    margin="none"
                                    onChange={(date) => { setServiceDate(new Date(date ?? Date.now()).toLocaleString()); }}
                                />

                            </div>
                            <div className="flex-responsive r-m-2 l-m-2">
                                <UIDateTimePicker
                                    type="date"
                                    className="t-m-1 r-m-3 r-m-phone-inv"
                                    value={startDate}
                                    views="d-m-y"
                                    label="Billing Activation Date"
                                    format="DD-MM-YYYY"
                                    margin="none"
                                    onChange={(date) => { setStartDate(new Date(date ?? Date.now()).toLocaleString()); }}
                                />
                                <UIDateTimePicker
                                    type="date"
                                    className="t-m-1"
                                    value={endDate}
                                    views="d-m-y"
                                    label="Cancellation Date"
                                    format="DD-MM-YYYY"
                                    margin="none"
                                    onChange={(date) => { setEndDate(new Date(date ?? Date.now()).toLocaleString()); }}
                                />
                            </div>
                            <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                                <UIButton type="submit" form="feature-form">
                                    Save
                                </UIButton>
                                <UIButton color="danger" onClick={() => handleBack()}>
                                    Cancel
                                </UIButton>
                            </DialogActions>
                        </form>}
                </div>
            </Fade>
        );
    }

    return (
        <Fade in={!checked}>
            <div>
                {/* {featureState.pmData.status === "Pending" ? (
                        <div className="loading flex-column flex-center">
                            <CircularProgress size={40} color="secondary" />
                        </div>
                    ) : ( */}
                <div className={clsx(classes.tbl, "custom-scroll")}>
                    {featureState.featureData?.data && <FeatureSettingsTable
                        onRowEvent={onRowEvent}
                        featureData={featureState.featureData.data.map(r => ({
                            id: r.ID,
                            name: getFeatureName(r.FeatureName),
                            serviceDate: (r.WentLiveDate) ? dayjs(r.WentLiveDate).format('DD/MMM/YYYY') : "",
                            billingDate: (r.BillingStartDate) ? dayjs(r.BillingStartDate).format('DD-MM-YYYY') : "",
                            cancellationDate: (r.CancellationDate) ? dayjs(r.CancellationDate).format('DD-MM-YYYY') : "", // r.to == null? '' : dayjs(r.to).format('DD MMM YYYY')
                            salesPerson: r.SalesPerson,
                            performanceManager: r.ManagedBy,
                            isSubscribed: (r.FeatureAttributes?.find(e => (e.Type == 1 && e.ItemValue.toLowerCase() == 'true')))
                        }))}
                    />}
                </div>
                {/* )} */}
                <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                    {/* <UIButton onClick={()=>setChecked(true)}>
                                Add
                            </UIButton> */}
                    <UIButton color="danger" onClick={props.handleClose}>
                        Close
                    </UIButton>
                </DialogActions>
            </div>
        </Fade>
    );
};

export default FeatureSettings;
