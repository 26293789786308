import { Drawer, makeStyles, Tooltip } from "@material-ui/core";
import * as React from "react";
import { Link } from "react-router-dom";
import PolicyIcon from '@material-ui/icons/Policy';
import FeedbackIcon from '@material-ui/icons/Feedback';
// import DescriptionIcon from '@material-ui/icons/Description';
import HelpIcon from '@material-ui/icons/Help';
import { useDispatch, useSelector } from "react-redux";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import clsx from "clsx";
import { ResponsiveDialog } from "../../../components/responsive-dialog";
import Scrollable from "../../../components/scrollable";
import { SubscriberSwitchDialogContent } from "../../../components/subscriber-switch-dialog-content";
import { RouteConfig } from "../../../model/route-config";
import { selectUser } from "../../../store/modules/authentication";
import BrandLogo from "./brand-logo";
import useNavbar from "./hooks/useNavbar";
import NavigationContent from "./navigation-content";
import QuickSubscriberSwitch from "./quick-subscriber-switch";
import defImageSrc from "../../../assets/images/user-avatar.png";
import { useAuthentication } from "../../../hooks/useAuthetication";
import { UserRoles } from "../../../model/types";
import { UIButton, UISwitch } from "../../../components/form";
// import ChatleadIcon from "../../../components/chatlead-icon";
import MenuListUI from "../../../components/menu-list/menu-list";
import { setFeedBackDialogOpen } from "../../../store/modules/ui";
import configuration from "../../../utils/configuration";
import ChatleadIcon from "../../../components/chatlead-icon";
import { AuthedRoutes } from "../../../router/route-constants";
import { renderIfNotUserRoles, renderIfUserRoles } from "../../../utils/render-utils";
import { cc24AdminRoles, cc24AdminRolesOnly } from "../../../model/roles-and-rights";
// import theme from "../../../theme";

export interface SideNavBarProps {
    onMobileClose: () => void;
    openMobileNav: boolean;
    routes: RouteConfig[];
    basePath: string;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256,
    },
    avatar: {
        cursor: "pointer",
        width: 64,
        height: 64,
    },
}));

const SideNavBar: React.FC<SideNavBarProps> = (props: SideNavBarProps) => {
    const { onMobileClose, openMobileNav, routes } = props;
    const { user } = useSelector(selectUser);
    const { handleLogout } = useAuthentication();
    const classes = useStyles();
    // const { feedBackDialog } = useSelector(selectFeedBackOpen);
    const dispatch = useDispatch();
    const onClickMenu = (options: string | number) => {
        if (options === 1) {
            dispatch({ type: setFeedBackDialogOpen.type, payload: true });
        }
        if (options === 2) {
            window.open(`${configuration.getValue("mainSite")}/eula-privacy-policy/`, "_blank");
        }
    };
    // const handleDialogClose = () => {
    //     dispatch({ type: setFeedBackDialogOpen.type, payload: false });
    // };

    const getRole = (role?: UserRoles) => {
        switch (role) {
            case "CC24Admin":
                return `${configuration.getValue("productAbbrName")} Admin`;
            case "CC24User":
                return `${configuration.getValue("productAbbrName")} User`;
            case "DealerAdmin":
                return "Admin";
            case "DealerUser":
                return "User";
            case "CC24PerformanceManager":
                return "Perf. Manager";
            case "CC24SalesManager":
                return "Sales Manager";
            case "DealerGroupAdmin":
                return "Group Admin";
            default:
                return "";
        }
    };

    const {
        subscriberSwitchDialogOpened,
        setOpenSubscriberSwitchDialog,
        isTab,
        btaBtnRef,
        isSideBarCollapsed,
        toggleCollapse,
        shouldBeCollapsed,
        testMode,
        toggleTestMode
    } = useNavbar(props);

    const Content = (
        <div className={clsx("navbar flex-column flex-grow", (shouldBeCollapsed) && "collapsed")}>
            <BrandLogo classes="brand" minimal={shouldBeCollapsed} />

            <QuickSubscriberSwitch
                openSubscriberSwitchDialog={() => setOpenSubscriberSwitchDialog(true)}
                btaButtonRef={btaBtnRef}
                minimal={shouldBeCollapsed}
            />

            {
                !isTab &&
                <Tooltip title={isSideBarCollapsed ? "Expand" : "Collapse"}>
                    <UIButton variant="contained" className="collapse-btn" size="small" color="secondary" onClick={toggleCollapse}>
                        <ChevronLeftRoundedIcon fontSize="small" className={clsx(isSideBarCollapsed && "rotate-180Deg")} />
                    </UIButton>
                </Tooltip>
            }

            <Scrollable classes="navbar-content">
                <NavigationContent routes={routes} basePath={props.basePath} />
            </Scrollable>
            <div className={clsx("bottom-area", (shouldBeCollapsed) && "minimal")}>
                {
                    renderIfUserRoles([] as UserRoles[], user?.role as UserRoles)(
                        <div className="flex-row justify-center b-m-4">
                            {
                                !shouldBeCollapsed ? <UISwitch label="Test Mode" size="medium" value={testMode} onChange={toggleTestMode} /> : <Tooltip title="Test Mode" placement="top" arrow>
                                    <span><UISwitch size="small" value={testMode} onChange={toggleTestMode} /></span>
                                </Tooltip>
                            }
                        </div>
                    )
                }
                <div className="profile">
                    <Link to={AuthedRoutes.profile}>
                        <UIButton variant="icon" size="small" className="profile__edit-button">
                            <ChatleadIcon icon="edit" size={shouldBeCollapsed ? 17 : 20} />
                        </UIButton>
                    </Link>
                    <img className="profile-image" src={user?.picture ?? defImageSrc} alt="Display Piture" />
                    <div className="profile-name text-bold">
                        <Tooltip title={user?.name ?? ""} arrow placement={shouldBeCollapsed ? "right" : "top"}>
                            <span>
                                {user?.name?.split(' ')[0]}
                            </span>
                        </Tooltip>
                    </div>
                    <div className="profile-dept">
                        <span>
                            {getRole(user?.role)}
                        </span>
                    </div>
                </div>
                <div className={clsx(shouldBeCollapsed ? "flex-column" : "flex-row", "space-between align-items-center")}>
                    <MenuListUI buttonSize="small" buttonColor="secondary" list={[{ label: "Feedback", value: 1, icon: <FeedbackIcon fontSize="small" className="r-m-2" /> }, { label: "View Policy", value: 2, icon: <PolicyIcon fontSize="small" className="r-m-2" /> }]} onClick={onClickMenu}>

                        <HelpIcon fontSize="small" />

                    </MenuListUI>

                    <UIButton
                        variant="text"
                        color="secondary"
                        style={{ margin: "0 auto", width: "fit-content", color: "var(--danger-red-font)" }}
                        buttonProps={{ startIcon: !shouldBeCollapsed && <ExitToAppIcon /> }}
                        onClick={handleLogout}
                    >Logout</UIButton>
                </div>
                {/* <FeedBackDialog dialogOpen={feedBackDialog} dialogClose={handleDialogClose} /> */}
            </div>
        </div>
    );
    return (
        <>
            {isTab ? <Drawer
                anchor="left"
                classes={{ paper: classes.mobileDrawer }}
                onClose={onMobileClose}
                open={openMobileNav}
                variant="temporary"
            >
                {Content}
            </Drawer> : Content}

            <ResponsiveDialog
                title="Select Subscriber"
                open={subscriberSwitchDialogOpened}
                handleClose={() => setOpenSubscriberSwitchDialog(false)}
            >
                <SubscriberSwitchDialogContent />
            </ResponsiveDialog>
        </>
    );
};

export default SideNavBar;
