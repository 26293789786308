import { useMediaQuery } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { cc24AdminRoles } from "../../../../model/roles-and-rights";
import { selectRole } from "../../../../store/modules/authentication";
import { selectCollapseSideBar, selectTestMode, setCollapseSidebarState, changeTestMode } from "../../../../store/modules/ui";
import theme from "../../../../theme";
import { SideNavBarProps } from "../side-navbar";

export default function useNavbar(props: SideNavBarProps) {
    const location = useLocation();

    const isTab = useMediaQuery(theme.breakpoints.down("md"));
    const role = useSelector(selectRole);
    const { isSideBarCollapsed } = useSelector(selectCollapseSideBar);
    const { testMode } = useSelector(selectTestMode);
    const dispatch = useDispatch();

    const { onMobileClose, openMobileNav } = props;
    const [subscriberSwitchDialogOpened, setOpenSubscriberSwitchDialog] = useState(false);
    const btaBtnRef = useRef<HTMLButtonElement | null>(null);
    const shouldBeCollapsed = !isTab && isSideBarCollapsed;

    useEffect(() => {
        if (openMobileNav && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname, subscriberSwitchDialogOpened]);

    useEffect(() => {
        setOpenSubscriberSwitchDialog(false);
    }, [location.pathname]);

    useEffect(() => {
        if (isTab) {
            dispatch(setCollapseSidebarState(false));
        }
    }, [isTab]);

    function triggerCommand(this: Window, evt: KeyboardEvent) {
        const { code, ctrlKey } = evt;
        if (code === "KeyK" && ctrlKey) {
            evt.preventDefault();
            setOpenSubscriberSwitchDialog(true);
        }
        if (code === "KeyE" && ctrlKey) {
            evt.preventDefault();
            btaBtnRef?.current?.click();
        }
    }

    useEffect(() => {
        if (cc24AdminRoles.includes(role)) {
            window.addEventListener("keydown", triggerCommand);
        }
        return () => {
            if (cc24AdminRoles.includes(role)) {
                window.removeEventListener("keydown", triggerCommand);
            }
        };
    }, []);

    const toggleCollapse = () => {
        dispatch(setCollapseSidebarState(!isSideBarCollapsed));
    };
    const toggleTestMode = () => {
        changeTestMode(!testMode)(dispatch);
    };

    return { subscriberSwitchDialogOpened, setOpenSubscriberSwitchDialog, isTab, btaBtnRef, isSideBarCollapsed, toggleCollapse, shouldBeCollapsed, testMode, toggleTestMode };
}
