/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { FilterOutput } from "../../../../model/filter-data";
import { FilterableProperties, keysOfSearchableProperties, SearchableProperties, SubscriberSearch_V2, keysOfFilterableProperties } from "../../../../model/subscriber-search-param";
import { convertBitmask, destructorCompositeKey } from "../../../../utils/object-utils";
import { objectToQuery, queryToObject } from "../../../../utils/uri-utils";
import { getWorkerInstance } from "../../../../workers";
import { bitmaskFields, compositeFieldOptions, compositeFields } from "../static-filter-options";
import { SubscriberSerchOptions } from "../../../../model/subscriber-search-options";
import configuration from "../../../../utils/configuration";

export const useFilterControls = () => {
    const [visibleQuickFilter, setQuickFilterVisibility] = useState<null | HTMLElement>(null); // To open or close the filter menu/bottomSheet
    const [visibleSuggestion, setSuggestionVisibility] = useState<boolean>(false); // To visible the search Suggestions
    const [selectedValues, setSelectedValues] = useState<FilterOutput>(); // for selected values from quick filter component output and url parameters [Stores composite keys with values]
    const [query, setQuery] = useState<string>(""); // Search query
    const [searchOptions, setSearchOptions] = useState<SubscriberSerchOptions>({}); // For search suggestions
    const [onlyFilterParams, setOnlyFilterParams] = useState<FilterableProperties>({}); // only filterable params
    const [onlySearchableParams, setOnlySearchableParams] = useState<SearchableProperties>({}); // only searchable params
    const [otherSubscribeSearchParams, setOtherSubscribeSearchParams] = useState<SubscriberSearch_V2>({}); // other properties which are not the concern of this hook

    const { location, ...history } = useHistory();
    const shouldUseCarType = ["ServicesPro", "LegalChat"].includes(configuration.getValue("productName"));

    useEffect(() => {
        // Close menus
        setQuickFilterVisibility(null);
        setSuggestionVisibility(false);

        const filterObject = queryToObject<SubscriberSearch_V2>(location.search);
        if (shouldUseCarType && "CarType" in filterObject) {
            delete filterObject.CarType;
        }
        const searchableProperties: SearchableProperties = {};
        const filterableProperties: FilterableProperties = {};
        const otherProperties: any = {};

        // Segregate objects
        Object.keys(filterObject).forEach(key => {
            if (~keysOfSearchableProperties.indexOf(key)) {
                searchableProperties[key as keyof SearchableProperties] = filterObject[key as keyof SearchableProperties];
            } else if (~keysOfFilterableProperties.indexOf(key)) {
                // @ts-ignore
                filterableProperties[key as keyof FilterableProperties] = filterObject[key as keyof FilterableProperties];
            } else {
                otherProperties[key] = filterObject[key as keyof SubscriberSearch_V2];
            }
        });
        // Populate corresponding states
        setOnlyFilterParams(() => filterableProperties);
        setOnlySearchableParams(() => searchableProperties);
        setOtherSubscribeSearchParams(() => otherProperties);
        setSelectedValues(() => processSelectedFilterOptions(filterableProperties));
        setQuery(() => searchableProperties.query || "");
    }, [location]);

    const onFilterSubmit = (data: FilterOutput) => {
        const filterData = convertToSubscriberSearchParam(data);
        setOnlyFilterParams(() => filterData);
        aggregateAndApplyFilter(filterData, "filterable");
    };

    /** Process the filter values to create the composite key entries and rest keep as it is */
    const processSelectedFilterOptions = useCallback((filterObj: FilterOutput) => {
        let processedKeys: string[] = [];
        const selectedValues: FilterOutput = {};
        Object.keys(filterObj).forEach(key => {
            if (~processedKeys.indexOf(key)) return;

            const compositeKey = getCorrespondingCompositeKey(key, compositeFields);
            if (compositeKey) {
                const atomicKeys = compositeKey.split("--");
                processedKeys = processedKeys.concat(atomicKeys);
                selectedValues[compositeKey] = parseSelectedCompositeKeyValues(compositeKey, filterObj, compositeFieldOptions[compositeKey]);
                return;
            }
            // Just add as it is
            selectedValues[key] = filterObj[key];
        });
        return selectedValues;
    }, [location]);

    const onSearchInput = (value: string) => {
        setQuery(() => value);
        value = value.trim(); // Don't change this and the exact upper line order
        if (value?.length > 2) {
            debounce(async () => {
                const data = await getWorkerInstance("subscriberSearchWorker")?.search(value) as SubscriberSerchOptions; // Subscriber Search web worker
                setSearchOptions(() => data || {});
                if (data && Object.keys(data).length) setSuggestionVisibility(true);
                else setSuggestionVisibility(false);
            }, 200)();
        } else setSuggestionVisibility(false);
    };

    const handleSuggestionClick = (path: keyof SubscriberSearch_V2, value: string) => {
        setQuery(() => value);
        value = value.trim();
        const searchableParam = { query: value, [path]: value };
        setOnlySearchableParams(() => searchableParam);
        aggregateAndApplyFilter(searchableParam, "searchable");
    };

    const handleOnBlurSearchField = (value: string) => {
        value = value?.trim();
        if (!value) {
            if (onlySearchableParams.query) { // If search was applied already
                resetSearch();
            }
        }
    };

    const handleEnterKeyInSearch = (value: string) => {
        value = value.trim();
        if (!value && onlySearchableParams.query) { resetSearch(); return; }
        const newSearchParam: SearchableProperties = { query: value, Keyword: value };
        setOnlySearchableParams(() => newSearchParam);
        aggregateAndApplyFilter(newSearchParam, 'searchable');
    };

    const resetSearch = () => {
        const blankSearchParam = {};
        setOnlySearchableParams(() => blankSearchParam);
        aggregateAndApplyFilter(blankSearchParam, 'searchable');
    };

    const applyFilterInUrl = (searchParamObj: SubscriberSearch_V2) => {
        history.push(`${location.pathname}?${objectToQuery(searchParamObj)}`);
    };

    const aggregateAndApplyFilter = (data: SubscriberSearch_V2, category: "searchable" | "filterable") => {
        let restData: SubscriberSearch_V2 = {};
        switch (category) {
            case "searchable":
                restData = { ...onlyFilterParams, ...otherSubscribeSearchParams };
                break;
            case "filterable":
                restData = { ...onlySearchableParams, ...otherSubscribeSearchParams };
                break;
            default:
                break;
        }
        applyFilterInUrl({ ...restData, ...data });
    };

    return {
        visibleQuickFilter,
        visibleSuggestion,
        selectedValues,
        query,
        searchOptions,
        setQuickFilterVisibility,
        setSuggestionVisibility,
        onFilterSubmit,
        onSearchInput,
        handleSuggestionClick,
        handleOnBlurSearchField,
        handleEnterKeyInSearch
    };
};

function convertToSubscriberSearchParam(data: FilterOutput): FilterableProperties {
    let params: any = data;

    Object.keys(params).forEach(key => {
        if (~compositeFields.indexOf(key)) {
            params = { ...params, ...destructorCompositeKey(key, data[key]) };
            delete params[key];
        } else if (~bitmaskFields.indexOf(key)) {
            params = { ...params, [key]: convertBitmask(data[key]) };
        }
    });

    return params as SubscriberSearch_V2;
}

function getCorrespondingCompositeKey(key: string, fields: string[]): string | null {
    let compositeKey: string | null = null;
    fields.forEach((item) => {
        if (~item.split("--").indexOf(key)) compositeKey = item;
    });
    return compositeKey;
}

function parseSelectedCompositeKeyValues(compositeKeys: string, filterObj: FilterOutput, allValues: any[]) {
    const atomicKeys = compositeKeys.split("--");
    let selectedValues: any[] = [...allValues];

    atomicKeys.forEach((key, i) => {
        const matchedValues: any[] = [];
        if (!selectedValues.length) return; // No need to proceed once fail

        const givenValues = filterObj[key];
        if (Array.isArray(givenValues)) {
            givenValues.forEach((gv: any) => {
                (i === 0 ? allValues : selectedValues).forEach(optionValue => {
                    if (optionValue[i] === gv) matchedValues.push(optionValue);
                });
            });
        } else if (typeof givenValues !== "undefined") {
            (i === 0 ? allValues : selectedValues).forEach(optionValue => {
                if (optionValue[i] === givenValues) matchedValues.push(optionValue);
            });
        }
        selectedValues = matchedValues;
    });
    return selectedValues.map((item: any[]) => {
        let value = '';
        item.forEach((v, i: number) => { value += `${v}${i !== item.length - 1 ? '--' : ''}`; });
        return value;
    });
}
