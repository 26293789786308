import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import FailedComponent from '../../components/failed-component';
import { PublicLayout } from '../../layouts/public-layout';
import { RouteAdditionalPropsV2 } from '../../model/route-additional-propsV2';
import configuration from '../../utils/configuration';
import { getFullBrandLogo } from '../../utils/image-util';
import { renderIf } from '../../utils/render-utils';
import { useChatTranscript } from './hooks/useChatTranscript';

export interface ChatTranscriptProps extends RouteAdditionalPropsV2 {

}

const ChatTranscript: React.FC<ChatTranscriptProps> = (props) => {
    const { apiData, isAuthed, MIN_DATE, checkNull, getLeadType, getPaymentType, getChatOrigin, getFormattedDateTime, getNextDay } = useChatTranscript();

    const { data, status, errors } = apiData;
    return (
        <PublicLayout {...props}>
            <div className="chat-transcript">
                <div className="page">
                    <div className="header flex-row flex-center space-between">
                        <img alt={configuration.getValue("productName")} src={getFullBrandLogo(configuration.getValue("productName"))} />
                        {/* <button onClick={window.print} className="pointer">PRINT</button> */}
                    </div>
                    {/* <div className="subhead">
                    Do not reply to this message. Emails sent to this email address are not monitored
                </div> */}
                    {
                        status === "Pending" && <div style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)", position: "absolute" }}>
                            <CircularProgress color="primary" size={40} />
                        </div>
                    }
                    {
                        status === "Failed" && <div style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)", position: "absolute" }}>
                            <FailedComponent type='error' size='medium' text={errors?.[0] ?? "No Data Found"} />
                        </div>
                    }
                    {
                        status === "Completed" &&
                        <div className="content">
                            {
                                renderIf(isAuthed)(
                                    <>
                                        <div className="content-headings">
                                            Summary
                                        </div>
                                        <div className="content-body">
                                            {
                                                data?.LeadSubmissionDateSubscriberTimezone !== MIN_DATE &&
                                                <div className="content-data">Date: <span className="value">{data?.LeadSubmissionDateSubscriberTimezone?.slice(0, 10) ?? ""}</span></div>
                                            }
                                            <div className="content-data">Department: <span className="value">{data?.leadInfo?.Department ?? ""}</span></div>
                                            <div className="content-data">Lead Type: <span className="value">{getLeadType(data?.leadInfo?.LeadType) ?? ""}</span></div>
                                            <div className="content-data">Name: <span className="value">{data?.SubscriberName ?? ""}</span></div>
                                            <div className="content-data">Visitor Name: <span className="value">{data?.leadInfo?.VisitorName ?? ""}</span></div>
                                            <div className="content-data">Email: <span className="value">{data?.leadInfo?.EmailId ?? ""}</span></div>
                                            <div className="content-data">Phone: <span className="value">{data?.leadInfo?.PhoneNumber ?? ""}</span></div>
                                            <div className="content-data">Phone 2: <span className="value">{data?.leadInfo?.Phone_2 ?? ""}</span></div>
                                            <div className="content-data">Appt. Time: <span className="value">{`${checkNull(data?.leadInfo?.AppointmentDate ?? "")} ${checkNull(data?.leadInfo?.AppointmentTime ?? "")}`}</span></div>
                                            <div className="content-data">Interested In: <span className="value">{`${checkNull(data?.leadInfo?.Interest ?? "")} ${checkNull(data?.leadInfo?.VhState ?? "")} ${checkNull(data?.leadInfo?.Make ?? "")} ${checkNull(data?.leadInfo?.Model ?? "")}`}</span></div>
                                            <div className="content-data">Trade-In: <span className="value">{`${checkNull(data?.leadInfo?.TradeIn_Year ?? "")} ${checkNull(data?.leadInfo?.TradeIn_Make ?? "")} ${checkNull(data?.leadInfo?.TradeIn_Model ?? "")}`}</span></div>
                                            <div className="content-data">Payment Type: <span className="value">{getPaymentType(data?.leadInfo?.PaymentMethod) ?? ""}</span></div>
                                            <div className="content-data">Subject: <span className="value">{data?.leadInfo?.Subject ?? ""}</span></div>
                                            <div className="content-data">Notes: <span className="value">{data?.leadInfo?.Notes ?? ""}</span></div>
                                            <div className="content-data">Chat Origination Page Url: <a href={data?.chatStartedPageUrl}>{data?.chatStartedPageUrl ?? ""}</a></div>
                                        </div>
                                    </>
                                )
                            }
                            <div className="content-headings">
                                Pre-Chat Survey
                            </div>
                            {(data?.preChatSurvey) ? (
                                <div className="content-body">
                                    <div className="content-data">Name: {data?.preChatSurvey?.surveyData?.fieldData?.Visitor_Name?.Values[0] ?? ""}</div>
                                    <div className="content-data">Visitor Question: {data?.preChatSurvey?.surveyData.fieldData?.Visitor_Questions?.Values[0] ?? ""}</div>
                                    <div className="content-data">Department: {data?.preChatSurvey?.surveyData?.fieldData?.Visitor_Department?.Values[0] ?? ""}</div>
                                </div>
                            ) : 'None'}
                            <div className="content-headings">
                                Chat Transcript
                            </div>
                            <div className="content-body">
                                {data?.Chatinfo?.map((chat, i) => (
                                    <div className="content-data" key={i}>
                                        [{chat?.SentTime.slice(-8) ?? ""}] {chat?.DisplayName ?? ""} {chat?.Origin !== 3 && <span>({getChatOrigin(chat.Origin)})</span>}: {(chat?.Origin === 1) ? <span className='value'>{chat?.Message}</span> : <span>{chat?.Message}</span>}
                                    </div>
                                ))}
                            </div>
                            {
                                data?.lead2RingData && <>
                                    {
                                        (data?.lead2RingData?.AgentCallSummary && data?.lead2RingData?.AgentCallSummary.length) && <>
                                            <div className="content-headings">
                                                Lead 2 Ring Call Details
                                            </div>
                                            <div className="content-body">
                                                {
                                                    data?.lead2RingData?.AgentCallSummary.map((callSum, i) => <div className="content-data" key={i}>
                                                        {getFormattedDateTime(callSum?.CallDateTime)} {data?.Timezone} <strong>{data?.leadInfo?.Department} #{callSum?.PhoneNum}</strong> - {callSum?.CallStatus}
                                                    </div>)
                                                }
                                            </div>
                                        </>
                                    }
                                    {
                                        (data?.lead2RingData?.VisitorCallSummary || (data?.lead2RingData?.AgentCallSummary && data?.lead2RingData?.AgentCallSummary.length)) && <>
                                            <div className="content-headings">
                                                Lead 2 Ring Conference Details
                                            </div>
                                            <div className="content-body">
                                                {
                                                    data?.lead2RingData?.AgentCallSummary.filter(r => r.CallStatus === "CONNECTED IN CONFERENCE").map((callSum, i) => <div className="content-data" key={i}>
                                                        {getFormattedDateTime(callSum?.CallDateTime)} {data?.Timezone} <strong>{data?.leadInfo?.Department} #{callSum?.PhoneNum}</strong> - {callSum?.CallStatus}
                                                    </div>)
                                                }
                                                <div className="content-data">
                                                    {getFormattedDateTime(data?.lead2RingData?.VisitorCallSummary?.CallDateTime)} {data?.Timezone} <strong>Visitor({data?.leadInfo?.VisitorName}) #{data?.lead2RingData?.VisitorCallSummary?.PhoneNum}</strong> - {data?.lead2RingData?.VisitorCallSummary?.CallStatus}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        data?.lead2RingData?.RecordingUrl && <>
                                            <div className="content-headings">
                                                Lead 2 Ring Download URL
                                            </div>
                                            <div className="content-body">
                                                Voice Call Recording (Available for download till {getNextDay(data?.lead2RingData?.VisitorCallSummary?.CallDateTime)}) <br />
                                                <a href={data?.lead2RingData?.RecordingUrl} target="_blank" rel="noreferrer">Click here for the recording URL</a>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            <div className="content-headings">
                                Post-Chat Survey
                            </div>
                            <div className="content-body">
                                {(data?.postChatSurvey) ? `Visitor Rating: ${data?.postChatSurvey?.surveyData?.fieldData?.Visitor_Rating?.Values[0]}` : 'None'}
                            </div>
                            {
                                renderIf(isAuthed)(
                                    <>
                                        <div className="content-headings">
                                            Visited pages
                                        </div>
                                        <div className="content-body">
                                            {data?.PageName?.map((page, i) => (
                                                <div className="content-data" key={i}>
                                                    <a href={page}>{page ?? ""}</a>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="content-headings">
                                            Visitor's Traffic Details
                                        </div>
                                        <div className="content-body">
                                            <div className="content-data">IP: {data?.geoIpInfo?.IP_Address ?? ""}</div>
                                            <div className="content-data">Browser: {data?.SysInfo?.BrowserName ?? `${' '}${data?.SysInfo.BrowserMajorVersion}` ?? ""}</div>
                                            <div className="content-data">Operating System: {data?.SysInfo?.Platform ?? ""}</div>
                                            <div className="content-data">City: {data?.geoIpInfo?.City ?? ""}</div>
                                            <div className="content-data">State: {data?.geoIpInfo?.State ?? ""}</div>
                                            <div className="content-data">Country: {data?.geoIpInfo?.Country ?? ""}</div>
                                            <div className="content-data">ISP: {data?.geoIpInfo?.ISP ?? ""}</div>
                                            <div className="content-data">Organization: {data?.geoIpInfo?.Organization ?? ""}</div>
                                        </div>
                                        {data?.isLeadReturnActive &&
                                            <div>
                                                <div className="content-headings">
                                                    Lead Return
                                                </div>
                                                <div className="content-body">
                                                    <div className="content-data">Product Name: {data?.ProductName ?? ""}</div>
                                                    <div className="content-data">Lead Return Url: <a href={data?.ProductName}>{data?.ProductName ?? ""}</a></div>
                                                </div>
                                            </div>}
                                    </>
                                )
                            }
                        </div>
                    }
                </div>
            </div>
        </PublicLayout>
    );
};

export default ChatTranscript;
