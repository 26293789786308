import * as React from "react";
import workInProgress from "../../assets/images/work-in-progress.gif";
import { PageWithHeaderFooter } from "../../layouts/page-with-header-footer";
import { RouteAdditionalPropsV2 } from "../../model/route-additional-propsV2";

export interface WorkInProgressProps extends RouteAdditionalPropsV2 {}

const WorkInProgress: React.FC<WorkInProgressProps> = (props) => {
    return (
        <PageWithHeaderFooter {...props}>
            <div className="in-progress-page flex-column">
                <div className="in-progress-page__animation-holder">
                    <img
                        src={workInProgress}
                        alt="Work in Progress"
                        className="animation"
                    />
                    <div className="label">Work In Progress</div>
                </div>
            </div>
        </PageWithHeaderFooter>
    );
};

export default WorkInProgress;
