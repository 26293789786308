/* eslint-disable camelcase */
// import { useMediaQuery } from "@material-ui/core";
import { Step } from "intro.js-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { cc24AdminRoles } from "../../../model/roles-and-rights";
import { SubscriberInfoBundleV2 } from "../../../model/subscriber-info-bundle";
import { SubscriberSearch_V2 } from "../../../model/subscriber-search-param";
import { XHRCallState } from "../../../model/xhrCallState";
import httpService from "../../../service/http-service";
import { selectRole, selectUser } from "../../../store/modules/authentication";
import appConfig from "../../../utils/app-config-utils";
import configuration from "../../../utils/configuration";
// import theme from "../../../theme";
import { getCookie, setCookie } from "../../../utils/cookie-utils";
import logger from "../../../utils/log-utils";
import { objectToQuery, queryToObject } from "../../../utils/uri-utils";

export function useManageSaas() {
    const pageSize = 20;

    const [pagedData, setPagedData] = useState<SubscriberInfoBundleV2[]>([]);
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
    const [totalRowsCountOnServer, setTotalRowsCountOnServer] = useState<number>(0); // Total number of data found using this filter
    const [firstTimeLoadDone, setFirstTimeLoadDone] = useState<boolean>(false);
    const [subscriberFetchInProgress, setSubscriberFetchInProgress] = useState<XHRCallState>({ status: 'Completed' });
    const [formData, setFormData] = useState<SubscriberInfoBundleV2 | undefined>();
    const [enableSteps, setEnableSteps] = useState(false);
    const [open, setOpen] = useState(false);
    const { user } = useSelector(selectUser);

    const { location, ...history } = useHistory();
    const role = useSelector(selectRole);
    const shouldUseCarType = ["ServicesPro", "LegalChat"].includes(configuration.getValue("productName"));

    const isGroupAdmin = role === "DealerGroupAdmin";
    const defaultQueryString = isGroupAdmin ? "?SubscriberStatus=99" : "?SubscriberStatus=1";
    const groupFilter = "?IsGroup=true";

    useEffect(() => {
        if (location.search) {
            setCookie(appConfig.cookieKeys.MANAGE_SAAS_LLP, location.search, 10);
        }
        if (!location.search) {
            const lastLoadedSearch = getCookie(appConfig.cookieKeys.MANAGE_SAAS_LLP);
            history.push(lastLoadedSearch ? `${location.pathname}${lastLoadedSearch}` : `${location.pathname}${defaultQueryString}`);
            return;
        }
        (async () => {
            const filterObject = queryToObject<SubscriberSearch_V2>(location.search);
            if (shouldUseCarType && "CarType" in filterObject) {
                delete filterObject.CarType;
            }
            const search = `?${objectToQuery(filterObject)}`;
            const queryParams = search || defaultQueryString;
            const data = await fetchSubscribers(queryParams + getExtraFixedParams() + getPagingParamsForNextPage(0) + getGroupAdminAsParam()); // reset the page number to zero

            setPagedData(() => rankSubscribers(data || [])); // Replace with new data;

            setTotalRowsCountOnServer(() => (data?.length ? data[0].NoOfRows : 0)); // Set total row count for applied filter

            if (!firstTimeLoadDone) setFirstTimeLoadDone(() => true);
        })();
    }, [location]);

    useEffect(() => {
        if (role) {
            cc24AdminRoles.includes(user?.role);
            const isWalkthroughShown = getCookie(appConfig.cookieKeys.SHOW_WALKTHROUGH);

            if (!isWalkthroughShown) {
                setEnableSteps(true);
            }
        }
    }, [role]);

    const handleStepClose = () => {
        setEnableSteps(false);
        setCookie(appConfig.cookieKeys.SHOW_WALKTHROUGH, "shown", 365);
    };

    const fetchSubscribers = async (query: string) => {
        try {
            setSubscriberFetchInProgress(() => ({ status: 'Pending' }));
            const response = await httpService.get<SubscriberInfoBundleV2[]>(`subscriber/GetSubscriberList${query}`);
            setSubscriberFetchInProgress(() => ({ status: 'Completed' }));
            // logger.log(JSON.stringify(response.data));
            return response.data;
        } catch (e) {
            // logger.log(JSON.stringify(e));
            setSubscriberFetchInProgress(() => ({ status: 'Failed' }));
            return [];
        }
    };

    const fetchNextPage = async () => {
        if (shouldFetchNextPage()) {
            const queryParams = location.search || defaultQueryString;
            const data = await fetchSubscribers(queryParams + getExtraFixedParams() + getPagingParamsForNextPage() + getGroupAdminAsParam());
            if (data?.length) {
                // Filter if any data already came in previous pages
                const alreadyPagedData = pagedData.map(item => item.SubscriberId);
                const removeDuplicates = data.filter(item => !~alreadyPagedData.indexOf(item.SubscriberId));
                setPagedData((state) => rankSubscribers([...state, ...removeDuplicates]));
            }
        }
    };

    const rankSubscribers = (subs: SubscriberInfoBundleV2[]) => {
        if (isGroupAdmin) {
            const groupHeadIndex = subs.findIndex(r => r.SubscriberId === user?.sub_id);
            if (groupHeadIndex > -1) {
                const newSubs = [subs[groupHeadIndex], ...subs.slice(0, groupHeadIndex), ...subs.slice(groupHeadIndex + 1)];
                return newSubs;
            }
        }
        return subs;
    };

    const trackScrolling = (el: HTMLElement) => {
        if (isBottom(el)) {
            fetchNextPage();
        }
    };

    const shouldFetchNextPage = () => {
        return pagedData.length < totalRowsCountOnServer;
    };

    const getPagingParamsForNextPage = (pageNumber?: number) => {
        const nextPageNumber = (typeof pageNumber !== 'undefined' ? pageNumber : currentPageNumber) + 1;
        setCurrentPageNumber(() => nextPageNumber);
        return `&NumberOfRows=${pageSize}&PageNo=${nextPageNumber}`;
    };
    const getExtraFixedParams = () => {
        let param = "";
        const perf = user?.role === "CC24PerformanceManager" ? user.email : "";
        if (perf) param += `&PerformanceManagerName=${perf}`;
        return param || "";
    };

    const getGroupAdminAsParam = () => {
        let param = "";
        const grpAdm = user?.role === "DealerGroupAdmin" ? user.sub_name : "";
        if (grpAdm) param += `&GroupName=${grpAdm}`;
        return param || "";
    };

    const gotoSubscriberControlPanel = (subscribeId: string) => {
        history.push(`/dashboard/subscriber/${subscribeId}`);
    };
    const handleClose = () => {
        setOpen(false);
        setFormData(undefined);
    };
    const opensettings = (subscribeId: string) => {
        // logger.log("hit");
        const ed = pagedData.find(r => r.SubscriberId === subscribeId);
        setFormData(ed);
        setOpen(true);
    };

    const walkthroughSteps: Step[] = user?.role === "DealerGroupAdmin" ? [
        {
            element: ".subscriber-switch",
            intro: "Switch to any subscriber directly from any page"
        },
        {
            element: ".collapse-btn",
            intro: "Expand or Collapse your sidebar clicking this button"
        }
    ] : [
        {
            element: ".search-field",
            intro: "Search any subscriber, group or reseller from this input"
        },
        {
            element: ".filter",
            intro: "Filter your subscribers by various parameter"
        },
        {
            element: ".subscriber-switch",
            intro: "Switch to any subscriber directly from any page"
        },
        {
            element: ".collapse-btn",
            intro: "Expand or Collapse your sidebar clicking this button"
        },
    ];

    const walkthroughStepsForMobile: Step[] = [
        {
            element: ".search-field",
            intro: "Search any subscriber, group or reseller from this input"
        },
        {
            element: ".filter",
            intro: "Filter your subscribers by various parameter"
        },

    ];

    return {
        pagedData,
        firstTimeLoadDone,
        subscriberFetchInProgress,
        trackScrolling,
        gotoSubscriberControlPanel,
        formData,
        opensettings,
        handleClose,
        walkthroughSteps,
        open,
        enableSteps,
        handleStepClose,
        walkthroughStepsForMobile,
        user
    };
}

function isBottom(el: HTMLElement) {
    // return el.getBoundingClientRect().bottom <= el.scrollHeight;
    const { scrollTop, scrollHeight, offsetHeight } = el;

    if (scrollTop >= (scrollHeight - offsetHeight)) {
        // TO SOMETHING HERE
        return true;
    }
    return false;
}
