import { CancelToken } from "axios";
import React from "react";
import hash from 'object-hash';
import { FormikErrors } from "formik";
import { MinimalSubscriberData } from "../../model/minimal-subscriber-data";
import httpService from "../../service/http-service";
import logger from "../../utils/log-utils";
import { UISelectComplete } from "../form";
import { MinimalSubscriberDataContextInfo, useSubsciberDataContext } from "./context/SubscriberDataContext";
import { _SubscriberFunctionalStatus } from "../../model/enums/subscriber-functional-status";
import { SalesPortalSubscriberData } from "../../model/sales-portal-subscriber-data";

interface SelectCompleteData {
    name: string;
    value: string | undefined;
    type?: "created" | "option";
}

interface SubscriberSelectProps {
    onChange: ((name: string, evt: SelectCompleteData | undefined) => void) | undefined;
    value?: string;
    errorMessage?: string;
    label?: string;
    name?: string;
    required?: boolean;
    className?: string;
    isGroupHead?: boolean;
    isReseller?: boolean;
    onlyActive?: boolean;
    searchForSalesPortal?: boolean;
    disabled?: boolean;
}

const SubscriberSelect: React.FC<SubscriberSelectProps> = ({ isGroupHead, isReseller, onlyActive = true, searchForSalesPortal = false, ...props }) => {
    const subContext = useSubsciberDataContext();
    const getAutoCompleteData = async (subId?: string, searchName?: string, cancelToken?: CancelToken) => {
        try {
            let data: MinimalSubscriberDataContextInfo[] = [];
            if (searchForSalesPortal && !(isGroupHead || isReseller)) {
                const objectHash = hash("sales-portal");
                if (subContext?.data[objectHash]) {
                    data = subContext?.data[objectHash];
                } else {
                    const response = await httpService.get<(SalesPortalSubscriberData[])>('SalesPortal/GetAllSubscribersWithSignUpPortal', {
                        cancelToken
                    });
                    data = (Array.isArray(response.data)) ? response.data.filter(r => {
                        let flag = true;
                        if (subContext?.user?.role === "CC24PerformanceManager") flag = flag && (r.AccountManagerName === subContext?.user?.email);
                        if (onlyActive) flag = flag && (r.SubscriberStatus === _SubscriberFunctionalStatus.Active);
                        // console.log(r.SubscriberName, r.AccountManagerName, flag, onlyActive, subContext?.user?.role, subContext?.user?.role === "CC24PerformanceManager");
                        return flag;
                    }).map((item) => ({ SubscriberName: item.SubscriberName, SubscriberId: item.SubscriberID })) : [];
                    subContext?.setData(state => ({ ...state, [objectHash]: data }));
                }
            } else {
                const params: any = {
                    SubscriberId: subId,
                    Keyword: searchName,
                    // query: searchName,
                    IsReseller: isReseller,
                    IsGroup: isGroupHead,
                    SubscriberStatus: onlyActive ? [_SubscriberFunctionalStatus.Active] : [_SubscriberFunctionalStatus.Any],
                    NumberOfRows: 10,
                    PageNo: 1
                };
                if (subContext?.user?.role === "CC24PerformanceManager") {
                    params.PerformanceManagerName = subContext?.user?.email;
                }
                if (subContext?.user?.role === "DealerGroupAdmin") {
                    params.GroupName = subContext?.user?.sub_name;
                }

                const objectHash = hash(params);
                if (subContext?.data[objectHash]) {
                    data = subContext?.data[objectHash];
                } else {
                    const response = await httpService.get<(MinimalSubscriberData[])>('Subscriber/GetSubscriberList', {
                        params,
                        cancelToken
                    });
                    data = (Array.isArray(response.data)) ? response.data.map((item) => ({ SubscriberName: item.SubscriberName, SubscriberId: item.SubscriberId })) : [];
                    subContext?.setData(state => ({ ...state, [objectHash]: data }));
                }
            }
            const mutatedData = (Array.isArray(data)) ? data.map((item) => ({ name: item.SubscriberName, value: item.SubscriberId })) : [];
            return mutatedData;
        } catch (error) {
            logger.error(error);
        }
        return [];
    };

    return <UISelectComplete
        getData={getAutoCompleteData}
        {...props}
    />;
};

export default SubscriberSelect;