import * as React from "react";
import FlagIcon from "@material-ui/icons/Flag";
import { Tabs, Tab, Tooltip, Box, useMediaQuery, Dialog, DialogTitle, makeStyles } from "@material-ui/core";
import dayjs from "dayjs";
import { Steps } from "intro.js-react";
import { PageWithHeaderFooter } from "../../layouts/page-with-header-footer";
import { RouteAdditionalPropsV2 } from "../../model/route-additional-propsV2";
import { TableColumn } from "../../model/table-column";
import { SubscriberInfoBundleV2 } from "../../model/subscriber-info-bundle";
import { AppTable } from "../../components/table";
import { _SubscriberFunctionalStatus } from "../../model/enums/subscriber-functional-status";
import FilterControls from "./components/filter-controls";
import { useManageSaas } from "./hooks/useManageSaas";
import { Loading } from "../../components/loading";
import { _CardType } from "../../model/enums/card-type";
import { _SubscriberFeature } from "../../model/enums/subscriber-feature";
import { appliedInBitmask } from "../../utils/object-utils";
import { getImage } from "../../utils/image-util";
import GroupSettings from "./components/group-settings";
import ResellerSettings from "./components/reseller-settings";
import RebrandingSettings from "./components/rebranding-settings";
import PerformanceManagerSettings from "./components/performance-manager-settings";
import BillingSettings from "./components/billing-settings";
import GeneralSettings from "./components/general-settings";
import FeatureSettings from "./components/feature-settings";
import NotificationSettings from "./components/notification-settings";
import CCAFLink from "./components/ccaf-link";
import theme from "../../theme";
import { UIButton } from "../../components/form";
import ChatleadIcon, { IconSet } from "../../components/chatlead-icon";
import "intro.js/introjs.css";
import "intro.js/themes/introjs-flattener.css";
import AgreementSettings from "./components/agreement-settings";
import NotesTable from "./components/notes-table";
import NotesTab from "./components/notes-tabs";
import { renderIfNotUserRoles } from "../../utils/render-utils";
import { UserRoles } from "../../model/types";
import { AccountUser } from "../../model";

export interface SubscriberProps extends RouteAdditionalPropsV2 { }

const ManageSaas: React.FunctionComponent<SubscriberProps> = (props) => {
    const {
        pagedData,
        firstTimeLoadDone,
        subscriberFetchInProgress,
        trackScrolling,
        gotoSubscriberControlPanel,
        walkthroughSteps,
        opensettings, handleClose, open, formData,
        enableSteps, handleStepClose, walkthroughStepsForMobile, user
    } = useManageSaas();

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (_event: any, newValue: any) => {
        setValue(newValue);
    };

    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const headerContent = (
        <div className="simple-flex-responsive space-between manage-saas_header--wrapper">
            <div className="justify-center flex-column">{props.routeInfo.title}</div>
            {
                renderIfNotUserRoles(["DealerGroupAdmin"], user?.role as UserRoles)(

                    <div className="filter-controls flex-row flex-grow">
                        <FilterControls />
                    </div>
                )
            }
        </div>
    );

    const tableColumns: TableColumn<SubscriberInfoBundleV2>[] = [
        {
            name: "SubscriberName",
            label: "Subscriber Name",
            key: "SubscriberInfo",
            content: (data) => getSubscriberInfo(data),
        },
        {
            name: "subscriptionName",
            label: "Billing and Subscription",
            key: "SubscriptionTemplateName",
            content: (data) => getSubscriptionInfo(data),
        },
        {
            name: "group",
            label: user?.role === "DealerGroupAdmin" ? ("Group/Performance Manager") : ("Group/Reseller/Performance Manager"),
            key: "group",
            content: (data) => getGroupResellerInfo(data, user),
        },
        {
            name: "timeline",
            label: "Timeline",
            key: "timeline",
            content: (data) => subscriptionTimeline(
                data.SignupDateTime,
                data.BillingActivationDateTime,
                data.DisableDateTime
            ),
        },
        {
            name: "actions",
            label: "Actions",
            key: "actions",
            content: ({ SubscriberId }) => (
                <div style={{ minWidth: 150 }}>
                    <Tooltip title="Go to Dashboard" arrow>
                        <UIButton
                            variant="icon"
                            type="button"
                            onClick={() => gotoSubscriberControlPanel(SubscriberId)}
                        >
                            <img className="go-to-dash" src={getImage("actionDashboard")} alt="dashboard" />
                        </UIButton>
                    </Tooltip>
                    <Tooltip title="Open Settings" arrow>
                        <UIButton
                            variant="icon"
                            type="button"
                            onClick={() => opensettings(SubscriberId)}
                        >
                            {/* <img className="go-to-settings" src={getImage("subscriberSettings")} alt="settings" /> */}
                            <ChatleadIcon icon="settings" color="var(--solids-dark-blue)" size={30} />
                        </UIButton>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <PageWithHeaderFooter
            {...props}
            bodyStyle={{ paddingRight: 3, flexGrow: 1, flexDirection: "column" }}
            headerContent={headerContent}
        >
            <Steps
                enabled={enableSteps}
                steps={isMobile ? walkthroughStepsForMobile : walkthroughSteps}
                initialStep={0}
                onExit={handleStepClose}
                options={{ nextToDone: true, hidePrev: true, skipLabel: "Skip", highlightClass: "walkthrough__header", tooltipClass: "walkthrough__tooltip" }}
            />
            {!firstTimeLoadDone && subscriberFetchInProgress.status === "Pending" ? (
                <Loading />
            ) : (
                <div className="manage-saas--wrapper">
                    <div
                        className="subscriber-table"
                        onScroll={(e) => trackScrolling(e.target as HTMLElement)}
                    >
                        <AppTable
                            useCard
                            data={pagedData}
                            columns={tableColumns}
                            idPath="SubscriberId"
                        />
                    </div>
                </div>
            )}

            <Dialog
                fullScreen={!!isMobile}
                open={open}
                onClose={handleClose}
                className={classes.dialog}
                // scroll="paper"
                PaperProps={{
                    style: {
                        flexDirection: isMobile ? 'column' : 'row',
                        minHeight: isMobile ? 'none' : '73vh',
                        // maxHeight: isMobile ? '90vh' : '73vh',
                        // marginTop: isMobile ? '9vh' : '0'

                    },
                }}
                maxWidth="md"
                fullWidth
            >

                <div className={classes.root} style={isMobile ? { maxWidth: '100%' } : { maxWidth: '20%' }}>
                    <DialogTitle id="scroll-dialog-title" className={classes.title}>

                        <div className="flex-row">
                            <span className="l-m-3 flex-g-1">
                                <img style={{ width: '20px', paddingRight: '5px', position: 'relative', top: '3px' }} src={getImage("subscriberSettings")} alt="settings" />
                                Settings
                            </span>
                            <div className="flex-row flex-center">
                                {isMobile
                                    && (<UIButton variant="icon" className="r-m-2" size="small" onClick={handleClose}>
                                        {/* <img src={getImage("crossIcon")} alt="close" style={{ width: 20, padding: 5 }} /> */}
                                        <ChatleadIcon icon="crossThin" size={20} color="var(--solids-grey-200)" />
                                    </UIButton>)}
                            </div>
                        </div>

                    </DialogTitle>

                    <Tabs
                        orientation={isMobile ? "horizontal" : "vertical"}
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs"
                        className={classes.tabs}
                    >
                        <Tab className={classes.tabItem} label="Group Settings" {...tabProps(0)} />,
                        <Tab className={classes.tabItem} label="Reseller Settings" {...tabProps(1)} />,
                        <Tab className={classes.tabItem} label="Rebranding Settings" {...tabProps(2)} />,
                        <Tab className={classes.tabItem} label="Billing" {...tabProps(3)} />,
                        <Tab className={classes.tabItem} label="Performance Manager Settings" {...tabProps(4)} />,
                        <Tab className={classes.tabItem} label="Admin Settings" {...tabProps(5)} />,
                        <Tab className={classes.tabItem} label="Feature Settings" {...tabProps(6)} />,
                        <Tab className={classes.tabItem} label="Notification Settings" {...tabProps(7)} />,
                        <Tab className={classes.tabItem} label="CCAF Link" {...tabProps(8)} />,
                        <Tab className={classes.tabItem} label="Agreement" {...tabProps(9)} />,
                        {/* <Tab className={classes.tabItem} label="Change Subscriber Status" {...tabProps(9)} />, */}
                        <Tab className={classes.tabItem} label="Notes" {...tabProps(10)} />
                    </Tabs>
                </div>
                <div style={{ flexGrow: 1, position: 'relative', maxWidth: isMobile ? '100%' : '80%' }}>
                    <div className="flex-row">
                        <h2 className="t-m-4 l-m-5 flex-g-1">
                            {formData?.SubscriberName || " "}
                        </h2>
                        <div className="flex-row flex-center">
                            {
                                !isMobile
                                && (<UIButton variant="icon" className="r-m-2" size="small" onClick={handleClose}>
                                    {/* <img src={getImage("crossIcon")} alt="close" style={{ width: 20, padding: 5 }} /> */}
                                    <ChatleadIcon icon="crossThin" size={20} color="var(--solids-grey-200)" />
                                </UIButton>)
                            }
                        </div>
                    </div>
                    <TabPanel className={classes.panel} value={value} index={0}>
                        <GroupSettings
                            handleClose={handleClose}
                            subscriberId={formData?.SubscriberId}
                            isGroupHead={formData?.IsGroupHead}
                            groupName={formData?.GroupName}
                            groupId={formData?.GroupId}
                        />
                    </TabPanel>
                    <TabPanel className={classes.panel} value={value} index={1}>
                        <ResellerSettings
                            handleClose={handleClose}
                            subscriberId={formData?.SubscriberId}
                            isResellerHead={formData?.IsReseller}
                            resellerName={formData?.ResellerName}
                            resellerId={formData?.SalesAgentId}
                        />
                    </TabPanel>
                    <TabPanel className={classes.panel} value={value} index={2}>
                        <RebrandingSettings
                            handleClose={handleClose}
                            subscriberId={formData?.SubscriberId}
                            isRebrandingHead={formData?.IsRebrandingHead}
                            rebrandingName={formData?.RebrandedName}
                            rebrandingId={formData?.RebrandedId}
                        />
                    </TabPanel>
                    <TabPanel className={classes.panel} value={value} index={3}>
                        <BillingSettings handleClose={handleClose} subscriberId={formData?.SubscriberId} date={formData?.BillingActivationDateTime} />
                    </TabPanel>
                    <TabPanel className={classes.panel} value={value} index={4}>
                        <PerformanceManagerSettings handleClose={handleClose} subscriberId={formData?.SubscriberId} subscriberName={formData?.SubscriberName} />
                    </TabPanel>
                    <TabPanel className={classes.panel} value={value} index={5}>
                        <GeneralSettings handleClose={handleClose} subscriberId={formData?.SubscriberId} />
                    </TabPanel>
                    <TabPanel className={classes.panel} value={value} index={6}>
                        <FeatureSettings handleClose={handleClose} subscriberId={formData?.SubscriberId} />
                    </TabPanel>
                    <TabPanel className={classes.panel} value={value} index={7}>
                        <NotificationSettings handleClose={handleClose} subscriberId={formData?.SubscriberId} />
                    </TabPanel>
                    <TabPanel className={classes.panel} value={value} index={8}>
                        <CCAFLink handleClose={handleClose} subscriberId={formData?.SubscriberId} />
                    </TabPanel>
                    {/* <TabPanel className={classes.panel} value={value} index={9}>
                        Change Subscriber Status
                    </TabPanel> */}
                    <TabPanel className={classes.panel} value={value} index={9}>
                        <AgreementSettings subscriberId={formData?.SubscriberId} />
                    </TabPanel>
                    <TabPanel className={classes.panel} value={value} index={10}>
                        <NotesTab subscriberId={formData?.SubscriberId} isGroup={formData?.IsGroupHead} />
                    </TabPanel>

                </div>
            </Dialog>

        </PageWithHeaderFooter>
    );
};

export default ManageSaas;
function TabPanel(props: { [x: string]: any; children: any; value: any; index: any; }) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function tabProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
        // background: "linear-gradient(90deg, #ebeeff 95%, #d3daff 100%)",
        // height: "calc(73vh - 60px)"
    },
    title: {
        background: "linear-gradient(90deg, #f1f4f9 95%, #d3daff 100%)",
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        height: 'calc(100% - 55px)',
        background: "linear-gradient(90deg, #f1f4f9 95%, #d3daff 100%)",
        "& .MuiTabs-indicator": {
            backgroundColor: "var(--primary-color)",
            width: '4px'
        },
        "& .Mui-selected": {
            backgroundColor: "#fff",
            color: 'var(--primary-color)'
        },
        "& .MuiTab-wrapper": {
            flexDirection: "row",
            justifyContent: "flex-start",
            textAlign: 'left'
        }
    },
    tabItem: {
        textTransform: 'none',
        fontSize: '.8rem',
        fontWeight: 'bold'
    },
    panel: {
        "& small": {
            fontWeight: 'bold'
        }
    },
    dialog: {
        // backgroundColor: 'red'
        // height: "73vh"
    },
}));

const getSubscriberInfo = (data: SubscriberInfoBundleV2) => (
    <div className="subscriber-info flex-column">
        <div className="name-status flex-row flex-center">
            <div className="status-flag">
                <Tooltip title={_SubscriberFunctionalStatus[data.SubscriberStatus]}>
                    <FlagIcon
                        fontSize="small"
                        htmlColor={data.SubscriberStatus === _SubscriberFunctionalStatus.Active ? "green" : "#7a7a7a"}
                    />
                </Tooltip>
            </div>
            <p>{data.SubscriberName}</p>
        </div>
        <div className="location">
            {`${data.City}${data.City ? "," : ""} ${data.State}`}
        </div>
    </div>
);

const getSubscriptionInfo = (data: SubscriberInfoBundleV2) => (
    <div className="subscription-info flex-column" style={{ maxWidth: 400, minWidth: 200 }}>
        <div className="flex-row flex-center align-items-center">
            <p className="subscription-name">{data.SubscriptionTemplateName}</p>
            {data.HasCreditCard && getCreditCardImage(data.CardType)}
        </div>
        <div className="flex-row flex-grow flex-wrap">
            {getFeatureImages(data.FeatureBitmask)}
        </div>
    </div>
);

const getCreditCardImage = (cardType: _CardType) => {
    let cardImagePath: IconSet = "blankCard";
    let title = "Unknown";

    switch (cardType) {
        case _CardType.visa:
            cardImagePath = "visa";
            title = "Visa";
            break;
        case _CardType.discover:
            cardImagePath = "discoverCard";
            title = "Discover";
            break;
        case _CardType.mastercard:
            cardImagePath = "masterCard";
            title = "MasterCard";
            break;
        case _CardType.amex:
            title = "Amex";
            break;
        default:
            break;
    }

    return <ChatleadIcon className="credit-card" icon={cardImagePath} size={30} title={title} />;
};

const getFeatureImages = (feature: _SubscriberFeature) => {
    const iconsPaths: string[] = [];
    const titles: string[] = [];

    if (appliedInBitmask(_SubscriberFeature.WebChat, feature)) {
        iconsPaths.push("webChat");
        titles.push("Web Chat");
    }
    if (appliedInBitmask(_SubscriberFeature.SMSChat, feature)) {
        iconsPaths.push("smsChat");
        titles.push("SMS Chat");
    }
    if (appliedInBitmask(_SubscriberFeature.FbChat, feature)) {
        iconsPaths.push("facebookChat");
        titles.push("Facebook Chat");
    }
    if (appliedInBitmask(_SubscriberFeature.FbMarketPlace, feature)) {
        iconsPaths.push("facebookMarketplace");
        titles.push("Marketplace Chat");
    }
    if (appliedInBitmask(_SubscriberFeature.LeadToRing, feature)) {
        iconsPaths.push("leadToRing");
        titles.push("Lead to Ring");
    }
    if (appliedInBitmask(_SubscriberFeature.VideoChat, feature)) {
        iconsPaths.push("videoChat");
        titles.push("Video chat");
    }
    if (appliedInBitmask(_SubscriberFeature.DualBilling, feature)) {
        iconsPaths.push("dualBilling");
        titles.push("Dual Billing");
    }
    if (appliedInBitmask(_SubscriberFeature.AppointmentService, feature)) {
        iconsPaths.push("appointment");
        titles.push("Appointment Service");
    }
    if (
        appliedInBitmask(_SubscriberFeature.LiveChatwithSMSTextMessaging, feature)
    ) {
        iconsPaths.push("chatsIcon");
        titles.push("Live Chat With SMS Text Messaging");
    }
    if (
        appliedInBitmask(_SubscriberFeature.ContentMarketingBlogAndSocialMedia, feature)
    ) {
        iconsPaths.push("contentMarketingAndBlog");
        titles.push("Content Marketing Blog & Social Media");
    }
    if (
        appliedInBitmask(
            _SubscriberFeature.GoogleAdsPayPerClickAdvertisingSubscribed,
            feature
        )
    ) {
        iconsPaths.push("googleAdsPerClick");
        titles.push("Google Ads per Click Advertising");
    }
    if (appliedInBitmask(_SubscriberFeature.AnsweringService, feature)) {
        iconsPaths.push("callService");
        titles.push("Call Service");
    }
    if (
        appliedInBitmask(_SubscriberFeature.SearchEngineFriendlyWebsite, feature)
    ) {
        iconsPaths.push("seoWebsite");
        titles.push("Seo Friendly Website");
    }
    if (
        appliedInBitmask(_SubscriberFeature.ServiceAppointment, feature)
    ) {
        iconsPaths.push("serviceAppointment");
        titles.push("Service Appointment");
    }
    if (
        appliedInBitmask(_SubscriberFeature.GoogleMyBusiness, feature)
    ) {
        iconsPaths.push("googleMyBusiness");
        titles.push("Google My Business");
    }

    if (
        appliedInBitmask(_SubscriberFeature.WebsitePro, feature)
    ) {
        iconsPaths.push("websitePro");
        titles.push("WebsitePro");
    }
    if (
        appliedInBitmask(_SubscriberFeature.SEOPro, feature)
    ) {
        iconsPaths.push("seoPro");
        titles.push("SEOPro");
    }
    if (
        appliedInBitmask(_SubscriberFeature.ContentPro, feature)
    ) {
        iconsPaths.push("contentPro");
        titles.push("ContentPro");
    }
    if (
        appliedInBitmask(_SubscriberFeature.LiveChatandSMSTextSoftware, feature)
    ) {
        iconsPaths.push("liveChatSMSTextSoftware");
        titles.push("LiveChat & SMS");
    }
    if (
        appliedInBitmask(_SubscriberFeature.ChatPro, feature)
    ) {
        iconsPaths.push("chatPro");
        titles.push("ChatPro");
    }
    if (
        appliedInBitmask(_SubscriberFeature.AppointmentSettingSoftware, feature)
    ) {
        iconsPaths.push("appointmentSettingSoftware");
        titles.push("Appointment Setting");
    }
    if (
        appliedInBitmask(_SubscriberFeature.AppointmentPro, feature)
    ) {
        iconsPaths.push("appointmentPro");
        titles.push("AppointmentPro");
    }
    if (
        appliedInBitmask(_SubscriberFeature.CitationPro, feature)
    ) {
        iconsPaths.push("citationPro");
        titles.push("CitationPro");
    }
    if (
        appliedInBitmask(_SubscriberFeature.PhonePro, feature)
    ) {
        iconsPaths.push("phonePro");
        titles.push("PhonePro");
    }
    if (
        appliedInBitmask(_SubscriberFeature.SocialMediaGoodPlan, feature)
    ) {
        iconsPaths.push("socialMediaGoodPlan");
        titles.push("Social Media Good Plan");
    }
    if (
        appliedInBitmask(_SubscriberFeature.SocialMediaBetterPlan, feature)
    ) {
        iconsPaths.push("socialMediaBetterPlan");
        titles.push("Social Media Better Plan");
    }
    if (
        appliedInBitmask(_SubscriberFeature.SocialMediaBestPlan, feature)
    ) {
        iconsPaths.push("socialMediaBestPlan");
        titles.push("Social Media Best Plan");
    }

    if (
        appliedInBitmask(_SubscriberFeature.WhatsApp, feature)
    ) {
        iconsPaths.push("whatsapp");
        titles.push("Whatsapp Chat");
    }
    if (
        appliedInBitmask(_SubscriberFeature.Instagram, feature)
    ) {
        iconsPaths.push("instagram");
        titles.push("Instagram Chat");
    }
    if (
        appliedInBitmask(_SubscriberFeature.ReputationManagement, feature)
    ) {
        iconsPaths.push("reputationManagement");
        titles.push("Reputation Management");
    }

    return iconsPaths.map((icon, i) => (
        <div className="feature-icon" key={i}>
            <Tooltip title={titles[i]} arrow>
                {/* <img src={`${icon}`} alt={titles[i]} /> */}
                <ChatleadIcon icon={icon as IconSet} size={23} />
            </Tooltip>
        </div>
    ));
};

const subscriptionTimeline = (
    signUpDate: Date | string,
    billingActivationDate?: Date | string,
    DisableDateTime?: Date | string
) => {
    const parsedSignUpDate = signUpDate ? dayjs(signUpDate) : null;
    const parsedBillingActivationDate = billingActivationDate
        ? dayjs(billingActivationDate)
        : null;
    const parsedDisabledDate = DisableDateTime ? dayjs(DisableDateTime) : null;

    return (
        <div className="timeline-wrapper flex-column">
            {signUpDate && (billingActivationDate || DisableDateTime) && (
                <div className="timeline-track" /> // Don't show track if only one date is present
            )}
            {parsedSignUpDate && (
                <div className="timeline-row flex-row flex-center">
                    <div
                        className="badge timeline-node"
                        style={{ background: "#129cf4" }}
                        title="SignUp Date"
                    >
                        S
                    </div>
                    <div className="timeline-date" style={{ color: "#129cf4" }}>
                        {parsedSignUpDate.format("MMM D, YY").toUpperCase()}
                    </div>
                </div>
            )}
            {parsedBillingActivationDate && (
                <div className="timeline-row flex-row flex-center">
                    <div
                        className="badge timeline-node"
                        style={{ background: "#00ffaa" }}
                        title="Billing Activation Date"
                    >
                        A
                    </div>
                    <div className="timeline-date" style={{ color: "#00ffaa" }}>
                        {parsedBillingActivationDate.format("MMM D, YY").toUpperCase()}
                    </div>
                </div>
            )}
            {parsedDisabledDate && (
                <div className="timeline-row flex-row flex-center">
                    <div className="badge timeline-node" title="Disable Date">
                        D
                    </div>
                    <div className="timeline-date">
                        {parsedDisabledDate.format("MMM D, YY").toUpperCase()}
                    </div>
                </div>
            )}
        </div>
    );
};

const getGroupResellerInfo = (data: SubscriberInfoBundleV2, user?: AccountUser) => {
    return (
        <div className="group-info flex-column">
            {data.IsGroupHead && (
                <div className="flex-row flex-center">
                    <div className="feature-icon">
                        {/* <img
                            src={getImage("groupHead")}
                            alt="Group head"
                            title="Group Head"
                        /> */}
                        <ChatleadIcon icon="groupHead" size={23} title="Group Head" />
                    </div>
                    <p>Group head</p>
                </div>
            )}
            {data.GroupName && (
                <div className="flex-row flex-center">
                    <div className="feature-icon">
                        {/* <img src={getImage("group")} alt="Group" title="Group" /> */}
                        <ChatleadIcon icon="group" size={23} title="Group" />
                    </div>
                    <p>{data.GroupName}</p>
                </div>
            )}
            {data.ResellerName && renderIfNotUserRoles(["DealerGroupAdmin"], user?.role as UserRoles)(
                <div className="flex-row flex-center">
                    <div className="feature-icon">
                        {/* <img src={getImage("reseller")} alt="Reseller" title="Reseller" /> */}
                        <ChatleadIcon icon="reseller" size={23} title="Reseller" />
                    </div>
                    <p>{data.ResellerName}</p>
                </div>
            )}
            {data.PerformanceManagerFullName && (
                <div className="flex-row flex-center">
                    <div className="feature-icon">
                        {/* <img
                            src={getImage("performanceManager")}
                            alt="performance manager"
                            title="Performance Manager"
                        /> */}
                        <ChatleadIcon icon="performanceManager" size={23} title="Performance Manager" />
                    </div>
                    <p>{data.PerformanceManagerFullName}</p>
                </div>
            )}
        </div>
    );
};
