import React from "react";
import { TableCell, TableSortLabel, Tooltip } from "@material-ui/core";
// import CreateIcon from '@material-ui/icons/Create';
// import { BlueResponsiveTable } from "../../../components/blue-responsive-table";
import { RowEventType } from "../../contact-settings/models/types";
import { FeatureAttribute } from "../../../model/feature-info";
// import logger from "../../../utils/log-utils";
import { UIButton } from "../../../components/form";
import { ResponsiveTable } from "../../../components/responsive-table";
import ChatleadIcon from "../../../components/chatlead-icon";
import { HeaderComponentProps, HeaderData } from "../../../components/responsive-table/responsive-table";

export type CellAlignment = "left" | "center" | "right";
export type TableSizes = "small" | "medium";
export type Order = 'asc' | 'desc';

interface FeatureData {
    id: string;
    name: string;
    serviceDate: string;
    billingDate: string;
    cancellationDate: string;
    salesPerson: string;
    performanceManager: string;
    isSubscribed: FeatureAttribute | undefined;
}

interface FeatureTableProps {
    featureData?: FeatureData[];
    onRowEvent: (id: string, eventType: RowEventType) => void;
}

const FeatureSettingsTable: React.FC<FeatureTableProps> = (props) => {
    // logger.log(`table props featureData: ${JSON.stringify(props.featureData)}`);
    return <ResponsiveTable
        components={{
            Header: HeaderItem,
            BodyComponent: BodyRowItem
        }}
        data={{
            headerData: headCells,
            values: props.featureData ?? []
        }}
        className="l-p-2 r-p-2 t-p-2 b-p-2"
        innerProps={{ bodyProps: { onRowEvent: props.onRowEvent } }}
        rowCount={5}
        isSelectable={false}
        size="small"
        orderBy="billingDate"
    />;
};

type HeadCell = HeaderData<FeatureData>;

type HeaderItemProps = HeaderComponentProps<FeatureData>;

const headCells: HeadCell[] = [
    { id: 'name', align: "left", disablePadding: false, label: 'Feature Name', isSortable: false },
    { id: 'serviceDate', align: "left", disablePadding: false, label: 'Service Activation', isSortable: false },
    { id: 'billingDate', align: "left", disablePadding: true, label: 'Billing Activation', isSortable: false },
    { id: 'cancellationDate', align: "left", disablePadding: true, label: 'Cancellation', isSortable: false },
    { id: 'salesPerson', align: "left", disablePadding: true, label: 'Sales Person', isSortable: false },
    { id: 'performanceManager', align: "left", disablePadding: true, label: 'Performance Manager', isSortable: false },
    { id: 'actions', align: "right", disablePadding: false, label: 'Actions', isSortable: false },
];

const HeaderItem: React.FC<HeaderItemProps> = (props) => {
    const { data: headCell, orderBy, order, onSort } = props;

    return <TableCell
        className='head'
        size='medium'
        key={headCell.id}
        align={headCell.align}
        sortDirection={orderBy === headCell.id ? order : false}
    >
        {
            (!headCell.isSortable) ? <span>{headCell.label}</span> : (<TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={onSort ? onSort(headCell.id) : undefined}
            >
                {headCell.label}
                {orderBy === headCell.id ? (
                    <span className="visually-hidden">
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                ) : null}
            </TableSortLabel>)
        }
    </TableCell>;
};

interface BodyRowItemProps {
    data: FeatureData;
    onRowEvent: (id: string, eventType: RowEventType) => void;
}

const BodyRowItem: React.FC<BodyRowItemProps> = (props) => {
    const { data: row, onRowEvent } = props;

    return <>
        <TableCell
            component="td"
            id={row.id}
            scope="row"
        >
            {row.name}
        </TableCell>

        <TableCell align="left">{row.serviceDate}</TableCell>
        <TableCell align="left">{row.billingDate}</TableCell>
        <TableCell align="left">{row.cancellationDate}</TableCell>
        <TableCell align="left">{row.salesPerson}</TableCell>
        <TableCell align="left">{row.performanceManager}</TableCell>
        {row.isSubscribed ? (<TableCell align="right">
            <div className="flex-row" style={{ justifyContent: 'flex-end' }}>
                <Tooltip title="Edit">
                    <UIButton variant="icon" buttonProps={{ "aria-label": "edit" }} size="small" onClick={() => onRowEvent(row.id, "edit")}>
                        <ChatleadIcon icon="edit" size={23} />
                    </UIButton>
                </Tooltip>
            </div>
        </TableCell>) : <TableCell />}
    </>;
};

export default FeatureSettingsTable;
