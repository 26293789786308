import { FormControlLabelProps, withStyles } from "@material-ui/core";
import MuiSwitch from "@material-ui/core/Switch";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";
import React, { forwardRef, memo } from "react";
import { CommonInputProps } from "../../../model/common-props";

interface UIRadioProps extends CommonInputProps {
    onChange?: (evt: React.ChangeEvent<{} | HTMLInputElement>, checked: boolean) => void;
    value?: boolean;
    inputProps?: FormControlLabelProps;
    size?: "small" | "medium";
    color?: 'primary' | 'secondary' | 'default';
}

const Switch = withStyles({
    switchBase: {
        "&$disabled": {
            color: "var(--surface-color)"
        },
        "&$disabled + $track": {
            backgroundColor: "var(--solids-grey-800)",
            opacity: 0.7
        }
    },
    track: {},
    disabled: {}
})(MuiSwitch);

const FormControlLabel = withStyles({
    disabled: {
        color: "var(--solids-grey-400) !important",
    },
})(MuiFormControlLabel);

const UISwitch: React.ForwardRefRenderFunction<HTMLButtonElement, UIRadioProps> = (props, ref) => {
    let { inputProps } = props;
    const { value, label, name, onChange, disabled = false, size, color } = props;

    if (!inputProps) inputProps = { label: "", control: <></> };

    // if (props.value !== undefined) { inputProps.value = props.value; }
    if (props.label !== undefined) { inputProps.label = label; }
    // if (props.onChange !== undefined) { inputProps.onChange = props.onChange; }

    return (
        <div className={clsx("flex-row", props.className)} style={props.style}>
            {
                label ? <FormControlLabel
                    className="flex-grow"
                    {...inputProps}
                    disabled={disabled}
                    control={<Switch checked={value} name={name} onChange={onChange} size={size} color={color} ref={ref} />}
                /> : <Switch
                    checked={value}
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    size={size}
                    color={color}
                    ref={ref}
                />
            }
        </div>
    );
};

export default memo(forwardRef(UISwitch));
