import { TableCell, TableSortLabel, Tooltip, Typography, Toolbar, InputBase, makeStyles, createStyles, Theme, fade, lighten } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import LaunchIcon from '@material-ui/icons/Launch';
import ChatleadIcon from '../../../components/chatlead-icon';
import { UIButton } from '../../../components/form';
import { HeaderComponentProps, HeaderData, Order } from '../../../components/responsive-table/responsive-table';
import { ResponsiveTable } from '../../../components/responsive-table';
// import { RowEventType, ToolbarEventType } from './models/types';

interface NotesData {
    id: string;
    note: string;
    userName: string;
    date: string;
    notiDate: string;
    attachment: string;
}

export type ToolbarEventType = "search" | "delete" | "add";
export type RowEventType = "edit" | "delete" | "view";

// interface HeadCell {
//     disablePadding: boolean;
//     id: string;
//     isSortable: boolean;
//     label: string;
//     align: "left" | "center" | "right";
// }
type HeadCell = HeaderData<NotesData>;

type HeaderItemProps = HeaderComponentProps<NotesData>;

const headCell: HeadCell[] = [
    { id: 'userName', align: 'left', disablePadding: true, label: 'User Name', isSortable: true },
    { id: 'date', align: 'left', disablePadding: true, label: 'Date', isSortable: true },
    { id: 'note', align: 'left', disablePadding: true, label: 'Note', isSortable: true },
    { id: 'notiDate', align: 'left', disablePadding: true, label: 'Notification Date', isSortable: true },
    { id: 'attachment', align: 'center', disablePadding: true, label: 'Attachment', isSortable: true },
    { id: 'actions', align: 'left', disablePadding: true, label: 'Actions ', isSortable: false, }
];

// interface HeaderItemProps {
//     data: HeadCell;
//     orderBy: string;
//     order: Order;
//     onSort?: (property: string) => (event: React.MouseEvent<unknown>) => void;
// }

const HeaderItem: React.FC<HeaderItemProps> = (props) => {
    const { data: headCell, orderBy, order, onSort } = props;
    return <TableCell
        key={headCell.id}
        align={headCell.align}
        sortDirection={orderBy === headCell.id ? order : false} // Doubt?
    >
        {
            (!headCell.isSortable) ? <span>{headCell.label}</span> : (
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={onSort ? onSort(headCell.id) : undefined}
                >
                    {headCell.label}

                </TableSortLabel>
            )
        }
    </TableCell>;
};

interface BodyRowItemProps {
    data: NotesData;
    onRowEvent: (id: string, eventType: RowEventType) => void;
}

const BodyRowItem: React.FC<BodyRowItemProps> = (props) => {
    const { data: row, onRowEvent } = props;
    return <>
        <TableCell
            component="td"
            id={row.id}
            //   padding="none"
            scope="row"
            align='left'
        >
            {row.userName}
        </TableCell>
        <TableCell align="left">{row.date}</TableCell>
        <TableCell align="left">{row.note}</TableCell>
        <TableCell align="left">{row.notiDate}</TableCell>
        <TableCell align="left">
            {
                !!row.attachment &&
                <UIButton className="l-m-2" variant="text" size="small" onClick={() => onRowEvent(row.id, "view")}>
                    Click to View
                    <LaunchIcon fontSize="small" htmlColor="var(--muted-text-color)" />
                </UIButton>
            }
        </TableCell>
        <TableCell align="left">
            <div className="flex flex-row flex-center">
                <Tooltip title="Edit">
                    <UIButton variant="icon" size="small" onClick={() => onRowEvent(row.id, "edit")}>
                        <ChatleadIcon icon="edit" size={20} />

                    </UIButton>
                </Tooltip>

                <Tooltip title="Delete">
                    <UIButton variant="icon" size="small" onClick={() => onRowEvent(row.id, "delete")}>
                        <ChatleadIcon icon="delete" size={20} color="var(--danger-red-font)" />

                    </UIButton>
                </Tooltip>

            </div>
        </TableCell>
    </>;
};

const useToolbarStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius + 1,
        backgroundColor: fade(theme.palette.common.black, 0.05),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, 0.07),
        },
        marginLeft: 0,
        marginRight: theme.spacing(1),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        '&:focus': {
            width: '25ch',
        },
        [theme.breakpoints.up('xs')]: {
            width: '15ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
    highlight:
        theme.palette.type === 'light' ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        } : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
        },
    title: {
        flex: '1 1 100%',
    },
}));

interface NotesTableProps {
    tableData?: NotesData[];
    onRowEvent?: (id: string, eventType: RowEventType) => void;
    // onToolBarIconClick?: (eventType: ToolbarEventType, text?: string) => void;
}
const NotesTable: React.FC<NotesTableProps> = (props) => {
    return (
        <ResponsiveTable
            data={{
                values: props.tableData ?? [],
                headerData: headCell
            }}
            components={{
                //  Header:,
                BodyComponent: BodyRowItem,
                Header: HeaderItem,
                // Toolbar: TableToolbar,
            }}
            innerProps={{
                bodyProps: { onRowEvent: props.onRowEvent },
                // toolBarProps: { onIconClick: props.onToolBarIconClick }
            }}
            rowCount={5}
            // isSelectable
            size="medium"
            orderBy="userName"
        />
    );
};

export default NotesTable;