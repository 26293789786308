import { Dispatch } from '@reduxjs/toolkit';
import { getEnvironment } from './environment-utils';
import { PENDING, SUCCESS } from '../constant/redux-constants';
import httpService from '../service/http-service';
import { Meta } from '../store/types';

/**
 * logError  - Log error without UI display
 * @param {Object} error      Error object caught in catch block
 * @param {String} type       Action type that caused error
 *
 * @returns {Promise}
 */
export const logError = (error: any, type: string) => {
    if (getEnvironment() === 'development') {
        console.error(`Error type: ${type}.`);
        console.error(error);
    }

    const errorMessage = error && error.response
        ? error.response.data
        : error;

    return Promise.reject(errorMessage);
};

/**
 * post - Generic action to make a POST request with axios
 * @param {Function} dispatch     React-redux's dispatch function
 * @param {String} type           Action type to be dispatched
 * @param {String} endpoint       Api endpoint to hit (e.g., '/auth/login')
 * @param {String} endpoint       Api endpoint to hit (e.g., '/auth/login')
 * @param {Object} data           Data to be posted to the api
 *
 * @returns {Promise}
 */
export const post = async (dispatch: Dispatch, type: string, endpoint: string, data?: Object, dispatchSuccess: boolean = true) => {
    try {
        dispatch({
            type,
            meta: { status: PENDING },
        });

        const response = await httpService.post(endpoint, data);

        if (dispatchSuccess) {
            dispatch({
                type,
                meta: { status: SUCCESS },
                payload: response.data,
            });
        }

        return response;
    } catch (err) {
        return logError(err, type);
    }
};

/**
 * put - Generic action to make a PUT request with axios
 * @param {Function} dispatch     React-redux's dispatch function
 * @param {String} type           Action type to be dispatched
 * @param {String} endpoint       Api endpoint to hit (e.g., '/user/:id')
 * @param {Object} data           Data to be posted to the api
 * @param {Boolean} requiresAuth  Whether or not request needs to be authenticated
 *
 * @returns {Promise}
 */
export const put = async (dispatch: Dispatch, type: string, endpoint: string, data: Object, dispatchSuccess: boolean = false) => {
    try {
        dispatch({
            type,
            meta: { status: PENDING },
        });

        const response = await httpService.put(endpoint, data);

        if (dispatchSuccess) {
            dispatch({
                type,
                meta: { status: SUCCESS },
                payload: response.data,
            });
        }

        return response;
    } catch (err) {
        return logError(err, type);
    }
};

/**
 * get - Generic action to make a GET request with axios
 * @param {Function} dispatch     React-redux's dispatch function
 * @param {String} type           Action type to be dispatched
 * @param {String} endpoint       Api endpoint to hit (e.g., '/user')
 * @param {Boolean} requiresAuth  Whether or not request needs to be authenticated
 *
 * @returns {Promise}
 */
export const get = async (dispatch: Dispatch, type: string, endpoint: string, params: Object, dispatchSuccess: boolean = false) => {
    try {
        dispatch({
            type,
            meta: { status: PENDING },
        });

        const response = await httpService.get(endpoint, {
            params
        });

        if (dispatchSuccess) {
            dispatch({
                type,
                meta: { status: SUCCESS },
                payload: response.data,
            });
        }
        return response;
    } catch (err) {
        return logError(err, type);
    }
};

/**
 * del - Generic action to make a DELETE request with axios
 * @param {Function} dispatch     React-redux's dispatch function
 * @param {String} type           Action type to be dispatched
 * @param {String} endpoint       Api endpoint to hit (e.g., '/user/:id')
 * @returns {Promise}
 */
export const del = async (dispatch: Dispatch, type: string, endpoint: string, data: Object, dispatchSuccess: boolean = false) => {
    try {
        dispatch({
            type,
            meta: { status: PENDING },
        });

        const response = await httpService.delete(endpoint, data);

        if (dispatchSuccess) {
            dispatch({
                type,
                meta: { status: SUCCESS },
                payload: response.data,
            });
        }
        return response;
    } catch (err) {
        return logError(err, type);
    }
};

export const createGenericAction = (type: string, payload: any, meta: Meta) => {
    return { type, payload, meta };
};
