import * as React from 'react';
import AgreementTab from '../../../components/Dialogs/agreement-dialog/components/agreement-tabs';

interface AgreementProps {
    subscriberId?: string;
}

const AgreementSettings: React.FC<AgreementProps> = (props) => {
    const { subscriberId } = props;
    return (
        <AgreementTab subscriberId={subscriberId} />
    );
};

export default AgreementSettings;