import { useEffect, useState } from "react";
import appToast from "../../../../components/app-toast";
import uniLoader from "../../../../components/uni-loader";
import { FeatureInfo } from "../../../../model/feature-info";
import { HttpStatusCode } from "../../../../model/http-status-code";
import { ApiData } from "../../../../model/xhrCallState";
import httpService from "../../../../service/http-service";
import logger from "../../../../utils/log-utils";
import { RowEventType } from "../../../contact-settings/models/types";

export default function useFeatureSettings(subId: string) {
    const initialState: InitialState = {
        featureData: new ApiData(),
    };
    type InitialState = {

        featureData: ApiData<FeatureInfo[]>,
    };
    const [checked, setChecked] = useState<boolean>(false);
    const [featureState, setFeatureState] = useState<InitialState>(initialState);
    const [edRowData, setEdRowData] = useState<FeatureInfo>();
    const [allPMList, setAllPMList] = useState<any[]>([]);
    const [selectedSP, setSelectedSP] = useState<string>("");
    const [serviceDate, setServiceDate] = useState<string | undefined>();
    const [startDate, setStartDate] = useState<string | undefined>();
    const [endDate, setEndDate] = useState<string | undefined>();

    useEffect(() => {
        if (featureState?.featureData.status === "Pending") uniLoader.showLoader(''); else uniLoader.close();
    }, [featureState]);

    const getFeatureInfo = async () => {
        // logger.log('getFeatureInfo --- Hit');
        setFeatureState((s) => ({ ...s, featureData: { status: "Pending" } }));
        try {
            // logger.log('getFeatureInfo --- Try Hit');
            const response = await httpService.get(`Subscriber/GetFeatureDetails?subscriberId=${subId}`);

            // logger.log(`response data: ${JSON.stringify(response.data)}`);
            // appToast.success('Get Feature Info succeeded.');
            setFeatureState(s => ({ ...s, featureData: { status: "Completed", data: response.data } }));
        } catch (error) {
            // logger.log('getFeatureInfo --- Catch Hit');
            logger.log(error);
            appToast.error('Get Feature Info Error');
            setFeatureState(s => ({ ...s, featureData: { status: "Failed" } }));
        }
    };
    const getAllPerformanceManager = async () => {
        setFeatureState((s) => ({ ...s, featureData: { status: "Pending" } }));
        try {
            // logger.log("getAllPMs");
            const response = await httpService.get(`Subscriber/GetAllPerformanceManager`);

            // logger.log(`response data: ${JSON.stringify(response.data)}`);
            // appToast.success('Get All PMs succeeded.');
            if (response.status === HttpStatusCode.OK) { setAllPMList(response.data.map((d: string) => ({ "label": d, "value": d }))); }
        } catch (error) {
            logger.log(error);
            appToast.error('Performance Manager data not available');
            setFeatureState(s => ({ ...s, featureData: { status: "Failed" } }));
        }
    };
    const saveFeature = async (e: any) => {
        e.preventDefault();
        if (edRowData) {
            edRowData.SalesPerson = selectedSP || "";
            edRowData.WentLiveDate = serviceDate ? new Date(serviceDate) : undefined;
            edRowData.BillingStartDate = startDate ? new Date(startDate) : undefined;
            edRowData.CancellationDate = endDate ? new Date(endDate) : undefined;
        }
        const body: any[] = [];
        body.push(edRowData);
        // logger.log(`saveFeature --- bosy: ${JSON.stringify(body)}`);
        setFeatureState((s) => ({ ...s, featureData: { status: "Pending" } }));
        try {
            // logger.log('saveFeature --- Try Hit');
            const response = await httpService.post(`Subscriber/SaveFeatureInfo`, body);

            // logger.log(`response data: ${JSON.stringify(response.data)}`);
            appToast.success('Feature saved successfully.');
            setFeatureState(s => ({ ...s, featureData: { status: "Completed", data: response.data } }));
        } catch (error) {
            // logger.log('saveFeature --- Catch Hit');
            logger.log(error);
            setFeatureState(s => ({ ...s, featureData: { status: "Failed" } }));
        }
    };
    const onRowEvent = (id: string, eventType: RowEventType) => {
        // logger.log(`${id} - ${eventType}`);
        switch (eventType) {
            case "edit":
                setEdRowData(featureState.featureData.data?.find(r => r.ID === id));
                // logger.log(`edited data: ${JSON.stringify(editedData)}`);
                break;
            default:
                break;
        }
    };

    const handleBack = async () => {
        await getFeatureInfo();
        setChecked(false);
    };

    useEffect(() => {
        getFeatureInfo();
        getAllPerformanceManager();
    }, []);
    // useEffect(() => {
    //     logger.log(`serviceDate: ${serviceDate}`);
    //     logger.log(`startDate: ${startDate}`);
    //     logger.log(`endDate: ${endDate}`);
    // }, [serviceDate, startDate, endDate]);

    useEffect(() => {
        if (edRowData) {
            if (edRowData.WentLiveDate != null) setServiceDate(new Date(edRowData.WentLiveDate).toLocaleString());
            if (edRowData.BillingStartDate != null) setStartDate(new Date(edRowData.BillingStartDate).toLocaleString());
            if (edRowData.CancellationDate != null) setEndDate(new Date(edRowData.CancellationDate).toLocaleString());
        }
        if (edRowData) setChecked(true); else setChecked(false);
    }, [edRowData]);

    return {
        featureState,
        onRowEvent,
        checked,
        setChecked,
        edRowData,
        saveFeature,
        allPMList,
        selectedSP,
        setSelectedSP,
        serviceDate,
        setServiceDate,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        handleBack
    };
}
