import * as React from "react";
import { RouteConfig } from "../../../model/route-config";
import useNavigationContent from "./hooks/useNavigationContent";
import SingleNavigationItem from "./single-navigation-item";

export interface NavigationContentProps {
    routes: RouteConfig[];
    basePath: string;
}

const NavigationContent: React.FC<NavigationContentProps> = ({
    routes,
    basePath,
}) => {
    const { filterRoutes } = useNavigationContent();

    return (
        <div className="navigation-content flex-grow flex-column">
            {filterRoutes(routes).map((route) => (
                <div key={route.path} className="flex-column navigation-item-group">
                    <div className="navigation-items">
                        <SingleNavigationItem navItem={route} basePath={basePath} l1Route />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default NavigationContent;
