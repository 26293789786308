import React from 'react';
import { ProductType, SubscriptionType, UserRightKeys, UserRoles } from "../model/types";
import UsersRoles from '../pages/users-roles';
import configuration from './configuration';

export const renderIf = (condition: boolean) => (node: React.ReactNode) => condition && node;
export const renderNodesIf = (condition: boolean) => (...nodes: React.ReactNode[]) => (condition ? nodes : []);

export const renderIfProduct = (products: ProductType[]) => (node: React.ReactNode) => renderIf(products.includes(configuration.getValue("productName") as ProductType))(node);
export const renderIfNotProduct = (products: ProductType[]) => (node: React.ReactNode) => renderIf(!products.includes(configuration.getValue("productName") as ProductType))(node);

export const renderIfUserRoles = (acceptRoles: UserRoles[], userRoles: UserRoles) => (node: React.ReactNode) => renderIf(acceptRoles.includes(userRoles))(node);
export const renderIfNotUserRoles = (acceptRoles: UserRoles[], userRoles: UserRoles) => (node: React.ReactNode) => renderIf(!acceptRoles.includes(userRoles))(node);

export const renderIfUserRights = (acceptRights: UserRightKeys[], userRights: UserRightKeys[]) => (node: React.ReactNode) => renderIf(acceptRights.every(i => userRights.includes(i)))(node);
export const renderIfNotUserRights = (acceptRights: UserRightKeys[], userRights: UserRightKeys[]) => (node: React.ReactNode) => renderIf(!acceptRights.every(i => userRights.includes(i)))(node);

export const renderNodesIfUserRoles = (acceptRoles: UserRoles[], userRoles: UserRoles) => (...nodes: React.ReactNode[]) => renderNodesIf(acceptRoles.includes(userRoles))(nodes);
export const renderNodesIfNotUserRoles = (acceptRoles: UserRoles[], userRoles: UserRoles) => (...nodes: React.ReactNode[]) => renderNodesIf(!acceptRoles.includes(userRoles))(nodes);

export const renderNodesIfSubsType = (acceptSubType: SubscriptionType[], userSubType: SubscriptionType) => (...nodes: React.ReactNode[]) => renderNodesIf(acceptSubType.includes(userSubType))(nodes);
export const renderNodesIfNotSubsType = (acceptSubType: SubscriptionType[], userSubType: SubscriptionType) => (...nodes: React.ReactNode[]) => renderNodesIf(!acceptSubType.includes(userSubType))(nodes);