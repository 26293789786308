import * as React from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Tooltip } from "@material-ui/core";
// import { Button } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import BusinessIcon from '@material-ui/icons/Business';
import clsx from "clsx";
import { useQuickSubscriberSwitch } from "./hooks/useQuickSubscriberSwitch";
import { UIButton } from "../../../components/form";

export interface QuickSubscriberSwitchProps {
    openSubscriberSwitchDialog: () => void;
    btaButtonRef?: React.Ref<HTMLButtonElement>;
    minimal?: boolean;
}

const QuickSubscriberSwitch: React.SFC<QuickSubscriberSwitchProps> = (
    { openSubscriberSwitchDialog, btaButtonRef, minimal = false }
) => {
    const {
        handleBackToAdmin,
        visibleSubscriberSwitch,
        isInsideSubscriberView,
        currentSubscriberName,
        user
    } = useQuickSubscriberSwitch();

    if (visibleSubscriberSwitch) {
        return (
            <div className={clsx(!minimal ? "flex-row" : "flex-column", "flex-center subscriber-switch wrapper", minimal && "minimal")} title={minimal && currentSubscriberName ? `Dealership Name: ${currentSubscriberName || "Subscriber"}` : undefined}>
                <Tooltip title={<><kbd>Ctrl</kbd> + <kbd>K</kbd> to open the ' Quick Switch '</>} placement="right" arrow>
                    <UIButton
                        color="secondary"
                        className="flex-row flex-grow btn--subscriber-switch"
                        style={{ borderRadius: 6 }}
                        buttonProps={{ endIcon: <ArrowDropDownIcon /> }}
                        onClick={openSubscriberSwitchDialog}
                        size={minimal ? "small" : "medium"}
                    >
                        {isInsideSubscriberView
                            ? <div className="flex-row flex-center"><BusinessIcon fontSize="small" className={clsx(!minimal && "r-m-1")} />{!minimal && <span className="text-ellipsis" style={{ maxWidth: 115 }}>{currentSubscriberName || "Subscriber"}</span>}</div>
                            : !minimal ? "Select Subscriber" : "Sub"}
                    </UIButton>
                </Tooltip>

                {isInsideSubscriberView && (
                    <Tooltip title={<><kbd>Ctrl</kbd> + <kbd>E</kbd> back to Manage Saas</>} placement="right" arrow>
                        <UIButton
                            className="btn--bta"
                            variant={minimal ? "contained" : "icon"}
                            color="secondary"
                            onClick={handleBackToAdmin}
                            ref={btaButtonRef}
                            buttonProps={{
                                "aria-label": "back to admin"
                            }}
                            size="medium"
                        >
                            <ExitToAppIcon style={{ fontSize: 20 }} />
                        </UIButton>
                    </Tooltip>
                )}
            </div>
        );
    }
    return user ? (
        <div className="flex-row subscriber-switch wrapper flex-center">
            <div className={clsx("sub-name-container flex-row flex-center", minimal && "minimal")} title={`Dealership Name: ${user?.sub_name || "Subscriber"}`}>
                <BusinessIcon fontSize="small" className={clsx(!minimal && "r-m-1")} /><span className="sub-name">{!minimal && (user?.sub_name || "Subscriber")}</span>
            </div>
        </div>
    ) : null;
};

export default QuickSubscriberSwitch;
