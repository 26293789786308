import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export function convertTimezone(date: string | dayjs.Dayjs | undefined) {
    return dayjs(date).tz('America/New_York');
}

export function stripTimeFromDateWithFormat(date?: string) {
    return `${dayjs(date).format('YYYY-MM-DD')}T00:00:00Z`;
}

export const stripTimeZone = (date: string) => {
    return date.length >= 19 ? (date.endsWith("Z") ? date.slice(0, -1) : date.slice(0, -6)) : date;
};