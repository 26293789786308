import { DialogActions, Fade } from "@material-ui/core";
import dayjs from "dayjs";
import React from "react";
import { UIButton, UISelect } from "../../../components/form";
import usePerformanceManagerSettings from "./hooks/usePerformanceManagerSettings";
import PerformanceManagerTable from "./performance-manager-table";

const PerformanceManagerSettings: React.FunctionComponent<any> = (props) => {
    const {
        state, checked, setChecked, allPMList, pmType, setselectedPM, setSelectedType, setPerformanceManager, handleBack
    } = usePerformanceManagerSettings(props.subscriberId, props.subscriberName);

    // const containerRef = React.useRef(null);
    // const handleChange = () => {
    //     setChecked((prev) => !prev);
    // };

    const formSchema = {
        pm_select: { name: "performanceManager", options: allPMList, label: "Select performance manager", className: "r-m-2 r-m-phone-inv", required: true },
        type_select: { name: "type", options: pmType, label: "Select a type", className: "r-m-2 r-m-phone-inv", required: true },
    };
    if (checked) {
        return (

            <div>
                <Fade in={checked} style={{ transitionDelay: checked ? '100ms' : '0ms' }}>
                    <form autoComplete="off" id="pm-form" onSubmit={(e) => setPerformanceManager(e)}>
                        <small>Add Performance Manager</small>
                        <div className="flex-responsive t-m-2 r-m-2 l-m-2">
                            <UISelect {...formSchema.pm_select} onChange={(e) => setselectedPM(`${e.target.value}`)} />
                        </div>
                        <div className="flex-responsive t-m-2 r-m-2 l-m-2">
                            <UISelect {...formSchema.type_select} onChange={(e) => setSelectedType(`${e.target.value}`)} />
                        </div>
                        <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                            <UIButton type="submit" form="pm-form">
                                Save
                            </UIButton>
                            <UIButton color="danger" onClick={() => handleBack()}>
                                Cancel
                            </UIButton>
                        </DialogActions>
                    </form>
                </Fade>
            </div>);
    }

    return (
        <div>
            <Fade in={!checked}>
                <div>
                    {/* {state.pmData.status === "Pending" ? (
                        <div className="loading flex-column flex-center">
                            <CircularProgress size={40} color="secondary" />
                        </div>
                    ) : (
                    <div> */}
                    {state.pmData?.data && <PerformanceManagerTable
                        pmData={state.pmData.data.map(r => ({
                            id: r.id,
                            name: r.name,
                            type: r.type === "1" ? "Sales" : "Service",
                            from: dayjs(r.from).format('DD MMM YYYY'),
                            to: r.to == null ? '' : dayjs(r.to).format('DD MMM YYYY')
                        }))}
                    />}
                    {/* </div>
                        )} */}
                    <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                        <UIButton onClick={() => setChecked(true)}>
                            Add
                        </UIButton>
                        <UIButton color="danger" onClick={props.handleClose}>
                            Close
                        </UIButton>
                    </DialogActions>
                </div>
            </Fade>
        </div>
    );
};

export default PerformanceManagerSettings;
