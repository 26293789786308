import { logSentryError, logSentryMessage } from "../serverLog";
import { getWorkerInstance } from "../workers";
import configuration from "./configuration";

const logger = (function (window: Window, document: Document, env: string) {
    const isLive = env === "live";
    const typeAwareStringify = (...optionalParams: any[]) => {
        let finalString = "";
        optionalParams.forEach((val, i) => {
            if (i !== 0 && val !== undefined) finalString += " ";
            if (typeof val === "string") finalString += val;
            if (typeof val === "boolean") finalString += val.toString();
            if (typeof val === "number") finalString += Number(val).toString();
            if (typeof val === "object") finalString += JSON.stringify(val);
        });
        return finalString;
    };

    function log(message?: any, ...optionalParams: any[]) {
        if (isLive) {
            // logSentryMessage(message, "log");
            getWorkerInstance("remoteLogger")?.logMessage(typeAwareStringify(message, ...optionalParams)); // Call api
        } else {
            console.log(message, ...optionalParams);
        }
    }
    function debug(message: string, forceConsole: boolean = false, ...optionalParams: any[]) {
        if (isLive && !forceConsole) {
            // logSentryMessage(message);
            getWorkerInstance("remoteLogger")?.logWarnMessage(typeAwareStringify(message, ...optionalParams));
        } else {
            console.debug(message, ...optionalParams);
        }
    }
    function error(error: any, forceConsole: boolean = false, ...optionalParams: any[]) {
        if (isLive && !forceConsole) {
            logSentryError(error);
            getWorkerInstance("remoteLogger")?.logErrorMessage(typeAwareStringify(error, ...optionalParams));
        } else {
            console.error(error, ...optionalParams);
        }
    }
    return {
        log,
        debug,
        error
    };
}(window, document, configuration.getValue("environment")));

export default logger;
