import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FeedbackIcon from '@material-ui/icons/Feedback';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import HelpIcon from '@material-ui/icons/Help';
import PolicyIcon from '@material-ui/icons/Policy';
import { RouteAdditionalPropsV2 } from "../../model/route-additional-propsV2";
import { selectFeedBackOpen, setFeedBackDialogOpen } from "../../store/modules/ui";
import FeedBackDialog from "../../components/Dialogs/feedback-dialog/feedback-dialog";
import MenuListUI from "../../components/menu-list/menu-list";
import configuration from "../../utils/configuration";
import { DataLoadState } from "../../model/types";

export interface PublicLayoutProps extends RouteAdditionalPropsV2 {
    title?: string;
    recaptchaToken?: string;
    isCaptchaLoading?: boolean;
    onFeedbackSubmissionComplete?: (status: DataLoadState) => void;
}
const PublicLayout: React.FC<PublicLayoutProps> = ({ children, recaptchaToken, isCaptchaLoading = false, onFeedbackSubmissionComplete, ...props }) => {
    // const { isMobileNavOpen, setMobileNavOpen } = useDashboardLayout();
    const PageTitle = props.routeInfo?.title || props.title;
    const { feedBackDialog } = useSelector(selectFeedBackOpen);
    const dispatch = useDispatch();
    const handleDialogClose = () => {
        dispatch({ type: setFeedBackDialogOpen.type, payload: false });
    };

    const onClickMenu = (options: string | number) => {
        if (options === 1) {
            dispatch({ type: setFeedBackDialogOpen.type, payload: true });
        }
        if (options === 2) {
            window.open(`${configuration.getValue("mainSite")}/eula-privacy-policy/`, "_blank");
        }
    };

    return (
        <>
            <Helmet>
                <title>{PageTitle || "Web Panel | Chatlead Inc"}</title>
                <meta name="description" content={`${configuration.getValue("productName")} Web Control Panel`} />

                <script src="https://www.google.com/recaptcha/api.js" />
            </Helmet>
            {children}

            <MenuListUI buttonVariant="text" buttonContainerClassName="flex-row flex-center btn-help" buttonSize="small" buttonColor="secondary" list={[{ label: "Feedback", value: 1, icon: <FeedbackIcon fontSize="small" className="r-m-2" /> }, { label: "View Policy", value: 2, icon: <PolicyIcon fontSize="small" className="r-m-2" /> }]} onClick={onClickMenu}>
                <HelpIcon className="r-m-1" fontSize="small" />
                Help
            </MenuListUI>

            <FeedBackDialog dialogOpen={feedBackDialog} dialogClose={handleDialogClose} recaptchaToken={recaptchaToken} isCaptchaLoading={isCaptchaLoading} onSubmissionComplete={onFeedbackSubmissionComplete} />
        </>
    );
};

export default PublicLayout;

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        background: theme.palette.background.default,
    },
}));
