import { useEffect, useState } from "react";
import { FilterOptionOutput, FilterOptionValue, FilterOutput } from "../../../model/filter-data";
import { QuickFilterProps } from "../quick-filter";

export const useQuickFilter = (props: QuickFilterProps) => {
    const [filterResult, setFilterResult] = useState<FilterOutput>({});

    useEffect(() => {
        let value: FilterOptionOutput = {} as FilterOptionOutput;
        if (props.selectedValues) {
            Object.keys(props.selectedValues).forEach((key: string) => {
                if (props.selectedValues && isValidValue(props.selectedValues[key])) {
                    value = { ...value, [key]: props.selectedValues[key] };
                }
            });
        }
        if (Object.keys(value).length) {
            setFilterResult(() => value);
        }
    }, [props.selectedValues]);

    const handleOptionChange = (data: FilterOptionOutput) => {
        if (isValidValue(data.value)) {
            setFilterResult(state => ({ ...state, [data.path]: data.value }));
        } else setFilterResult(state => (removeProperty(state, data.path)));
    };

    const handleFilterSubmit = () => {
        props.onSubmitFilter(filterResult);
    };

    const handleClearFilter = () => {
        setFilterResult(() => ({}));
    };

    return { filterResult, handleClearFilter, handleOptionChange, handleFilterSubmit };
};

function removeProperty(object: { [x: string]: any }, propertyName: string) {
    const { [propertyName]: _, ...rest } = object;
    return rest;
}
function isValidValue(value?: FilterOptionValue): boolean {
    if (Array.isArray(value)) { return !!value.length; }
    if (typeof value === "undefined" || value === null) return !!value;
    return true;
}
