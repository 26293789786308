import { Avatar, createStyles, makeStyles, Theme, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) => createStyles({
    iconAvatar: {
        width: 18,
        height: 18,
    }
}));

export interface NavItemTextProps {
    text?: string;
    icon?: string | React.ReactElement;
    showIconsIfNotPresent?: boolean;
    showOnlyIcon?: boolean;
}

const NavItemText: React.FC<NavItemTextProps> = ({ icon, text, showOnlyIcon = false, showIconsIfNotPresent = false }) => {
    const classes = useStyles();

    const getIcon = () => {
        return icon ?? (showIconsIfNotPresent && <Avatar
            alt={`${text} icon`}
            className={clsx(classes.iconAvatar, "avatar-icon")}
        // style={{ width: 18, height: 18, backgroundColor: "currentColor", fontWeight: "bold", fontSize: 18 }}
        >{text?.charAt(0)}</Avatar>);
    };
    const content = <div className="flex-row flex-grow flex-center">
        {getIcon()}
        {(text && !showOnlyIcon) && <span className={clsx("text-ellipsis navigation-text", (icon || showIconsIfNotPresent) && "l-m-1")}>{text}</span>}
    </div>;

    return (showOnlyIcon ? <Tooltip title={text || ""}>
        {content}
    </Tooltip> : content
    );
};

export default NavItemText;
