import * as React from "react";
import { Switch } from "react-router-dom";
import { NonAuthRoute, ProtectedRoute, UnprotectedRoute } from ".";
import { constructPath } from "../../utils/route-utils";
import { RouteConfig } from "../../model/route-config";

export interface RouteWithSubRoutesProps {
  routes: RouteConfig[];
  currentPath?: string;
}

const RouteWithSubRoutes: React.FC<RouteWithSubRoutesProps> = ({
  routes,
  currentPath,
}) => {
    const getAcuateRouteComponent = (route: RouteConfig) => {
        if (route.authRequired) return createProtectedRoute(route);
        if (route.nonAuthRequired) return createNonAuthRequiredRoute(route);
        return createUnprotectedRoute(route);
    };

    const createProtectedRoute = (route: RouteConfig) => {
        return (
            <ProtectedRoute
                key={route.path}
                {...route}
                path={constructPath(route.path, currentPath)}
            />
        );
    };

    const createNonAuthRequiredRoute = (route: RouteConfig) => {
        return (
            <NonAuthRoute
                key={route.path}
                {...route}
                path={constructPath(route.path, currentPath)}
            />
        );
    };

    const createUnprotectedRoute = (route: RouteConfig) => {
        return (
            <UnprotectedRoute
                key={route.path}
                {...route}
                path={constructPath(route.path, currentPath)}
            />
        );
    };

    return <Switch>{routes.filter(route => !route.externalLink).map((route) => getAcuateRouteComponent(route))}</Switch>;
};

export default RouteWithSubRoutes;
