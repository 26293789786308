import React, { FC, Suspense } from "react";
import { Loading } from "../loading";

const SuspendedPage: FC<any> = ({ children }) => (<Suspense fallback={<Loading />}>
    {
        children
    }
</Suspense>);

export default SuspendedPage;
