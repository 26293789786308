import development from "../configurations/development.json";
import carchat24Test from "../configurations/carchat24-test.json";
import carchat24Live from "../configurations/carchat24-live.json";
import boatchatLive from "../configurations/boatchat-live.json";
import boatchatTest from "../configurations/boatchat-test.json";
import rvchatLive from "../configurations/rvchat-live.json";
import rvchatTest from "../configurations/rvchat-test.json";
import legalchatLive from "../configurations/legalchat-live.json";
import legalchatTest from "../configurations/legalchat-test.json";
import chatleadLive from "../configurations/chatlead-live.json";
import chatleadTest from "../configurations/chatlead-test.json";
import servicesProLive from "../configurations/servicespro-live.json";
import servicesProTest from "../configurations/servicespro-test.json";

interface AppSettings {
    productAbbrName: string;
    productName: string;
    brandName: string;
    apiBase: string;
    backend: string;
    static: string;
    server: string;
    mainSite: string;
    androidAppLink: string,
    iphoneAppLink: string,
    l2rService: string;
    callHandlerService: string;
    environment: string;
    baseUrl: string;
    repoManageServ: string
}

const devConfig: AppSettings = {
    "productAbbrName": "CC24",
    "productName": "CarChat24",
    "brandName": "CarChat24",
    "apiBase": "https://localhost:44338/controlpanelapi/api",
    "backend": "https://localhost:44338/",
    "static": "https://static11carchat24test.chatlead.com",
    "server": "https://service11carchat24test.chatlead.com",
    "mainSite": "https://www.carchat24.com",
    "androidAppLink": "https://play.google.com/store/apps/details?id=com.carchat24.www.carchat",
    "iphoneAppLink": "https://apps.apple.com/us/app/carchat24-mobile-chat/id1236729768",
    "l2rService": "https://l2rservicetest.chatlead.com",
    "callHandlerService": "",
    "repoManageServ": "https://localhost:7059/",
    "environment": "development",
    "baseUrl": "."
};

class Configuration {
    private config: AppSettings = devConfig;
    private configLoaded: boolean = false;

    getValue(key: keyof AppSettings) {
        if (!this.configLoaded) this.loadConfig();

        return this.config[key];
    }

    loadConfig() {
        let partialConfig: Partial<AppSettings> = {
            baseUrl: process.env.PUBLIC_URL,
            brandName: process.env.REACT_APP_PRODUCT_NAME,
            productName: process.env.REACT_APP_PRODUCT_NAME,
            productAbbrName: process.env.REACT_APP_ABBR_PRODUCT_NAME,
        };
        switch (process.env.REACT_APP_CONFIG) {
            case "development":
                partialConfig = { ...development, ...partialConfig };
                break;
            case "carchat24_test":
                partialConfig = { ...carchat24Test, ...partialConfig };
                break;
            case "carchat24_live":
                partialConfig = { ...carchat24Live, ...partialConfig };
                break;
            case "boatchat_test":
                partialConfig = { ...boatchatTest, ...partialConfig };
                break;
            case "boatchat_live":
                partialConfig = { ...boatchatLive, ...partialConfig };
                break;
            case "rvchat_test":
                partialConfig = { ...rvchatTest, ...partialConfig };
                break;
            case "rvchat_live":
                partialConfig = { ...rvchatLive, ...partialConfig };
                break;
            case "legalchat_test":
                partialConfig = { ...legalchatTest, ...partialConfig };
                break;
            case "legalchat_live":
                partialConfig = { ...legalchatLive, ...partialConfig };
                break;
            case "chatlead_test":
                partialConfig = { ...chatleadTest, ...partialConfig };
                break;
            case "chatlead_live":
                partialConfig = { ...chatleadLive, ...partialConfig };
                break;
            case "servicespro_test":
                partialConfig = { ...servicesProTest, ...partialConfig };
                break;
            case "servicespro_live":
                partialConfig = { ...servicesProLive, ...partialConfig };
                break;
            default:
                partialConfig = { ...development, ...partialConfig };
                break;
        }
        this.config = partialConfig as AppSettings;
        this.configLoaded = true;
    }
}

const configuration = new Configuration();
export default configuration;
