import { TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import clsx from "clsx";
import React, { memo, useRef } from "react";
import { CommonInputProps } from "../../../model/common-props";

interface AutoCompleteData {
    name: string;
    value: string;
}
interface UIAutoCompleteProps extends CommonInputProps {
    onChange?: (evt: AutoCompleteData) => void;
    inputProps?: TextFieldProps;
    autoCompleteProps?: AutocompleteProps<AutoCompleteData, boolean, boolean, boolean>;
    getData: () => Promise<AutoCompleteData[]>;
}

const UIAutoComplete: React.FC<UIAutoCompleteProps> = (props) => {
    let { inputProps } = props;
    const { required, id, name, getData, onChange, errorMessage, autoCompleteProps, disabled } = props;
    if (!inputProps) inputProps = {};
    inputProps.variant = "outlined";
    if (props.label !== undefined) { inputProps.label = props.label; }

    const mounted = useRef<boolean>(false);
    const firstValue = useRef<string>("");
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState<AutoCompleteData[]>([]);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        mounted.current = !mounted.current;
        if (mounted.current) firstValue.current = props.value;
        // logger.log(`auto ${mounted.current}`);
    }, []);
    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const response = await getData();
            if (active) {
                setOptions(response);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const getVal = (val: string | AutoCompleteData | (string | AutoCompleteData)[] | null) => {
        if (val === null) return val;
        if (typeof (val) === "string") return val;
        if ("name" in val) return val.name;
        if (Array.isArray(val) && typeof (val[0]) === "string") return val[0]; // TODO: for multiple, will think later
        if (Array.isArray(val) && (typeof (val[0]) === "object" && "name" in val[0])) return val[0]?.name; // TODO: for multiple, will think later
        return "";
    };

    return (
        <div className={clsx("flex-row", props.className)} style={{ width: "100%", ...props.style }}>
            {
                <Autocomplete
                    {...autoCompleteProps}
                    disabled={disabled ?? false}
                    inputValue={props.value}
                    style={{ width: "100%" }}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    getOptionSelected={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name}
                    options={options}
                    loading={loading}
                    onInputChange={(event, newInputValue, reason) => {
                        // logger.log(`auto1 ${mounted.current} ${newInputValue} ${reason}`);
                        if (onChange && mounted.current && (reason === "input" || reason === "clear")) {
                            onChange({ name: name ?? id ?? "", value: newInputValue });
                        }
                    }}
                    onChange={(event, newInputValue, reason) => {
                        // logger.log(`auto2 ${mounted.current} ${newInputValue} ${reason}`);
                        if (onChange && mounted.current && (reason === "select-option")) {
                            onChange({ name: name ?? id ?? "", value: getVal(newInputValue) ?? "" });
                        }
                    }}
                    // inputValue={inputValue}
                    renderInput={
                        (params) => (<TextField
                            {...params}
                            name={name}
                            required={required}
                            type="text"
                            className="flex-grow"
                            // onChange={e => setInputValue(e.target.value)}
                            error={!!errorMessage}
                            helperText={errorMessage}
                            {...inputProps}
                        />)
                    }
                />
            }
        </div>
    );
};

export default memo(UIAutoComplete);
