import React from "react";
import { createStyles, DialogActions, Fade, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { UIButton, UIDateTimePicker, UIInput, UISelect } from "../../../components/form";
import { _InstructionPriority } from "../../../model/chat-window- notification";
import useNotificationSettings from "./hooks/useNotificationSettings";
import NotificationSettingsTable from "./notification-settings-table";

const useStyles = makeStyles((theme: Theme) => createStyles({
        tbl: {
            height: "calc(73vh - 140px)",
            overflowY: "auto",
        },
}),);

const NotificationSettings: React.FC<any> = (props) => {
    const {
        notificationState, onRowEvent, checked, edRowData, saveNotificationSetup, allSites, selectedSite,
        setSelectedSite, priority, setPriority, getPriority, msgTitle, setMsgTitle, msgBody, setMsgBody,
        startDate, setStartDate, endDate, setEndDate, textColor, setTextColor, backColor, setBackColor, getSiteName, priorityType, handleBack
    } = useNotificationSettings(props.subscriberId);

    const classes = useStyles();
    const formSchema = {
        sites_select: { name: "sites", options: allSites, label: "Select Site(s)", className: "r-m-2 r-m-phone-inv", required: true },
        priority_select: { name: "priority", options: priorityType, label: "Select Priority", className: "r-m-2 r-m-phone-inv", required: true },
        msgTitle: { label: 'Message Title', name: 'msgTitle', className: "r-m-4 r-m-phone-inv", value: msgTitle, required: true },
        msgBody: { label: 'Message Body', name: 'msgBody', className: "r-m-4 r-m-phone-inv", value: msgBody, required: true },
        txtColor: { label: 'Text Color', name: 'txtColor', className: "r-m-4 r-m-phone-inv", value: textColor, required: true },
        backColor: { label: 'Background Color', name: 'backColor', className: "r-m-4 r-m-phone-inv", value: backColor, required: true }
    };

    if (checked) {
        return (
            <Fade in={checked} style={{ transitionDelay: checked ? '100ms' : '0ms' }}>
                <div>
                    {edRowData && allSites.length > 0 &&
                    <form className={clsx(classes.tbl, "flex-grow", "custom-scroll")} autoComplete="off" id="feature-form" onSubmit={(e) => saveNotificationSetup(e)}>
                        <div className="flex-responsive t-m-8 r-m-4 l-m-2">
                            <UISelect {...formSchema.sites_select} value={selectedSite} onChange={(e) => setSelectedSite(`${e.target.value}`)} />
                        </div>
                        <div className="flex-responsive t-m-4 r-m-2 l-m-2">
                            <UIInput {...formSchema.msgTitle} onChange={(e) => setMsgTitle(e.target.value)} />
                        </div>
                        <div className="flex-responsive t-m-4 r-m-2 l-m-2">
                            <UIInput {...formSchema.msgBody} onChange={(e) => setMsgBody(e.target.value)} />
                        </div>
                        <div className="flex-responsive t-m-4 r-m-2 l-m-2">
                            <UIDateTimePicker
                                type="date"
                                className="r-m-2 r-m-phone-inv"
                                value={startDate}
                                views="d-m-y"
                                label="Start Date"
                                format="DD-MM-YYYY"
                                onChange={(date) => { setStartDate(new Date(date ?? Date.now()).toLocaleString()); }}
                            />

                            <UIDateTimePicker
                                type="date"
                                className="r-m-2 r-m-phone-inv"
                                value={endDate}
                                views="d-m-y"
                                label="End Date"
                                format="DD-MM-YYYY"
                                onChange={(date) => { setEndDate(new Date(date ?? Date.now()).toLocaleString()); }}
                            />

                        </div>
                        <div className="flex-responsive t-m-4 r-m-2 l-m-2">
                            <UISelect {...formSchema.priority_select} value={priority} onChange={(e) => setPriority(`${e.target.value}`)} />
                            <UIInput {...formSchema.txtColor} onChange={(e) => setTextColor(e.target.value)} />
                            <UIInput {...formSchema.backColor} onChange={(e) => setBackColor(e.target.value)} />
                        </div>
                        <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                            <UIButton type="submit" form="feature-form">
                                Save
                            </UIButton>
                            <UIButton color="danger" onClick={() => handleBack()}>
                                Cancel
                            </UIButton>
                        </DialogActions>
                    </form>}
                </div>
            </Fade>
        );
    }

    return (
        <Fade in={!checked}>
            <div>
                {/* {notificationState.notificationData.status === "Pending" ? (
                        <div className="loading flex-column flex-center">
                            <CircularProgress size={40} color="secondary" />
                        </div>
                    ) : ( */}
                <div className={clsx(classes.tbl, "custom-scroll")}>
                    {notificationState.notificationData?.data && <NotificationSettingsTable
                        onRowEvent={onRowEvent}
                        notificationData={notificationState.notificationData.data.map(r => ({
                                id: r.ID,
                                site: getSiteName(r.SiteID),
                                messageTitle: (r.MessageTitle),
                                startDate: (r.StartDate) ? dayjs(r.StartDate).format('DD-MM-YYYY') : "",
                                endDate: (r.EndDate) ? dayjs(r.EndDate).format('DD-MM-YYYY') : "",
                                priority: getPriority(r.Priority),
                                textColor: r.TextColor,
                                backColor: r.Color,
                        }))}
                    />}
                </div>
                {/* )} */}
                <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                    {/* <UIButton onClick={()=>setChecked(true)}>
                                Add
                            </UIButton> */}
                    <UIButton color="danger" onClick={props.handleClose}>
                        Close
                    </UIButton>
                </DialogActions>
            </div>
        </Fade>
    );
};

export default NotificationSettings;
