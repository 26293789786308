import { useEffect, useState } from "react";
import appToast from "../../../../components/app-toast";
import uniLoader from "../../../../components/uni-loader";
import { ApiData } from "../../../../model/xhrCallState";
import httpService from "../../../../service/http-service";
import logger from "../../../../utils/log-utils";

export default function useResellerSettings() {
    interface ResellerData {
        label: string;
        value: string;
    }
    const [status, setStatus] = useState<ApiData<any>>();
    const [data, setData] = useState<ResellerData[]>([]);

    useEffect(() => {
        if (status?.status === "Pending") uniLoader.showLoader(''); else uniLoader.close();
    }, [status]);

    const getAllResellers = async () => {
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            const response = await httpService.get(`Subscriber/GetResellerHeads`);

            // logger.log("getAllResellers");
            // logger.log(response.data)
            // appToast.success('Get All Groups succeeded.');
            const data: ResellerData[] = [];
            Object.entries<string>(response.data).map(
                ([key, value]) => data.push({ label: value, value: key })
            );
            setData(data);
            // logger.log(data);
            logger.log("getAllResellers: completed");
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    const setAsResellerHead = async (subId: any) => {
        // logger.log("setAsResellerHead");
        // logger.log(`SetAsResellerHead: SubscriberId: ${subId}`);
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            await httpService.post<any>(`Subscriber/SetAsResellerHead?subscriberId=${subId}&isReseller=${true}`, null);
            // logger.log(response.data);
            // logger.log("setAsResellerHead: Completed");
            appToast.success('Subscriber has been set as reseller head');
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            appToast.error('Set reseller head unsuccessful');
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    const SetAsReseller = async (subId: any, resellerId: any) => {
        // logger.log("SetAsReseller");
        // logger.log(`SetAsReseller: SubscriberId: ${subId}`);
        // logger.log(`SetAsReseller: rslrId: ${resellerId}`);
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            await httpService.post<any>(`Subscriber/SetAsReseller?subscriberId=${subId}&resellerId=${resellerId}`, null);
            // logger.log(response.data);
            // logger.log("SetAsReseller: Completed");
            appToast.success('Subscriber has been set as reseller member');
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            appToast.error('Set group member unsuccessful');
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    const unsetAsReseller = async (subId: any, isResellerHead: any) => {
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            await httpService.post<any>(`Subscriber/UnSetAsReseller?subscriberId=${subId}&isSalesAgent=${isResellerHead}`, null);
            appToast.success('Subscriber has been unset as reseller head');
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            appToast.error('Unset as reseller head unsuccessful');
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    useEffect(() => {
        getAllResellers();
    }, []);

    return { status, data, setAsResellerHead, SetAsReseller, unsetAsReseller };
}
