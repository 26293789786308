import React from "react";
import { Redirect } from "react-router-dom";
import { defineAuthedRoute, defineInvisibleRoute, definePublicRoute } from "../../utils/route-utils";
import Login from "../../pages/login";
import { RouteConfig } from "../../model/route-config";
import { DashboardLayout } from "../../layouts/dashbaord-layout";
import ProcessPayment from "../../pages/process-payment";
import ChatTranscript from "../../pages/chat-transcript";
import configuration from "../../utils/configuration";
import ChatleadIconDemo from "../../pages/chatlead-icon-demo";
import AgreementTranscript from "../../pages/agreement-transcript";

const Routes: RouteConfig[] = [
    defineAuthedRoute(
        defineInvisibleRoute({
            name: "redirect",
            path: "/",
            exact: true,
            render: () => <Redirect to="/dashboard" />,
        })
    ),
    defineInvisibleRoute({
        name: "Login",
        title: "Login",
        path: "/login",
        component: Login,
        nonAuthRequired: true,
    }),
    definePublicRoute(
        defineInvisibleRoute({
            name: "Chat Transcript",
            title: "Chat Transcript",
            path: "/chat-transcript",
            component: ChatTranscript,
        })
    ),
    defineAuthedRoute(
        defineInvisibleRoute({
            name: "Agreement Transcript",
            title: "Agreement Transcript",
            path: "/agreement-transcript",
            component: AgreementTranscript,
            nonAuthRequired: true,
        })
    ),

    defineAuthedRoute(
        defineInvisibleRoute({
            name: "Dashboard",
            path: "/dashboard",
            title: "Dashboard",
            component: DashboardLayout,
        })
    ),
    defineAuthedRoute(
        defineInvisibleRoute({
            name: "Payment Process",
            path: "/processPayment",
            title: "Payment | Don't close or refresh browser window",
            component: ProcessPayment,
        })
    ),
];

if (configuration.getValue("environment") === "development" || configuration.getValue("environment").includes("test")) {
    Routes.push(
        defineInvisibleRoute({
            name: "icons",
            title: "Icons",
            path: "/icons",
            exact: true,
            component: ChatleadIconDemo,
            // nonAuthRequired: true,
        })
    );
}

export { Routes };
