import { DialogActions, InputAdornment } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { UIButton, UIInput } from "../../../components/form";
import useCCAFLink from "./hooks/useCCAFLink";

const CCAFLink: React.FunctionComponent<any> = (props) => {
    const { handleClose, subscriberId } = props;
    const { ccafData, saveCCInfo, getInitialValues, validationSchema, url } = useCCAFLink(subscriberId);

    if (url) {
        return (
            <>
                <small>Generate CCAF Link</small>
                <div className="flex-row flex-center justify-center t-m-8 r-m-2 l-m-2">
                    <a href={url} target="_blank" rel="noreferrer">
                        <UIButton variant="text">
                            Go to the CCAF Link
                        </UIButton>
                    </a>
                </div>
                <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                    <UIButton onClick={() => { navigator.clipboard.writeText(url); }}>
                        Copy To clipboard
                    </UIButton>
                    <UIButton color="danger" onClick={handleClose}>
                        Close
                    </UIButton>
                </DialogActions>
            </>
        );
    }

    return (
        <>
            <Formik
                initialValues={getInitialValues()}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={saveCCInfo}
            >
                {
                    (
                        {
                            values,
                            errors,
                            handleChange,
                            handleSubmit,
                        }
                    ) => {
                        const formSchema = {
                            setup: { label: 'Setup Fee', name: 'setupFee', className: "r-m-4 r-m-phone-inv t-m-3", disabled: ccafData?.status === "Pending", value: values?.setupFee || "", required: true, errorMessage: errors?.setupFee },
                            fixed: { label: 'Fixed Monthly Fees', name: 'fixedFee', className: "r-m-4 r-m-phone-inv t-m-3", disabled: ccafData?.status === "Pending", value: values?.fixedFee || "", required: true, errorMessage: errors?.fixedFee },
                            variable: { label: 'Variable Monthly Fees', helperText: "Maximum Authorized Recurring Variable Monthly Fees", disabled: ccafData?.status === "Pending", name: 'variableFee', className: "r-m-4 r-m-phone-inv t-m-3", value: values?.variableFee || "", required: true, errorMessage: errors?.variableFee },
                        };
                        return <form onSubmit={handleSubmit} id="ccaf-form">
                            <small>Generate CCAF Link</small>
                            <div className="flex-responsive t-m-1 r-m-2 l-m-2">
                                <UIInput
                                    onChange={handleChange}
                                    inputProps={
                                        {
                                            type: "number",
                                            InputProps: {
                                                inputProps: {
                                                    min: 0
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">{ccafData?.data?.currencySymbol || ""}</InputAdornment>
                                                )
                                            }
                                        }
                                    }
                                    {...formSchema.setup}
                                />
                                <UIInput
                                    onChange={handleChange}
                                    inputProps={
                                        {
                                            type: "number",
                                            InputProps: {
                                                inputProps: {
                                                    min: 0
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">{ccafData?.data?.currencySymbol || ""}</InputAdornment>
                                                ),
                                            }
                                        }
                                    }
                                    {...formSchema.fixed}
                                />
                            </div>
                            <div className="flex-responsive t-m-1 r-m-2 l-m-2">
                                <UIInput
                                    onChange={handleChange}
                                    inputProps={
                                        {
                                            type: "number",
                                            InputProps: {
                                                inputProps: {
                                                    min: 0
                                                },
                                                startAdornment: (
                                                    <InputAdornment position="start">{ccafData?.data?.currencySymbol || ""}</InputAdornment>
                                                )
                                            }
                                        }
                                    }
                                    {...formSchema.variable}
                                />
                            </div>
                        </form>;
                    }
                }
            </Formik>
            <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                <UIButton type="submit" form="ccaf-form">
                    Proceed
                </UIButton>
                <UIButton color="danger" onClick={handleClose}>
                    Close
                </UIButton>
            </DialogActions>
        </>
    );
};

export default CCAFLink;
