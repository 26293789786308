import { CircularProgress, Snackbar } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { ObjectSchema, Shape } from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import PublishIcon from '@material-ui/icons/Publish';
// import { Alert } from '@material-ui/lab';
import { CheckCircleRounded } from '@material-ui/icons';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { AgreementCompactData } from '../../../../model/agreement-compact-data';
import { DataLoadState } from '../../../../model/types';
import { ApiData } from '../../../../model/xhrCallState';
import FailedComponent from '../../../failed-component';
import { UIButton, UIDateTimePicker, UIInput, UILoaderButton } from '../../../form';
import UIFileDropZone from '../../../form/inputElements/ui-file-dropzone';
import TabView, { TabPanel } from '../../../tab-view';
import { AgreementFormData } from '../models/agreement-form-data';
import { AgreementTableData } from '../models/response';
import { FormActionType, RowEventType } from '../models/types';
import AgreementTable from './agreement-table';
import useAgreementDialog from '../hooks/useAgreementDialog';

interface AgreementTabProps {
    subscriberId?: string;
}

const Alert = React.forwardRef((props: AlertProps, ref) => {
    return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

const AgreementTab: React.FC<AgreementTabProps> = ({ subscriberId }) => {
    const { iniValues, valSchema, customTimeRangeEnd, agreementTable,
        customTimeRangeStart, getData, tabValue, handleChange,
        formActionType, onRowEventType, extractFileName, handleSnackBarClose, activeDeleteTransId,
        handleSnackBarConfirm, handleFileUpload, fileUpload, handleUploadAgreementFile, saveAgreementFile,
        formikRef, getTabTitle,
        handleButtonClose, fileUploadState } = useAgreementDialog(subscriberId);
    return (
        <>
            <TabView className="max-w-small-desktop hm-m-auto" tabValue={tabValue} onTabValueChange={handleChange} initialTabValue={0} tabHeaders={["Agreement", getTabTitle(formActionType)]} centered roundedCorners>
                {({ value }) => {
                    return [
                        <TabPanel value={value} index={0} key={0}>
                            <div className='t-m-3'>
                                {
                                    agreementTable?.status === "Completed" &&
                                    <AgreementTable
                                        agreementTableData={getData()}
                                        onRowEvent={onRowEventType}
                                    />
                                }
                                {
                                    agreementTable?.status === "Pending" &&
                                    <div className="flex-row flex-center justify-center vm-m-4">
                                        <CircularProgress size={30} color="secondary" />
                                    </div>
                                }
                                {
                                    agreementTable?.status === "Failed" &&
                                    <div className="flex-row flex-center justify-center vm-m-4">
                                        <FailedComponent type='error' text='No Data Found' size='medium' />
                                    </div>
                                }
                            </div>

                        </TabPanel>,
                        <TabPanel value={value} index={1} key={1}>
                            <Formik
                                innerRef={formikRef}
                                initialValues={iniValues}
                                validationSchema={valSchema}
                                enableReinitialize
                                onSubmit={(values, { setFieldValue }) => {
                                    saveAgreementFile(values, setFieldValue);
                                    // console.log(saveAgreementFile)
                                }}

                            >
                                {
                                    ({
                                        values,
                                        errors,
                                        isValid,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                    }) => {
                                        const formSchema = {
                                            title: { name: "title", label: "Title", value: values.title, errorMessage: errors?.title },
                                            fromDate: { type: 'date' as "date" | "time", label: 'From', name: 'fromDate', format: 'MM/DD/YYYY', value: values?.fromDate, errorMessage: errors?.fromDate, maxDate: values?.toDate },
                                            toDate: { type: 'date' as "date" | "time", label: 'To', name: 'toDate', format: 'MM/DD/YYYY', errorMessage: errors?.toDate, value: values?.toDate, minDate: values?.fromDate },
                                            comment: { name: "comment", label: "Comment", value: values.comment, errorMessage: errors?.comment, inputProps: { multiline: true, rows: 5 } },
                                            fileName: { name: "fileName", value: values.fileName }
                                        };
                                        return <>
                                            {(formActionType) &&
                                                <form id='Agreement Controll' onSubmit={handleSubmit}>
                                                    <UIInput {...formSchema.title} onChange={handleChange} className="t-m-5" />
                                                    <div className="t-m-1">
                                                        <UIDateTimePicker {...formSchema.fromDate} onChange={customTimeRangeStart} />
                                                        <UIDateTimePicker {...formSchema.toDate} onChange={customTimeRangeEnd} />
                                                    </div>
                                                    <UIInput {...formSchema.comment} onChange={handleChange} className="t-m-3" />
                                                    <div className='t-m-3'>

                                                        {
                                                            values.fileName ? (
                                                                <div className='agreement-dialog__filetype'>
                                                                    <h3>File Details:</h3>
                                                                    <a href={values.fileName} target='_blank' rel="noreferrer">
                                                                        <p>{extractFileName(values.fileName)}</p>
                                                                    </a>
                                                                </div>
                                                            ) : (
                                                                <UIFileDropZone
                                                                    dropAreaTitle='Upload File'
                                                                    onChange={handleFileUpload}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                    {
                                                        fileUpload &&
                                                        <div className="flex-row">
                                                            <UILoaderButton isLoading={fileUploadState === "Pending"} shouldBeDisabledWhileLoading type='button' onClick={handleUploadAgreementFile(setFieldValue)} buttonProps={fileUploadState !== "Pending" ? { startIcon: <PublishIcon fontSize='small' /> } : undefined}>
                                                                Upload File
                                                            </UILoaderButton>
                                                        </div>
                                                    }
                                                    <div className='flex-row justify-end t-m-6 b-m-3'>
                                                        <div>
                                                            <UIButton type='submit' form='Agreement Controll' disabled={!isValid}>Save</UIButton>
                                                        </div>
                                                        <div className='l-m-2'>
                                                            <UIButton form='Agreement Controll' onClick={handleButtonClose}>Cancel</UIButton>
                                                        </div>
                                                    </div>
                                                </form>}
                                        </>;
                                    }
                                }
                            </Formik>

                        </TabPanel>
                    ];
                }}
            </TabView>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={!!activeDeleteTransId}
                // autoHideDuration={6000}
                onClose={handleSnackBarClose}
            >
                <Alert
                    severity="error"
                    action={
                        <>
                            {/* <UIButton buttonProps={{
                        color: "default", size: "small"
                    }} onClick={handleSnackBarClose}>
                        Yes
                    </UIButton> */}
                            <UIButton variant="icon" size="small" aria-label="close" color="inherit" onClick={handleSnackBarConfirm}>
                                <CheckCircleRounded fontSize="small" />
                            </UIButton>
                            <UIButton variant="icon" size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                                <CloseIcon fontSize="small" />
                            </UIButton>
                        </>
                    }
                >Are you Sure?</Alert>
            </Snackbar>
        </>
    );
};

export default AgreementTab;