import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import { AccountUser } from "../../../model";
import { RouteConfig } from "../../../model/route-config";
import { selectUser } from "../../../store/modules/authentication";
import cc24UserRoutes from "../../../router/routes/cc24-user-routes";
import { subscriberRoutes } from "../../../router/routes/subscriber-routes";
import { RouteGuard } from "../../../model/route-guard";
import { ProductType, SubscriptionType, UserRoles } from "../../../model/types";
import { enableSubscriberView, selectSwitchToSubscriberView } from "../../../store/modules/ui";
import { isAnyCarchat24User } from "../../../utils/account-user-utils";
import { emptyCurrentSubscriberInfo, emptyOrganisation, loadSubscriberInfo, selectSubscriberInfo } from "../../../store/modules/subscriber";
import configuration from "../../../utils/configuration";
import { SubscriberInfoBundleV2 } from "../../../model/subscriber-info-bundle";

const productName: ProductType = configuration.getValue("productName") as ProductType;

export function useDashboardLayout() {
    const dispatch = useDispatch();

    const [subscriberViewBasePath, setSubscriberViewBasePath] = useState('');
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    const { user, loading: userLoading } = useSelector(selectUser);
    const subscriberView = useSelector(selectSwitchToSubscriberView);
    const { currentSubscriber, loading: currentSubscriberLoading } = useSelector(selectSubscriberInfo);

    const getFilteredSubscriberRoutes = useMemo(() => filterRoutes(cloneDeep(subscriberRoutes), user, currentSubscriber), [user, currentSubscriber]);
    const getRoutes = useMemo(() => (user ? getRouteBasedOnUserInformation(user, currentSubscriber) : []), [user, currentSubscriber]);

    const { pathname: currentLocation } = useLocation();

    useEffect(() => {
        const match = matchPath<{ id: string; }>(currentLocation, {
            path: '/dashboard/subscriber/:id'
        });
        // logger.log("effect path")
        if (match && match.params && user) {
            // logger.log("effect path 1")
            if (!currentSubscriber || currentSubscriber.SubscriberId !== match.params.id) {
                dispatch(enableSubscriberView(true));
                dispatch(loadSubscriberInfo(match.params.id));
            }
            setSubscriberViewBasePath(`/dashboard/subscriber/${match.params.id}`);
        } else if (user) {
            // logger.log("effect path 2")
            dispatch(enableSubscriberView(false));
            dispatch(emptyOrganisation());
            dispatch(emptyCurrentSubscriberInfo());
        }
    }, [currentLocation, dispatch, user]);

    return { isMobileNavOpen, setMobileNavOpen, getFilteredSubscriberRoutes, getRoutes, subscriberView, currentSubscriber, subscriberViewBasePath, currentSubscriberLoading, user, userLoading, currentLocation };
}

/* Route Filter */
function getRouteBasedOnUserInformation(user?: AccountUser, currentSub?: SubscriberInfoBundleV2) {
    if (!user) return [];

    if (isAnyCarchat24User(user)) {
        return filterRoutes(cloneDeep(cc24UserRoutes), user, currentSub);
    }
    return filterRoutes(cloneDeep(subscriberRoutes), user, currentSub);
}

function filterRoutes(routes: RouteConfig[], user?: AccountUser, currentSub?: SubscriberInfoBundleV2): RouteConfig[] {
    if (!user) { return []; }

    return routes.filter(({ guard, path }) => {
        return guard ? isProductTypeAllowed(guard, productName) && isUserRoleAllowed(guard, user.role) && (currentSub ? isSubscriptionTypeAllowed(guard, currentSub.SubscriptionCategoryName) : isSubscriptionTypeAllowed(guard, user.subs_type)) : true;
    }).map(item => {
        if (item.routes) {
            item.routes = filterRoutes(item.routes, user, currentSub);
        }
        return item;
    });
}

function isUserRoleAllowed(guard: RouteGuard, role: UserRoles): boolean {
    let status = true;

    if (guard.allowedRoles) {
        status = status && guard.allowedRoles.indexOf(role) > -1;
    }
    if (guard.exceptRoles) {
        status = status && guard.exceptRoles.indexOf(role) < 0;
    }
    return status;
}

function isSubscriptionTypeAllowed(guard: RouteGuard, subscription: SubscriptionType): boolean {
    let status = true;

    if (guard.allowedSubscriptions) {
        status = status && guard.allowedSubscriptions.indexOf(subscription) > -1;
    }
    if (guard.exceptSubscriptions) {
        status = status && guard.exceptSubscriptions.indexOf(subscription) < 0;
    }
    return status;
}
function isProductTypeAllowed(guard: RouteGuard, product: ProductType): boolean {
    let status = true;

    if (guard.allowedProducts) {
        status = status && guard.allowedProducts.indexOf(product) > -1;
    }
    if (guard.exceptProducts) {
        status = status && guard.exceptProducts.indexOf(product) < 0;
    }
    return status;
}
