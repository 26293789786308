import { useEffect, useState } from "react";
import * as yup from 'yup';
import { AdminEmailParams, UpdateAdminInfo } from "../../../../model/Admin-info";
import { ContactType } from "../../../../model/enums/contact-type";
import { ApiData } from "../../../../model/xhrCallState";
import httpService from "../../../../service/http-service";
import logger from "../../../../utils/log-utils";

export default function useGeneralSettings(subId: string) {
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formData, setFormData] = useState<AdminEmailParams | undefined>();
    const [status, setStatus] = useState<ApiData<any>>();
    const [oldUsername, setOldUsername] = useState<string>("");
    const [showCellularProvider, setShowCellularProvider] = useState<boolean>(false);

    const contactTypes = [{ label: 'None', value: `${ContactType.None}` }, { label: 'Email & SMS', value: `${ContactType.Both}` }, { label: 'SMS', value: `${ContactType.SMS}` }, { label: 'Email', value: `${ContactType.Email}` }];
    const cellularProvider = [
        { label: "Select", value: "Select" },
        { label: "AT&T", value: "at&t" },
        { label: "Bell Mobility", value: "bell_mobility" },
        { label: "Boost Mobile", value: "boost_mobile" },
        { label: "Fido", value: "fido" },
        { label: "Kudo Mobile", value: "kudo_mobile" },
        { label: "Metro PCS", value: "metro_pcs" },
        { label: "MTS", value: "mts" },
        { label: "Nextel", value: "nextel" },
        { label: "President's Choice", value: "president's_choice" },
        { label: "Rogers Wireless", value: "rogers_wireless" },
        { label: "Sasktel", value: "sasktel" },
        { label: "Solo", value: "solo" },
        { label: "Sprint", value: "sprint" },
        { label: "Sprint PCS", value: "sprint_pcs" },
        { label: "Telus", value: "telus" },
        { label: "T-Mobile", value: "t-mobile" },
        { label: "Verizon", value: "verizon" },
        { label: "Virgin", value: "virgin" },
        { label: "Virgin Mobile", value: "virgin_mobile" }
    ];

    const getAdminInfo = async () => {
        setFormSubmitting(true);
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            const response = await httpService.get(`Subscriber/GetExistingAdminDetails?subscriberId=${subId}`);
            // logger.log(JSON.parse(JSON.stringify(response.data)));
            // logger.log(response.data);
            setOldUsername(response.data.subscriberActor.Contact.EmailID1 ?? "");
            // logger.log(response.data.subscriberActor.Contact.EmailID1);
            // appToast.success('Old Admin Info succeeded.');
            // setData(
            //     Object.entries(response.data).map(
            //         ([key, value]) => new groupData({label: value, value: key})
            //     )
            // );
            setStatus((s) => ({ ...s, status: "Completed" }));
            setFormSubmitting(false);
        } catch (error) {
            setFormSubmitting(false);
            logger.log(error);
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    const UpdateAdminEmail = async (adminEmail: AdminEmailParams) => {
        logger.log(`updateAdmin Hit: data --   ${JSON.stringify(adminEmail)}`);
        const response = await httpService.post('Subscriber/UpdateAdminEmail', adminEmail);
        return response;
    };

    useEffect(() => {
        getAdminInfo();
    }, []);

    const getInitialValues = () => {
        // let initValues: AdminInfo = {
        //     SubscriberID: formData?.SubscriberID ?? "",
        //     oldUserName: formData?.oldUserName ?? "",
        //     newUserName: formData?.newUserName ?? "",
        //     firstName: formData?.firstName ?? "",
        //     lastName: formData?.lastName ?? "",
        //     newPassword: formData?.newPassword ?? "",
        //     contact: formData?.contact,
        //     SalesLead: formData?.SalesLead ?? false,
        //     SalesLeadContactType: formData?.SalesLeadContactType ?? ContactType.None,
        //     SalesQuery: formData?.SalesQuery ?? false,
        //     SalesQueryContactType: formData?.SalesQueryContactType,
        //     SalesService: formData?.SalesService ?? false,
        //     SalesServiceContactType: formData?.SalesServiceContactType ?? ContactType.None,
        //     PartsService: formData?.PartsService ?? false,
        //     PartsServiceContactType: formData?.SalesServiceContactType ?? ContactType.None,
        //     BodyShopService: formData?.BodyShopService ?? false,
        //     BodyShopServiceContactType: formData?.SalesServiceContactType ?? ContactType.None
        // }

        // let initValues: UpdateAdminInfo = {
        //     firstName: formData?.firstName ?? "",
        //     lastName: formData?.lastName ?? "",
        //     email: formData?.oldUserName ?? "",
        //     password: formData?.newPassword ?? "",
        //     confirmPassword: formData?.newPassword ?? "",
        //     adminPhone: formData?.contact?.Phone1 ?? "",
        //     extension: "",
        //     adminMobile: formData?.contact?.Mobile1 ?? "",
        //     salesLeads: formData?.SalesLead ?? false,
        //     serviceLeads: formData?.SalesService ?? false,
        //     partsLeads: formData?.PartsService ?? false,
        //     contactType: formData?.SalesLeadContactType ?? ContactType.None
        // }

        const initValues: UpdateAdminInfo = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            adminPhone: "",
            extension: "",
            adminMobile: "",
            salesLeads: false,
            serviceLeads: false,
            partsLeads: false,
            contactType: ContactType.None,
            cellularProvider: ""
        };

        return initValues;
    };

    const getValidationSchema = () => {
        // let validationSchema = yup.object();
        // validationSchema = yup.object().shape({
        //     SubscriberID: yup.string().uuid().required("SubscriberId is required").notOneOf([EMPTY_GUID], "Select one of the given options"),
        //     firstName: yup.string().required("Firstname is required"),
        //     lastName: yup.string().required("Lastname is required"),
        //     newUserName: yup.string().email().required("Email is a required field"),
        //     password: yup.string().required("Password is a required field"),
        //     confirmPassword: yup.string().required("Confirm password is a required field"),
        //     adminPhone: yup.string(),
        //     extension: yup.string(),
        //     adminMobile: yup.string(),
        //     titleType: yup.string().oneOf(Object.values(contactDesignationType).map(v => v.value)),

        //     salesLead: yup.boolean(),
        //     serviceLeads: yup.boolean(),
        //     partsLeads: yup.boolean(),
        //     gender: yup.string().oneOf([`${Gender.Any}`, `${Gender.Male}`, `${Gender.Female}`]),
        //     userGroupId: yup.string().uuid().required("User Group is required").notOneOf([EMPTY_GUID], "Select one of the given options"),

        // });
        let validationSchema = yup.object();
        validationSchema = yup.object().shape({
            firstName: yup.string().required("Firstname is required"),
            lastName: yup.string().required("Lastname is required"),
            email: yup.string().email().required("Email is a required field"),
            password: yup.string().required("Password is a required field"),
            confirmPassword: yup.string().required("Confirm Password is a required field")
                .oneOf([yup.ref('password')], 'Passwords must match'),
            adminPhone: yup.string(),
            extension: yup.string(),
            adminMobile: yup.string(),
            salesLeads: yup.boolean(),
            serviceLeads: yup.boolean(),
            partsLeads: yup.boolean(),
            contactType: yup.string().oneOf([`${ContactType.None}`, `${ContactType.Email}`, `${ContactType.SMS}`, `${ContactType.Both}`]),
            cellularProvider: yup.string()
        });
        return validationSchema;
    };

    return {
        formData,
        setFormData,
        status,
        formSubmitting,
        contactTypes,
        cellularProvider,
        oldUsername,
        showCellularProvider,
        setShowCellularProvider,
        setFormSubmitting,
        getInitialValues,
        getValidationSchema,
        UpdateAdminEmail
    };
}
