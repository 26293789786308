import { CircularProgress } from "@material-ui/core";
import * as React from "react";

export interface LoadingProps {
    show?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ show = true }) => {
    return (
        <div className="flex-row flex-grow" style={{ height: '100%' }}>
            <div className="flex-column flex-grow justify-center flex-center">
                <CircularProgress />
            </div>
        </div>
    );
};

export default Loading;
