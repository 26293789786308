// Note: These routes are different from the open routes
// These routes can't be open if the user is authed.
export const NonAuthRoutes = {
    login: `/login`,
    register: `/register`,
    chatTranscript: `/chat-transcript`,
    agreementTranscript: `/agreement-transcript`,
};

export const AuthedRoutes = {
    salesportalManage: "/dashboard/sales-portal/sales-portal-manage",
    home: `/dashboard`,
    manageSaas: `/dashboard/subscriber`,
    profile: `/dashboard/profile`
};

export const SubscriberRoutes = {
    organization: "/account/organization",
    contacts: "/account/contacts",
    billing: "/account/billing"
};
