import { CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import * as React from "react";

export interface ColorCardProps {
  type: ColorCardType;
  value: string;
  background?: string;
  error?: string;
  isLoading?: boolean;
}

const ColorCard: React.FC<ColorCardProps> = (props) => {
    const config: { [x: string]: { class: string; label: string } } = {
    Visitor: { class: "visitor", label: "Website Visitors" },
    WebChat: { class: "web-chat", label: "Web Chats" },
    TextChat: { class: "text-chat", label: "Text Chats" },
    FacebookChat: { class: "facebook-chat", label: "Facebook Chats" },
    Operator: { class: "live-operator", label: "Live Operators" },
    };

    return (
        <div
            className={clsx(
              "color-card flex-column justify-center",
              "r-m-2",
              "l-m-2",
              "t-m-2",
              "b-m-2",
              config[props.type]?.class
            )}
        >
            <img className="bg" src={props.background} alt="Decorative BG" />
            <div className="label">{config[props.type]?.label}</div>
            {
                props.isLoading && <div className="flex-row justify-center flex-center"> <CircularProgress color="secondary" size={25} /></div>
            }
            {
                (!props.isLoading && props.value) &&
                <div className="value">{props.value}</div>
            }
            {
                (!props.isLoading && !props.value) &&
                <div className="value">{props.error}</div>
            }
        </div>
    );
};

export default ColorCard;

type ColorCardType =
  | "Visitor"
  | "WebChat"
  | "TextChat"
  | "FacebookChat"
  | "Operator";
