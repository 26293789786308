import configuration from './configuration';
/**
 * getEnvironment - Returns the current environment, or development by default
 * @returns {String}
 */
export const getEnvironment = () => (process.env.NODE_ENV
    ? process.env.NODE_ENV
    : 'development');

/**
 * getBackendUrl  - Returns the URL for the backend, given the current environment
 * @returns {String}
 */
export const getBackendUrl = (): string => {
    return configuration.getValue("backend");
};

/**
 * getAppUrl  - Returns the URL for the app, given the environment
 * @returns {String}
 */
export const getAppUrl = () => {

};

export const isProduction = () => {
    return configuration.getValue("environment") === "live";
};

export const isCarChat24 = configuration.getValue("productName") === "CarChat24";
export const isBoatChat = configuration.getValue("productName") === "BoatChat";
export const isRVChat = configuration.getValue("productName") === "RVChat";
export const isLegalChat = configuration.getValue("productName") === "LegalChat";
export const isServicesPro = configuration.getValue("productName") === "ServicesPro";
