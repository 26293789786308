import * as React from "react";
import { CircularProgress, createStyles, makeStyles, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import clsx from "clsx";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { PublicLayout } from "../../layouts/public-layout";
import { RouteAdditionalPropsV2 } from "../../model/route-additional-propsV2";
import { useLogin } from "./hooks/useLogin";
import cc24GrayLogo from "../../assets/images/carchat24/cc24_logo_gray.svg";
import cc24WhiteLogo from "../../assets/images/carchat24/cc24_logo_white.svg";
import bcGrayLogo from "../../assets/images/boatchat/bc_logo_gray.svg";
import bcWhiteLogo from "../../assets/images/boatchat/bc_logo_white.svg";
import lcGrayLogo from "../../assets/images/legalchat/lc_logo.svg";
// import lcWhiteLogo from "../../assets/images/legalchat/lc_logo.svg";
import rvcGrayLogo from "../../assets/images/rvchat/rv_logo_gray.svg";
import rvcWhiteLogo from "../../assets/images/rvchat/rv_logo_white.svg";
import clGrayLogo from "../../assets/images/chatlead/cl_logo_gray.svg";
import clWhiteLogo from "../../assets/images/chatlead/cl_logo_white.svg";
import spGrayLogo from "../../assets/images/servicespro/sp_logo_gray.svg";
import spWhiteLogo from "../../assets/images/servicespro/sp_logo_white.svg";
// import rightArrow from "../../assets/images/login/right-arrow.svg";
import appStore from "../../assets/images/login/app-store.png";
import googlePlay from "../../assets/images/login/google-play.png";
import trustySeal from "../../assets/images/seal.svg";
import { selectAuth } from "../../store/modules/authentication";
import '../../styles/pages/resolve-url-loader-fix.css';
import configuration from "../../utils/configuration";
// import appToast from "../../components/app-toast";
import { UIButton, UIInput } from "../../components/form";
import theme from "../../theme";
import ChatleadIcon from "../../components/chatlead-icon";
import Recaptcha from "../../components/recaptcha";

const useStyles = makeStyles((theme) => createStyles({
    notchedOutline: {
        // borderWidth: "1px",
        borderColor: "white !important",
        color: "white !important",
    },
    input: {
        color: "white !important",
        "&:-webkit-autofill": {
            boxShadow: configuration.getValue("productName") === "LegalChat" ? "0 0 0 1000px rgba(255, 118, 0, 0.63) inset !important" : "0 0 0 1000px rgba(0, 88, 255, 0.63) inset !important",
            textFillColor: "white"
        }
    },
    label: {
        color: "white !important",
    }
}));

export interface LoginProps extends RouteAdditionalPropsV2 { }

const Login: React.FC<LoginProps> = (props) => {
    const { onFormSubmit, handleChange,
        loginState, recaptchaRef, getForgotPasswordLink,
        recaptchaToken, isCaptchaLoading, onFeedbackSubmissionComplete,
        showPass, onCaptchaReady, onTokenGenerated, setShowPass } = useLogin();

    const { loading } = useSelector(selectAuth);
    const product = configuration.getValue("productName");
    const server = configuration.getValue("server");
    const mainSite = configuration.getValue("mainSite");
    const androidAppLink = configuration.getValue("androidAppLink");
    const iphoneAppLink = configuration.getValue("iphoneAppLink");

    const isTab = useMediaQuery(theme.breakpoints.down("sm"));

    const classes = useStyles();

    const inputProps = isTab ? {
        InputProps: {
            classes: {
                notchedOutline: classes.notchedOutline,
                input: classes.input
            }
        },
        InputLabelProps: {
            classes: {
                root: classes.label
            }
        }
    } : undefined;

    const getWhiteLogo = () => {
        switch (product) {
            case "CarChat24":
                return cc24WhiteLogo;
            case "BoatChat":
                return bcWhiteLogo;
            case "LegalChat":
                return lcGrayLogo;
            case "RVChat":
                return rvcWhiteLogo;
            case "ChatLead":
                return clWhiteLogo;
            case "ServicesPro":
                return spWhiteLogo;
            default:
                return "";
        }
    };
    const getGreyLogo = () => {
        switch (product) {
            case "CarChat24":
                return cc24GrayLogo;
            case "BoatChat":
                return bcGrayLogo;
            case "LegalChat":
                return lcGrayLogo;
            case "RVChat":
                return rvcGrayLogo;
            case "ChatLead":
                return clGrayLogo;
            case "ServicesPro":
                return spGrayLogo;
            default:
                return "";
        }
    };
    const getTitle = () => {
        switch (product) {
            case "CarChat24":
                return "Live Chat Support Services & Software for Automotive Dealers";
            case "BoatChat":
                return "Live Chat Software and Support Services for Boat Dealership Websites";
            case "LegalChat":
                return "Live Chat Software and Support Services for Law Firm Websites";
            case "RVChat":
                return "Live Chat Support Services and Software for RV Dealership Websites";
            case "ChatLead":
                return "ChatLead Managed Live Chat Support Services and Software";
            case "ServicesPro":
                return "Complete Solutions for Local Businesses";
            default:
                return "";
        }
    };

    return (
        <PublicLayout recaptchaToken={recaptchaToken} isCaptchaLoading={isCaptchaLoading} onFeedbackSubmissionComplete={onFeedbackSubmissionComplete} {...props}>
            <div className="login">
                {loginState.errorText && (
                    <div className="login__error">{loginState.errorText}</div>
                )}
                <div className={clsx("login__left-sec", product.toLowerCase())}>
                    <h1>{getTitle()}</h1>
                </div>
                <div className="login__right-sec">
                    <div className="login__form-wrapper">

                        <form className="login__form" method="post" onSubmit={onFormSubmit}>
                            <div className="login__form__inputs">
                                <a
                                    className="login__logo"
                                    href={mainSite}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="desktop-logo"
                                        src={getGreyLogo()}
                                        alt={product}
                                    />
                                    <img className="mob-logo" src={getWhiteLogo()} alt={product} />
                                </a>
                                <UIInput
                                    // asp-validation-for="txtUsername"
                                    className="login-field b-m-4"
                                    type="text"
                                    id="txtUsername"
                                    name="username"
                                    value={loginState.formData.username}
                                    // @ts-ignore
                                    inputProps={inputProps}
                                    label="Username"
                                    onChange={handleChange}
                                />
                                {/* <span style="padding-left: 10px; font-size:11px; position: absolute; margin-left: 2%; bottom: -18px; color:red;" id="errUsername" className="err">Please enter a valid username</span> */}

                                <UIInput
                                    className="login-field"
                                    type={showPass ? "text" : "password"}
                                    id="txtPassword"
                                    name="password"
                                    label="Password"
                                    // @ts-ignore
                                    inputProps={{
                                        InputProps: {
                                            ...inputProps?.InputProps,
                                            endAdornment: <UIButton className="l-m-3" variant="icon" size="small" onClick={() => setShowPass(!showPass)}><ChatleadIcon color={isTab ? "white" : "inherit"} icon={!showPass ? "view" : "noView"} size={20} /></UIButton>
                                        },
                                        InputLabelProps: inputProps?.InputLabelProps,
                                    }}
                                    value={loginState.formData.password}
                                    onChange={handleChange}
                                />
                            </div>
                            {/* <span style="padding-left: 10px; font-size:11px; position: absolute; margin-left: 2%; bottom: -18px; color:red;" id="errPassword" className="err">Please enter a valid password</span> */}
                            <a
                                className="no-link-decor"
                                href={getForgotPasswordLink()}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <UIButton
                                    className="btn-forgot-pass t-m-2"
                                    variant="text"
                                    size="small"
                                >
                                    Forgot Password?
                                </UIButton>
                            </a>
                            <div className="login-action">
                                <button
                                    type="submit"
                                    id="btnsubmit"
                                    className="login__btn"
                                    data-testid="loginsubmitbtn"
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <CircularProgress color="inherit" />
                                    ) : (
                                        // <img src={rightArrow} alt="Login" />
                                        <ArrowForwardIcon fontSize="small" htmlColor="inherit" />
                                    )}
                                </button>
                            </div>
                            <Recaptcha ref={recaptchaRef} onReady={onCaptchaReady} onTokenGenerated={onTokenGenerated} />
                        </form>
                        {
                            product !== "ChatLead" &&
                            <div className="links">
                                <a
                                    className=""
                                    href={`${server}/Console`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Go to our Console <em>here</em>
                                </a>
                                <div style={{ height: "2vh" }} />

                                {
                                    product !== "ServicesPro" &&
                                    <>
                                        <p>Download our app</p>
                                        <div style={{ height: "2vh" }} />

                                        <div className="app-link-holder flex-row">
                                            <a
                                                className="app-link"
                                                href={iphoneAppLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src={appStore} alt="Apple App store" />
                                            </a>
                                            <a
                                                className="app-link"
                                                href={androidAppLink}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <img src={googlePlay} alt="Google play store" />
                                            </a>
                                        </div>
                                    </>
                                }

                            </div>
                        }
                    </div>

                    <div className="privacy-section flex-row space-between">
                        <a
                            className="privacy"
                            href={`${mainSite}/eula-privacy-policy/`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Privacy Policy
                        </a>
                        {/* <a
                            className="truste"
                            href="https://privacy.truste.com/privacy-seal/validation?rid=1f609957-674d-47e5-a7e4-1380574c6368"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={trustySeal} alt="Trusty" />
                        </a> */}
                    </div>

                </div>
            </div>
        </PublicLayout>
    );
};

export default Login;
