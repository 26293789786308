import { MinimalSubscriberData } from "../model/minimal-subscriber-data";
// import { SubscriptionDetail } from "../model/subscriber-subscription-details";
import SubscriberSearchWorker from './subscriber-search';
import SubscriptionDataWorker from './subscription-data-processor';
import ScriptedResponseSearchWorker from './scripted-response-search';
import RemoteLoggerWorker from './remote-logger';

const workerRegistry: Workers = {};

interface Workers {
    subscriberSearchWorker?: SubscriberSearchWorker;
    subscriptionDataWorker?: SubscriptionDataWorker;
    scriptedResponseSearch?: ScriptedResponseSearchWorker;
    remoteLogger?: RemoteLoggerWorker;
}

export function InitSubscriberSearchWorker(subscriberList: MinimalSubscriberData[], performanceManager?: string) {
    if (workerRegistry.subscriberSearchWorker) workerRegistry.subscriberSearchWorker.terminate();

    const subscriberSearch = new SubscriberSearchWorker();
    subscriberSearch.initRepos(subscriberList, performanceManager);
    workerRegistry.subscriberSearchWorker = subscriberSearch;
}

export function InitSubscriptionDataWorker() {
    if (workerRegistry.subscriptionDataWorker) workerRegistry.subscriptionDataWorker.terminate();

    const subscriptionDataProcessor = new SubscriptionDataWorker();
    // subscriptionDataProcessor.getGroupedSubscriptionData(subDetails);
    workerRegistry.subscriptionDataWorker = subscriptionDataProcessor;
}

export function InitScriptedResponseSearch() {
    if (workerRegistry.scriptedResponseSearch) workerRegistry.scriptedResponseSearch.terminate();

    const scriptedResponseDataProcessor = new ScriptedResponseSearchWorker();

    workerRegistry.scriptedResponseSearch = scriptedResponseDataProcessor;
}

export function InitRemoteLogger() {
    if (workerRegistry.remoteLogger) workerRegistry.remoteLogger.terminate();

    const remoteLogger = new RemoteLoggerWorker();

    workerRegistry.remoteLogger = remoteLogger;
}

export function getWorkerInstance(workerName: keyof Workers) {
    return workerRegistry[workerName];
}
