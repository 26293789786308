import { CircularProgress, Snackbar } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { ObjectSchema, Shape } from 'yup';
import CloseIcon from '@material-ui/icons/Close';
import PublishIcon from '@material-ui/icons/Publish';
// import { Alert } from '@material-ui/lab';
import { CheckCircleRounded } from '@material-ui/icons';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import FailedComponent from '../../../components/failed-component';
import { UIButton, UICheckbox, UIDateTimePicker, UIInput, UILoaderButton } from '../../../components/form';
import UIFileDropZone from '../../../components/form/inputElements/ui-file-dropzone';
import TabView, { TabPanel } from '../../../components/tab-view';
import NotesTable from './notes-table';
import useNotesDialog from './hooks/useNotesTabs';
import logger from '../../../utils/log-utils';

interface NotesTabProps {
    subscriberId?: string;
    isGroup?: boolean;
    subscriberName?: string;
}

const Alert = React.forwardRef((props: AlertProps, ref) => {
    return <MuiAlert elevation={6} variant="filled" ref={ref} {...props} />;
});

const NotesTab: React.FC<NotesTabProps> = ({ subscriberId, isGroup, subscriberName }) => {
    const { iniValues, valSchema, customTimeRangeEnd, notesTable,
        customTimeRangeStart, getData, tabValue, handleChange,
        formActionType, onRowEventType, extractFileName, handleSnackBarClose, activeDeleteTransId,
        handleSnackBarConfirm, handleFileUpload, fileUpload, handleUploadNotesFile, saveNotesFile,
        formikRef, getTabTitle,
        handleButtonClose, fileUploadState, onCheckChange } = useNotesDialog(subscriberId, subscriberName);
    return (
        <>
            <TabView className="max-w-small-desktop hm-m-auto" tabValue={tabValue} onTabValueChange={handleChange} initialTabValue={0} tabHeaders={["Notes", getTabTitle(formActionType)]} centered roundedCorners>
                {({ value }) => {
                    return [
                        <TabPanel value={value} index={0} key={0}>
                            <div className='t-m-3'>
                                {
                                    notesTable?.status === "Pending" &&
                                    <div className="flex-row flex-center justify-center vm-m-4">
                                        <CircularProgress size={30} color="secondary" />
                                    </div>
                                }
                                {
                                    notesTable?.status === "Completed" &&
                                    <NotesTable tableData={getData()} onRowEvent={onRowEventType} />
                                }
                                {
                                    notesTable?.status === "Failed" &&
                                    <div className="flex-row flex-center justify-center vm-m-4">
                                        <FailedComponent type='error' text='No Data Found' size='medium' />
                                    </div>
                                }
                            </div>

                        </TabPanel>,
                        <TabPanel value={value} index={1} key={1}>
                            <Formik
                                innerRef={formikRef}
                                initialValues={iniValues}
                                validationSchema={valSchema}
                                enableReinitialize
                                onSubmit={(values, { setFieldValue }) => {
                                    saveNotesFile(values, setFieldValue);
                                    logger.log(saveNotesFile);
                                }}

                            >
                                {
                                    ({
                                        values,
                                        errors,
                                        isValid,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                    }) => {
                                        const formSchema = {
                                            notes: { name: "notes", label: "Notes", value: values.notes, errorMessage: errors?.notes, inputProps: { multiline: true, rows: 5 } },
                                            alertDate: { type: 'date' as "date" | "time", label: 'Alert Date', name: 'alertDate', format: 'MM/DD/YYYY', value: values?.alertDate, errorMessage: errors?.alertDate },
                                            // toDate: { type: 'date' as "date" | "time", label: 'To', name: 'toDate', format: 'MM/DD/YYYY', errorMessage: errors?.toDate, value: values?.toDate, minDate: values?.fromDate },
                                            fileName: { name: "fileName", value: values.fileName },
                                            forAll: { name: 'forAll', label: 'For All Group Members?', value: values.forAll, checked: values.forAll }
                                        };
                                        return <>
                                            {(formActionType) &&
                                                <form id='Notes Control' onSubmit={handleSubmit}>
                                                    <UIInput {...formSchema.notes} onChange={handleChange} className="t-m-5" />
                                                    <div className="t-m-1">
                                                        <UIDateTimePicker {...formSchema.alertDate} onChange={customTimeRangeStart} />
                                                        {/* <UIDateTimePicker {...formSchema.toDate} onChange={customTimeRangeEnd} /> */}
                                                    </div>
                                                    {
                                                        isGroup &&
                                                        <UICheckbox {...formSchema.forAll} onChange={onCheckChange(setFieldValue)} />
                                                    }
                                                    <div className='t-m-3'>

                                                        {
                                                            values.fileName ? (
                                                                <div className='agreement-dialog__filetype'>
                                                                    <h3>File Details:</h3>
                                                                    <a href={values.fileName} target='_blank' rel="noreferrer">
                                                                        <p>{extractFileName(values.fileName)}</p>
                                                                    </a>
                                                                </div>
                                                            ) : (
                                                                <UIFileDropZone
                                                                    dropAreaTitle='Upload File'
                                                                    onChange={handleFileUpload}
                                                                />
                                                            )
                                                        }
                                                    </div>
                                                    {
                                                        fileUpload &&
                                                        <div className="flex-row">
                                                            <UILoaderButton isLoading={fileUploadState === "Pending"} shouldBeDisabledWhileLoading type='button' onClick={handleUploadNotesFile(setFieldValue)} buttonProps={fileUploadState !== "Pending" ? { startIcon: <PublishIcon fontSize='small' /> } : undefined}>
                                                                Upload File
                                                            </UILoaderButton>
                                                        </div>
                                                    }
                                                    <div className='flex-row t-m-3 justify-end'>
                                                        <div>
                                                            <UIButton type='submit' form='Notes Control' disabled={!isValid}>Save</UIButton>
                                                        </div>
                                                        <div className='l-m-2'>
                                                            <UIButton onClick={handleButtonClose}>Cancel</UIButton>
                                                        </div>
                                                    </div>
                                                </form>}
                                        </>;
                                    }
                                }
                            </Formik>

                        </TabPanel>
                    ];
                }}
            </TabView>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={!!activeDeleteTransId}
                // autoHideDuration={6000}
                onClose={handleSnackBarClose}
            >
                <Alert
                    severity="error"
                    action={
                        <>
                            {/* <UIButton buttonProps={{
                        color: "default", size: "small"
                    }} onClick={handleSnackBarClose}>
                        Yes
                    </UIButton> */}
                            <UIButton variant="icon" size="small" aria-label="close" color="inherit" onClick={handleSnackBarConfirm}>
                                <CheckCircleRounded fontSize="small" />
                            </UIButton>
                            <UIButton variant="icon" size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
                                <CloseIcon fontSize="small" />
                            </UIButton>
                        </>
                    }
                >Are you Sure?</Alert>
            </Snackbar>
        </>
    );
};

export default NotesTab;