import * as React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { RouteConfig } from "../../model/route-config";
import { constructPath } from "../../utils/route-utils";

export interface NavigationLinkProps {
    activeClassName?: string;
    activeStyle?: React.CSSProperties;
    to?: string;
    className?: string;
    route: RouteConfig;
    children?: React.ReactNode;
}

const NavigationLink: React.ForwardRefRenderFunction<HTMLAnchorElement, NavigationLinkProps> = ({
    to,
    route,
    children,
    ...others
}, ref) => {
    const { url } = useRouteMatch();

    return route.externalLink ? (
        <a
            ref={ref}
            target="_blank"
            href={route.path}
            className="text-ellipsis navigation-text"
            rel="noreferrer"
        >
            {children || route.name}
        </a>
    ) : (
        <NavLink
            ref={ref}
            exact={!!route.exact}
            to={to || constructPath(route.path, url)}
            {...others}
        >
            {children || route.name}
        </NavLink>
    );
};

export default React.forwardRef(NavigationLink);
