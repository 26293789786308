import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { ApiData } from "../../../model/xhrCallState";
import httpService from '../../../service/http-service';
import { selectAuth } from "../../../store/modules/authentication";
import { stripTimeZone } from "../../../utils/date-timezone-utils";
import logger from '../../../utils/log-utils';
import { getErrorString } from "../../../utils/object-utils";
import { queryToObject } from '../../../utils/uri-utils';
import { ChatTranscriptResponse } from '../chatTranscriptResponse';

interface ChatTranscriptParams {
    chatId?: string;
    subId?: string;
}

export function useChatTranscript() {
    const { location } = useHistory();
    const [data, setData] = useState<ApiData<ChatTranscriptResponse>>(new ApiData());
    const { isAuthed } = useSelector(selectAuth);

    const MIN_DATE = "0001-01-01T00:00:00";

    useEffect(() => {
        const filterObj = queryToObject<ChatTranscriptParams>(location.search);
        // logger.log(filterObj);

        getTranscript(filterObj);
    }, [location]);

    const getTranscript = async (param: ChatTranscriptParams) => {
        setData({ status: "Pending" });
        try {
            const response = await httpService.get<any>('SubscriberAnalytics/PrintChatTranscript', { params: param });
            setData({ status: "Completed", data: response.data });
        } catch (error) {
            setData({ status: "Failed", errors: [getErrorString(error)] });
            logger.log(error);
        }
    };

    const checkNull = (val: any) => {
        if (val === null) return "";
        return val;
    };

    const getLeadType = (val: any) => {
        switch (Number(val)) {
            case 0: return '';
            case 1: return 'E Lead';
            case 2: return 'P Lead';
            case 3: return 'VIP Lead';
            case 4: return 'Query';
            default: return '';
        }
    };

    const getPaymentType = (val: any) => {
        switch (Number(val)) {
            case 0: return '';
            case 1: return 'Finance';
            case 2: return 'Cash';
            default: return '';
        }
    };

    const getChatOrigin = (val: any) => {
        switch (Number(val)) {
            case 0: return 'Operator';
            case 1: return 'Visitor';
            case 3: return 'System';
            default: return '';
        }
    };

    const getFormattedDateTime = (date?: string) => {
        return date ? dayjs(stripTimeZone(date)).format("MM/DD/YYYY hh:mm:ss A") : "";
    };

    const getNextDay = (date?: string) => {
        return date ? dayjs(stripTimeZone(date)).add(1, "day").format("MM/DD/YYYY") : "";
    };

    return { apiData: data, isAuthed, MIN_DATE, checkNull, getLeadType, getPaymentType, getChatOrigin, getFormattedDateTime, getNextDay };
}
