import { TableCell, TableSortLabel } from "@material-ui/core";
import * as React from "react";
import { ResponsiveTable } from "../../../components/responsive-table";
import { HeaderComponentProps, HeaderData } from "../../../components/responsive-table/responsive-table";
import { LeadData, LeadInfo } from "../../../model/dashboard-table";

interface LeadsTableProps {
    leadData: LeadData;
}
interface BodyRowItemProps {
    data: LeadInfo;
}

type HeadCell = HeaderData<LeadInfo>;

type HeaderItemProps = HeaderComponentProps<LeadInfo>;

const LeadsTable: React.FC<LeadsTableProps> = (props) => {
    return (
        <>
            {/* <div className="card-title">{props.leadData.title}</div> */}
            <ResponsiveTable
                components={{
                    Header: HeaderItem,
                    BodyComponent: BodyRowItem
                }}
                data={{
                    headerData: headCells,
                    values: props.leadData.data,
                }}
                innerProps={{}}
                rowCount={5}
                isSelectable={false}
                size="medium"
                orderBy="firstName"
            />
        </>
    );
};

const BodyRowItem: React.FC<BodyRowItemProps> = (props) => {
    const { data: row } = props;

    return <>
        {/* <TableCell
            component="th"
            id={row.id}
            scope="row"
        >
            {row.created}
        </TableCell> */}
        <TableCell align="left">{row.firstName}</TableCell>
        <TableCell align="left">{row.lastName}</TableCell>
        <TableCell align="left">{row.phone}</TableCell>
        <TableCell align="right">{row.email}</TableCell>
    </>;
};

const headCells: HeadCell[] = [
    // { id: 'created', align: "left", disablePadding: true, label: 'Created', isSortable: true },
    { id: 'firstName', align: "left", disablePadding: true, label: 'First Name', isSortable: true },
    { id: 'lastName', align: "left", disablePadding: false, label: 'Last Name', isSortable: false },
    { id: 'phone', align: "left", disablePadding: false, label: 'Phone', isSortable: false },
    { id: 'email', align: "right", disablePadding: false, label: 'Email', isSortable: false },
];

const HeaderItem: React.FC<HeaderItemProps> = (props) => {
    const { data: headCell, orderBy, order, onSort } = props;

    return <TableCell
        key={headCell.id}
        align={headCell.align}
        sortDirection={orderBy === headCell.id ? order : false}
    >
        {
            (!headCell.isSortable) ? <span>{headCell.label}</span> : (
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={onSort ? onSort(headCell.id) : undefined}
                >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                        <span className="visually-hidden">
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                    ) : null}
                </TableSortLabel>
            )
        }
    </TableCell>;
};

export default LeadsTable;
