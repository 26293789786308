/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import clsx from "clsx";
import * as React from "react";
import { FilterData, FilterOptionValue } from "../../../model/filter-data";
import Scrollable from "../../scrollable";
import { OptionGroupProps } from "../models/option-group-props";
import { useOptionGroup } from "./hooks/useOptionGroup";

const OptionGroup: React.FC<OptionGroupProps> = (props) => {
    const { handleOptionClick, options } = useOptionGroup(props);

    const selectedOptions = options.filter((option) => option.selected);
    return (
        <div
            key={props.path}
            className="filter-options-group flex-column flex-noShrink"
        >
            <div className="filter-item-title flex-noshrink text-ellipsis">
                {props.groupLabel}
            </div>

            {props.filterType === "select" && (
                <>
                    <Scrollable superSlim styles={{ flexGrow: 0 }}>
                        <div className="filter-item-list-container flex-responsive-reverse flex-noshrink">
                            {options &&
                                options
                                    .filter((option) => !option.selected)
                                    .map((option, i) => (
                                        <div
                                            key={props.path + i}
                                            className={clsx("filter-item text-ellipsis", {
                                                selected: option.selected,
                                            })}
                                            onClick={() => handleOptionClick(option)}
                                        >
                                            {option.displayValue}
                                        </div>
                                    ))}
                        </div>
                    </Scrollable>
                    {!!selectedOptions.length && (
                        <>
                            <div
                                className="filter-item-title text-ellipsis flex-noshrink"
                                style={{ fontSize: 14, marginTop: 4 }}
                            >
                                Selected
                            </div>
                            <Scrollable superSlim styles={{ flexShrink: 0, maxHeight: 160 }}>
                                <div className="filter-item-list-container flex-responsive-reverse flex-noshrink">
                                    {selectedOptions
                                        .filter((option) => option.selected)
                                        .map((option, i) => (
                                            <div
                                                key={props.path + i}
                                                className={clsx("filter-item text-ellipsis", {
                                                    selected: option.selected,
                                                })}
                                                onClick={() => handleOptionClick(option)}
                                            >
                                                {option.displayValue}
                                            </div>
                                        ))}
                                </div>
                            </Scrollable>
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default OptionGroup;
