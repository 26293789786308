// import { useDispatch, useSelector } from "react-redux";
import { StorageService } from "./storage-service";
// import { selectDateFilter, setFilterDate } from "../store/modules/ui";
import { DateFilterParam } from "../model/date-filter-param";

// const StartDateStorage = new StorageService('start-date');
// const EndDateStorage = new StorageService('end-date');
export const dateStorage = new StorageService('date-filter');
// const { DateRangeFilter } = useSelector(selectDate);

export const mountState = () => {
    // let dateObj: DateFilterParam;
    const dateFilter = dateStorage.get();
    return dateFilter;
};

export const demountState = (date: DateFilterParam) => {
    dateStorage.set(date);
};
