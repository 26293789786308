import React from "react";
import { CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import Donut from "../../../components/Charts/donut";
import refresh from "../../../assets/images/icons/refresh.svg";
import { DashboardData } from "../../../model/dashboard-chart-hc";
import leadIcon from "../../../assets/images/icons/leadIcon.svg";
import chatsIcon from "../../../assets/images/icons/chatsIcon.svg";
import websiteIcon from "../../../assets/images/icons/websiteIcon.svg";
import FailedComponent from "../../../components/failed-component";
// import { useDashboardChart } from "../hooks/useDashboardChart";
import { UIButton } from "../../../components/form";
import { useDashboardChartNew } from "../hooks/useDashboardChartNew";
// import UiButton from "../../../components/form/inputElements/ui-button";

export interface DashboardChartProps {
    data: DashboardData;
    loading: string[];
    refreshOnClick: () => void;
}

export interface DashboardChartState { }

const DashboardChartForServicesPro: React.FC = () => {
    const { totalChat, totalLead, totalUV, refreshLeadData, leadByservice, leadBySource } = useDashboardChartNew();
    const loading: string[] = [leadByservice.status, leadBySource.status, totalChat.status, totalLead.status, totalUV.status];
    const dashboardChartData = {
        websiteChatCount: totalChat.data,
        websiteLeadCount: totalLead.data,
        websiteUVCount: totalUV.data,
        // leadByDept: (leadByDepartment.data) ? leadByDepartment.data : [],
        leadByService: (leadByservice.data) ? leadByservice.data : [],
        // leadsByType: (leadByType.data) ? leadByType.data : [],
        leadBySource: (leadBySource.data) ? leadBySource.data : []
    };

    return (
        <div className={clsx("card", "b-m-4 r-m-4 r-m-phone-inv card-half")}>
            <div className="card-title">Performance Dashboard (Last 24 hrs)</div>
            <div className="card-body">
                {loading.indexOf("Pending") > -1 ? (<div className="loading flex-column flex-center" style={{ marginBottom: 100 }}>
                    <CircularProgress size={40} color="secondary" />
                </div>) :
                    loading.indexOf("Failed") > -1 ? (
                        <div className="card-error " style={{ marginBottom: 10, textAlign: "center" }}>
                            <FailedComponent type="error" text="No Data Found" size="small" />
                        </div>
                    ) : (
                        <>
                            <div className="refresh-graph-area">
                                <UIButton
                                    variant="icon"
                                    onClick={refreshLeadData}
                                    size="small"
                                >
                                    <img alt="refresh icon" src={refresh} />
                                </UIButton>
                            </div>
                            <div className="flex-row">
                                <div className="info-pill r-m-8" style={{ marginRight: '10!important', color: 'var(--muted-text-color)' }}>
                                    <img alt="website icon" src={websiteIcon} />
                                    <div>Total websites UV:<span className="info-span">{dashboardChartData.websiteUVCount?.data ?? 0}</span>
                                    </div>
                                </div><div className="info-pill r-m-8" style={{ marginRight: '10!important', color: 'var(--muted-text-color)' }}>
                                    <img alt="chats icon" src={chatsIcon} />
                                    <div>Total Chats:<span className="info-span">{dashboardChartData.websiteChatCount?.data ?? 0}</span>
                                    </div>
                                </div>
                                <div className="info-pill" style={{ color: 'var(--muted-text-color)' }}>
                                    <img alt="lead icon" src={leadIcon} />
                                    <div>Total Leads:<span className="info-span">{dashboardChartData.websiteLeadCount?.data ?? 0}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="flex-responsive" style={{ justifyContent: "center" }}>
                                {
                                    !(!!dashboardChartData.leadByService.length && !!dashboardChartData.leadBySource.length) &&
                                    <FailedComponent size="small" text="Data is not available" type="error" />
                                }
                                {
                                    (!!dashboardChartData.leadByService.length && !!dashboardChartData.leadBySource.length) &&
                                    <>

                                        <div className="chart-card">
                                            <Donut
                                                id="dou1"
                                                data={dashboardChartData.leadByService}
                                                title="Leads by Services"
                                            />
                                        </div>
                                        <div className="chart-card">
                                            <Donut
                                                id="dou2"
                                                data={dashboardChartData.leadBySource}
                                                title="Leads by Source"
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    )}
            </div>
        </div>
    );
};
export default DashboardChartForServicesPro;
