import dayjs from "dayjs";
// import timezone from 'dayjs/plugin/timezone';
// import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from "react";
import appToast from "../../../../components/app-toast";
import uniLoader from "../../../../components/uni-loader";
import { ApiData } from "../../../../model/xhrCallState";
import httpService from "../../../../service/http-service";
import { convertTimezone } from "../../../../utils/date-timezone-utils";
import logger from "../../../../utils/log-utils";

// dayjs.extend(utc);
// dayjs.extend(timezone);

export default function useGroupSettings(dateString: string) {
    const [status, setStatus] = useState<ApiData<any>>();
    const [date, setDate] = useState<string | undefined>(dateString ? convertTimezone(dayjs(dateString)).format() : undefined);

    useEffect(() => {
        if (status?.status === "Pending") uniLoader.showLoader(''); else uniLoader.close();
    }, [status]);

    const handleDate = (date: string) => {
        const dateString = convertTimezone(dayjs(date)).format();
        setDate(dateString);
    };

    const SetBillingActivation = async (subId: string) => {
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            await httpService.post(`Subscriber/BillingActivation?subscriberId=${subId}&dtActivationDate=${date}`);
            // logger.log(response.data);
            appToast.success('Billing activation date has been set');
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            appToast.error('Billing activation date has not been set');
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };
    const SetNoBilling = async (subId: string) => {
        setStatus((s) => ({ ...s, status: "Pending" }));
        try {
            await httpService.post(`Subscriber/BillingActivation?subscriberId=${subId}&dtActivationDate=12-12-9999`);
            // logger.log(response.data);
            appToast.success('No biling set');
            setStatus((s) => ({ ...s, status: "Completed" }));
        } catch (error) {
            logger.log(error);
            appToast.error('Billing activation date has not been set');
            setStatus((s) => ({ ...s, status: "Failed" }));
        }
    };

    return { date, handleDate, SetBillingActivation, SetNoBilling };
}
