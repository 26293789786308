import {
    Dialog,
    DialogContent,
    DialogProps,
    DialogTitle,
    // IconButton,
    useMediaQuery,
} from "@material-ui/core";
import * as React from "react";
// import CloseIcon from "@material-ui/icons/Close";
import theme from "../../theme";
import { UIButton } from "../form";
import ChatleadIcon from "../chatlead-icon";

export interface ResponsiveDialogProps extends DialogProps {
    title?: string;
    handleClose: () => void;
}

const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({
    title,
    children,
    handleClose,
    ...others
}) => {
    const fullScreenDialog = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <Dialog
            {...others}
            className="responsive-dialog"
            maxWidth="sm"
            onClose={handleClose}
            fullScreen={fullScreenDialog}
            fullWidth
        >
            <DialogTitle>
                <div className="flex-row flex-center space-between">
                    {title && <span className="title">{title}</span>}
                    {handleClose ? (
                        <UIButton
                            variant="icon"
                            size="small"
                            // className="close-btn"
                            onClick={handleClose}
                            buttonProps={{ "aria-label": "close" }}
                        >
                            {/* <CloseIcon fontSize="small" /> */}
                            <ChatleadIcon size={20} icon="crossThin" color="var(--solids-grey-200)" />
                        </UIButton>
                    ) : null}
                </div>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

export default ResponsiveDialog;
