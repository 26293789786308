import { TableCell, TableHead, TableRow } from "@material-ui/core";
import * as React from "react";
import { TableColumn } from "../../../model/table-column";

export interface AppTableHeadProps {
  columns: TableColumn<any>[];
}

const AppTableHead: React.FC<AppTableHeadProps> = (props) => {
    const renderTableCell = React.useCallback(
    (columns: TableColumn[]) => (
        <>
            {columns.map((column) => {
                const { name, label, content, ...others } = column;
                return (
                    <TableCell
                        key={name}
                        {...others}
                        style={{ position: "sticky", top: 0 }}
                    >
                        {label}
                    </TableCell>
                );
            })}
        </>
    ),
    []
    );

    return (
        <TableHead>
            <TableRow>{renderTableCell(props.columns)}</TableRow>
        </TableHead>
    );
};

export default AppTableHead;
