import React, { Component } from "react";
import appConfig from "../../utils/app-config-utils";

declare let grecaptcha: any;

interface RecaptchaProps {
    onTokenGenerated?: (token: string) => void;
    onReady?: () => void;
    elemId?: string;
    theme?: "light" | "dark";
}

interface RecaptchaState {
    isRecaptchaReady: boolean;
    widget: any;
}

export default class Recaptcha extends Component<RecaptchaProps, RecaptchaState> {
    private readyCheckInterVal: any;
    private recaptchaMountInterval: any;

    // eslint-disable-next-line react/static-property-placement
    public static defaultProps: Partial<RecaptchaProps> = {
        elemId: "g-recaptcha",
        theme: "light"
    };

    constructor(props: RecaptchaProps) {
        super(props);

        this.state = {
            isRecaptchaReady: this.isReady(),
            widget: null,
        };
        if (!this.state.isRecaptchaReady && typeof window !== 'undefined') {
            this.readyCheckInterVal = setInterval(this.updateReadyState, 1000);
        }
    }

    componentDidMount() {
        // appToast.warn("If the connection is slow, the recaptcha service will take some time to load. The form submit won't work before that!");
        if (this.state.isRecaptchaReady) {
            this.renderGrecaptcha();
        } else {
            this.recaptchaMountInterval = setInterval(this.updateMount, 1000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.readyCheckInterVal);
    }

    private isReady = () => typeof window !== 'undefined'
        && typeof grecaptcha !== 'undefined'
        && typeof grecaptcha.render === 'function'

    private updateReadyState = () => {
        if (this.isReady()) {
            this.setState({
                isRecaptchaReady: true,
            });
            clearInterval(this.readyCheckInterVal);
            if (this.props.onReady) this.props.onReady();
        }
    }

    private updateMount = () => {
        if (this.isReady()) {
            const flag = this.renderGrecaptcha();
            if (!flag) return;
            clearInterval(this.recaptchaMountInterval);
        }
    }

    private renderGrecaptcha = () => {
        if (!this.props.elemId) return false;
        const widgetItem = grecaptcha.render(this.props.elemId, {
            sitekey: appConfig.recaptchaSiteKey,
            callback: this.onTokenSubmit,
            theme: this.props.theme,
            size: "invisible",
            badge: "bottomleft",
            action: "submit"
        });

        this.setState({
            ...this.state,
            widget: widgetItem
        });
        grecaptcha.ready(() => {
            // appToast.success("The recaptcha service is ready!");
            if (this?.props?.onReady) this.props.onReady();
        });
        return true;
    }

    onTokenSubmit = (token: string) => {
        // appToast.success("New token is ready!");
        if (this.props.onTokenGenerated) this.props.onTokenGenerated(token);
    }

    execute() {
        const { isRecaptchaReady: isWidgetReady, widget } = this.state;
        if (isWidgetReady && widget !== null) {
            // grecaptcha.reset(widget);
            grecaptcha.execute(widget);
        }
    }

    render() {
        return <div
            id={this.props.elemId}
            // className={this.props.className}
            data-sitekey={appConfig.recaptchaSiteKey}
            data-theme={this.props.theme}
            data-size="invisible"
            data-badge="bottomleft"
            data-action="submit"
        />;
    }
}