import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit";
import { get } from 'lodash';
import { AxiosResponse } from "axios";
import { AccountUser, LoginCredentials } from "../../model";
import { GenericState, Meta } from "../types";
import * as authService from "../../service/auth-service";
import { buildGenericInitialState, updateStore, handleError, selectGenericsOfActionType } from "../../utils/store-utils";
import { post, get as httpGet } from "../../utils/http-utils";
import { AppStore } from "..";
import { HttpStatusCode } from "../../model/http-status-code";

// Declarations
interface InitialState extends GenericState {
    authenticated: boolean;
    user?: AccountUser;
}

const initialState: InitialState = {
    authenticated: authService.isAuthed(),
    ...buildGenericInitialState([])
};

// Slices
const { actions, reducer } = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        changeAuth: (state, action: PayloadAction<string | null>) => {
            return updateStore<typeof state>(state, action, {
                authenticated: !!action.payload
            });
        },

        userVerified: {
            reducer(state, action: PayloadAction<AccountUser, string, Meta>) {
                return updateStore<typeof state>(state, action, {
                    user: get(action, "payload.email") ? action.payload : null
                });
            },
            prepare(payload: AccountUser, meta: Meta) {
                if (payload && payload.auth_token) delete payload.auth_token; // prevent the auth token from storing
                return { payload, meta };
            }
        }
    }
});

export default { reducer, actions };

// Action Creators

export const login = (credential: LoginCredentials, postLoginPath: string = '/') => async (dispatch: Dispatch) => {
    const type = actions.changeAuth.type;

    try {
        const response = await post(dispatch, type, `auth/login`, { username: credential.username, password: credential.password, rememberMe: credential.rememberMe });

        authService.onLoginSuccess(response.data, postLoginPath);
    } catch (err: any) {
        await handleError(dispatch, err, type);
    }
};

export const verifyUser = () => async (dispatch: Dispatch) => {
    const type = actions.userVerified.type;
    try {
        const response = await httpGet(dispatch, type, 'auth/validate', false);

        if (response.status === HttpStatusCode.OK) {
            const user = authService.getCurrentUser();
            if (user) {
                return dispatch(actions.userVerified(user, { status: "SUCCESS" }));
            }
        }
        dispatch(actions.changeAuth(null));
        authService.logout();
    } catch (error: any) {
        if (error.response?.status === HttpStatusCode.Unauthorized || error.response?.status === HttpStatusCode.Forbidden) {
            dispatch(actions.changeAuth(null));
            authService.logout();
        }
        await handleError(dispatch, error, type);
    }
    return Promise.resolve();
};

export const logout = () => async (dispatch: Dispatch) => {
    const type = actions.changeAuth.type;
    try {
        const response = await httpGet(dispatch, type, 'auth/logout', false);

        if (response.status === HttpStatusCode.OK) {
            dispatch(actions.changeAuth(null));
            authService.logout();
        }
    } catch (error: any) {
        await handleError(dispatch, error, type);
    }
};

export const forceLogout = (dispatch: Dispatch, forced: boolean = false) => (resp: AxiosResponse<any> | any) => {
    const response: AxiosResponse<any> = resp.response || resp;
    if (response.request) {
        const reqUrl = response.request.responseURL;
        if (!forced) {
            if ((reqUrl.includes("/api/auth/validate") || reqUrl.includes("/api/auth/logout")) && (response.status === HttpStatusCode.Forbidden || response.status === HttpStatusCode.Unauthorized)) {
                dispatch(actions.changeAuth(null));
                authService.logout();
            }
        } else {
            dispatch(actions.changeAuth(null));
            authService.logout();
        }
    }
    return response;
};

// Selectors

export const selectAuth = (state: AppStore) => ({ isAuthed: state.authentication.authenticated, ...selectGenericsOfActionType<InitialState>(state.authentication, actions.changeAuth.type) });
export const selectUser = (state: AppStore) => ({ user: state.authentication.user, ...selectGenericsOfActionType<InitialState>(state.authentication, actions.userVerified.type) });
export const selectRole = (state: AppStore) => state.authentication.user?.role;
