import { CircularProgress, TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import axios, { CancelToken } from "axios";
import clsx from "clsx";
import React, { memo, useRef } from "react";
import { CommonInputProps } from "../../../model/common-props";
import logger from "../../../utils/log-utils";

interface SelectCompleteData {
    name: string;
    value: string | undefined;
    // type?: "created" | "option";
}
interface UISelectCompleteProps extends CommonInputProps {
    onChange?: (name: string, evt: SelectCompleteData | undefined) => void;
    inputProps?: TextFieldProps;
    autoCompleteProps?: AutocompleteProps<SelectCompleteData, boolean, boolean, boolean>;
    getData: (currentValue?: string, searchName?: string, cancelToken?: CancelToken) => Promise<SelectCompleteData[]>;
}

const UISelectComplete: React.FC<UISelectCompleteProps> = (props) => {
    let { inputProps } = props;
    const { required, id, name, getData, onChange, errorMessage, autoCompleteProps, disabled } = props;
    if (!inputProps) inputProps = {};
    inputProps.variant = "outlined";
    if (props.label !== undefined) { inputProps.label = props.label; }

    const mounted = useRef<boolean>(false);
    const firstValue = useRef<string>("");
    const [open, setOpen] = React.useState(false);
    const [isOptionsLoading, setOptionsLoading] = React.useState(false);
    const [options, setOptions] = React.useState<SelectCompleteData[]>([]);
    const loading = (open && options.length === 0);

    const [inputValue, setInputValue] = React.useState<string | undefined>("");
    const [selectValue, setSelectValue] = React.useState<SelectCompleteData | undefined>();

    React.useEffect(() => {
        mounted.current = !mounted.current;
        if (mounted.current) firstValue.current = props.value;
        // logger.log(`auto ${mounted.current}`);
    }, []);

    React.useEffect(() => {
        const val = getOption(props.value);
        if (mounted.current) {
            if (val) {
                if (JSON.stringify(val) !== JSON.stringify(selectValue)) setSelectValue(val);
            } else if (props.value) {
                (async () => {
                    setOptionsLoading(true);
                    const [mainData, ...rest] = await getData(props.value);
                    setSelectValue(mainData);
                    setOptionsLoading(false);
                })();
            } else {
                setSelectValue(undefined);
            }
        }
    }, [props.value]);

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            setOptionsLoading(true);
            const response = await getData();
            if (active) {
                setOptions(response);
            }
            setOptionsLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    const getVal = (val: string | SelectCompleteData | (string | SelectCompleteData)[] | null) => {
        if (val === null) return val;
        if (typeof (val) === "string") return val;
        if ("value" in val) return val.value;
        if (Array.isArray(val) && typeof (val[0]) === "string") return val[0]; // TODO: for multiple, will think later
        if (Array.isArray(val) && (typeof (val[0]) === "object" && "value" in val[0])) return val[0]?.value; // TODO: for multiple, will think later
        return "";
    };

    const getOption = (value: string): SelectCompleteData | undefined => {
        const data = options.find(r => r.value === value);
        return data;
    };

    const getOptions = async (subName: string, cancelToken?: CancelToken) => {
        setOptionsLoading(true);

        const response = await getData(undefined, subName, cancelToken);
        setOptions(response);
        setOptionsLoading(false);
    };

    React.useEffect(() => {
        const source = axios.CancelToken.source();
        const timer = setTimeout(() => {
            if (inputValue) getOptions(inputValue, source.token);
        }, 300);
        return () => { clearTimeout(timer); setOptionsLoading(false); source.cancel("Cancelled due to successive calls!"); };
    }, [inputValue]);

    return (
        <div className={clsx("flex-row", props.className)} style={{ width: "100%", ...props.style }}>
            {
                <Autocomplete
                    {...autoCompleteProps}
                    disabled={disabled ?? false}
                    inputValue={inputValue}
                    value={selectValue || null}
                    style={{ width: "100%" }}
                    open={open}
                    onOpen={() => {
                        setOpen(true);
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                    getOptionSelected={(option, selected) => option.value === selected.value}
                    getOptionLabel={(option) => option.name}
                    options={options}
                    loading={isOptionsLoading}
                    includeInputInList
                    onInputChange={(event, newInputValue, reason) => {
                        // logger.log(`auto1`, mounted.current, newInputValue, reason);
                        setInputValue(newInputValue);
                        // if (onChange && mounted.current && (reason === "input" || reason === "clear")) {
                        //     const data = options.find(r => r.name === newInputValue);
                        //     if (data) {
                        //         onChange(name ?? id ?? "", { ...data, type: "option" });
                        //     } else {
                        //         onChange(name ?? id ?? "", { name: newInputValue, value: undefined, type: "created" });
                        //     }
                        // }
                    }}
                    onChange={(event, newInputValue, reason) => {
                        // logger.log(`auto2`, mounted.current, newInputValue, reason);
                        if (onChange && mounted.current && (reason === "select-option" || reason === "clear")) {
                            // onChange({ name: name ?? id ?? "", value: getVal(newInputValue) ?? "" });
                            switch (reason) {
                                case "select-option": {
                                    const val = getVal(newInputValue);
                                    const data = options.find(r => r.value === val);
                                    if (data) {
                                        onChange(name ?? id ?? "", data);
                                    }
                                    break;
                                }
                                case "clear": {
                                    if (!newInputValue) {
                                        onChange(name ?? id ?? "", undefined);
                                    }
                                    break;
                                }
                                default:
                                    break;
                            }
                        }
                    }}
                    // inputValue={inputValue}
                    renderInput={
                        (params) => (<TextField
                            {...params}
                            name={name}
                            required={required}
                            type="text"
                            className="flex-grow"
                            // onChange={e => setInputValue(e.target.value)}
                            error={!!errorMessage}
                            helperText={errorMessage}
                            {...inputProps}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isOptionsLoading ? <CircularProgress color="primary" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />)
                    }
                />
            }
        </div>
    );
};

export default memo(UISelectComplete);
