import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

interface TabPanelProps<ValueType> {
    children?: React.ReactNode;
    index: ValueType;
    value: ValueType;
    unmountOnExit?: boolean;
}

const TabPanel = <K extends number>({ children, index, value, unmountOnExit }: TabPanelProps<K>) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        // {...other}
        >
            {
                unmountOnExit ? <>
                    {value === index && (
                        <div>{children}</div>
                    )}
                </> : <>
                    <div style={{ display: value === index ? "block" : "none" }}>{children}</div>
                </>
            }
        </div>
    );
};

export default TabPanel;