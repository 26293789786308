import { ThemeProvider } from "@material-ui/core";
import React from "react";
import {
    BrowserRouter,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Routes } from "../../router/routes";
import theme from "../../theme";
import appConfig from "../../utils/app-config-utils";
import configuration from "../../utils/configuration";
import { Loading } from "../loading";
import RouteWithSubRoutes from "../routers/route-with-subroutes";
import { SubsciberDataProvider } from "../subscriber-select";
import "./App.css";
import useApp from "./hooks/useApp";

const App: React.FunctionComponent = () => {
    const { userLoading } = useApp();
    const content = userLoading ? (
        <Loading />
    ) : (
        <RouteWithSubRoutes routes={Routes} />
    );

    return (
        <ThemeProvider theme={theme}>
            <SubsciberDataProvider>
                <ToastContainer
                    position="bottom-right"
                    containerId={appConfig.defaultToastContainerId}
                    autoClose={3000}
                    enableMultiContainer
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    theme="colored"
                    draggable
                    pauseOnHover
                />
                <ToastContainer
                    position="top-center"
                    containerId={appConfig.loaderToastContainerId}
                    autoClose={false}
                    pauseOnHover={false}
                    pauseOnFocusLoss={false}
                    closeButton={false}
                    closeOnClick={false}
                    toastClassName="LoaderToastify__toast"
                    className="LoaderToastify__toast-container"
                    draggable={false}
                    limit={1}
                    theme="colored"
                    icon={false}
                    enableMultiContainer
                    hideProgressBar
                    newestOnTop={false}
                    rtl={false}
                />
                <BrowserRouter basename={configuration.getValue("baseUrl") || "."}>{content}</BrowserRouter>
            </SubsciberDataProvider>
        </ThemeProvider>
    );
};

export default App;
