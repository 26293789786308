import axios, { CancelToken } from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cc24AdminRoles } from "../../../model/roles-and-rights";
import { ApiData } from "../../../model/xhrCallState";
import httpService from "../../../service/http-service";
import { selectUser } from "../../../store/modules/authentication";
import { selectSubscriberInfo } from "../../../store/modules/subscriber";
import logger from "../../../utils/log-utils";

export function useProfileCompleteness() {
    const { currentSubscriber } = useSelector(selectSubscriberInfo);
    const { user } = useSelector(selectUser);

    const [stateData, setStateData] = useState<{
        profileCompleteStatus: ApiData<number>
    }>({
        profileCompleteStatus: new ApiData<number>()
    });

    const getRespectiveSubscriber = () => {
        return currentSubscriber?.SubscriberId || user?.sub_id;
    };

    const getSubscriberIdParameterBasedOnUserRole = (object: { [x: string]: any; } = {}) => {
        return cc24AdminRoles.includes(user?.role) ? { ...object, subscriberID: currentSubscriber?.SubscriberId } : object;
    };

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (getRespectiveSubscriber()) { getProfileCompleteness(source.token); }
        return () => { source.cancel("Cancelled due to successive calls!"); };
    }, [currentSubscriber?.SubscriberId || user?.sub_id]);

    const getProfileCompleteness = async (cancelToken?: CancelToken) => {
        setStateData((s) => ({ ...s, profileCompleteStatus: { status: "Pending" } }));
        try {
            const response = await httpService.get<number>('SubscriberDashboard/Profile/GetProfileCompleteness', { params: getSubscriberIdParameterBasedOnUserRole(), cancelToken });
            setStateData((s) => ({ ...s, profileCompleteStatus: { status: "Completed", data: response.data } }));
        } catch (error) {
            logger.log(error);
            if (!axios.isCancel(error)) setStateData((s) => ({ ...s, profileCompleteStatus: { status: "Failed" } }));
        }
    };

    return {
        getProfileCompleteness, profileCompleteStatus: stateData.profileCompleteStatus, user
    };
}