// @ts-nocheck
import React, { memo, ReactNode } from "react";
import { DatePicker, DatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';
// import { JsxElement } from 'typescript';
import dayjs from "dayjs";
import clsx from "clsx";
import { CommonInputProps } from '../../model/common-props';

interface CalendarProps extends CommonInputProps {
    renderDay?: (day: dayjs.Dayjs, selectedDate: dayjs.Dayjs, dayInCurrentMonth: boolean, dayComponent: ReactNode) => ReactNode;
    onChange?: (date: string, value?: string) => void;
    onMonthChange?: (date: string, value?: string) => void;
    onOpen?: () => void;
    onPickerViewChange?: (date: string | dayjs.Dayjs) => void | Promise<void>;
    inputProps?: DatePickerProps;
}

const Calendar: React.FC<CalendarProps> = (props) => {
    const { renderDay, onChange, onOpen, onPickerViewChange, value, onMonthChange } = props;
    let { inputProps } = props;
    if (!inputProps) inputProps = {};
    if (props.label !== undefined) { inputProps.label = props.label; }
    const [selectedDate, setSelectedDate] = React.useState<string>(
        (dayjs(value) ?? dayjs()).format()
    );
    React.useEffect(() => {
        // console.log("Rang");
        if (value) setSelectedDate((dayjs(value) ?? dayjs()).format());
        else setSelectedDate(null);
    }, [value]);

    const handleDateChange = (date: dayjs.Dayjs | null, value?: string) => {
        // console.log("Rang 1");
        setSelectedDate(dayjs(date)?.format());
    };
    const onOpenPicker = () => {
        // console.log("View Change");
        onPickerViewChange(selectedDate);
    };

    return (
        <div className={clsx(props.className)} style={{ ...props.style }}>
            <MuiPickersUtilsProvider utils={DayjsUtils}>
                <DatePicker
                    orientation="landscape"
                    renderDay={renderDay}
                    onOpen={onOpenPicker}
                    onMonthChange={onMonthChange ?? onPickerViewChange}
                    onYearChange={onPickerViewChange}
                    variant='static'
                    openTo="date"
                    value={selectedDate}
                    onChange={onChange ?? handleDateChange}
                    showTodayButton
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default memo(Calendar);
