/* eslint-disable prefer-const */
/* eslint-disable camelcase */
export function setCookie(c_name: string, value: string | boolean | number, exdays: number) {
    let c_value = value.toString();
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    const domain = (document.domain === "localhost") ? '' : `;domain=.${document.domain}`;
    c_value = `${escape(c_value) + ((exdays === null) ? "" : `; expires=${exdate.toUTCString()}`)};path=/${domain}`;
    document.cookie = `${c_name}=${c_value}`;
}
export function deleteCookie(name: string) {
    let date; let expires; let domain; let
        path;
    date = new Date();
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
    expires = "; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    domain = (document.domain === "localhost") ? '' : `;domain=.${document.domain}`;
    path = `;path=/${domain}`;
    document.cookie = `${name}=${expires};${domain}; ${path}`;
}

export function getCookie(c_name: string) {
    if (document.cookie) {
        let i; let x; let y; const
            ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x === c_name) {
                return unescape(y);
            }
        }
    }
    return undefined;
}

export function getCookie_V2(c_name: string) {
    if (document.cookie) {
        let i; let x; let y; const ARRcookies = document.cookie.split(";");
        // let returnValueEnc;
        let returnValueDec;
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x === c_name) {
                // return unescape(y);
                returnValueDec = decodeURIComponent(y);
                return returnValueDec;
            }
        }
    }
    return undefined;
}

export function checkIfCookieEnabled() {
    const c_name = "CWTestCookie";
    const testVal = "test";
    setCookie(c_name, testVal, 1);

    const c_val = getCookie(c_name);

    if (c_val !== testVal) {
        // i.e. cookie disabled
        return false;
    }

    deleteCookie("CWTestCookie");

    // i.e. cookie enabled
    return true;
}
