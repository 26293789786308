import { unstable_createMuiStrictModeTheme as createMuiTheme, colors } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import configuration from '../utils/configuration';
import shadows from './shadows';
import typography from './typography';

declare module "@material-ui/core/styles/createBreakpoints" {
    interface BreakpointOverrides {
        xxs: true;
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        xxl: true;
        xxxl: true;
    }
}

const palletes: { [id: string]: PaletteOptions | undefined } = {
    all: {
        header: {
            main: colors.common.white,
        },
        background: {
            default: '#F4F6F8',
            paper: colors.common.white
        },
        primary: {
            main: "#0058ff"
        },
        secondary: {
            main: "#ffd200"
        },
        error: {
            main: "#e90162"
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600],
            header: colors.blueGrey[600]
        }
    },
    legalchat: {
        header: {
            main: colors.common.white,
        },
        background: {
            default: '#F4F6F8',
            paper: colors.common.white
        },
        primary: {
            main: "#f15d22"
        },
        secondary: {
            main: "#04A777"
        },
        error: {
            main: "#e90162"
        },
        text: {
            primary: colors.grey[900],
            secondary: colors.grey[600],
            header: colors.grey[600]
        }
    }
};

const theme = createMuiTheme({
    palette: palletes[(configuration.getValue("productName") || "").toLowerCase()] || palletes.all,
    overrides: {
        MuiOutlinedInput: {
            root: {
                borderRadius: 8
            }
        },
        MuiButton: {
            root: {
                borderRadius: "5px"
            }
        }
    },
    breakpoints: {
        values: {
            xxs: 0,
            xs: 321,
            sm: 576,
            md: 769,
            lg: 1025,
            xl: 1280,
            xxl: 1368,
            xxxl: 1920,
        },
    },
    shadows,
    typography
});

export default theme;
