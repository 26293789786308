import * as React from "react";
import { PageWithHeaderFooter } from "../../layouts/page-with-header-footer";
import { RouteAdditionalPropsV2 } from "../../model/route-additional-propsV2";
import ProfileCompleteness from "./components/profile-completeness";
import DashboardChart from "./components/dashboard-chart";
import DashboardTable from "./components/dashboard-table";

import Realtime from "./components/realtime";
import AppointmentCalendar from "./components/appointment-calendar";
// import useSubscriberDashboard from "./hooks/useSubscriberDashboard";
import configuration from "../../utils/configuration";
import DashboardChartForServicesPro from "./components/dashboard-chart-new";
import { renderIfNotProduct, renderIfProduct } from "../../utils/render-utils";

export interface ISubscriberDashboardProps extends RouteAdditionalPropsV2 { }

const SubscriberDashboard: React.FC<ISubscriberDashboardProps> = (props) => {
    // const {
    //   leadByDepartment,
    //   leadByType,
    //   totalChat,
    //   totalLead,
    //   totalUV,
    //   leadInfo,
    //   profileCompleteStatus,
    //   websiteVisitors,
    //   webChat,
    //   textChat,
    //   fbChat,
    //   liveOperator,
    //   refreshLeadData,
    //   currentSubscriber } = useSubscriberDashboard();

    // const dashboardChartData = {
    //   websiteChatCount: totalChat.data,
    //   websiteLeadCount: totalLead.data,
    //   websiteUVCount: totalUV.data,
    //   leadByDept: (leadByDepartment.data) ? leadByDepartment.data : [],
    //   leadsByType: (leadByType.data) ? leadByType.data : [],
    // };

    // const leadsData = {
    //   data: (leadInfo.data?.data) ? leadInfo.data.data : [],
    //   title: leadInfo.data?.title ? leadInfo.data?.title : ""
    // }

    return (
        <PageWithHeaderFooter {...props}>
            <section className="subscriber-d">
                <div className="flex-responsive-breakline">
                    <Realtime />

                    <ProfileCompleteness />
                </div>
                <div className="flex-responsive-breakline">
                    {renderIfProduct(["ServicesPro", "LegalChat"])(<DashboardChartForServicesPro />)}
                    {renderIfNotProduct(["ServicesPro", "LegalChat"])(<DashboardChart />)}
                    <DashboardTable />
                </div>
                <div className="flex-responsive appt-calendar">
                    <AppointmentCalendar />
                </div>
            </section>
        </PageWithHeaderFooter>
    );
};

export default SubscriberDashboard;
