import axios, { AxiosResponse } from "axios";
import configuration from "../utils/configuration";

const baseURL = configuration.getValue("apiBase");

const instance = axios.create({ baseURL });

function injectAuthToken(jwt: string) {
    instance.defaults.headers.common.Authorization = `Bearer ${jwt}`;
}

function removeAuthToken() {
    delete instance.defaults.headers.common.Authorization;
}

function insertResponseInterceptor(onFulfilled?: ((value: AxiosResponse<any>) => AxiosResponse<any> | Promise<AxiosResponse<any>>) | undefined, onRejected?: ((error: any) => any) | undefined) {
    instance.interceptors.response.use(onFulfilled, onRejected);
}

export default {
    get: instance.get,
    post: instance.post,
    delete: instance.delete,
    put: instance.put,
    injectAuthToken,
    removeAuthToken,
    insertResponseInterceptor
};
