import React from "react";
import Calendar from "../../../components/calendar/calendar";
import { CardReport } from "../../../components/card-reports";
import { useApptCalendar } from "../hooks/useApptCalendar";

const AppointmentCalendar: React.FC = () => {
    const { renderDayInPicker, onPickerViewChange, selectedDate, handleMonthChange } = useApptCalendar();
    return (
        <div className="card b-m-4 flex-g-1">
            <h1 className="card-title b-m-2">Appointments</h1>
            <div className="card-body flex-column flex-center card-padding-mobile" style={{ overflow: 'auto' }}>
                <Calendar className="calendar card-padding-mobile" value={selectedDate} renderDay={renderDayInPicker} onPickerViewChange={onPickerViewChange} onMonthChange={handleMonthChange} />
            </div>
        </div>
    );
};

export default AppointmentCalendar;
