import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApiData } from "../../../model/xhrCallState";
import httpService from "../../../service/http-service";
import { selectSubscriberInfo } from "../../../store/modules/subscriber";
import logger from "../../../utils/log-utils";

export function useRealTime() {
    const { currentSubscriber } = useSelector(selectSubscriberInfo);

    const [stateData, setStateData] = useState<{

        websiteVisitors: ApiData<number>,
        webChat: ApiData<number>,
        textChat: ApiData<number>,
        fbChat: ApiData<number>,
        liveOperator: ApiData<number>
    }>({

        websiteVisitors: new ApiData<number>(),
        webChat: new ApiData<number>(),
        textChat: new ApiData<number>(),
        fbChat: new ApiData<number>(),
        liveOperator: new ApiData<number>()
    });

    useEffect(() => {
        if (currentSubscriber?.SubscriberId) {
            getWebsiteVisitors();
            getWebChat();
            getFbChat();
            getTextChat();
            getLiveOperator();
        }
        const interval = setInterval(() => {
            getWebsiteVisitors();
            getWebChat();
            getFbChat();
            getTextChat();
            getLiveOperator();
            // logger.log("10s");
        }, 10000);
        return () => { clearInterval(interval); };
    }, [currentSubscriber?.SubscriberId]);

    const getWebsiteVisitors = async () => {
        setStateData((s) => ({ ...s, websiteVisitors: { status: "Pending" } }));
        try {
            const response = await httpService.get<number>('SubscriberDashboard/Realtime/GetRealtimeWebsiteVisitor', { params: { subscriberID: currentSubscriber?.SubscriberId } });
            setStateData((s) => ({ ...s, websiteVisitors: { status: "Completed", data: response.data } }));
        } catch (error) {
            logger.log(error);
            setStateData((s) => ({ ...s, websiteVisitors: { status: "Failed" } }));
        }
    };

    const getWebChat = async () => {
        setStateData((s) => ({ ...s, webChat: { status: "Pending" } }));
        try {
            const response = await httpService.get<number>('SubscriberDashboard/Realtime/GetRealtimeWebChat', { params: { subscriberID: currentSubscriber?.SubscriberId } });
            setStateData((s) => ({ ...s, webChat: { status: "Completed", data: response.data } }));
        } catch (error) {
            logger.log(error);
            setStateData((s) => ({ ...s, webChat: { status: "Failed" } }));
        }
    };

    const getTextChat = async () => {
        setStateData((s) => ({ ...s, textChat: { status: "Pending" } }));
        try {
            const response = await httpService.get<number>('SubscriberDashboard/Realtime/GetRealtimeTextChat', { params: { subscriberID: currentSubscriber?.SubscriberId } });
            setStateData((s) => ({ ...s, textChat: { status: "Completed", data: response.data } }));
        } catch (error) {
            logger.log(error);
            setStateData((s) => ({ ...s, textChat: { status: "Failed" } }));
        }
    };

    const getFbChat = async () => {
        setStateData((s) => ({ ...s, fbChat: { status: "Pending" } }));
        try {
            const response = await httpService.get<number>('SubscriberDashboard/Realtime/GetRealtimeFBChat', { params: { subscriberID: currentSubscriber?.SubscriberId } });
            setStateData((s) => ({ ...s, fbChat: { status: "Completed", data: response.data } }));
        } catch (error) {
            logger.log(error);
            setStateData((s) => ({ ...s, fbChat: { status: "Failed" } }));
        }
    };

    const getLiveOperator = async () => {
        setStateData((s) => ({ ...s, liveOperator: { status: "Pending" } }));
        try {
            const response = await httpService.get<number>('SubscriberDashboard/Realtime/GetRealtimeOperators', { params: { subscriberID: currentSubscriber?.SubscriberId } });
            setStateData((s) => ({ ...s, liveOperator: { status: "Completed", data: response.data } }));
        } catch (error) {
            logger.log(error);
            setStateData((s) => ({ ...s, liveOperator: { status: "Failed" } }));
        }
    };

    return {
        websiteVisitors: stateData.websiteVisitors,
        webChat: stateData.webChat,
        fbChat: stateData.fbChat,
        textChat: stateData.textChat,
        liveOperator: stateData.liveOperator,
    };
}