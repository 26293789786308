import * as yup from 'yup';
import React, { useRef } from 'react';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';
// import { AgreementTableData } from '../models/response';
import httpService from '../../../../service/http-service';
// import { AgreementDialogProps } from '../models/agreement-dialog-props';
import { HttpStatusCode } from '../../../../model/http-status-code';
import appToast from '../../../../components/app-toast';
import { DataLoadState } from '../../../../model/types';
// import { AgreementFormData } from '../models/agreement-form-data';
import logger from '../../../../utils/log-utils';
import { ApiData } from '../../../../model/xhrCallState';
import { convertTimezone } from '../../../../utils/date-timezone-utils';
import { NotesFormData, NotesTableData } from '../../model/notes-data';
import { TableData } from '../../../../model/table-data';
import { RowEventType } from '../notes-table';

// dayjs.extend(utc);
// dayjs.extend(timezone);

type FormActionType = "add" | "edit" | undefined;

export default function useNotesDialog(activeSubscriberId?: string, subscriberName?: string) {
    const startDate = dayjs();
    // const [startDatestate, setStartDateState] = React.useState(convertTimezone(dayjs().year(2015)).format())
    // const [endDateState, setEndDateState] = React.useState(convertTimezone(dayjs()).format())
    const [notesTable, setNotesTable] = React.useState<ApiData<TableData<NotesTableData>>>(new ApiData('Pending'));
    const [formActionType, setFormActionType] = React.useState<FormActionType>();
    const [formData, setFormData] = React.useState<NotesTableData | null>();
    // const [url, setUrl] = React.useState<string | null>()
    const [tabValue, setTabValue] = React.useState(0);
    const [activeDeleteTransId, setActiveDeleteTransId] = React.useState<string>();
    const [fileUpload, setFileUpload] = React.useState<File | undefined>();
    const [fileUploadState, setFileUploadState] = React.useState<DataLoadState>("Completed");
    const formikRef = useRef<FormikProps<NotesFormData> | null>(null);

    const onRowDeleteClick = (transId: string) => {
        setActiveDeleteTransId(transId);
    };
    const handleSnackBarClose = () => {
        setActiveDeleteTransId(undefined);
    };
    const handleSnackBarConfirm = () => {
        const ed = notesTable?.data?.Table?.find(r => r.SerialNo.toString() === activeDeleteTransId);
        if (ed) {
            deleteNotesTableData(ed).then(() => {
                setActiveDeleteTransId(undefined);
                if (activeSubscriberId) { getNotesTableData(activeSubscriberId); }
            });
        }
    };
    const handleChange = (newValue: number) => {
        setTabValue(newValue);
        setFileUpload(undefined);
        if (newValue === 1 && formActionType === undefined) {
            setFormActionType("add");
            setFormData(null);
            setFileUpload(undefined);
            // setUrl(null)
            formikRef.current?.setFieldValue("fileName", undefined);
        }
    };
    const handleEditIconChange = () => {
        setFormActionType("edit");
        setFileUpload(undefined);
        // handleChange(newValue)
    };
    const handleButtonChange = () => {
        setFormActionType("add");
        setFormData(null);
        // handleChange(undefined, 1);
        // setUrl(null)
        formikRef.current?.setFieldValue("fileName", undefined);
        setTabValue(1);
    };
    const customTimeRangeStart = (date: string) => {
        const st = dayjs(date);
        formikRef.current?.setFieldValue("fromDate", convertTimezone(st).format("MM/DD/YY"));
    };
    const customTimeRangeEnd = (date: string) => {
        const end = dayjs(date);
        formikRef.current?.setFieldValue("toDate", convertTimezone(end).format("MM/DD/YY"));
    };
    const handleButtonClose = () => {
        setFormActionType(undefined);
        setTabValue(0);
    };

    const handleFileUpload = (file: File) => {
        setFileUpload(file);
    };

    const handleUploadNotesFile = (setFieldValue: (field: keyof NotesFormData, value: any, shouldValidate?: boolean | undefined) => void) => () => {
        if (fileUpload) {
            uploadNotesFile(fileUpload).then((url) => {
                setFieldValue("fileName", url);
                // setUrl(url);
            }).catch((err) => {
                appToast.error(err.message);
            });
        } else {
            appToast.error("Select a file");
        }
    };

    const uploadNotesFile = async (file: File) => {
        const formData = new FormData();
        formData.append("file", file);
        setFileUploadState("Pending");
        try {
            const res = await httpService.post<string>(`/SalesPortal/UploadNotes`, formData, { params: { SubscriberId: activeSubscriberId, SubscriberName: subscriberName } });
            if (res.status === HttpStatusCode.OK) {
                setFileUploadState("Completed");
                setFileUpload(undefined);
                return res.data;
            }
            setFileUploadState("Failed");
            return Promise.reject(new Error("File Upload Failed"));
        } catch (error) {
            setFileUploadState("Failed");
            return Promise.reject(new Error("File Upload Failed"));
        }
    };

    const saveNotesFile = async (values: NotesFormData, setFieldValue: (field: keyof NotesFormData, value: any, shouldValidate?: boolean | undefined) => void) => {
        try {
            const response = await httpService.post('/Subscriber/SubscriberNoteOperations', {
                "subscriberId": activeSubscriberId,
                "alertDate": dayjs(values.alertDate).utc().toISOString(),
                "notes": values.notes,
                "isForAll": values.forAll || false,
                "serialNo": formData?.SerialNo ?? 0,
                "attachedURL": values.fileName || null,
            }, { params: { operation: formData ? 1 : 0 } });
            if (response.status === 200) {
                if (activeSubscriberId) {
                    getNotesTableData(activeSubscriberId);
                    appToast.success("Notes Saved!");
                    // setUrl(null)
                    setFieldValue("fileName", undefined);
                    setFileUpload(undefined); // doubt
                }
            } else {
                appToast.error("Notes Couldn't be saved");
            }
        } catch (error) {
            appToast.error("Notes Couldn't be saved");
        }
    };

    const onRowEventType = (id: string, eventType: RowEventType) => {
        switch (eventType) {
            case "edit": {
                const ed = notesTable?.data?.Table?.find(r => r.SerialNo.toString() === id);
                setFormData(ed);
                handleEditIconChange();
                // setUrl(ed?.FilePath)
                formikRef.current?.setFieldValue("filename", ed?.AttachedURL);
                setTabValue(1);
                // setFileName(ed?.FilePath?.substring(ed?.FilePath?.lastIndexOf('/') + 1))
                break;
            }
            case "delete": {
                onRowDeleteClick(id);
                break;
            }
            case "view": {
                const vw = notesTable?.data?.Table?.find(r => r.SerialNo.toString() === id);
                window.open(vw?.AttachedURL);
                break;
            }

            default: break;
        }
    };
    React.useEffect(() => {
        if (activeSubscriberId) { getNotesTableData(activeSubscriberId); }
        return () => {
            setTabValue(0);
        };
    }, [activeSubscriberId]);

    const extractFileName = (path: string | undefined) => {
        return path?.substring(path.lastIndexOf('/') + 1) || "";
    };

    const iniValues: NotesFormData = {
        notes: formData?.Notes ?? "",
        alertDate: formData?.AlertDate ?? convertTimezone(startDate).toISOString(),
        // toDate: formData?.ToActive ?? convertTimezone(dayjs()).toISOString(),
        fileName: formData?.AttachedURL ?? "",
        forAll: false
    };

    const valSchema = yup.object().shape({
        notes: yup.string().required("Note is required"),
        alertDate: yup.string(),
        fileName: yup.string(),
    });

    type SetFieldType = (field: string, value: any, shouldValidate?: boolean | undefined) => void;

    const onCheckChange = (setFieldValue: SetFieldType) => (evt: React.ChangeEvent<{} | HTMLInputElement>, checked: boolean) => {
        setFieldValue("forAll", checked);
    };

    const getNotesTableData = async (activeSubscriberId: string) => {
        setNotesTable(new ApiData("Pending"));
        try {
            const res = await httpService.post<TableData<NotesTableData>>(`/Subscriber/SubscriberNoteOperations`, {
                "subscriberId": activeSubscriberId,
                "alertDate": null,
                "notes": "",
                "isForAll": true,
                "serialNo": 0,
                "attatchedURL": ""
            }, { params: { operation: 4 } });
            setNotesTable({ status: "Completed", data: res.data });
            // console.log(res.data)
        } catch (error) {
            setNotesTable(new ApiData("Failed"));
            logger.error(error);
        }
    };

    const deleteNotesTableData = async (tableData: NotesTableData) => {
        try {
            const currentDate = convertTimezone(dayjs());
            const response = await httpService.post('/Subscriber/SubscriberNoteOperations', {
                "subscriberId": activeSubscriberId,
                "serialNo": tableData.SerialNo,
                // "attachedURL": tableData.AttachedURL,

            }, { params: { operation: 2 } });
            if (response.status === HttpStatusCode.OK) {
                appToast.info("Notes has been deleted");
            }
        } catch (error) {
            appToast.error("Couldn't delete the agreement!");
        }
    };

    const dateValidCheck = (date: string) => {
        if (date === "Invalid Date") return "";
        return date;
    };
    const getData = () => notesTable?.data?.Table?.map((r, i) => ({
        id: r.SerialNo.toString(),
        note: r.Notes,
        userName: r.LogUserID,
        date: dateValidCheck(dayjs(r.LogDTS).format("MM/DD/YYYY")),
        notiDate: dateValidCheck(dayjs(r.AlertDate).format("MM/DD/YYYY")),
        attachment: r.AttachedURL,

    }));

    const getTabTitle = (actionType: FormActionType) => {
        switch (actionType) {
            case "add":
                return "Add";
            case "edit":
                return "Edit";
            default:
                return "Add/Edit";
        }
    };

    return {
        iniValues,
        valSchema,
        handleButtonChange,
        customTimeRangeEnd,
        customTimeRangeStart,
        getData,
        handleEditIconChange,
        onRowEventType,
        extractFileName,
        handleButtonClose,
        tabValue,
        setTabValue,
        formActionType,
        handleChange,
        handleSnackBarClose,
        activeDeleteTransId,
        handleSnackBarConfirm,
        getTabTitle,
        notesTable,
        uploadNotesFile,
        handleFileUpload,
        fileUpload,
        handleUploadNotesFile,
        fileUploadState,
        saveNotesFile,
        formikRef,
        onCheckChange
    };
}
