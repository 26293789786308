/* eslint-disable camelcase */
/* eslint-disable max-classes-per-file */
import { _SubscriberFunctionalStatus } from "./enums/subscriber-functional-status";
import { _SubscriberSubscriptionType } from "./enums/subscriber-subscription-type";
import { _AdditionalSubscriptionType } from "./enums/additional-subscription-type";
import { _PaymentModeForSubscriberSearch } from "./enums/payment-mode-for-subscriber-search";
import { _SubscriberFeature } from "./enums/subscriber-feature";
import { _SubscriberSubscriptionCategory } from "./enums/subscriber-subscription-category";
import { _VehicleType } from "./enums/vehicle-type";

export interface SubscriberSearch_V2 extends SearchableProperties, PaginationProperties, FilterableProperties {
    //  SubscriberId?: string; //  Not needed
    SignupStartDate?: Date | string;
    SignupEndDate?: Date | string;
    ActivationStartDate?: Date | string;
    ActivationEndDate?: Date | string;
    DisableStartDate?: Date | string;
    DisableEndDate?: Date | string;

    IsReseller?: boolean;
    SalesAgentId?: string;

    // For Excel Report if needed
    IsExcel?: boolean;
    ExcelReportName?: string;
    ExcelReportType?: string;
    requestId?: string;
}

export class SearchableProperties {
    query?: string; //  For front end use only
    Keyword?: string;
    // Subscriber
    DealershipName?: string;
    OwnerName?: string;
    // Location
    City?: string;
    State?: string;
    Country?: string;
    Zip?: string;
    GroupName?: string;
    PerformanceManagerName?: string;
}

export class FilterableProperties {
    CarType?: _VehicleType[];
    SubscriberStatus?: _SubscriberFunctionalStatus[];

    // Subscription
    SubscriptionCategory?: _SubscriberSubscriptionCategory[];
    SubscriptionType?: _SubscriberSubscriptionType[];
    AdditionalSubscriptionType?: _AdditionalSubscriptionType[];

    // Feature
    FeatureBitmask?: _SubscriberFeature;

    // Billing
    HasBillingActivated?: boolean;
    HasCC?: boolean;
    PaymentMode?: _PaymentModeForSubscriberSearch;
    HasAgreement?: boolean;
    HasGoneLive?: boolean;
    HasWebIntegration?: boolean;
}

export class PaginationProperties {
    // For Server-side Pagination
    PageNo?: number;
    NumberOfRows?: number;
}
export const keysOfFilterableProperties = Object.keys(new FilterableProperties());
export const keysOfPaginationProperties = Object.keys(new PaginationProperties());
export const keysOfSearchableProperties = Object.keys(new SearchableProperties());
