export interface LeadInfoV2 {
    LeadId: string,
    SubscriberId: string,
    SessionId: string,
    ChatId: string,
    LeadType: _LeadType,
    Department: string,
    Subject: string,
    LeadEmailSubject: string,
    Notes: string,
    VisitorName: string,
    PhoneNumber: string,
    "Phone_2": string,
    ZipOrPc: string,
    EmailId: string,
    AppointmentDate: Date,
    AppointmentTime: string,
    AppointmentMode: string,
    AppointmentConfirmationDetail: string,
    AppointmentAdditionalInfo: string,
    Interest: string,
    Make: string,
    Model: string,
    Type: string,
    Year: string,
    VIN: string,
    Stock: string,
    "TradeIn_Make": string,
    "TradeIn_Model": string,
    "TradeIn_Type": string,
    "TradeIn_Year": string,
    "TradeIn_Odo_Km_Or_Miles": string,
    PaymentMethod: _PaymentMethod,
    DownPayment: Number,
    VhState: string,
    submissionDate: Date
}

export interface LeadInformationV2 {

    LeadId: string,
    SubscriberId: string,
    SessionId: string,
    ChatId: string,
    LeadType: _LeadType,
    Department: string,
    Subject: string,
    Notes: string,
    VisitorName: string,
    PhoneNumber: string,
    "Phone_2": string,
    ZipOrPc: string,
    EmailId: string,
    AppointmentDate: Date,
    AppointmentTime: string,
    AppointmentMode: string,
    SubmissionDate: Date,
    ChatType: _ChatType
}

export enum _PaymentMethod {
    None = 0,
    Finance,
    Cash
}
export enum _LeadType {
    None,
    Elead,
    Plead,
    VIPlead,
    Query
}
export enum _ChatType {
    None = 0,
    SignalR = 1,
    Polling = 2,
    SMS = 3,
    Facebook = 4,
    Call = 5,
    GoogleBusinessChat = 6,
    Instagram = 7,
    WhatsApp = 8
}
