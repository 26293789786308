import * as React from "react";
import ColorCard from "./color-card";
import webVisitorBG from "../../../assets/images/web-visitors-bg.svg";
import fbChatBG from "../../../assets/images/fb-chats.svg";
import liveOptBG from "../../../assets/images/live-operators-bg.svg";
import textChatBG from "../../../assets/images/text-chats-bg.svg";
import webChatBG from "../../../assets/images/web-chats-bg.svg";
import { useRealTime } from "../hooks/useRealTime";

const Realtime: React.FC = () => {
  // const { webVisitorStatus, webVisitorData, webChatData, webChatStatus, textChatData, textChatStatus, fbChatData, fbChatStatus, liveOperatorData, liveOperatorStatus } = props;
    const { websiteVisitors, webChat, textChat, fbChat, liveOperator } = useRealTime();
    return (
        <div className="card b-m-4 r-m-4 r-m-phone-inv flex-g-1 ">
            <h1 className="card-title b-m-2">Realtime Dashboard</h1>
            <div className="card-body flex-row flex-wrap counters">
                <ColorCard type="Visitor" value={`${websiteVisitors.data ?? ""}`} isLoading={websiteVisitors.status === "Pending"} error={websiteVisitors.status === "Failed" ? "No data found" : ""} background={webVisitorBG} />
                <ColorCard type="WebChat" value={`${webChat.data ?? ""}`} isLoading={webChat.status === "Pending"} error={webChat.status === "Failed" ? "No data found" : ""} background={webChatBG} />
                <ColorCard type="TextChat" value={`${textChat.data ?? ""}`} isLoading={textChat.status === "Pending"} error={textChat.status === "Failed" ? "No data found" : ""} background={textChatBG} />
                <ColorCard type="FacebookChat" value={`${fbChat.data ?? ""}`} isLoading={fbChat.status === "Pending"} error={fbChat.status === "Failed" ? "No data found" : ""} background={fbChatBG} />
                <ColorCard type="Operator" value={`${liveOperator.data ?? ""}`} isLoading={liveOperator.status === "Pending"} error={liveOperator.status === "Failed" ? "No data found" : ""} background={liveOptBG} />
            </div>
        </div>
    );
};

export default React.memo(Realtime);
