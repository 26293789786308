import queryString from "query-string";

export function queryToObject<T>(query: string): T {
    // const params = new URLSearchParams(query);
    // const obj: any = {}
    // params.forEach((v, k: string) => {
    //     obj[k] = !!~v.indexOf(',') ? v.split(',').map(value => castInRealType(value)) : castInRealType(v);
    // });

    return queryString.parse(query, { parseBooleans: true, parseNumbers: true }) as unknown as T;
}

export function objectToQuery(object: { [x: string]: any }) {
    // const query = new URLSearchParams(object);
    return queryString.stringify(object);
}

export function objectToQueryCommaSperated(object: { [x: string]: any }) {
    return queryString.stringify(object, { arrayFormat: "comma" });
}
