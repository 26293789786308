import { DataLoadState } from "./types";

export interface XHRCallState {
    status: DataLoadState;
    msg?: string; // To store a single error or any other message
    errors?: string[]; // To Store list of error messages
}

export class ApiData<T> {
    status: DataLoadState = "Completed";
    msg?: string | undefined;
    errors?: string[] | undefined;
    data?: T;

    constructor(firstStatus?: DataLoadState) {
        this.status = firstStatus ?? "Completed";
        this.data = undefined;
    }
}
