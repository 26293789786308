import React from "react";
import IcoMoon, { IconProps } from "react-icomoon";
import iconSelection from "../../assets/json/iconSelection.json";

export type IconSet = "actionDashboard" | "add" | "americanExpress" | "appointment" | "appointmentPro" |
    "appointmentSettingSoftware" | "billOverview" | "blankCard" | "calender" | "callService" | "callTransfer" |
    "camera" | "chatPro" | "chatsIcon" | "chatTranscript" | "cirrus" | "citationPro" | "contentMarketingAndBlog"
    | "contentPro" | "crossBold" | "crossThin" | "delete" | "discoverCard" | "dualBilling" | "edit" |
    "excelDownload" | "excelGenerate" | "facebookChat" | "facebookMarketplace" | "filter"
    | "googleAdsPerClick" | "googleMyBusiness" | "group" | "groupHead" | "instagram" | "leadIcon" |
    "leadToRing" | "liveChatSMSTextSoftware" | "maestro" | "mail" | "masterCard" | "noView" | "paypal" |
    "performanceManager" | "phonePro" | "progressBarIconBoat" | "progressBarIconCar" | "progressBarIconLegal"
    | "progressBarIconRV" | "progressBarIconSP" | "refresh" | "reputationManagement" | "reseller" | "resellerHead" |
    "search" | "seoPro" | "seoWebsite" | "serviceAppointment" | "settings" | "smsChat" | "socialMediaBestPlan" |
    "socialMediaBetterPlan" | "socialMediaGoodPlan" | "submitLead" | "tick" | "user" | "videoChat" | "view" |
    "visa" | "webChat" | "websiteIcon" | "websitePro" | "whatsapp";
interface ChatleadIconProps extends IconProps {
    icon: IconSet;
}

const ChatleadIcon: React.ForwardRefRenderFunction<HTMLSpanElement, ChatleadIconProps> = (({ size = 20, className, color = "var(--muted-text-color)", ...props }, ref) => (
    <span className={className} style={{ width: size, height: size }} ref={ref}>
        <IcoMoon iconSet={iconSelection} size={size} color={color} {...props} />
    </span>
));

export default React.forwardRef(ChatleadIcon);
