// @ts-nocheck
// TODO: fix the button type checking
import React from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
// import { makeStyles } from '@material-ui/core/styles';
// import { IconButton } from '@material-ui/core';
import { PropTypes } from '@material-ui/core';
import { CommonProps } from '../../model/common-props';
import { UIButton } from '../form';

interface MenuListProps extends CommonProps {
    onClick?: (val: number | string) => void;
    children?: React.ReactNode;
    list: { label: string, value: string | number, icon?: React.ReactNode }[];
    buttonSize?: "small" | "medium";
    buttonColor?: PropTypes.Color;
    buttonClassName?: string;
    buttonContainerClassName?: string;
    buttonVariant?: "text" | "outlined" | "contained" | "icon";
    // menuOpen?: boolean;
}

const MenuListUI: React.FC<MenuListProps> = (props) => {
    const { list, buttonSize, buttonColor, buttonClassName, onClick, buttonContainerClassName, buttonVariant = "icon" } = props;
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };
    return (
        <>
            <span className={buttonContainerClassName}>
                <UIButton className={buttonClassName} variant={buttonVariant} ref={anchorRef} size={buttonSize} color={buttonColor} onClick={handleToggle}>{props.children}</UIButton>
            </span>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition style={{ zIndex: 9999 }}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow">
                                    {
                                        list && list.map((v, i) => <MenuItem onClick={() => onClick(v.value)} key={v.value}> {!!v.icon && v.icon} {v.label} </MenuItem>)
                                    }

                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};

export default MenuListUI;
