import { TextField, TextFieldProps, InputAdornment, CircularProgress } from "@material-ui/core";
import clsx from "clsx";
import React, { forwardRef, memo } from "react";
import InputMask from "react-input-mask";
import { CommonInputProps } from "../../../model/common-props";

export interface UIInputProps extends CommonInputProps {
    onChange?: (evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onBlur?: (evt: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    inputProps?: TextFieldProps;
    mask?: any;
    maskChar?: string;
    type?: string;
    loading?: boolean;
    multiline?: boolean;
    rows?: number;
    color?: "primary" | "secondary";
    helperText?: string;
}

const UIInput: React.ForwardRefRenderFunction<HTMLInputElement | HTMLTextAreaElement, UIInputProps> = (props, ref) => {
    let { inputProps } = props;
    const { disabled, required, readOnly, name, value, color, type = "text", mask, id, maskChar, onChange, errorMessage, loading, multiline, helperText, rows } = props;

    if (!inputProps) inputProps = {};
    const { variant, ...inputPropsXVariant } = inputProps;

    if (!mask && props.value !== undefined) { inputPropsXVariant.value = props.value; }
    if (props.label !== undefined) { inputPropsXVariant.label = props.label; }
    if (!mask && props.onChange !== undefined) { inputPropsXVariant.onChange = props.onChange; }
    if (!mask && props.onBlur !== undefined) { inputPropsXVariant.onBlur = props.onBlur; }
    return (
        <div className={clsx("flex-row", props.className)} style={{ width: "100%", ...props.style }}>

            {
                mask ? (<InputMask
                    mask={mask}
                    value={value}
                    name={name}
                    readOnly={readOnly}
                    maskChar={maskChar}
                    onChange={onChange}
                    disabled={disabled ?? false}
                >
                    {() => <TextField
                        disabled={disabled ?? false}
                        name={name}
                        required={required}
                        multiline={multiline}
                        rows={rows}
                        id={id}
                        inputRef={ref}
                        className="flex-grow"
                        type="text"
                        color={color}
                        variant="outlined"
                        error={!!errorMessage}
                        helperText={errorMessage || helperText}
                        InputProps={loading ? {
                            readOnly,
                            endAdornment: <InputAdornment position="end"><CircularProgress color="secondary" size={20} /></InputAdornment>
                        } : {
                            readOnly,
                        }}
                        {...inputPropsXVariant}
                    />}
                </InputMask>) : (
                    <TextField
                        disabled={disabled}
                        name={name}
                        id={id}
                        required={required}
                        multiline={multiline}
                        rows={rows}
                        type={type}
                        className="flex-grow"
                        variant="outlined"
                        inputRef={ref}
                        color={color}
                        error={!!errorMessage}
                        helperText={errorMessage || helperText}
                        InputLabelProps={
                            {
                                htmlFor: id || name
                            }
                        }
                        InputProps={loading ? {
                            readOnly,
                            endAdornment: <InputAdornment position="end"><CircularProgress color="secondary" size={20} /></InputAdornment>
                        } : {
                            readOnly,
                        }}
                        {...inputPropsXVariant}
                    />
                )
            }
        </div>
    );
};

export default memo(forwardRef(UIInput));
