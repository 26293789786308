import { useEffect, useState } from "react";
import { FilterOption } from "../../../../model/filter-data";
import { OptionGroupProps } from "../../models/option-group-props";

export const useOptionGroup = (props: OptionGroupProps) => {
    const [options, setOptions] = useState([] as FilterOption[]);

    const applySelectedValues = ({ options, selectedValues, isBitMask }: OptionGroupProps) => {
        if (Array.isArray(selectedValues) && selectedValues.length) {
            return options?.map(option => (~selectedValues.indexOf(option.internalValue) ? { ...option, selected: true } : option));
        } if (typeof selectedValues !== 'undefined' || selectedValues !== null) {
            if (isBitMask) {
                return options?.map(option => ((
                    (option.internalValue as number) & (selectedValues as number)) === option.internalValue ? { ...option, selected: true } :
                    option));
            }
            return options?.map(option => (option.internalValue === selectedValues ? { ...option, selected: true } : option));
        }
        return options;
    };

    useEffect(() => {
        if (props.options) setOptions(applySelectedValues(props) || []);
    }, [props]);

    const handleOptionClick = (option: FilterOption) => {
        if (props.selectionBehavior === "single") {
            props.onOptionChange({ path: props.path, value: !option.selected ? option.internalValue : undefined });
        } else {
            const selectedValues: any[] = [];
            options.map(item => {
                const processedOption = (item === option) ? { ...item, selected: !item.selected } : item;
                if (processedOption.selected) selectedValues.push(processedOption.internalValue);
                return processedOption;
            });

            props.onOptionChange({ path: props.path, value: selectedValues });
        }
    };

    return {
        handleOptionClick,
        options
    };
};
