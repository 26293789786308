import { Button, ButtonProps, IconButton, IconButtonProps, PropTypes, withStyles } from "@material-ui/core";
import { pink, teal } from "@material-ui/core/colors";
import clsx from "clsx";
import React, { forwardRef, memo } from "react";
import { CommonProps } from "../../../model/common-props";

export interface UIButtonProps extends CommonProps {
    type?: "button" | "submit" | "reset";
    size?: "small" | "medium" | "large";
    children?: React.ReactNode;
    buttonProps?: ButtonProps;
    color?: PropTypes.Color | "teal" | "danger",
    rounded?: boolean;
    variant?: "text" | "outlined" | "contained";
    onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
    title?: string;
}
export interface UIIconButtonProps extends CommonProps {
    type?: "button" | "submit" | "reset";
    size?: "small" | "medium";
    children?: React.ReactNode;
    buttonProps?: IconButtonProps;
    color?: PropTypes.Color,
    variant?: "icon";
    onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
    title?: string;
}

const TealColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(teal.A200),
        backgroundColor: teal.A200,
        '&:hover': {
            backgroundColor: teal.A400,
        },
        '&:disabled': {
            backgroundColor: teal.A700,
        },
    },
}))(Button);

const DangerColorButton = withStyles((theme) => ({
    root: {
        color: pink.A200,
        borderColor: pink.A200,
        '&:hover': {
            borderColor: pink.A400,
        },
        '&:disabled': {
            borderColor: pink.A700,
        },
    },
}))(Button);

const UIButton: React.ForwardRefRenderFunction<HTMLButtonElement, UIButtonProps | UIIconButtonProps> = (props, ref) => {
    let { buttonProps } = props;
    const { children, type, form, disabled, variant, color, size, className, buttonProps: ignoreProps, style, onClick, ...restPropsUni } = props;

    if (!buttonProps) buttonProps = {};

    const { rounded, ...restProps } = restPropsUni as any;

    if (props.onClick) { buttonProps.onClick = onClick; }
    if (props.style !== undefined) { buttonProps.style = style; }
    if (variant !== "icon" && rounded) {
        if (props.style !== undefined) { buttonProps.style = { borderRadius: 30, ...props.style }; } else { buttonProps.style = { borderRadius: 30 }; }
    }

    return (
        variant === "icon" ? (
            <IconButton ref={ref} size={size} className={clsx(className)} disabled={disabled} type={type} form={form} color={color || "default"} {...(buttonProps as IconButtonProps)} {...restProps}>
                {children}
            </IconButton>
        ) : (
            color === "teal" ? (
                <TealColorButton ref={ref} size={size} className={clsx(className)} disabled={disabled} type={type} form={form} variant={variant ?? "contained"} color="primary" {...buttonProps} {...restProps}>
                    {children}
                </TealColorButton>
            ) : color === "danger" ? (
                <DangerColorButton ref={ref} size={size} className={clsx(className)} disabled={disabled} type={type} form={form} variant={variant ?? "outlined"} color="primary" {...buttonProps} {...restProps}>
                    {children}
                </DangerColorButton>
            ) : (
                <Button ref={ref} size={size} className={clsx(className)} disabled={disabled} type={type} form={form} variant={variant ?? "contained"} color={color || "primary"} {...buttonProps} {...restProps}>
                    {children}
                </Button>
            )
        )
    );
};

export default memo(forwardRef(UIButton));
