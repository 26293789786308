import * as React from "react";
import { PublicLayout } from "../../layouts/public-layout";
import { _TransactionStatus } from "../../model/enums/invoice-enums";
import { RouteAdditionalPropsV2 } from "../../model/route-additional-propsV2";
import useProcessPayment from "./hooks/useProcessPayment";
import PaymentFailure from "../../assets/images/payment-failure-cross.svg";
import PaymentSuccess from "../../assets/images/payment-success-tick.svg";
import { UIButton } from "../../components/form";

export interface ProcessPaymentProps extends RouteAdditionalPropsV2 { }

const ProcessPayment: React.FC<ProcessPaymentProps> = (props) => {
    const {
        state,
        isPaymentProcessing,
        isPaymentSuccess,
        isPaymentFailed,
        backToInvoice,
    } = useProcessPayment();
    return (
        <PublicLayout {...props}>
            <div className="process-payment flex-row full-size justify-center">
                <div className="flex-column justify-center flex-center">
                    {isPaymentProcessing() && (
                        <div className="process-payment__processing flex-column flex-center">
                            <div className="process-payment__loader">
                                <div className="inside">
                                    <div />
                                </div>
                            </div>

                            <div className="process-payment__label">
                                <p>Your payment is being processed.</p>
                                <p>Please don't close or refresh the browser.</p>
                            </div>
                        </div>
                    )}

                    {isPaymentSuccess() && (
                        <div className="process-payment__success">
                            <div className="ticket flex-column flex-center">
                                <div className="success-tick">
                                    <img src={PaymentSuccess} alt="success" />
                                </div>
                                <div className="success-label">Payment Successful!</div>
                                <div className="info">Transaction Number : {state?.transactionId ?? ""}</div>
                                <div className="dashed-seperator" />
                                <div className="amount info flex-row">
                                    <div className="r-m-1">Amount Paid :</div>
                                    <div style={{ fontWeight: 600 }}>${state.amount ?? ""}</div>
                                </div>
                            </div>
                        </div>
                    )}

                    {isPaymentFailed() && (
                        <div className="process-payment__failed">
                            <div className="ticket flex-column flex-center">
                                <div className="failure-cross">
                                    <img src={PaymentFailure} alt="falied" />
                                </div>
                                <div className="failure-label">Payment Failed!</div>
                            </div>
                        </div>
                    )}

                    {!isPaymentProcessing() && (
                        <div className="process-payment__button">
                            <UIButton
                                size="large"
                                rounded
                                // className="btn-primary btn--round"
                                onClick={backToInvoice}
                            >
                                Back to Invoice
                            </UIButton>
                        </div>
                    )}
                </div>

                <svg
                    className="svg"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ position: "fixed", zIndex: -1 }}
                >
                    <clipPath id="ticket-clip-path" clipPathUnits="objectBoundingBox">
                        <path d="M1,0 l0,0.429 c-0.015,0.007,-0.026,0.033,-0.026,0.065 s0.011,0.058,0.026,0.065 V1 H0 V0.559 c0.015,-0.007,0.026,-0.033,0.026,-0.065 S0.015,0.436,0,0.429 V0 h1" />
                    </clipPath>
                </svg>
            </div>
        </PublicLayout>
    );
};

export default ProcessPayment;
