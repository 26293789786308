import * as React from "react";
import clsx from "clsx";
import { useRouteMatch } from "react-router-dom";
import { CircularProgress, useMediaQuery } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import RouteWithSubRoutes from "../../components/routers/route-with-subroutes";
import Scrollable from "../../components/scrollable";
import SideNavBar from "./components/side-navbar";
import TopBar from "./components/topBar";
import { useDashboardLayout } from "./hooks/useDashboardLayout";
import theme from "../../theme";
import { cc24AdminRoles } from "../../model/roles-and-rights";
import { selectFeedBackOpen, setFeedBackDialogOpen } from "../../store/modules/ui";
import FeedBackDialog from "../../components/Dialogs/feedback-dialog/feedback-dialog";

export interface DashboardLayoutProps { subscriberViewBasePath: string; }

const DashboardLayout: React.FC<DashboardLayoutProps> = () => {
    const { url } = useRouteMatch();
    // const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
    const isTab = useMediaQuery(theme.breakpoints.down("md"));
    const {
        isMobileNavOpen,
        setMobileNavOpen,
        subscriberViewBasePath,
        getRoutes,
        getFilteredSubscriberRoutes,
        currentSubscriberLoading,
        subscriberView,
        currentLocation,
        userLoading, user
    } = useDashboardLayout();
    const { feedBackDialog } = useSelector(selectFeedBackOpen);
    const dispatch = useDispatch();
    const handleDialogClose = () => {
        dispatch({ type: setFeedBackDialogOpen.type, payload: false });
    };

    const loader = <div className="flex-grow flex flex-column" style={{ position: 'relative', height: "100%" }}>
        <div style={{ top: "50%", left: "50%", transform: "translate(-50%, -50%)", position: "absolute" }}>
            <CircularProgress size={40} />
        </div>
    </div>;
    const contentArea = (
        <Scrollable classes={clsx("content-wrapper")}>
            {getRoutes.map((item) => (
                <RouteWithSubRoutes
                    key={item.path}
                    routes={[item]}
                    currentPath={url}
                />
            ))}
        </Scrollable>
    );

    return (
        <div className="flex-column full-size">
            <div className="flex-row flex-grow scroll-hidden">
                <div className="flex-column">
                    <SideNavBar
                        onMobileClose={() => setMobileNavOpen(false)}
                        openMobileNav={isMobileNavOpen}
                        routes={
                            subscriberView.enable ? getFilteredSubscriberRoutes : getRoutes
                        }
                        basePath={subscriberView.enable ? subscriberViewBasePath : url}
                    />
                </div>
                <div className="flex-column flex-grow" style={{ width: isTab ? "100%" : "calc(100% - 256px)", background: "var(--surface-color)" }}>
                    {
                        isTab &&
                        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
                    }
                    <motion.div
                        className="flex-grow flex flex-column"
                        key={currentLocation}
                        variants={{
                            initial: { opacity: 0, x: 100 },
                            animate: { opacity: 1, x: 0, overflow: "auto" },
                            exit: { opacity: 0, x: 100 },
                        }}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{ duration: 0.3 }}
                    >
                        {
                            !userLoading && user ?
                                cc24AdminRoles.includes(user?.role) ? (
                                    !subscriberView.loading ?
                                        subscriberView.enable ?
                                            currentSubscriberLoading ? loader
                                                : contentArea
                                            : contentArea
                                        : loader
                                ) : (
                                    userLoading ? loader : contentArea
                                )
                                : loader
                        }
                    </motion.div>
                </div>
            </div>
            <FeedBackDialog dialogOpen={feedBackDialog} dialogClose={handleDialogClose} />
        </div>
    );
};

export default DashboardLayout;
