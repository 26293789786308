import { DialogActions } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { UIButton, UIRadio, UIRadioGroup, UISelect } from "../../../components/form";
import useRebrandingSettings from "./hooks/useRebrandingSettings";

export interface RebrandingSettingsProps {
    handleClose: any,
    subscriberId: any
}

const RebrandingSettings: React.FunctionComponent<any> = (props) => {
    const { handleClose, subscriberId, isRebrandingHead, rebrandingName } = props;

    const {
        data, setAsRebrandingHead, SetAsRebranding, unsetAsRebranding
    } = useRebrandingSettings();

    const [rbSelected, setrbSelected] = useState<string>('');
    const [disabled, setDisabled] = useState<boolean>(true);
    const [rbrndSelected, setRbrndSelected] = useState<string>('');

    const formSchema = {
        rebrandingRadio: {},
        rebranding: { name: "rebranding", options: data, value: rbrndSelected, label: "Select an option", disabled, className: "r-m-2 r-m-phone-inv" },
    };

    // const handleChange =(e: React.ChangeEvent<any>)=>{
    //     setrbSelected(e.target.value);
    // }
    const handleChange = (_event: any, value: any) => {
        setrbSelected(value);
    };
    const handleSelectChange = (e: React.ChangeEvent<any>) => {
        setRbrndSelected(e.target.value);
    };
    const saveData = () => {
        if (rbSelected === 'rbMakeAsRebrandingHead') {
            setAsRebrandingHead(subscriberId);
        } else if (rbSelected === 'rbAddtoRebranding' && rbrndSelected !== '') {
            SetAsRebranding(subscriberId, rbrndSelected);
        } else if (rbSelected === 'rbUnsetAsRebrandingHead') {
            unsetAsRebranding(subscriberId);
        }
    };

    useEffect(() => {
        // logger.log(`rbselected: ${rbSelected}`);
        if (rbSelected === 'rbAddtoRebranding') setDisabled(false); else setDisabled(true);
        // logger.log(disabled);
    }, [rbSelected]);

    return (
        <div>
            <div className="flex-responsive">
                {rebrandingName ? <small>Rebranded Member of {rebrandingName}</small> : ''}
            </div>
            <div className="flex-responsive t-m-4">
                <small>Action:</small>
            </div>
            <div className="flex-responsive">
                {(() => {
                    if (isRebrandingHead) {
                        return (
                            <UIRadioGroup {...formSchema.rebrandingRadio} onChange={handleChange}>
                                <UIRadio id="rbUnsetAsRebrandingHead" value='rbUnsetAsRebrandingHead' label="Unset this subscriber as rebranding head" />
                                <UIRadio id="rbAddtoRebranding" value='rbAddtoRebranding' label="Add to the following rebranding below" disabled />
                            </UIRadioGroup>);
                    }

                    return (
                        <UIRadioGroup {...formSchema.rebrandingRadio} onChange={handleChange}>
                            <UIRadio id="rbMakeAsRebrandingHead" value='rbMakeAsRebrandingHead' label="Make this subscriber rebranding head" />
                            <UIRadio id="rbAddtoRebranding" value='rbAddtoRebranding' label="Add to the following rebranding below" />
                        </UIRadioGroup>);
                }
                )()}
            </div>
            <div className="flex-responsive t-m-1 r-m-2 l-m-2">
                <UISelect {...formSchema.rebranding} onChange={handleSelectChange} />
            </div>
            <DialogActions className="vm-m-2" style={{ position: 'absolute', bottom: '0', width: '100%', right: '10px' }}>
                <UIButton onClick={saveData}>
                    Save
                </UIButton>
                <UIButton color="danger" onClick={handleClose}>
                    Close
                </UIButton>
            </DialogActions>
        </div>
    );
};

export default RebrandingSettings;
