import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import React from 'react';
import ChatleadIcon from '../../components/chatlead-icon';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
        flexWrap: "wrap",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        overflow: "hidden auto"
    },
    paper: {
        padding: theme.spacing(2),
        margin: theme.spacing(1),
        textAlign: 'center',
        minWidth: 300,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: theme.palette.text.secondary,
    },
}));

const ChatleadIconDemo = () => {
    const classes = useStyles();

    const icons = ["actionDashboard", "add", "americanExpress", "appointment", "appointmentPro",
        "appointmentSettingSoftware", "billOverview", "blankCard", "calender", "callService", "callTransfer",
        "camera", "chatPro", "chatsIcon", "chatTranscript", "cirrus", "citationPro", "contentMarketingAndBlog",
        "contentPro", "crossBold", "crossThin", "delete", "discoverCard", "dualBilling", "edit",
        "excelDownload", "excelGenerate", "facebookChat", "facebookMarketplace", "filter",
        "googleAdsPerClick", "googleMyBusiness", "group", "groupHead", "instagram", "leadIcon",
        "leadToRing", "liveChatSMSTextSoftware", "maestro", "mail", "masterCard", "noView", "paypal",
        "performanceManager", "phonePro", "progressBarIconBoat", "progressBarIconCar", "progressBarIconLegal",
        "progressBarIconRV", "progressBarIconSP", "refresh", "reputationManagement", "reseller", "resellerHead",
        "search", "seoPro", "seoWebsite", "serviceAppointment", "settings", "smsChat", "socialMediaBestPlan",
        "socialMediaBetterPlan", "socialMediaGoodPlan", "submitLead", "tick", "user", "videoChat", "view",
        "visa", "webChat", "websiteIcon", "websitePro", "whatsapp"];

    return (
        <div className={classes.root}>
            {
                icons.map((icon, i) => <Paper className={classes.paper} key={i}>
                    <ChatleadIcon icon={icon as any} size={40} color="red" />
                    <span>{icon}</span>
                </Paper>)
            }
        </div>
    );
};

export default ChatleadIconDemo;
