import { CircularProgress, PropTypes } from "@material-ui/core";
import React, { forwardRef, ForwardRefRenderFunction, memo } from "react";
import { UIButton } from "..";
import { UIButtonProps, UIIconButtonProps } from "./ui-button";

interface UILoaderButtonProps {
    isLoading?: boolean;
    shouldBeDisabledWhileLoading?: boolean;
}

const UILoaderButton: ForwardRefRenderFunction<HTMLButtonElement, UILoaderButtonProps & (UIButtonProps | UIIconButtonProps)> = ({ isLoading, shouldBeDisabledWhileLoading = false, children, disabled, ...props }, ref) => {
    const getProperPropsForLoader = (): { size?: number, color?: "inherit" | "primary" | "secondary" } => {
        const { buttonProps } = props;
        let { size, color, variant } = props;
        if (color === undefined) { color = (buttonProps?.color || "primary") as (PropTypes.Color | "teal" | "danger" | undefined); }
        if (variant === undefined) { variant = color === "teal" ? "contained" : color === "danger" ? "outlined" : "contained"; }
        if (size === undefined) { size = size || "medium"; }
        let circProgProps = {};
        circProgProps = { ...circProgProps, color: "inherit" };

        if (size === "large") { circProgProps = { ...circProgProps, size: 26.25 }; }
        if (size === "medium") { circProgProps = { ...circProgProps, size: 24.5 }; }
        if (size === "medium" && variant === "icon") { circProgProps = { ...circProgProps, size: 25 }; }
        if (size === "small") { circProgProps = { ...circProgProps, size: 22.75 }; }
        if (size === "small" && variant === "icon") { circProgProps = { ...circProgProps, size: 20 }; }

        return circProgProps;
    };
    return <UIButton disabled={shouldBeDisabledWhileLoading ? (isLoading || disabled) : disabled} {...props} ref={ref}>{isLoading ? <CircularProgress {...getProperPropsForLoader()} /> : children}</UIButton>;
};

export default memo(forwardRef(UILoaderButton));