import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { AppBar } from '@material-ui/core';
import clsx from 'clsx';
import FailedComponent from '../failed-component';

interface ChildProps<T> {
    value: T;
    unmountOnExit?: boolean;
}

interface TabViewProps<ValueType> {
    tabHeaders: string[];
    children: (data: ChildProps<ValueType>) => React.ReactNode;
    appBar?: boolean;
    centered?: boolean;
    variant?: 'standard' | 'scrollable' | 'fullWidth';
    initialTabValue: ValueType;
    roundedCorners?: boolean;
    onTabValueChange?: (value: ValueType) => void;
    className?: string;
    tabValue?: ValueType;
    unmountOnExit?: boolean;
}

const TabView = <K extends number>({ tabHeaders, children, centered, onTabValueChange, variant, initialTabValue, className, tabValue, roundedCorners = false, appBar = false, unmountOnExit = false }: TabViewProps<K>) => {
    const useStyles = makeStyles((theme: Theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            borderBottomLeftRadius: roundedCorners ? 5 : 0,
            borderBottomRightRadius: roundedCorners ? 5 : 0
        },
    }));

    const classes = useStyles();
    const [value, setValue] = React.useState<K>(initialTabValue);

    React.useEffect(() => {
        if (tabValue) { setValue(tabValue); }
    }, [tabValue]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: K) => {
        setValue(newValue);
        if (onTabValueChange) { onTabValueChange(newValue); }
    };

    function a11yProps(index: any) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const tabs = <Tabs value={tabValue || value} onChange={handleChange} aria-label="simple tabs" centered={centered} variant={variant ?? 'standard'}>
        {
            tabHeaders.map((header, i) => (
                <Tab label={header} {...a11yProps(i)} key={i} />
            ))
        }
    </Tabs>;

    return (
        <div className={clsx(className, classes.root)}>
            {tabHeaders.length === React.Children.count(children({ value })) ? <>
                {
                    appBar ? (
                        <AppBar position='static' style={roundedCorners ? { borderTopRightRadius: 5, borderTopLeftRadius: 5 } : {}}>
                            {tabs}
                        </AppBar>) : tabs
                }
                {
                    children({ value, unmountOnExit })
                }
            </> : <FailedComponent type="error" text="Tab Headers are not present" />}
        </div>
    );
};

export default TabView;