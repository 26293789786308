import { useDispatch } from "react-redux";
import { LoginCredentials } from "../model";
import { login, logout } from "../store/modules/authentication";

export function useAuthentication() {
    const dispatch = useDispatch();
    // TODO: Make the optional parameter mandatory
    const handleLogin = (credentials?: LoginCredentials) => {
        if (credentials) {
            dispatch(
                login(credentials)
            );
        }
    };
    const handleLogout = () => {
        dispatch(logout());
    };

    return { handleLogin, handleLogout };
}