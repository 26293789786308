import { useEffect, useState } from "react";
import appToast from "../../../../components/app-toast";
import uniLoader from "../../../../components/uni-loader";
import { HttpStatusCode } from "../../../../model/http-status-code";
import { ApiData } from "../../../../model/xhrCallState";
import httpService from "../../../../service/http-service";
import logger from "../../../../utils/log-utils";
import { castAs } from "../../../../utils/object-utils";
import { AccountManager } from "../../model/PerformanceManagerData";

export default function usePerformanceManagerSettings(subId: string, subName: string) {
    class PMData {
        constructor(options?: { id: any; name: any; type: any; from: any; to: any; }) {
            if (options) {
                this.id = options.id;
                this.name = options.name;
                this.type = options.type;
                this.from = options.from;
                this.to = options.to;
            }
        }

        id: string = '';
        name: string = '';
        type: string = '';
        from: string = '';
        to: string = '';
    }

    const initialState: InitialState = {
        pmData: new ApiData(),
    };
    type InitialState = {

        pmData: ApiData<PMData[]>,
    };

    const [state, setState] = useState<InitialState>(initialState);
    const [checked, setChecked] = useState<boolean>(false);
    const [selectedPM, setselectedPM] = useState<string>("");
    const [selectedType, setSelectedType] = useState<string>("");
    const [allPMList, setAllPMList] = useState<any[]>([]);
    const pmType = [{ label: 'Sales', value: 1 }, { label: 'Service', value: 2 }];
    // const [status, setStatus] = useState<ApiData<any>>();
    // const [data, setData] = useState<PerformanceManagerData>({ Table: [] });
    // const [data, setData] = useState<PMData[]>([]);

    useEffect(() => {
        if (state?.pmData.status === "Pending") uniLoader.showLoader(''); else uniLoader.close();
    }, [state]);

    const getPerformanceManagerHistory = async () => {
        setState((s) => ({ ...s, pmData: { status: "Pending" } }));
        try {
            // logger.log("getAllPMs");
            // logger.log(`subId = ${subId}`)
            const response = await httpService.get(`Subscriber/GetAccountManagersPerSubscriber?subscriberId=${subId}`);

            // logger.log(`response data: ${JSON.stringify(response.data)}`);
            // appToast.success('Get PMs History succeeded.');
            // setData(response.data.Table);
            const table: any[] = response.data.Table;
            setState(s => ({
                ...s,
                pmData: {
                    status: "Completed",
                    data: table.map(
                        d => new PMData({
                            id: d.id,
                            name: d.AccountManagerUserName,
                            type: d.AccountManagerType,
                            from: d.CreationDate,
                            to: d.DisabledDate
                        })
                    )
                }
            }));
        } catch (error) {
            logger.log(error);
            setState(s => ({ ...s, pmData: { status: "Failed" } }));
        }
    };

    const getAllPerformanceManager = async () => {
        setState((s) => ({ ...s, pmData: { status: "Pending" } }));
        try {
            // logger.log("getAllPMs");
            const response = await httpService.get(`Subscriber/GetAllPerformanceManager`);

            // logger.log(`response data: ${JSON.stringify(response.data)}`);
            // appToast.success('Get All PMs succeeded.');
            if (response.status === HttpStatusCode.OK) { setAllPMList(response.data.map((d: string) => ({ "label": d, "value": d }))); }
        } catch (error) {
            logger.log(error);
            setState(s => ({ ...s, pmData: { status: "Failed" } }));
        }
    };

    const setPerformanceManager = async (e: any) => {
        e.preventDefault();
        setState((s) => ({ ...s, pmData: { status: "Pending" } }));
        try {
            const body: AccountManager = {
                ID: "",
                Username: selectedPM,
                SubscriberID: subId,
                SubscriberName: subName,
                AccountManagerType: castAs<number>(selectedType),
                Status: 1
            };
            const response = await httpService.post(`Subscriber/SetAccountManagersPerSubscriber`, body);

            // logger.log(`response data: ${JSON.stringify(response.data)}`);
            if (response.status === HttpStatusCode.OK) {
                appToast.success('Performance Manager saved successfully.');
                await getPerformanceManagerHistory();
                setChecked(false);
            } else { appToast.error('Performance Manager save unsuccessful'); }
        } catch (error) {
            logger.log(error);
            appToast.error('Error occured. Performance Manager save unsuccessful');
            setState(s => ({ ...s, pmData: { status: "Failed" } }));
        }
    };

    const handleBack = async () => {
        await getPerformanceManagerHistory();
        setChecked(false);
    };

    useEffect(() => {
        getAllPerformanceManager();
        getPerformanceManagerHistory();
    }, []);

    return { state, checked, setChecked, allPMList, pmType, setselectedPM, setSelectedType, setPerformanceManager, handleBack };
}
