// import AdminDashboard from "../../pages/admin-dashboard";
import React from "react";
import { Redirect } from "react-router-dom";
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import BusinessCenterRoundedIcon from '@material-ui/icons/BusinessCenterRounded';
import ShowChartRoundedIcon from '@material-ui/icons/ShowChartRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import ThumbUpRoundedIcon from '@material-ui/icons/ThumbUpRounded';
import {
  defineAuthedRoute,
  defineInvisibleRoute,
  defineRouteWithCc24AdminRole,
  defineRouteWithChildRouteRenderer,
  suspendedComponent
} from "../../utils/route-utils";
import { RouteConfig } from "../../model/route-config";
import { subscriberRoutes } from "./subscriber-routes";
import ChildRouteRender from "../../components/routers/child-route-renderer";
import ManageSaas from "../../pages/manage-sass";
import WorkInProgress from "../../components/work-in-progress/work-in-progress";
// import MyProfile from "../../pages/my-profile";
// import SalesPortalManage from "../../pages/sales-portal-manage";
// import SalesPortalMonitor from "../../pages/sales-portal-monitor";
// import SalesPortalAgreement from "../../pages/sales-portal-agreement";
// import GeneralSettings from "../../pages/settings-general";
// import SettingsSites from "../../pages/settings-sites";
// import ReputationManagementReviews from "../../pages/reputation-management-reviews";
// import IPBlacklist from "../../pages/ip-blacklist";
// import IPWhitelist from "../../pages/ip-whitelist";
// import VisitorBlock from "../../pages/visitor-block";
// import BlockLocationPhone from "../../pages/block-location-phone";

const ChatAnalysisLazy = React.lazy(() => import("../../pages/chat-analysis"));
const ChatSnapshotLazy = React.lazy(() => import("../../pages/chat-snapshot"));
const LeadAnalysisLazy = React.lazy(() => import("../../pages/lead-analysis"));
const LeadSnapshotLazy = React.lazy(() => import("../../pages/lead-snapshot"));
const MissedChatLazy = React.lazy(() => import("../../pages/chat-missed"));
const OfflineLeadLazy = React.lazy(() => import("../../pages/lead-offline"));
const OperatorAnalysisLazy = React.lazy(() => import("../../pages/operator-analysis"));
const OperatorMissedChatLazy = React.lazy(() => import("../../pages/operator-missed-chat"));
const OperatorPerformanceLazy = React.lazy(() => import("../../pages/operator-performance"));
const OperatorSnapshotLazy = React.lazy(() => import("../../pages/operator-snapshot"));
const OperatorTimeclockLazy = React.lazy(() => import("../../pages/operator-timeclock"));
const ReportOverviewLazy = React.lazy(() => import("../../pages/report-overview"));
const SurveyAnalysisLazy = React.lazy(() => import("../../pages/survey-analysis"));
const Top10Lazy = React.lazy(() => import("../../pages/top-10"));
const VisitorSnapshotLazy = React.lazy(() => import("../../pages/visitor-snapshot"));
const MyProfileLazy = React.lazy(() => import("../../pages/my-profile"));
const SalesPortalManageLazy = React.lazy(() => import("../../pages/sales-portal-manage"));
const SalesPortalMonitorLazy = React.lazy(() => import("../../pages/sales-portal-monitor"));
const SalesPortalAgreementLazy = React.lazy(() => import("../../pages/sales-portal-agreement"));
const GeneralSettingsLazy = React.lazy(() => import("../../pages/settings-general"));
const SettingsSitesLazy = React.lazy(() => import("../../pages/settings-sites"));
const ReputationManagementReviewsLazy = React.lazy(() => import("../../pages/reputation-management-reviews"));
const IPBlacklistLazy = React.lazy(() => import("../../pages/ip-blacklist"));
const IPWhitelistLazy = React.lazy(() => import("../../pages/ip-whitelist"));
const VisitorBlockLazy = React.lazy(() => import("../../pages/visitor-block"));
const BlockLocationPhoneLazy = React.lazy(() => import("../../pages/block-location-phone"));
const DeptBasedRoutingLazy = React.lazy(() => import("../../pages/dept-based-routing"));
const BrandCampaignLazy = React.lazy(() => import("../../pages/ten-dlc-brand-campaign"));
const TenDlcNumberLazy = React.lazy(() => import("../../pages/ten-dlc-number"));

const ChatAnalysis = suspendedComponent(ChatAnalysisLazy);
const ChatSnapshot = suspendedComponent(ChatSnapshotLazy);
const LeadAnalysis = suspendedComponent(LeadAnalysisLazy);
const LeadSnapshot = suspendedComponent(LeadSnapshotLazy);
const MissedChat = suspendedComponent(MissedChatLazy);
const OfflineLead = suspendedComponent(OfflineLeadLazy);
const OperatorAnalysis = suspendedComponent(OperatorAnalysisLazy);
const OperatorPerformance = suspendedComponent(OperatorPerformanceLazy);
const OperatorMissedChat = suspendedComponent(OperatorMissedChatLazy);
const OperatorTimeclock = suspendedComponent(OperatorTimeclockLazy);
const OperatorSnapshot = suspendedComponent(OperatorSnapshotLazy);
const ReportOverview = suspendedComponent(ReportOverviewLazy);
const SurveyAnalysis = suspendedComponent(SurveyAnalysisLazy);
const Top10 = suspendedComponent(Top10Lazy);
const VisitorSnapshot = suspendedComponent(VisitorSnapshotLazy);
const MyProfile = suspendedComponent(MyProfileLazy);
const SalesPortalManage = suspendedComponent(SalesPortalManageLazy);
const SalesPortalMonitor = suspendedComponent(SalesPortalMonitorLazy);
const SalesPortalAgreement = suspendedComponent(SalesPortalAgreementLazy);
const GeneralSettings = suspendedComponent(GeneralSettingsLazy);
const SettingsSites = suspendedComponent(SettingsSitesLazy);
const ReputationManagementReviews = suspendedComponent(ReputationManagementReviewsLazy);
const IPBlacklist = suspendedComponent(IPBlacklistLazy);
const IPWhitelist = suspendedComponent(IPWhitelistLazy);
const VisitorBlock = suspendedComponent(VisitorBlockLazy);
const BlockLocationPhone = suspendedComponent(BlockLocationPhoneLazy);
const DeptBasedRouting = suspendedComponent(DeptBasedRoutingLazy);
const BrandCampaignDetails = suspendedComponent(BrandCampaignLazy);
const TenDlcNumberDetails = suspendedComponent(TenDlcNumberLazy);

/* start /report */
const reportChatRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Snapshot",
    path: "/chat-snapshot",
    title: "Analytics | Chat Snapshot",
    pageHeader: "Chat Snapshot",
    component: ChatSnapshot,
    guard: { exceptRoles: ["CC24User"] }
  }),
  defineAuthedRoute({
    name: "Analysis",
    path: "/chat-analysis",
    title: "Analytics | Chat Analysis",
    pageHeader: "Chat Analysis",
    component: ChatAnalysis
  }),
  defineAuthedRoute({
    name: "Missed Chat",
    path: "/chat-missed",
    title: "Analytics | Missed Chat",
    pageHeader: "Missed Chat",
    component: MissedChat,
    guard: {
      exceptSubscriptions: ["FullSupport"],
      exceptRoles: ["CC24User"]
    }
  }),
];
const reportOperatorRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Snapshot",
    path: "/operator-snapshot",
    title: "Analytics | Operator Snapshot",
    pageHeader: "Operator Snapshot",
    component: OperatorSnapshot,
  }),
  defineAuthedRoute({
    name: "Analysis",
    path: "/operator-analysis",
    title: "Analytics | Operator Analysis",
    pageHeader: "Operator Analysis",
    component: OperatorAnalysis,
  }),
  defineAuthedRoute({
    name: "Timeclock",
    path: "/operator-timeclock",
    title: "Analytics | Operator Timeclock",
    pageHeader: "Operator Timeclock",
    component: OperatorTimeclock,
  }),
  defineAuthedRoute({
    name: "Missed Chat Details",
    path: "/operator-missed-chat",
    title: "Analytics | Missed Chat Details",
    pageHeader: "Missed Chat Details",
    component: OperatorMissedChat,
    guard: { allowedRoles: ["CC24Admin", "CC24SalesManager", "CC24User"] }
  }),
  defineAuthedRoute({
    name: "Performance",
    path: "/operator-performance",
    title: "Analytics | Operator Performance",
    pageHeader: "Operator Performance",
    component: OperatorPerformance,
    guard: { allowedRoles: ["CC24Admin", "CC24SalesManager", "CC24User"] }
  }),
];
const reportLeadRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Snapshot",
    path: "/lead-snapshot",
    title: "Analytics | Lead Snapshot",
    pageHeader: "Lead Snapshot",
    component: LeadSnapshot,
    guard: { exceptRoles: ["CC24User"] }
  }),
  defineAuthedRoute({
    name: "Analysis",
    path: "/lead-analysis",
    title: "Analytics | Lead Analysis",
    pageHeader: "Lead Analysis",
    component: LeadAnalysis
  }),
  defineAuthedRoute({
    name: "Offline Lead",
    path: "/lead-offline",
    title: "Analytics | Offline Lead",
    pageHeader: "Offline Lead",
    component: OfflineLead,
    guard: { exceptRoles: ["CC24User"] }
  }),
];
const reportVisitorRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Snapshot",
    path: "/visitor-snapshot",
    title: "Analytics | Visitor Snapshot",
    pageHeader: "Visitor Snapshot",
    component: VisitorSnapshot
  }),
  defineAuthedRoute({
    name: "Blocked",
    path: "/visitor-block",
    title: "Analytics | Blocked Visitor",
    pageHeader: "Blocked Visitor",
    component: VisitorBlock
  }),
];

const blockRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "IP Blacklist",
    path: "/ip-blacklist",
    title: "Block | IP Blacklist",
    pageHeader: "IP Blacklist",
    component: IPBlacklist
  }),
  defineAuthedRoute({
    name: "IP Whitelist",
    path: "/ip-whitelist",
    title: "Block | IP Whitelist",
    pageHeader: "IP Whitelist",
    component: IPWhitelist
  }),
  defineAuthedRoute({
    name: "Location/Phone",
    path: "/location-phone",
    title: "Block | Location/Phone",
    pageHeader: "Location/Phone",
    component: BlockLocationPhone
  }),
];

const TenDlCRoutes = [
  defineAuthedRoute({
    name: "Brand Campaign",
    path: "/brand-campaign",
    title: "10DLC",
    pageHeader: "Register Brand & Campaign",
    component: BrandCampaignDetails
  }),
  defineAuthedRoute({
    name: "Phone Number",
    path: "/phone-number",
    title: "10DLC | Register Phone Number",
    pageHeader: "Register Phone Number",
    component: TenDlcNumberDetails
  }),
];

const RoutingRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Department",
    path: "/department",
    title: "Routing | Department",
    pageHeader: "Department",
    component: DeptBasedRouting
  }),

];

const reportRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Overview",
    path: "/",
    exact: true,
    title: "Analytics | Overview",
    component: ReportOverview,
    guard: { exceptRoles: ["CC24User"] },
  }),
  defineAuthedRoute({
    name: "Top 10",
    path: "/top-10",
    title: "Analytics | Top 10",
    component: Top10,
    guard: { exceptRoles: ["CC24User"] },

  }),
  defineAuthedRoute({
    name: "Chat",
    path: "/chat",
    title: "Chat",
    component: ChildRouteRender,
    routes: reportChatRoutes,
  }),
  defineAuthedRoute({
    name: "Operator",
    path: "/operator",
    title: "Operator",
    component: ChildRouteRender,
    routes: reportOperatorRoutes,
    guard: { exceptSubscriptions: ["FullSupport"] }
  }),
  defineAuthedRoute({
    name: "Lead",
    path: "/lead",
    title: "Lead",
    component: ChildRouteRender,
    routes: reportLeadRoutes,
  }),
  defineAuthedRoute({
    name: "Visitor",
    path: "/visitor-snapshot",
    title: "Visitor",
    component: ChildRouteRender,
    routes: reportVisitorRoutes,
    guard: { exceptRoles: ["CC24User"] },

  }),
  defineAuthedRoute({
    name: "Survey Analysis",
    path: "/survey-analysis",
    title: "Analytics | Survey Analysis",
    component: SurveyAnalysis,
    guard: {
      exceptSubscriptions: ["FullSupport"],
      exceptRoles: ["CC24User"]
    }
  }),
];
/* end /report */

/* start /settings */
const settingsRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "General",
    path: "/general",
    title: "Settings | General",
    component: GeneralSettings,
  }),
  defineAuthedRoute({
    name: "Sites",
    pageHeader: "Manage Sites",
    path: "/sites",
    title: "Settings | Manage Sites",
    component: SettingsSites,
  }),
  defineAuthedRoute({
    name: "Block",
    path: "/block",
    title: "Block Settings",
    component: ChildRouteRender,
    routes: blockRoutes
  }),
  defineAuthedRoute({
    name: "10DLC",
    path: "/ten-dlc",
    title: "10DLC",
    component: ChildRouteRender,
    routes: TenDlCRoutes,
    guard: { allowedRoles: ["CC24Admin"] }

  })
  // defineAuthedRoute({
  //   name: "Routing",
  //   path: "/routing",
  //   title: "Routing",
  //   component: ChildRouteRender,
  //   routes: RoutingRoutes
  // })
];
/* end /settings */

/* start /reputation-management */
const reputationManagementRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Reviews",
    path: "/reviews",
    title: "Reviews",
    component: ReputationManagementReviews,
  }),
];

/* end /reputation-management */

/* start /salesportal */
const salesPortalRoutes: RouteConfig[] = [
  defineAuthedRoute({
    name: "Monitor",
    path: "/sales-portal-monitor",
    title: "Sales Portal | Monitor",
    component: SalesPortalMonitor
  }),
  defineAuthedRoute({
    name: "Agreement",
    path: "/sales-portal-agreement",
    title: "Sales Portal | Agreement",
    component: SalesPortalAgreement
  }),
  defineAuthedRoute({
    name: "Manage",
    path: "/sales-portal-manage",
    title: "Sales Portal | Manage",
    component: SalesPortalManage
  }),
];

/* end /salesportal */

const mainRoute: RouteConfig[] = [
  // defineAuthedRoute({
  //   name: "Home",
  //   path: "/",
  //   title: "Home",
  //   exact: true,
  //   // component: AdminDashboard,
  //   component: WorkInProgress,
  // }),

  defineInvisibleRoute({
    name: "redirect",
    path: "/",
    exact: true,
    render: () => <Redirect to="/dashboard/subscriber" />,
  }),

  defineRouteWithCc24AdminRole({
    name: "Manage Subscriber",
    path: "/subscriber",
    title: "Manage Subscriber",
    component: ManageSaas,
    exact: true,
    icon: <GroupRoundedIcon fontSize="small" />
  }),
  defineRouteWithCc24AdminRole({
    name: "Manage Group",
    path: "/subscriber",
    title: "Manage Group",
    component: ManageSaas,
    exact: true,
    guard: { allowedRoles: ["DealerGroupAdmin"] },
    icon: <GroupRoundedIcon fontSize="small" />
  }),
  defineRouteWithCc24AdminRole({
    name: "Dashboard",
    path: "/subscriber",
    title: "Operator Console",
    component: WorkInProgress,
    exact: true,
    guard: { allowedRoles: ["CC24User"] },
    icon: <GroupRoundedIcon fontSize="small" />
  }),
  defineRouteWithChildRouteRenderer(
    defineRouteWithCc24AdminRole(
      defineInvisibleRoute({
        name: "All Subscriber",
        path: "/subscriber/:id",
        title: "All Subscribers",
        routes: [...subscriberRoutes],
        guard: { allowedRoles: ["CC24Admin", "CC24PerformanceManager", "CC24SalesManager", "DealerGroupAdmin"] }
      })
    )
  ),
  defineRouteWithChildRouteRenderer(
    defineAuthedRoute({
      name: "Sales Portal",
      path: "/sales-portal",
      routes: salesPortalRoutes,
      guard: { allowedRoles: ["CC24Admin", "CC24PerformanceManager", "CC24SalesManager"] },
      icon: <BusinessCenterRoundedIcon fontSize="small" />
    })
  ),
  defineRouteWithChildRouteRenderer(
    defineAuthedRoute({
      name: "Analytics",
      path: "/analytics",
      routes: reportRoutes,
      guard: { allowedRoles: ["CC24Admin", "CC24PerformanceManager", "CC24SalesManager", "CC24User"] },
      icon: <ShowChartRoundedIcon fontSize="small" />
    })
  ),
  defineRouteWithChildRouteRenderer(
    defineAuthedRoute({
      name: "Reputation",
      path: "/reputation",
      routes: reputationManagementRoutes,
      guard: { allowedRoles: ["DealerAdmin", "DealerUser"] },
      icon: <ThumbUpRoundedIcon fontSize="small" />
    })
  ),
  defineRouteWithChildRouteRenderer(
    defineAuthedRoute({
      name: "Settings",
      path: "/settings",
      routes: settingsRoutes,
      guard: { allowedRoles: ["CC24Admin"] },
      icon: <SettingsRoundedIcon fontSize="small" />
    })
  ),
  defineRouteWithCc24AdminRole({
    name: "My Profile",
    path: "/profile",
    title: "My Profile",
    component: MyProfile,
    bypassDisplay: true,
    exact: true,
    guard: { allowedRoles: ["CC24Admin", "CC24PerformanceManager", "CC24SalesManager", "DealerGroupAdmin", "CC24User"] }
  }),

];

export default mainRoute;
