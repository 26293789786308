import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { UserRightKeys } from "../model/types";
import { selectUser } from "../store/modules/authentication";
import { queryToObject } from "../utils/uri-utils";

export function useQuery(): { [x: string]: string } {
    return queryToObject(useLocation().search);
}

export function useQueryWithType<T>(): T {
    return queryToObject(useLocation().search);
}

export function useMounted(): boolean {
    const ref = useRef<boolean>(false);
    useEffect(() => {
        ref.current = true;
        return () => {
            ref.current = false;
        };
    }, []);
    return ref.current;
}

export function useHasUserRightFor(operator: "&" | "|" = "&", ...params: UserRightKeys[]) {
    const { user } = useSelector(selectUser);
    return useMemo(() => {
        switch (operator) {
            case "&":
                return params.every(r => user?.rights.includes(r));
            case "|":
                return params.some(r => user?.rights.includes(r));
            default:
                return false;
        }
    }, [user]);
}