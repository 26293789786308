import React from "react";
import { useSelector } from "react-redux";
import { useQuery } from "../../../hooks/custom-router-hooks";
import { ApiData } from "../../../model/xhrCallState";
import httpService from "../../../service/http-service";
import { selectUser } from "../../../store/modules/authentication";
import logger from "../../../utils/log-utils";
import { AgreementTableData } from "../models/response";

export type IniValue = {
    subscriberName: string,
    startDate: string,
    endDate: string,
    subscriptionType: number,
    subscriptionPlan: number,
    backupSupport: number,
    createdBy: string
}
export default function useAgreementTranscript() {
    const [agreementTable, setAgreementTable] = React.useState<ApiData<string>>(new ApiData());
    const { user } = useSelector(selectUser);
    // const [agreementData, setAgreementData] = React.useState<AgreementTableData>();
    // const [qparam, SetQparam] = React.useState<IniValue>();

    const query = useQuery();

    React.useEffect(() => {
        if (user) getAgreementTableData(query.subscriberId);
    }, [user]);

    const getAgreementTableData = async (subscriberId: string) => {
        setAgreementTable(new ApiData("Pending"));
        try {
            const response = await httpService.get<{ Table: AgreementTableData[] }>(`/SalesPortal/SearchSalesPortalSubscriberInfo?SubscriberId=${subscriberId}`);
            // setSearchText("");
            // &StartDate=${values.startDate}&EndDate=${values.endDate}
            const table = response.data?.Table;
            setAgreementTable({ status: "Completed", data: table?.[0]?.AgreementData });
        } catch (error) {
            setAgreementTable(new ApiData("Failed"));
            logger.log(error);
        }
    };

    return {
        getAgreementTableData, agreementTable,
    };
}
