export interface ChatWindowNotification {
    ID: string;
    SubscriberID: string;
    SiteID: string;
    MessageTitle: string;
    MessageBody: string;
    StartDate: Date;
    EndDate: Date;
    Color: string;
    TextColor: string;
    Priority: _InstructionPriority;
}
export enum _InstructionPriority {
    High = 0,
    Normal = 1,
    Low = 2,
    All = 99
}

export class ChatWindowNotificationSearchParam {
    SubscriberID: string = '';
    SiteID: any = null;
    ID: string = '00000000-0000-0000-0000-000000000000';
}
