import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { PublicLayout } from '../../layouts/public-layout';
import { RouteAdditionalPropsV2 } from '../../model/route-additional-propsV2';
import configuration from '../../utils/configuration';
import { getFullBrandLogo } from '../../utils/image-util';
import useAgreementTranscript from './hooks/useAgreementTranscript';

export interface AgreementTranscriptProps extends RouteAdditionalPropsV2 {

}

const AgreementTranscript: React.FC<AgreementTranscriptProps> = (props) => {
    const { agreementTable } = useAgreementTranscript();
    return (
        <PublicLayout {...props}>
            <div className="agreement-transcript">
                <div className="page">
                    <div className="header flex-row flex-center space-between">
                        <img alt={configuration.getValue("productName")} src={getFullBrandLogo(configuration.getValue("productName"))} />

                    </div>
                    {
                        agreementTable?.status === "Pending" &&
                        <div className="t-m-8 b-m-8 flex-column flex-center">
                            <CircularProgress size={40} color="secondary" />
                        </div>
                    }
                    {agreementTable?.data &&
                        <div className="content-body" dangerouslySetInnerHTML={{ __html: agreementTable?.data }} />}
                </div>
            </div>
        </PublicLayout>
    );
};

export default AgreementTranscript;
