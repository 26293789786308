import * as React from "react";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { RouteAdditionalPropsV2 } from "../../model/route-additional-propsV2";
import ErrorBoundary from "../../components/error-boundary";
import configuration from "../../utils/configuration";
import { selectSubscriberInfo } from "../../store/modules/subscriber";

export interface PageWithHeaderProps extends RouteAdditionalPropsV2 {
    title?: string;
    headerContent?: React.ReactNode;
    bodyClasses?: string;
    bodyStyle?: React.CSSProperties;
    errorResetKeys?: unknown[];
    onReset?: (...args: unknown[]) => void;
}

const PageWithHeaderFooter: React.FC<PageWithHeaderProps> = (props) => {
    const PageTitle = props.routeInfo?.title || props.title;
    const { currentSubscriber } = useSelector(selectSubscriberInfo);
    return (
        <>
            <Helmet>
                <title>{currentSubscriber ? `${currentSubscriber?.SubscriberName} | ${PageTitle}` : PageTitle || "Web Panel | Chatlead Inc"}</title>
                <meta name="description" content={`${configuration.getValue("productName")} Web Control Panel`} />
            </Helmet>
            <div className="flex-grow page-wrapper flex-column flex-grow">
                <div className="page-header">
                    {props.headerContent || props.routeInfo.pageHeader || props.routeInfo.name}
                </div>
                <ErrorBoundary resetKeys={props.errorResetKeys} onReset={props.onReset}>
                    <main className="flex-grow flex-column">
                        <div
                            className={clsx("page-body", props.bodyClasses)}
                            style={props.bodyStyle}
                        >
                            {props.children}
                        </div>
                    </main>
                </ErrorBoundary>
                <footer>
                    <div className="page-footer">
                        Copyright © {new Date().getFullYear()} ChatLead.com, Inc., {configuration.getValue("brandName")} - All Rights
                        Reserved.
                    </div>
                </footer>
            </div>
        </>
    );
};

export default PageWithHeaderFooter;
